import React, { useContext, useState, useEffect } from 'react';
import useDebounce from '../../../../hooks/useDebounce';

import './Components.css';

import PropTypes from 'prop-types';

import Vehicles from '../Vehicles/Vehicles';
import Drivers from '../Drivers/Drivers';
import TripPoints from '../TripPoints/TripPoints';

import moment from 'moment';

// grahpql queries
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import {
	GET_DRIVERS,
	GET_VEHICLES,
	CHECK_DUPLICATE_DRIVERS,
	CHECK_DUPLICATE_VEHICLES,
	GET_DISTINCT_MAKE,
	GET_DISTINCT_MODEL,
} from '../../graphql/query';

// contexts
import LoaderContext from '../../../../context/LoaderContext/LoaderContext';
import ParserContext from '../../../../context/ParserContext/ParserContext';

import { Tab } from 'semantic-ui-react';

import {
	chunkArray,
	isEmail,
	encrypt,
	stringSanitizeNoLowerCase,
	isPersonName,
	isAlphaNumSpace,
	isMobileNum,
} from '../../../../utils';

import { Days } from '../../../../models';

const Components = ({
	props,
	paginate,
	setPaginate,
	onPageChange,
	onTabChange,
}) => {
	const client = useApolloClient();
	const { showLoading, hideLoading } = useContext(LoaderContext);
	const {
		fileData,
		setFileError,
		fileError,
		setFileData,
		setExcelFile,
		setError,
	} = useContext(ParserContext);
	const [parsedDatas, setParsedDatas] = useState([]);
	const [defaultActiveIndex, setDefaultActiveIndex] = useState(0);

	const [makes, setMakes] = useState([]);
	const [models, setModels] = useState([]);
	const [regularSort, setRegularSort] = useState(true);
	/** Drivers */
	const [drivers, setDrivers] = useState({
		drivers: [],
		total_drivers: 0,
	});

	const [driverFilter, setDriverFilter] = useState({
		// drivers
		fullname: '',
		status: null,
		restday: null,
	});

	useEffect(() => {
		getDrivers('no-cache');
	}, [useDebounce(driverFilter, 500), paginate.page]);
	/** End Drivers */

	/** Vehicles */
	const [vehicles, setVehicles] = useState({
		vehicles: [],
		total_vehicles: 0,
	});

	const [vehicleFilter, setVehicleFilter] = useState({
		// vehicles
		plate_number: '',
		status: 'all',
		coding_day: 'all',
		make: 'all',
		model: 'all',
	});

	const [searchMakeString, setSearchMakeString] = useState(null);
	const debouncedSearchMakeString = useDebounce(searchMakeString, 500);
	const [makeLoading, setMakeLoading] = useState(false);

	const [searchModelString, setSearchModelString] = useState(null);
	const debouncedSearchModelString = useDebounce(searchModelString, 500);
	const [modelLoading, setModelLoading] = useState(false);

	// when typing and searching on make option
	const onFilterMakesDropdown = evt => {
		setSearchMakeString(evt.target.value);
	};

	// when typing and searching on make option
	const onFilterModelsDropdown = evt => {
		setSearchModelString(evt.target.value);
	};

	useEffect(() => {
		getDistinctMake();
	}, [debouncedSearchMakeString]);

	useEffect(() => {
		getDistinctModel();
	}, [debouncedSearchModelString]);

	/* useEffect(() => {
		getDistinctMake();
	}, [getDistinctMake]); */

	useEffect(() => {
		getVehicles('no-cache');
	}, [useDebounce(vehicleFilter, 500), paginate.page]);
	/** End Vehicles */

	/** Start driver related functions */
	const getDrivers = async (fetchPolicy = null) => {
		showLoading(`Loading...`);
		let options = {
			query: GET_DRIVERS,
			variables: {
				input: {
					...driverFilter,
					fullname: driverFilter.fullname,
					status: driverFilter.status,
					restday: driverFilter.restday,
					regular_sort: regularSort,
				},
				service_instances_id:
					props.location.state.site[0].service_instances_id,
				// sort: sort_obj[0],
				paginate,
			},
		};

		if (fetchPolicy) options = { ...options, fetchPolicy };
		const result = await client.query(options);

		setDrivers({
			drivers: result.data.getDrivers.drivers,
			total_drivers: result.data.getDrivers.total,
		});

		hideLoading();
	};

	const handleChangeFilter = (tab, key, value) => {
		if (tab == 'vehicle') {
			setVehicleFilter({
				...vehicleFilter,
				[key]: value,
			});
		} else if (tab == 'driver') {
			setDriverFilter({
				...driverFilter,
				[key]: value,
			});
		} else if (tab == 'tripPoint') {
			// TODO
		}

		setPaginate({
			...paginate,
			page: 1,
		});
	};
	/** End driver related functions */

	useEffect(() => {
		// clean up
		return () => {
			clearForm();
		};
	}, []);

	// parsing excel file when fileData changes
	useEffect(() => {
		/* if (fileData && fileData.length <= 0) {
			clearForm();
		} */
		formatParseData();
	}, [fileData]);
	const formatParseData = async () => {
		let parsed_datas = [];
		let err = null;
		let updatedParsedData;

		if (fileData && fileData.length > 0) {
			const fileIndex = fileData.length - 1;
			const parsedData = fileData[fileIndex];

			if (parsedData.data.length <= 3) {
				err = 'File must have at least one row/line data';
			}
			if (parsedData.data && parsedData.data.length > 0) {
				for (let i = 0; i < parsedData.data.length; i++) {
					const rows = parsedData.data[i];
					if (err === null && i > 2) {
						if (i >= 3) {
							if (
								rows.includes('') ||
								rows.includes(null) ||
								rows.includes(undefined)
							) {
								err = 'We found error/s on your uploaded file';
								break;
							}

							if (parsedData.id === 'upload-vehicles') {
								// check row length
								// if capacity is not a number
								// or if capacity is less than or equal to zero
								// or coding_day value is not parseable in moment
								if (
									rows.length !== 5 ||
									isAlphaNumSpace(rows[0]) == false ||
									isNaN(rows[3]) ||
									rows[3] <= 0 ||
									!Days.some(
										day =>
											day.name.toLowerCase() ==
											rows[4].trim().toLowerCase()
									) ||
									['sunday', 'saturday'].includes(
										rows[4].toLowerCase()
									)
								) {
									err =
										'We found error/s on your uploaded file';
									break;
								}

								parsed_datas.push({
									plate_number: rows[0].trim(),
									make: rows[1],
									model: rows[2],
									capacity: rows[3],
									coding_day: rows[4].trim(),
								});
							} else if (parsedData.id === 'upload-drivers') {
								if (rows.length !== 7) {
									err =
										'We found error/s on your uploaded file';

									break;
								}

								let invalidRestDays;
								let rest_days = stringSanitizeNoLowerCase(
									rows[5] ? rows[5] : ''
								).split(',');

								rest_days.forEach((restday, i) => {
									invalidRestDays = Days.filter(
										day =>
											day.name.toLowerCase() ==
											restday.toLowerCase()
									);
								});
								if (
									// isPersonName(rows[1]) == false ||
									// isPersonName(rows[2]) == false ||
									moment(
										rows[3],
										'DD/MM/YYYY',
										true
									).isValid() == false ||
									isMobileNum(rows[4]) == false ||
									isEmail(rows[6]) == false ||
									invalidRestDays.length == 0
								) {
									err =
										'We found error/s on your uploaded file';
									break;
								}

								parsed_datas.push({
									driver_id: rows[0],
									last_name: rows[1],
									first_name: rows[2],
									birthdate: rows[3],
									mobile_no: rows[4],
									rest_days: rows[5],
									email_address: rows[6],
								});
							}
						}
					}
				}

				if (!err) {
					let chunkedDatas = chunkArray(parsed_datas, 1000);

					for (
						let chunkedIndex = 0;
						chunkedIndex < chunkedDatas.length;
						chunkedIndex++
					) {
						showLoading(
							`Parsing...${
								chunkedDatas.length > 1
									? chunkedIndex + '/' + chunkedDatas.length
									: ''
							}`
						);

						let duplicateDataEntry = null;
						if (parsedData.id === 'upload-vehicles') {
							const vehiclesArray = chunkedDatas[
								chunkedIndex
							].map(vehicle => {
								return {
									plate_number: vehicle.plate_number,
								};
							});
							const duplicateVehiclesQuery = await client.query({
								query: CHECK_DUPLICATE_VEHICLES,
								fetchPolicy: 'network-only',
								variables: {
									sites_id: props.location.state.site[0].id,
									vehicles: vehiclesArray,
								},
							});
							duplicateDataEntry =
								duplicateVehiclesQuery.data
									.checkDuplicateVehicle;
						} else {
							const duplicateDriversQuery = await client.query({
								query: CHECK_DUPLICATE_DRIVERS,
								fetchPolicy: 'network-only',
								variables: {
									service_instances_id:
										props.location.state.site[0]
											.service_instances_id,
									drivers: chunkedDatas[chunkedIndex],
								},
							});
							duplicateDataEntry =
								duplicateDriversQuery.data
									.checkDuplicateDrivers;
						}

						if (!duplicateDataEntry.status) {
							err = 'We found error/s on your uploaded file';
							setFileError([
								...fileError,
								{
									id: parsedData.id,
									fileError: err,
								},
							]);
							hideLoading();
							return false;
						}

						hideLoading();
					}
				}

				if (
					Object.prototype.hasOwnProperty.call(
						parsedDatas,
						parsedData.id
					)
				) {
					updatedParsedData = {
						[parsedData.id]: parsed_datas,
					};
				} else {
					updatedParsedData = {
						...parsedDatas,
						[parsedData.id]: parsed_datas,
					};
				}
			} else {
				updatedParsedData = {
					[parsedData.id]: [],
				};

				err = 'We found error/s on your uploaded file';
			}

			if (err) {
				updatedParsedData = {
					[parsedData.id]: [],
				};
				setFileError([
					...fileError,
					{
						id: parsedData.id,
						fileError: err,
					},
				]);
			} else {
				setParsedDatas(updatedParsedData);
			}
		}
	};
	const clearForm = () => {
		setFileData([]);
		setError([]);
		setFileError([]);
		setParsedDatas([]);
		setExcelFile([]);
	};

	/** Vehicle related functions */
	const getVehicles = async (fetchPolicy = null) => {
		showLoading(`Loading...`);
		let options = {
			query: GET_VEHICLES,
			variables: {
				input: {
					...vehicleFilter,

					plate_number: vehicleFilter.plate_number,
					status: vehicleFilter.status,
					coding_day: vehicleFilter.coding_day,
					make: vehicleFilter.make,
					model: vehicleFilter.model,
					regular_sort: regularSort,
				},
				service_instances_id:
					props.location.state.site[0].service_instances_id,
				paginate,
			},
		};

		if (fetchPolicy) options = { ...options, fetchPolicy };
		const result = await client.query(options);

		setVehicles({
			vehicles: result.data.getVehicles.vehicles,
			total_vehicles: result.data.getVehicles.total,
		});

		hideLoading();
	};
	/** End vehicle related functions */
	const tabPanes = [
		{
			menuItem: 'Vehicles',
			render: () => (
				<Tab.Pane attached={false}>
					<Vehicles
						props={props}
						vehicles={vehicles}
						getVehicles={getVehicles}
						vehicleFilter={vehicleFilter}
						paginate={paginate}
						onPageChange={onPageChange}
						handleChangeFilter={handleChangeFilter}
						regularSort={regularSort}
						setRegularSort={setRegularSort}
						parsedDatas={parsedDatas}
						clearForm={clearForm}
						makes={makes}
						models={models}
						getDistinctMake={getDistinctMake}
						getDistinctModel={getDistinctModel}
						setPaginate={setPaginate}
						onTabChange={onTabChange}
						onFilterMakesDropdown={onFilterMakesDropdown}
						makeLoading={makeLoading}
						setSearchMakeString={setSearchMakeString}
						onFilterModelsDropdown={onFilterModelsDropdown}
						modelLoading={modelLoading}
						setSearchModelString={setSearchModelString}
					/>
				</Tab.Pane>
			),
		},
		{
			menuItem: 'Drivers',
			render: () => (
				<Tab.Pane attached={false}>
					<Drivers
						props={props}
						drivers={drivers}
						getDrivers={getDrivers}
						driverFilter={driverFilter}
						paginate={paginate}
						onPageChange={onPageChange}
						handleChangeFilter={handleChangeFilter}
						regularSort={regularSort}
						setRegularSort={setRegularSort}
						parsedDatas={parsedDatas}
						clearForm={clearForm}
						setPaginate={setPaginate}
						onTabChange={onTabChange}
					/>
				</Tab.Pane>
			),
		},
		// Commenting this out as Trip Points is OOS
		/* {
			menuItem: 'Trip points',
			render: () => (
				<Tab.Pane attached={false}>
					<TripPoints />
				</Tab.Pane>
			),
		}, */
	];

	const onComponentTabChange = (evt, tab) => {
		setPaginate({
			...paginate,
			page: 1,
		});
		setVehicleFilter({
			plate_number: '',
			status: 'all',
			coding_day: 'all',
			make: 'all',
			model: 'all',
		});
		setDriverFilter({
			fullname: '',
			status: null,
			restday: null,
		});
		setSearchMakeString('');
		setSearchModelString('');
	};

	const getDistinctMake = async () => {
		setMakeLoading(true);
		const makes = await client.query({
			query: GET_DISTINCT_MAKE,
			fetchPolicy: 'network-only',
			variables: {
				service_instances_id:
					props.location.state.site[0].service_instances_id,
				filter: debouncedSearchMakeString,
			},
		});
		await setMakes(
			makes.data.getDistinctMake.map(make => {
				return {
					id: encrypt(make.name),
					text: make.name,
					value: make.name,
				};
			})
		);
		setMakeLoading(false);
	};

	const getDistinctModel = async () => {
		setModelLoading(true);
		const models = await client.query({
			query: GET_DISTINCT_MODEL,
			fetchPolicy: 'network-only',
			variables: {
				service_instances_id:
					props.location.state.site[0].service_instances_id,
				filter: debouncedSearchModelString,
			},
		});
		await setModels(
			models.data.getDistinctModel.map(model => {
				return {
					id: encrypt(model.name),
					text: model.name,
					value: model.name,
				};
			})
		);
		setModelLoading(false);
	};

	return (
		<div>
			<div className="sub-title-shuttle-date">
				<p>Components</p>
			</div>
			<div className="tab-inner">
				<Tab
					defaultActiveIndex={defaultActiveIndex}
					className="service-dashboard-site-inner-tab"
					panes={tabPanes}
					onTabChange={(evt, tab) => onComponentTabChange(evt, tab)}
				/>
			</div>
		</div>
	);
};

Components.propTypes = {
	props: PropTypes.any,
	paginate: PropTypes.object,
	setPaginate: PropTypes.func,
	onPageChange: PropTypes.func,
	location: PropTypes.any,
	history: PropTypes.any,
	match: PropTypes.any,
	onTabChange: PropTypes.func,
};

export default Components;

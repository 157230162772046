import React from 'react';
import { Form, Dropdown, Select, Input } from 'semantic-ui-react';

import Table from '../../../../../components/Table/Table';

import PropTypes from 'prop-types';

const searchImg = process.env.REACT_APP_BASE_NAME + 'svgs/search.svg';

export const ReportsTable = ({
	floors,
	filter,
	tableHeaders,
	tableDatas,
	paginate,
	slots,
	onPageChange,
	onFilterFloorsDropdowwn,
	handleChangeFilter,
	floorLoading,
	service,
	tripFilter,
	handleTripFilterChange,
	tripOrigins,
	tripDestinations,
	vehicles,
	drivers,
	onFilterVehiclePlateNumber,
	onFilterDriverName,
	vehiclesLoading,
	driversLoading,
	vehiclesKeyword,
	driversKeyword,
	searchFloorString,
	tripOriginLoading,
	onFilterOrigin,
	originsKeyword,
	tripDestinationLoading,
	onFilterDestination,
	destinationKeyword,
}) => {
	return (
		<div className="table-list">
			<div
				className={`table-list-filter ${
					service.service_type.name !== 'Shuttle'
						? 'workdesks-filter'
						: ''
				}`}
			>
				<Form>
					<Form.Group inline>
						{service.service_type.name !== 'Shuttle' ? (
							<>
								<label>Showing</label>
								<Form.Field width={2}>
									<Dropdown
										// icon={
										// 	<img
										// 		src={searchImg}
										// 		className="filter-searchIcon"
										// 	/>
										// }

										className="slot-search-filter"
										icon="angle down"
										selectOnBlur={false}
										placeholder="Floor #"
										fluid
										search
										selection
										options={[
											{
												id: 'all',
												text: 'All floors',
												value: '0',
												selected: false,
											},
											...floors,
										]}
										value={
											filter.floors_id
												? filter.floors_id
												: null
										}
										onSearchChange={onFilterFloorsDropdowwn}
										{...(filter.floors_id
											? {
													searchQuery: `${searchFloorString}`,
											  }
											: {})}
										onChange={(e, { value }) =>
											handleChangeFilter(
												'floors_id',
												value
											)
										}
									>
										{floorLoading ? (
											<div className="sk-chase search-loader">
												<div className="sk-chase-dot"></div>
												<div className="sk-chase-dot"></div>
												<div className="sk-chase-dot"></div>
												<div className="sk-chase-dot"></div>
												<div className="sk-chase-dot"></div>
												<div className="sk-chase-dot"></div>
											</div>
										) : null}
									</Dropdown>
								</Form.Field>
							</>
						) : (
							<>
								<Form.Field>
									<Input
										icon={
											<img
												src={searchImg}
												className="filter-search-icon"
											/>
										}
										placeholder="Type search here"
										iconPosition="left"
										onChange={e =>
											handleTripFilterChange(
												'keyword',
												e.target.value
											)
										}
										value={
											tripFilter.keyword
												? tripFilter.keyword
												: ''
										}
									/>
								</Form.Field>
								<label>Showing</label>
								{/* <Form.Field
									control={Select}
									selectOnBlur={false}
									{...(tripFilter.origin === null
										? {
												text: 'Select origin',
										  }
										: {})}
									onChange={(e, { value }) =>
										handleTripFilterChange('origin', value)
									}
									style={{ minWidth: '250px' }}
									options={[
										{
											key: 0,
											value: 0,
											text: 'All origins',
										},
										...trip_points.map(trip_point => {
											return {
												key: trip_point.id,
												text: trip_point.name,
												value: trip_point.id,
											};
										}),
									]}
									className="filter-select"
									icon="angle down"
									placeholder="Select origin"
									name="status"
								/> */}
								<Dropdown
									className="slot-search-filter reports-dropdown"
									noResultsMessage={
										tripOriginLoading &&
										tripOrigins.length <= 0
											? 'Searching...'
											: 'No results found.'
									}
									icon={
										tripOriginLoading ? (
											<div className="sk-chase search-loader">
												<div className="sk-chase-dot"></div>
												<div className="sk-chase-dot"></div>
												<div className="sk-chase-dot"></div>
												<div className="sk-chase-dot"></div>
												<div className="sk-chase-dot"></div>
												<div className="sk-chase-dot"></div>
											</div>
										) : (
											'angle down'
										)
									}
									selectOnBlur={false}
									placeholder="Select driver"
									{...(tripFilter.origin === null
										? {
												text: 'Select origin',
										  }
										: {})}
									// clearable
									fluid
									search
									selection
									options={[
										{
											key: 0,
											value: 0,
											text: 'All origins',
										},
										...tripOrigins.map(trip_point => {
											return {
												key: trip_point.id,
												text: trip_point.name,
												value: trip_point.id,
											};
										}),
									]}
									value={tripFilter.origin}
									onSearchChange={onFilterOrigin}
									{...(originsKeyword
										? {
												searchQuery: `${originsKeyword}`,
										  }
										: {})}
									onChange={(e, { value }) =>
										handleTripFilterChange(
											'origin',
											tripOrigins.findIndex(
												origin => origin.id === value
											) > -1
												? tripOrigins[
														tripOrigins.findIndex(
															origin =>
																origin.id ===
																value
														)
												  ]
												: value
										)
									}
								></Dropdown>
								{/* <Form.Field
									control={Select}
									selectOnBlur={false}
									{...(tripFilter.destination === null
										? {
												text: 'Select destination',
										  }
										: {})}
									onChange={(e, { value }) =>
										handleTripFilterChange(
											'destination',
											value
										)
									}
									style={{ minWidth: '250px' }}
									options={[
										{
											key: '0',
											value: '0',
											text: 'All destinations',
										},
										...tripDestinations.map(trip_point => {
											return {
												key: trip_point.id,
												text: trip_point.name,
												value: trip_point.id,
											};
										}),
									]}
									className="filter-select"
									icon="angle down"
									placeholder="Select destination"
									name="status"
								/> */}
								<Dropdown
									className="slot-search-filter reports-dropdown"
									noResultsMessage={
										tripDestinationLoading &&
										tripDestinations.length <= 0
											? 'Searching...'
											: 'No results found.'
									}
									icon={
										tripDestinationLoading ? (
											<div className="sk-chase search-loader">
												<div className="sk-chase-dot"></div>
												<div className="sk-chase-dot"></div>
												<div className="sk-chase-dot"></div>
												<div className="sk-chase-dot"></div>
												<div className="sk-chase-dot"></div>
												<div className="sk-chase-dot"></div>
											</div>
										) : (
											'angle down'
										)
									}
									selectOnBlur={false}
									placeholder="Select driver"
									{...(tripFilter.destination === null
										? {
												text: 'Select destination',
										  }
										: {})}
									// clearable
									fluid
									search
									selection
									options={[
										{
											key: 0,
											value: 0,
											text: 'All destinations',
										},
										...tripDestinations.map(trip_point => {
											return {
												key: trip_point.id,
												text: trip_point.name,
												value: trip_point.id,
											};
										}),
									]}
									value={tripFilter.destination}
									onSearchChange={onFilterDestination}
									{...(destinationKeyword
										? {
												searchQuery: `${destinationKeyword}`,
										  }
										: {})}
									onChange={(e, { value }) =>
										handleTripFilterChange(
											'destination',
											tripDestinations.findIndex(
												destination =>
													destination.id === value
											) > -1
												? tripDestinations[
														tripDestinations.findIndex(
															destination =>
																destination.id ===
																value
														)
												  ]
												: value
										)
									}
								></Dropdown>
								{/* <Form.Field
									control={Select}
									selectOnBlur={false}
									forceSelection={false}
									{...(tripFilter.vehicle === null
										? {
												text: 'Select vehicle',
										  }
										: {})}
									style={{ minWidth: '250px' }}
									options={[
										{
											key: 0,
											value: 0,
											text: 'All vehicles',
										},
										...vehicles.map(vehicle => {
											return {
												key: vehicle.id,
												text: `${vehicle.make} ${vehicle.model} ${vehicle.plate_number}`,
												value: vehicle.id,
											};
										}),
									]}
									value={tripFilter.vehicle}
									onChange={(e, { value }) =>
										handleTripFilterChange('vehicle', value)
									}
									className="filter-select"
									icon="angle down"
									placeholder="Select vehicle"
									name="status"
								/> */}
								<Dropdown
									className="slot-search-filter reports-dropdown"
									noResultsMessage={
										vehiclesLoading && vehicles.length <= 0
											? 'Searching...'
											: 'No results found.'
									}
									icon={
										vehiclesLoading ? (
											<div className="sk-chase search-loader">
												<div className="sk-chase-dot"></div>
												<div className="sk-chase-dot"></div>
												<div className="sk-chase-dot"></div>
												<div className="sk-chase-dot"></div>
												<div className="sk-chase-dot"></div>
												<div className="sk-chase-dot"></div>
											</div>
										) : (
											'angle down'
										)
									}
									selectOnBlur={false}
									placeholder="Select vehicle"
									{...(tripFilter.vehicle === null
										? {
												text: 'Select vehicle',
										  }
										: {})}
									// clearable
									fluid
									search
									selection
									options={[
										{
											key: 0,
											value: 0,
											text: 'All vehicles',
										},
										...vehicles.map(vehicle => {
											return {
												key: vehicle.id,
												text: `${vehicle.make} ${vehicle.model} ${vehicle.plate_number}`,
												value: vehicle.id,
											};
										}),
									]}
									value={tripFilter.vehicle}
									{...(vehiclesKeyword
										? {
												searchQuery: `${vehiclesKeyword}`,
										  }
										: {})}
									onSearchChange={onFilterVehiclePlateNumber}
									onChange={(e, { value }) => {
										handleTripFilterChange(
											'vehicle',
											vehicles.findIndex(
												vehicle => vehicle.id === value
											) > -1
												? vehicles[
														vehicles.findIndex(
															vehicle =>
																vehicle.id ===
																value
														)
												  ]
												: value
										);
									}}
								></Dropdown>
								{/* <Form.Field
									control={Select}
									selectOnBlur={false}
									forceSelection={false}
									{...(tripFilter.driver === null
										? {
												text: 'Select vehicle',
										  }
										: {})}
									style={{ minWidth: '210px' }}
									options={[
										{
											key: 0,
											value: 0,
											text: 'All drivers',
										},
										...drivers.map(driver => {
											return {
												key: driver.id,
												text: `${driver.user.profile.first_name} ${driver.user.profile.last_name}`,
												value: driver.id,
											};
										}),
									]}
									value={tripFilter.driver}
									onChange={(e, { value }) =>
										handleTripFilterChange('driver', value)
									}
									className="filter-select"
									icon="angle down"
									placeholder="Select driver"
									name="status"
								/> */}
								<Dropdown
									className="slot-search-filter reports-dropdown"
									noResultsMessage={
										driversLoading && drivers.length <= 0
											? 'Searching...'
											: 'No results found.'
									}
									icon={
										driversLoading ? (
											<div className="sk-chase search-loader">
												<div className="sk-chase-dot"></div>
												<div className="sk-chase-dot"></div>
												<div className="sk-chase-dot"></div>
												<div className="sk-chase-dot"></div>
												<div className="sk-chase-dot"></div>
												<div className="sk-chase-dot"></div>
											</div>
										) : (
											'angle down'
										)
									}
									selectOnBlur={false}
									placeholder="Select driver"
									{...(tripFilter.driver === null
										? {
												text: 'Select driver',
										  }
										: {})}
									// clearable
									fluid
									search
									selection
									options={[
										{
											key: 0,
											value: 0,
											text: 'All drivers',
										},
										...drivers.map(driver => {
											return {
												key: driver.id,
												text: `${driver.user.profile.first_name} ${driver.user.profile.last_name}`,
												value: driver.id,
											};
										}),
									]}
									value={tripFilter.driver}
									onSearchChange={onFilterDriverName}
									{...(driversKeyword
										? {
												searchQuery: `${driversKeyword}`,
										  }
										: {})}
									onChange={(e, { value }) => {
										handleTripFilterChange(
											'driver',
											drivers.findIndex(
												driver => driver.id === value
											) > -1
												? drivers[
														drivers.findIndex(
															driver =>
																driver.id ===
																value
														)
												  ]
												: value
										);
									}}
								></Dropdown>
							</>
						)}
					</Form.Group>
				</Form>
			</div>
			<Table
				headers={tableHeaders}
				datas={tableDatas}
				page={paginate.page}
				totalRows={
					service &&
					service.service_type &&
					service.service_type.name === 'Shuttle'
						? slots.total_trips
						: slots.total_slots
				}
				noDataMsg={
					service &&
					service.service_type &&
					service.service_type.name === 'Shuttle'
						? `No trips available`
						: `No floors available`
				}
				onPageChange={onPageChange}
			/>
		</div>
	);
};

ReportsTable.propTypes = {
	floors: PropTypes.array,
	filter: PropTypes.object,
	tableHeaders: PropTypes.array,
	tableDatas: PropTypes.array,
	paginate: PropTypes.object,
	slots: PropTypes.object,
	onPageChange: PropTypes.func,
	onFilterFloorsDropdowwn: PropTypes.func,
	handleChangeFilter: PropTypes.func,
	floorLoading: PropTypes.any,
	service: PropTypes.object,
	tripFilter: PropTypes.object,
	handleTripFilterChange: PropTypes.func,
	tripOrigins: PropTypes.array,
	tripDestinations: PropTypes.array,
	vehicles: PropTypes.array,
	drivers: PropTypes.array,
	onFilterVehiclePlateNumber: PropTypes.func,
	onFilterDriverName: PropTypes.func,
	vehiclesLoading: PropTypes.bool,
	driversLoading: PropTypes.bool,
	vehiclesKeyword: PropTypes.any,
	driversKeyword: PropTypes.any,
	searchFloorString: PropTypes.string,
	tripOriginLoading: PropTypes.bool,
	onFilterOrigin: PropTypes.func,
	originsKeyword: PropTypes.string,
	tripDestinationLoading: PropTypes.bool,
	onFilterDestination: PropTypes.func,
	destinationKeyword: PropTypes.string,
};
export default ReportsTable;

// react lib
import React from 'react';

// react-router
import { NavLink, useHistory, useParams } from 'react-router-dom';

// hoc
// import LayoutPage from '../../hoc/Layout/Admin/AdminMaster';
import LayoutPage from '../../hoc/Layout/Admin/AdminMaster';

// styles
import './CompanyDashboard.css';

// semantic ui components
import { Container } from 'semantic-ui-react';

// prop-types for mapping props
import PropTypes from 'prop-types';

const CompanyDashboard = ({ children }) => {
	const params = useParams();
	const { location } = useHistory();
	return (
		// <LayoutPage title="Company Administrator">
		// 	<div className="company-dashboard">
		// 		<div className="navigation">
		// 			<Container className="navigation-content">
		// 				<NavLink
		// 					to="/companysite"
		// 					isActive={() => {
		// 						if (
		// 							location.pathname === '/companysite' ||
		// 							location.pathname ===
		// 								'/companysite-details/' +
		// 									params.encryptedId ||
		// 							location.pathname ===
		// 								'/new-service/' + params.sites_id
		// 						) {
		// 							return true;
		// 						}
		// 					}}
		// 					className="navigation-item"
		// 					activeClassName="navigation-item--active"
		// 				>
		// 					Office sites
		// 				</NavLink>
		// 				<NavLink to="/companysite" className="navigation-item">
		// 					G Access services
		// 				</NavLink>
		// 				<NavLink
		// 					to="/employeelist"
		// 					isActive={() => {
		// 						if (
		// 							location.pathname === '/employeelist' ||
		// 							location.pathname === '/add-new-employee/'
		// 						) {
		// 							return true;
		// 						}
		// 					}}
		// 					className="navigation-item"
		// 					activeClassName="navigation-item--active"
		// 				>
		// 					Employee list
		// 				</NavLink>
		// 				<NavLink to="/companysite" className="navigation-item">
		// 					App branding
		// 				</NavLink>
		// 				<NavLink to="/companysite" className="navigation-item">
		// 					App reports
		// 				</NavLink>
		// 			</Container>
		// 		</div>
		// 		{children}
		// 	</div>
		// </LayoutPage>
		<div></div>
	);
};

CompanyDashboard.propTypes = {
	children: PropTypes.any,
};

export default CompanyDashboard;

import React, { useContext } from 'react';
import { Button, Grid, Message } from 'semantic-ui-react';
import FileUpload from '../../../../components/FileUpload/FileUpload';
import ParserContext from '../../../../context/ParserContext/ParserContext';

const CompanySiteBulk = () => {
	const { fileError } = useContext(ParserContext);
	return (
		<Grid>
			<Grid.Column
				mobile="sixteen"
				tablet="fourteen"
				computer="six"
			></Grid.Column>
			<Grid.Column mobile="sixteen" tablet="fourteen" computer="seven">
				<FileUpload id="employees" />
				{fileError.some(file => file.id === 'employees') ? (
					<Message
						negative
						className="file-error-message"
						size="tiny"
					>
						{
							fileError.filter(file => file.id === 'employees')[0]
								.fileError
						}
					</Message>
				) : (
					<div className="fileupload-info">
						<div>
							<i>
								- File must be less than 3MB of .csv or .xlsx
								file
							</i>
						</div>
						{/* <div>
							<i>- CSV file or any Excel/Google sheet</i>
						</div> */}
					</div>
				)}
			</Grid.Column>
		</Grid>
	);
};

export default CompanySiteBulk;

// react lib
import React from 'react';

import PropTypes from 'prop-types';

// less (styling)
import './CompanyListManually.css';

// semantic ui component
import { Grid, Form, Button } from 'semantic-ui-react';

// imported images
const moreImg = process.env.REACT_APP_BASE_NAME + 'svgs/more.svg';
const closeImg = process.env.REACT_APP_BASE_NAME + 'svgs/close-blue.svg';

const CompanySiteManually = ({
	site,
	handleOnchange,
	addNewSite,
	removeSiteHandler,
}) => {
	return (
		<div>
			{site.map((siteVal, siteIndex) => (
				<div
					className={`step-highlight site-padding site-item ${
						site.length > 1 ? 'custom-step-highlight' : ''
					}`}
					key={siteIndex}
				>
					<div className="site-close">
						{site.length > 1 ? (
							<Button
								icon
								onClick={() => removeSiteHandler(siteIndex)}
								className="btn-remove"
							>
								<img
									src={closeImg}
									className="custom-close-icon"
								/>
							</Button>
						) : null}
					</div>
					<Grid>
						<Grid.Column
							width="ten"
							computer="10"
							tablet="16"
							mobile="16"
						>
							<Form.Field>
								<label>Site name</label>
								<input
									type="text"
									placeholder="Enter site name here"
									name="name"
									onChange={ev =>
										handleOnchange({
											property: 'name',
											key: siteIndex,
											value: ev.target.value,
										})
									}
									value={siteVal.name}
								/>
							</Form.Field>
						</Grid.Column>
						<Grid.Column
							width="six"
							computer="6"
							tablet="16"
							mobile="16"
						>
							<Form.Field>
								<label>Site abbreviation (if applicable)</label>
								<input
									type="text"
									placeholder="Enter site abbreviation"
									onChange={ev =>
										handleOnchange({
											property: 'abbrev',
											key: siteIndex,
											value: ev.target.value,
										})
									}
									value={siteVal.abbrev}
								/>
							</Form.Field>
						</Grid.Column>
						<Grid.Column width="sixteen">
							<Form.Field>
								<label>Site address</label>
								<input
									type="text"
									placeholder="Enter site address here"
									name="siteAddress"
									onChange={ev =>
										handleOnchange({
											property: 'address',
											key: siteIndex,
											value: ev.target.value,
										})
									}
									value={siteVal.address}
								/>
							</Form.Field>
						</Grid.Column>
						<Grid.Column
							width="eight"
							computer="6"
							tablet="16"
							mobile="16"
						>
							<Form.Field>
								<label>Floor/unit number (optional)</label>
								<input
									type="text"
									placeholder="Enter floor/unit no. here"
									onChange={ev =>
										handleOnchange({
											property: 'floor',
											key: siteIndex,
											value: ev.target.value,
										})
									}
									value={siteVal.floor}
								/>
							</Form.Field>
						</Grid.Column>
					</Grid>
				</div>
			))}

			<span className="add-site" onClick={() => addNewSite()}>
				<img src={moreImg} className="custom-more-icon" alt="add" />
				Add another site
			</span>
		</div>
	);
};

CompanySiteManually.propTypes = {
	site: PropTypes.array,
	handleOnchange: PropTypes.func,
	addNewSite: PropTypes.func,
	removeSiteHandler: PropTypes.func,
};

export default CompanySiteManually;

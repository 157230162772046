// react lib
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

import { useQuery, useApolloClient, useMutation } from '@apollo/client';
import { GET_SITE, GET_SITE_SERVICE } from '../graphql/query'; // from parent

// less (styling)
import './CompanySiteDetails.css';

// semantic ui component
import {
	Icon,
	Header,
	Grid,
	Input,
	Checkbox,
	Modal,
	Button,
} from 'semantic-ui-react';

// layout
import LayoutPage from '../../../hoc/Layout/Admin/AdminMaster';

// component
import Table from '../../../components/Table/Table';
import Page404 from '../../Page404/Page404';
import GLoader from '../../../components/Loader/Loader';
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';
import useDebounce from '../../../hooks/useDebounce';

// loader context
import LoaderContext from '../../../context/LoaderContext/LoaderContext';
import { decrypt } from '../../../utils';
import { PATCH_SERVICE_ENABLED, DELETE_SITE } from '../graphql/mutation';

// imported images
const searchImg = process.env.REACT_APP_BASE_NAME + 'svgs/search.svg';

const CompanySiteDetails = props => {
	let siteId = props.match.params.encryptedId;
	const client = useApolloClient();
	const { showLoading, hideLoading } = useContext(LoaderContext);
	const [siteService, setSiteService] = useState([]);
	const [siteServiceDetails, setSiteServiceDetails] = useState({
		name: '',
	});
	const [serviceModal, setServiceModal] = useState(false);
	const [serviceAdmin, setServiceAdmin] = useState('');
	const [serviceEnabledModal, setServiceEnabledModal] = useState(false);
	const [deleteSiteModal, setDeleteSiteModal] = useState(false);
	const [deleteWarningModal, setDeleteWarningModal] = useState(false);
	const [serviceResponseModal, setServiceResponseModal] = useState(false);
	const [serviceStatus, setServiceStatus] = useState('');

	// Patch service enabled mutation instance
	const [patchServiceEnabled] = useMutation(PATCH_SERVICE_ENABLED);
	const [deleteSiteService] = useMutation(DELETE_SITE);

	useEffect(() => {
		fetchSiteService();
	}, [useDebounce(siteServiceDetails, 500)]);

	const fetchSiteService = async () => {
		showLoading('Loading services...');
		const result = await client.query({
			query: GET_SITE_SERVICE,
			fetchPolicy: 'network-only',
			variables: {
				input: {
					sites_id: siteId,
					name: siteServiceDetails.name,
				},
			},
		});
		setSiteService(result.data.getService);
		hideLoading();
	};

	const {
		data: siteData,
		errors: siteErrors,
		loading: isSiteLoading,
	} = useQuery(GET_SITE, {
		fetchPolicy: 'network-only',
		variables: {
			input: {
				id: siteId,
			},
		},
	});

	if (isSiteLoading) {
		return <GLoader />;
	}

	if (siteId == false || siteErrors) {
		return <Page404 />;
	}

	// breadcrumbs navigation
	const breadcrumbNavigation = breadcrumb => {
		props.history.push(`/${breadcrumb.link}`, {
			breadcrumbs: props.history.location.state.breadcrumbs,
		});
	};

	const handleOnchange = (key, value) => {
		setSiteServiceDetails({
			...siteServiceDetails,
			[key]: value,
		});
	};

	const header = [
		{
			name: 'Service name',
			width: 2,
		},
		{
			name: 'Assigned service owner',
			width: 3,
		},
		{
			name: 'Email address',
			width: 3,
		},
		{
			name: 'Contact no.',
			width: 4,
		},
		{
			name: 'Action',
			width: 3,
		},
		{
			name: 'Enable Service',
			width: 3,
		},
	];

	/**
	 * Toggle Service Types
	 * @param {*} service_type_id
	 * @returns
	 */
	const toggleEnableService = async service_type_id => {
		// Copy of services data in sites
		let siteServiceCopy = JSON.parse(JSON.stringify([...siteService]));
		// Find selected service index
		const index = siteServiceCopy.findIndex(
			service => service.id === service_type_id
		);
		// Set selected service administrator to display in modal
		setServiceAdmin(siteServiceCopy[index].user.email);
		/**
		 * Check if service has not been set up yet
		 * serviceable_id will return null if true
		 */
		if (siteServiceCopy[index].serviceable_id === null) {
			setServiceModal(true);
			return;
		}
		// Get selected service enabled status
		const currentServiceEnabled = siteServiceCopy[index].enabled;
		// Set new value of enable status in the site service copy
		siteServiceCopy[index].enabled = currentServiceEnabled == 1 ? 0 : 1;
		// API for enabling service

		const serviceEnabled = await patchServiceEnabled({
			variables: {
				enabled: siteServiceCopy[index].enabled,
				id: decrypt(service_type_id),
			},
		});

		if (serviceEnabled.data.patchServiceEnabled.code === 422) {
			setServiceResponseModal(true);
		}
		/**
		 * Mutate current site service state with modified site service copy
		 * because site Service object state is readonly
		 */
		if (serviceEnabled.data.patchServiceEnabled.code === 200) {
			setSiteService(siteServiceCopy);
			// Set enabled or disabled to be displayed in the modal
			setServiceStatus(
				Boolean(currentServiceEnabled) === true ? 'disabled' : 'enabled'
			);

			// Open service enabled/disabled modal
			setServiceEnabledModal(true);
		}
	};

	/**
	 * Soft Delete Site
	 * @param {*} site_id
	 * @returns
	 */
	const deleteSite = async site_id => {
		const site = await deleteSiteService({
			variables: {
				site_id: site_id,
			},
		});

		// need to disable open services
		if (site.data.deleteSite.code === '422') {
			setDeleteWarningModal(true);
		}

		if (site.data.deleteSite.code === '200') {
			props.history.goBack();
		}
	};

	const renderData = siteService.map(service => {
		return {
			service_name: service.service_type.name,
			name:
				service.user.profile.first_name &&
				service.user.profile.last_name
					? `${service.user.profile.first_name} ${
							service.user.profile.last_name
					  } ${
							service.user.profile.suffix
								? service.user.profile.suffix.toLowerCase() ===
								  'n/a'
									? ''
									: service.user.profile.suffix
								: ''
					  }`
					: '',
			email: service.user.email,
			mobile_no: service.user.profile.mobile_no,
			action: (
				<>
					<a
						className="btnLink"
						onClick={() =>
							props.history.push(`/edit-service/${service.id}`, {
								breadcrumbs: [
									...props.history.location.state.breadcrumbs,
									{
										label:
											siteData && siteData.getSite
												? siteData.getSite.name
												: 'Company Site Details',
										link: `companysite-details/${props.match.params.encryptedId}`,
									},
								],
							})
						}
					>
						Edit service admin
					</a>
					{/* <a className="btnLink">Suspend</a>
					<a className="btnLink">Remove</a> */}
				</>
			),
			toggle: (
				<>
					<div className="ui fitted toggle checkbox">
						<Checkbox
							checked={service.enabled}
							onClick={() => toggleEnableService(service.id)}
							toggle
						/>
					</div>
				</>
			),
		};
	});

	return (
		<LayoutPage>
			<div className="company-detail">
				<Breadcrumbs
					breadcrumbs={[
						...props.history.location.state.breadcrumbs,
						{
							label:
								siteData && siteData.getSite
									? siteData.getSite.name
									: 'Company Site Details',
							link: `/companysite-details/${siteId}`,
						},
					]}
					breadcrumbNavigation={breadcrumbNavigation}
				/>
				<Header as="h1">
					{siteData && siteData.getSite ? siteData.getSite.name : ''}
				</Header>
				<Grid>
					<Grid.Column
						mobile="sixteen"
						tablet="sixteen"
						computer="ten"
					>
						<div className="element-container element-container-white">
							<div className="element-header">
								<Header as="h3">Site details</Header>
								<a
									className="btnLink"
									onClick={() => {
										setDeleteSiteModal(true);
									}}
								>
									Delete
								</a>
								{/* <a className="btnLink">Edit</a> */}
							</div>
							<div className="element-body">
								<Grid>
									<Grid.Column
										mobile="sixteen"
										tablet="eight"
										computer="eight"
									>
										<Header
											as="h5"
											className="header-margin-bottom"
										>
											Site name
										</Header>
										<p>
											{siteData && siteData.getSite
												? siteData.getSite.name
												: 'Not specified'}
										</p>
									</Grid.Column>
									<Grid.Column
										mobile="sixteen"
										tablet="eight"
										computer="eight"
									>
										<Header
											as="h5"
											className="header-margin-bottom"
										>
											Site abbreviation
										</Header>
										<p>
											{siteData && siteData.getSite
												? siteData.getSite.abbrev
												: 'Not specified'}
										</p>
									</Grid.Column>
									<Grid.Column
										mobile="sixteen"
										tablet="eight"
										computer="eight"
									>
										<Header
											as="h5"
											className="header-margin-bottom"
										>
											Site address
										</Header>
										<p>
											{siteData && siteData.getSite
												? siteData.getSite.address
												: 'Not specified'}
										</p>
									</Grid.Column>
								</Grid>
							</div>
						</div>
					</Grid.Column>
					<Grid.Column
						mobile="sixteen"
						tablet="sixteen"
						computer="six"
					>
						<div className="element-container element-container-gray">
							<Header as="h3">G Access users summary</Header>
							<p
								className="company-details-font"
								style={{ marginBottom: '1rem' }}
							>
								Based on employee list against app users
							</p>
							<div className="employee-count">
								<div className="employee-count-header">
									<div className="employee-icon">
										<img
											src={`${process.env.REACT_APP_BASE_URL}/images/couple.svg`}
										/>
									</div>
									<div className="employee-total">
										<Header as="h2" className="employee-h2">
											{siteData && siteData.getSite
												? siteData.getSite
														.employee_count
												: '0'}
										</Header>
										<p className="company-details-font">
											total no of employees
										</p>
									</div>
								</div>
								<div className="employee-count-body"></div>
							</div>
						</div>
					</Grid.Column>
				</Grid>
				<Header as="h3">Services available</Header>
				<div className="table-list">
					<div className="table-list-filter">
						<Input
							className="input-class filter-searchContainer"
							iconPosition="left"
							placeholder="Type search here"
							icon={
								<img
									src={searchImg}
									className="filter-searchIcon"
								/>
							}
							onChange={e =>
								handleOnchange('name', e.target.value)
							}
						/>
						<a
							className="ui button app-primary-button"
							style={{ whiteSpace: 'nowrap' }}
							onClick={() =>
								props.history.push(
									`/new-service/${props.match.params.encryptedId}`,
									{
										breadcrumbs: [
											...props.history.location.state
												.breadcrumbs,
											{
												label:
													siteData && siteData.getSite
														? siteData.getSite.name
														: 'Company Site Details',
												link: `companysite-details/${siteId}`,
											},
										],
									}
								)
							}
						>
							<Icon name="plus" />
							Create new service
						</a>
					</div>
					<div className="top-header-item border-right">
						<Modal open={serviceModal} className="service-modal">
							<Modal.Content>
								<img
									src={`${process.env.REACT_APP_BASE_URL}/images/setupservice-complete.png`}
								/>
								<Header as="h3">
									Service has not been set up yet
								</Header>
								<p className="">
									Please notify the assigned service
									administrator (
									<span className="service-admin">
										{serviceAdmin}
									</span>
									) to setup this service in order to be
									enabled.
								</p>
								<Button
									className="app-primary-button service-toggle-btn"
									onClick={() => setServiceModal(false)}
								>
									Okay
								</Button>
							</Modal.Content>
						</Modal>
					</div>
					<div className="top-header-item border-right">
						<Modal
							open={serviceEnabledModal}
							className="service-modal"
						>
							<Modal.Content>
								<img
									src={`${process.env.REACT_APP_BASE_URL}/images/setupservice-complete.png`}
								/>
								<Header as="h3">
									Service is now {serviceStatus}
								</Header>
								<p className="">
									This service is now visible in the GAccess
									Mobile Application
								</p>
								<Button
									className="app-primary-button service-toggle-btn"
									onClick={() =>
										setServiceEnabledModal(false)
									}
								>
									Okay
								</Button>
							</Modal.Content>
						</Modal>
					</div>
					<div className="top-header-item border-right">
						<Modal
							open={deleteWarningModal}
							className="service-modal"
						>
							<Modal.Content>
								<Header as="h3">
									The location your deleting still has active
									services scheduled.
								</Header>
								<Button
									className="app-primary-button service-toggle-btn"
									onClick={() => setDeleteWarningModal(false)}
								>
									Cancel
								</Button>
							</Modal.Content>
						</Modal>
					</div>
					<div className="top-header-item border-right">
						<Modal
							open={serviceResponseModal}
							className="service-modal"
						>
							<Modal.Content>
								<Header as="h3">
									The location your deleting still has active
									bookings scheduled.
								</Header>
								<Button
									className="app-primary-button service-toggle-btn"
									onClick={() =>
										setServiceResponseModal(false)
									}
								>
									Cancel
								</Button>
							</Modal.Content>
						</Modal>
					</div>
					<div className="top-header-item border-right">
						<Modal open={deleteSiteModal} className="service-modal">
							<Modal.Content>
								<Header as="h3">
									Are you sure you wish to delete?
								</Header>
								<Button
									className="app-primary-button service-toggle-btn"
									onClick={() => {
										setDeleteSiteModal(false);
										deleteSite(siteId);
									}}
								>
									Delete
								</Button>
								<Button
									className="app-primary-button service-toggle-btn"
									onClick={() => setDeleteSiteModal(false)}
								>
									Cancel
								</Button>
							</Modal.Content>
						</Modal>
					</div>
					<Table
						headers={header}
						datas={renderData}
						page={1}
						totalRows={1}
						rowsPerPage={10}
						noDataMsg="No service added yet"
					/>
				</div>
			</div>
		</LayoutPage>
	);
};

CompanySiteDetails.propTypes = {
	history: PropTypes.any,
	match: PropTypes.any,
};

export default CompanySiteDetails;

// initialize months for sorting array
const months = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December',
];

export const format_key_values = value => {
	return value
		.replace(/\./g, '')
		.replace(/\s+/g, '_')
		.replace('-', '')
		.toLowerCase();
};

export const sanitizeFilter = value => {
	return value
		.replace(/ +?/g, '')
		.replace('-', '')
		.toLowerCase();
};

// Email checker
export const isEmail = value => {
	const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

	return pattern.test(value);
};

export const splittedName = name => {
	if (name) {
		const index = name.indexOf(' ');
		return [name.substr(0, index), name.substr(index + 1)];
	}
	return name;
};

/**
 * This is helper for object deep cloning
 * @param {oldObject} - old object that will be update
 * @param {updatedProperties} - Updated value for each the property
 */
export const updateObject = (oldObject, updatedProperties) => {
	return {
		...oldObject,
		...updatedProperties,
	};
};

/**
 * Helper to sort an array of object based on the given property
 * @param {property} - property where the array will sort
 */
export const arraySort = property => {
	var sortOrder = 1;
	if (property[0] === '-') {
		sortOrder = -1;
		property = property.substr(1);
	}
	return function(a, b) {
		/* next line works with strings and numbers,
		 * and you may want to customize it to your needs
		 */
		var result =
			a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
		return result * sortOrder;
	};
};

/**
 * Helper to sort an array of object based on the given property
 * @param {property} - property where the array will sort
 */
export const ordinalSort = property => {
	var sortOrder = 1;
	if (property[0] === '-') {
		sortOrder = -1;
		property = property.substr(1);
	}
	return function(a, b) {
		/* next line works with strings and numbers,
		 * and you may want to customize it to your needs
		 */
		a = parseInt(a[property].match(/\d+/));
		b = parseInt(b[property].match(/\d+/));
		var result = a < b ? -1 : a > b ? 1 : 0;
		return result * sortOrder;
	};
};

/**
 * Ellipsis
 * @param {word} - string that needs to check if exceeds to limited characters
 */
export const wordEllipsis = (word, length) => {
	return word && word.length > length
		? word.substring(0, length).concat('...')
		: word;
};

/**
 * Sanitizing String by removing white space and force the string to lower case
 * @param {string} - string to sanitize(removing white space, force to lower)
 */
export const stringSanitize = string => {
	if (string) {
		const sanitezedString = string.replace(/ +?/g, '');
		return sanitezedString.toLowerCase().trim();
	}

	return string;
};

/**
 * Sorting array of object based on the reference
 * @param {array} - array that needs to reorder
 * @param {order} - reference of sorting
 * @param {key} - object key for reorder
 */
export const mapOrder = (array, order, key) => {
	array.sort(function(a, b) {
		var A = a[key],
			B = b[key];

		if (order.indexOf(A) > order.indexOf(B)) {
			return 1;
		} else {
			return -1;
		}
	});

	return array;
};

/**
 * Chunk array into given length
 * @param {array} - array that needs to chunk
 * @param {length} - length of values per chunk
 */
export const chunkArray = (array, length) => {
	let arraySet = [];
	let i, j;
	for (i = 0, j = array.length; i < j; i += length) {
		arraySet.push(array.slice(i, i + length));
	}
	return arraySet;
};

/**
 * Splitting string
 * @param {str} - string to be splitted
 */
export const splitString = str => {
	return str.replace(/\s+/, '\x01').split('\x01');
};

export const sorterByMonthAndYear = (a, b) => {
	if (a.year !== b.year) {
		return a.year - b.year;
	} else {
		return months.indexOf(a.month) - months.indexOf(b.month);
	}
};

/**
 * Checking array values for duplicate entry
 * @param {array} - array of string that needs to check
 */
export const checkIfDuplicateExists = array => {
	return new Set(array).size !== array.length;
};

export const doubleDigitNumber = (number, targetLength) => {
	var output = number + '';
	while (output.length < targetLength) {
		output = '0' + output;
	}
	return output;
};

/**
 * Concat fullname based on given first name, surname and suffix (if there's any)
 */
export const concatFullname = (firstName, lastName, suffix = null) => {
	return `${firstName} ${lastName} ${
		suffix ? (suffix.toLowerCase() === 'n/a' ? '' : suffix) : ''
	}`;
};

/**
 * Check if given param is null, return n/a if yes
 */
export const checkIfNull = arg => {
	return arg ? arg : 'n/a';
};

export const isMobileNum = value => {
	const pattern = /^[0]?[9]\d{9}$/; // allow digits and dash and exactly 11 chars

	return pattern.test(value);
};

export const stringSanitizeNoLowerCase = string => {
	return string.replace(/\s/g, '').trim();
};

export const isAlphaNumSpace = value => {
	const pattern = /^[a-zA-Z0-9\s]+$/;

	return pattern.test(value);
};

export const isPersonName = value => {
	const pattern = /^[a-zA-Z., -]{1,50}$/;

	return pattern.test(value);
};

// exports.stringSanitizeNoLowerCase = string => {
// 	// str.replace(/\s+/g, ' ').trim()
// 	return string.replace(/\s/g, '').trim();
// };

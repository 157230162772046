import React, { useContext } from 'react';

// semantic ui component
import { Container, Dimmer, Loader } from 'semantic-ui-react';

// prop-types for mapping props
import PropTypes from 'prop-types';

// style
import './AdminMaster.css';

// nav component
import Nav from '../../../components/Nav/Nav';
import MenuBar from '../../../components/MenuBar/MenuBar';

// context
// import { ParserProvider } from '../../../context/ParserContext/ParserContext';
import LoaderContext from '../../../context/LoaderContext/LoaderContext';

import GLoader from '../../../components/Loader/Loader';

const navLinks = [
	{
		to: '/companysite',
		label: 'Office sites',
	},
	{
		to: '/gaccess-services',
		label: 'G Access services',
	},
	{
		to: '/employeelist',
		label: 'Employee list',
	},
	{
		to: '/app-branding',
		label: 'App branding',
	},
	{
		to: '/app-reports',
		label: 'App reports',
	},
];

const LayoutPage = ({ children, title, ...props }) => {
	const { loading, gloader } = useContext(LoaderContext);
	if (gloader) {
		return <GLoader />;
	}
	return (
		<>
			<Dimmer active={loading.state} className="overlay">
				<Loader indeterminate>{loading.message}</Loader>
			</Dimmer>
			<div>
				<Nav title={title} />
				<Container className="page-container">
					<MenuBar navLinks={navLinks} />
					{children}
				</Container>
			</div>
		</>
	);
};

LayoutPage.propTypes = {
	children: PropTypes.any,
	title: PropTypes.any,
};

export default LayoutPage;

import gql from 'graphql-tag';

// insert service and assigning an user as service admin
export const POST_SERVICE_INSTANCE = gql`
	mutation($inputServices: [inputServiceInstance]) {
		postService(inputServices: $inputServices) {
			code
			message
		}
	}
`;

// update assigned service admin
export const PATCH_SERVICE = gql`
	mutation(
		$service_instances_id: EncryptedString!
		$users_id: EncryptedString!
	) {
		patchService(
			service_instances_id: $service_instances_id
			users_id: $users_id
		) {
			code
			message
		}
	}
`;
//update status if suspended or active
export const PATCH_COMPANY_EMPLOYEE_STATUS = gql`
	mutation($input: inputCompanyEmployeeStatus) {
		patchCompanyEmployeeStatus(input: $input) {
			message
			status
			service_instances {
				id
				site {
					id
					name
				}
				service_type {
					id
					name
				}
			}
			service_to_redirect {
				site {
					id
					name
				}
			}
		}
	}
`;
//insert new company employee
export const POST_COMPANY_EMPLOYEE = gql`
	mutation($input: inputCompanyEmployee) {
		postCompanyEmployee(input: $input) {
			message
		}
	}
`; //update company employee
export const PATCH_COMPANY_EMPLOYEE = gql`
	mutation($input: inputCompanyEmployee) {
		patchCompanyEmployee(input: $input) {
			message
		}
	}
`;
// insert new company sites

export const POST_COMPANY_SITE = gql`
	mutation($input: inputCompanySite) {
		postSite(input: $input) {
			message
		}
	}
`;

/**
 * Patch enabling service mutation request body
 */
export const PATCH_SERVICE_ENABLED = gql`
	mutation($enabled: Int, $id: String) {
		patchServiceEnabled(enabled: $enabled, id: $id) {
			code
			message
		}
	}
`;

/**
 * Delete Site
 */
export const DELETE_SITE = gql`
	mutation($site_id: String) {
		deleteSite(site_id: $site_id) {
			code
			message
		}
	}
`;

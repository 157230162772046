// react lib
import React from 'react';

// less styling
import './Workflow.css';

// semantic ui components
import { Grid, Form, Header, Icon } from 'semantic-ui-react';

import PropTypes from 'prop-types';

import { ServiceTypes } from '../../../../models/ServiceTypes';

const approverOptions = [
	{ key: '0', text: 'Approver 1', value: '0' },
	{ key: '1', text: 'Approver 2', value: '1' },
	{ key: '2', text: 'Approver 3', value: '2' },
];

const assignedApproverOptions = [
	{ key: '0', text: 'Assigned Approver 1', value: '0' },
	{ key: '1', text: 'Assigned Approver 2', value: '1' },
	{ key: '2', text: 'Assigned Approver 3', value: '2' },
];

const Workflow = ({ form, serviceType }) => {
	// const [approval, setApproval] = useState(false);
	return (
		<div>
			<Grid centered reversed="computer">
				<Grid.Column
					mobile="sixteen"
					tablet="sixteen"
					computer="sixteen"
				>
					<div className="step-container">
						<Grid>
							<Grid.Column
								mobile="sixteen"
								tablet="sixteen"
								computer="sixteen"
							>
								<Header as="h3">Workflow</Header>
								<Form.Group grouped className="ungroup-fields">
									<Header as="h4">Workflow type</Header>
									<Grid>
										<Grid.Column width={8}>
											<Form.Group className="input-check-container">
												<label className="checkcontainer">
													<input
														type="radio"
														name="workflow"
														value={0}
														checked={
															!form.service
																.with_approval
														}
													/>
													<span>
														&nbsp;Without approval
													</span>
													<span className="radiobtn"></span>
												</label>
											</Form.Group>
										</Grid.Column>
										<Grid.Column width={8}>
											<Form.Field
												className="service-radio"
												type="radio"
												control="input"
												label="With approval"
												disabled
												checked={
													form.service.with_approval
												}
											/>
										</Grid.Column>
									</Grid>
								</Form.Group>
								<Form.Group grouped>
									{form.service.with_approval ? (
										<WithApproval />
									) : (
										<WithoutApproval
											serviceType={serviceType}
										/>
									)}
								</Form.Group>
							</Grid.Column>
						</Grid>
					</div>
				</Grid.Column>
			</Grid>
		</div>
	);
};

const WithoutApproval = ({ serviceType }) => {
	return (
		<div className="subfields-group w-95 subfields-margin">
			<Header as="h4">Without approval workflow</Header>
			<div className="workflow">
				<div className="workflow-item">
					<div className="workflow-content">
						<div className="workflow-title">Submit form</div>
						<div className="workflow-subtitle">
							Employee submits booking form
						</div>
					</div>
				</div>
				<div className="workflow-item">
					<div className="workflow-content">
						<div className="workflow-title">Allocate</div>
						<div className="workflow-subtitle">
							Employee is allocated a{' '}
							{ServiceTypes[serviceType.name]
								? ServiceTypes[serviceType.name].slotType2
								: null}
						</div>
					</div>
				</div>
				<div className="workflow-item">
					<div className="workflow-content">
						<div className="workflow-title">Check-in</div>
						<div className="workflow-subtitle">
							Employee checks in
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const WithApproval = () => {
	return (
		<div>
			<Header as="h6">With approval workflow</Header>
			<div className="workflow">
				<div className="workflow-item">
					<div className="workflow-content">
						<div className="workflow-title">Submit form</div>
						<div className="workflow-subtitle">
							Employee submits booking form
						</div>
					</div>
				</div>
				<div className="workflow-item">
					<div className="workflow-content">
						<Form.Select
							fluid
							options={approverOptions}
							value={approverOptions[0].value}
						/>
						<div className="assign-approver">
							<Form.Select
								fluid
								label="Assign an approver"
								options={assignedApproverOptions}
								value={assignedApproverOptions[0].value}
							/>
						</div>
					</div>
				</div>
			</div>
			<a href="">
				<Icon name="plus"></Icon>Add new step
			</a>
		</div>
	);
};

Workflow.propTypes = {
	form: PropTypes.array,
	onChangeValueHandler: PropTypes.func,
	serviceType: PropTypes.object,
};

WithoutApproval.propTypes = {
	serviceType: PropTypes.object,
};

export default Workflow;

// react lib
import React, { useContext, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Button, Message } from 'semantic-ui-react';
import ImageContext from '../../context/ImageContext/ImageContext';
import LoaderContext from '../../context/LoaderContext/LoaderContext';
import { wordEllipsis } from '../../utils';

import './FileUpload.css';

const closeBlue = process.env.REACT_APP_BASE_NAME + 'svgs/close-blue.svg';
const closeRed = process.env.REACT_APP_BASE_NAME + 'svgs/close-red.svg';
const errorAlert = process.env.REACT_APP_BASE_NAME + 'svgs/dark-alert.svg';

const ImageUpload = () => {
	// setting of setFileData to consume anywhere
	const {
		imageHandler,
		imageFile,
		setImageFile,
		setFileError,
		fileError,
		setImageSrc,
	} = useContext(ImageContext);
	const { showLoading, hideLoading } = useContext(LoaderContext);

	const { getRootProps, getInputProps } = useDropzone({
		onDrop: acceptedFiles => checkFileHandler(acceptedFiles[0]),
	});

	const checkFileHandler = file => {
		showLoading('Validating image...');
		let fileError = null;
		if (!file || file == undefined) {
			hideLoading();
			return false;
		}

		// set ImageSrc to null initially
		setImageSrc(null);
		// store file
		// define accepted file types
		let validTypes = ['image/svg+xml', 'image/png'];

		if (!file) {
			setFileError(
				'Invalid file type. Please select a .SVG or .PNG file'
			);
			hideLoading();
			return false;
		}

		// validates file type
		if (!validTypes.includes(file.type)) {
			fileError = 'Invalid file type. Please select a .SVG or .PNG file';
		} else if (file.size / 1024 / 1024 > 3) {
			fileError = 'file must be less than 3MB';
		}

		// check if there's an errors
		if (fileError) {
			setFileError(fileError);
			hideLoading();
			return false;
		}

		imageHandler(file);
		return () => {
			setFileError(null);
		};
	};

	const onRemoveFileHandler = useCallback(event => {
		event.preventDefault();
		event.stopPropagation();
		setFileError(null);
		setImageFile(null);
		setImageSrc(null);
	}, []);

	return (
		<div>
			<section className="container">
				<div
					{...getRootProps({
						className: 'dropzone',
						onClick: evt => evt.preventDefault(),
					})}
				>
					<input {...getInputProps()} />
					<div className="text-center">
						{fileError ? (
							<Message
								negative
								className="selected-file file-error"
							>
								<img
									src={errorAlert}
									className="alert-icon"
									alt="error alert"
								/>
								<span className="mr-10">{fileError}</span>
								<img
									src={closeRed}
									className="file-close pointer-cursor"
									onClick={onRemoveFileHandler}
								/>
							</Message>
						) : imageFile ? (
							<Message
								info
								visible
								className="selected-file file-success img-success-message"
							>
								{/* <img src={uploadIcon} /> */}
								<span>{wordEllipsis(imageFile.path, 30)}</span>
								<img
									src={closeBlue}
									className="file-close pointer-cursor"
									onClick={onRemoveFileHandler}
								/>
							</Message>
						) : (
							<>
								<span className="custom-upload-icon-text">
									Drop your file here to upload or
								</span>
								<Button className="app-primary-inverted-button mt-10">
									Select file from computer
								</Button>
							</>
						)}
					</div>
				</div>
			</section>
		</div>
	);
};

export default ImageUpload;

import React from 'react';
import PropTypes from 'prop-types';

const CharCount = ({ current, total }) => {
	return (
		<>
			<div className="character-count">
				{current}/{total} characters
			</div>
		</>
	);
};

CharCount.propTypes = {
	current: PropTypes.number,
	total: PropTypes.number,
};

export default CharCount;

import React from 'react';
import PropTypes from 'prop-types';
import { Document, Font } from '@react-pdf/renderer';

import styled from '@react-pdf/styled-components';

// fonts
import FSElliotPro from '../../assets/fonts/FSElliotPro.ttf';
import FSElliotProBold from '../../assets/fonts/FSElliotPro-Bold.ttf';
import { wordEllipsis } from '../../utils';

const inAppLogo =
	process.env.REACT_APP_BASE_NAME + 'images/GAccess-inApp@2x.png';

const Body = styled.Page`
	flex-direction: row;
	display: flex;
	flex-wrap: wrap;
	margin: 0 32px;
`;

const PageView = styled.View`
	width: 240px;
	height: 264px;
	background: #0064d8 0% 0% no-repeat padding-box;
	opacity: 1;
	text-align: center;
	justify-content: center;
	margin: 8px;
`;

const Picture = styled.Image`
	width: 103px;
	height: 32px;
	display: block;
	align-self: center;
`;

const Title = styled.Text`
	font-size: 25pt;
	color: white;
	font-weight: 700;
	text-align: center;
	display: block;
	font-family: FSElliotProBold;
`;

const Subtitle = styled.Text`
	font-size: 11pt;
	margin-bottom: 8px;
	color: white;
	text-align: center;
	display: block;
	font-family: FSElliotPro;
`;

const QRBody = styled.View`
	width: 138px;
	height: 138px;
	background: #ffffff 0% 0% no-repeat padding-box;
	border: 1px solid #b9b9b9;
	opacity: 1;
	display: block;
	align-self: center;
`;

const QRImage = styled.Image`
	width: 100%;
	height: 100%;
`;

// PDF Document component
const PDFQRCode = ({ slots, service }) => {
	return (
		<Document>
			<Body wrap orientation="landscape">
				{slots.map(slot => {
					return (
						<PageView wrap={false} key={slot.id}>
							<Picture src={inAppLogo} />
							<Title>{wordEllipsis(slot.serviceName, 15)}</Title>
							<Subtitle>{service}</Subtitle>
							<QRBody>
								<QRImage src={slot.qr} />
							</QRBody>
						</PageView>
					);
				})}
			</Body>
		</Document>
	);
};

Font.register({
	family: 'FSElliotPro',
	src: FSElliotPro,
	format: 'truetype',
});

Font.register({
	family: 'FSElliotProBold',
	src: FSElliotProBold,
	format: 'truetype',
});

PDFQRCode.propTypes = {
	slots: PropTypes.array,
	service: PropTypes.string,
};

export default PDFQRCode;

// react lib
import React, {
	useState,
	useEffect,
	useContext,
	useRef,
	useCallback,
} from 'react';
import { Redirect } from 'react-router-dom';

// hoc
import LoginMaster from '../../../hoc/Layout/Login/LoginMaster';

// less(styling)
import '../Login.css';

// grahpql queries
import { useMutation, useLazyQuery } from '@apollo/client';
import { CHANGE_PASSWORD_VIA_EMAIL } from '../graphql/mutation';

// semantic ui component
import {
	Form,
	Button,
	Header,
	Message,
	Icon,
	Modal,
	Popup,
	Grid,
} from 'semantic-ui-react';

import PropTypes from 'prop-types';

// context
import LoaderContext from '../../../context/LoaderContext/LoaderContext';

// helpers
import { decrypt } from '../../../utils';

// component
import Page404 from '../../Page404/Page404';

import useForm from '../../../hooks/useForm';

const SetupNewPassword = props => {
	const [form, setForm] = useState({
		email: decrypt(props.match.params.encryptedEmail),
		code: decrypt(props.match.params.encryptedCode),
		new_password: '',
		confirm_password: '',
	});
	const [error, setError] = useState({});
	const [is404, set404] = useState(false);
	const [loading, setLoading] = useState(true);
	const [redirectTo, setRedirectTo] = useState(null);
	const [open, setOpen] = useState(false);
	const [passwordErrors, setPasswordErrors] = useState([]);
	const [passwordRetypeErrors, setPasswordRetypeErrors] = useState([]);
	const passwordRef = useRef();
	const passwordRetypeRef = useRef();

	const { showGloader, hideGloader } = useContext(LoaderContext);

	const { handleSubmit } = useForm();

	useEffect(() => {
		if (!form.email || !form.code) {
			set404(true);
			return;
		}
		hideGloader(false);
	}, []);

	const [
		changePassword,
		{
			data: checkPasswordResetCodeResponse,
			errors: patchErrors,
			loading: isPatchLoading,
		},
	] = useMutation(CHANGE_PASSWORD_VIA_EMAIL, {
		onError: error => {},
		onCompleted: data => {
			hideGloader();
			if (data.changePasswordViaEmail.status == 1) {
				setOpen(true);
				setTimeout(() => {
					setLoading(true);
					setTimeout(() => {
						props.history.push('/');
					}, 1000);
				}, 2000);
			} else if (data.changePasswordViaEmail.status == 0) {
				setError({ ...data.changePasswordViaEmail });
			}
		},
	});

	const onSubmitHandler = () => {
		if (
			form.new_password.length > 0 &&
			form.confirm_password.length > 0 &&
			passwordErrors.length == 0 &&
			passwordRetypeErrors.length == 0
		) {
			showGloader();
			changePassword({
				variables: {
					input: form,
				},
			});
		}
	};

	const handleChange = (key, value) => {
		setForm({
			...form,
			[key]: value,
		});
	};

	const validatePasswordFormat = useCallback(() => {
		let hasError = false;
		let tempErrors = [];

		if (form.new_password.length < 8) {
			hasError = true;
			tempErrors = [...tempErrors, 'Must be at least 8 characters'];
		}

		if (
			/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[\d]).{0,}$/gm.test(
				form.new_password
			) == false
		) {
			hasError = true;
			tempErrors = [
				...tempErrors,
				'A combination of uppercase, lowercase & numbers',
			];
		}

		if (/^(?=.*?[\W_]).{0,}$/gm.test(form.new_password) == false) {
			hasError = true;
			tempErrors = [...tempErrors, 'Must have special characters'];
		}

		if (!hasError) {
			setPasswordErrors([]);
		} else {
			setPasswordErrors(tempErrors);
		}

		if (form.confirm_password.length > 0) {
			validatePasswordRetype();
		}
	}, [passwordErrors, form]);

	const validatePasswordRetype = useCallback(() => {
		let hasError = false;
		let tempErrors = [];
		if (!form.confirm_password || form.confirm_password == '') {
			hasError = true;
			tempErrors = [...tempErrors, 'Re-type password is required'];
		}

		if (
			form.confirm_password !== '' &&
			form.new_password !== form.confirm_password
		) {
			hasError = true;
			tempErrors = [...tempErrors, 'Password did not match'];
		}

		if (!hasError) {
			setPasswordRetypeErrors([]);
		} else {
			setPasswordRetypeErrors(tempErrors);
		}
	}, [passwordRetypeErrors, form]);

	if (!form.email || !form.code || is404) {
		return <Page404 />;
	}

	if (redirectTo !== null) {
		return <Redirect to={redirectTo} />;
	}

	return (
		<LoginMaster>
			<div className="login-header">
				<Header as="h3">Setup a new password</Header>
				<ul
					style={{
						listStyleType: 'disc',
						color: '#707070',
					}}
					className="password-errors"
				>
					<li>At least 8 characters</li>
					<li>A combination of uppercase, lowercase & numbers</li>
					<li>Must have special characters</li>
				</ul>
			</div>
			<Grid>
				<Grid.Row>
					<Grid.Column
						mobile="sixteen"
						tablet="tablet"
						computer="six"
					>
						{error && error.status === 0 ? (
							<Message negative>
								<Icon
									className="error-icon"
									name="exclamation circle"
								/>
								{error.message}
							</Message>
						) : null}
						<Form onSubmit={handleSubmit(onSubmitHandler)}>
							<Form.Field>
								<label htmlFor="">New password</label>
								<input
									className={
										passwordErrors &&
										passwordErrors.length > 0
											? 'input-error'
											: ''
									}
									type="password"
									autoComplete="off"
									placeholder="Enter password here"
									name="password"
									autoFocus
									// ref={register({
									// 	required: 'Password is required*',
									// 	pattern: {
									// 		value: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[\d])(?=.*?[\W_]).{8,}$/gm,
									// 		message:
									// 			'Password must be at least 8 characters with a mix of uppercase, lowercase and special characters*',
									// 	},
									// 	min: {
									// 		value: 8,
									// 		message:
									// 			'Password must be atleast 8 characters*',
									// 	},
									// })}
									ref={passwordRef}
									onBlur={e => {
										validatePasswordRetype();
										validatePasswordFormat();
									}}
									onChange={e =>
										handleChange(
											'new_password',
											e.target.value
										)
									}
								/>
							</Form.Field>
							<Popup
								context={passwordRef}
								position="right center"
								open={
									passwordErrors && passwordErrors.length > 0
								}
								size="mini"
								wide="very"
								basic
								style={{
									marginLeft: '20px',
									boxShadow: 'none',
									backgroundColor: '#FFCBCB',
									color: '#E55151',
									border: 'none',
								}}
							>
								<ul
									style={{
										listStyleType: 'disc',
										color: '#E55151',
										fontWeight: 'bold',
									}}
									className="password-errors"
								>
									{passwordErrors.map((error, i) => {
										return <li key={i}>{error}</li>;
									})}
								</ul>
							</Popup>
							<Form.Field>
								<label htmlFor="">Re-type password</label>
								<input
									className={
										passwordRetypeErrors &&
										passwordRetypeErrors.length > 0
											? 'input-error'
											: ''
									}
									type="password"
									autoComplete="off"
									placeholder="Re-type password here"
									name="confirm_password"
									// ref={register({
									// 	required: 'Re-type password is required*',
									// 	validate: value =>
									// 		form.new_password === value ||
									// 		'Password did not match*',
									// })}
									ref={passwordRetypeRef}
									onFocus={e => validatePasswordFormat()}
									onBlur={e => validatePasswordRetype()}
									onChange={e =>
										handleChange(
											'confirm_password',
											e.target.value
										)
									}
								/>
							</Form.Field>
							<Popup
								context={passwordRetypeRef}
								position="right center"
								open={
									passwordRetypeErrors &&
									passwordRetypeErrors.length > 0
								}
								size="mini"
								wide="very"
								basic
								style={{
									marginLeft: '20px',
									boxShadow: 'none',
									backgroundColor: '#FFCBCB',
									color: '#E55151',
									border: 'none',
								}}
							>
								<ul
									style={{
										listStyleType: 'disc',
										color: '#E55151',
										fontWeight: 'bold',
									}}
									className="password-errors"
								>
									{passwordRetypeErrors.map((error, i) => {
										return <li key={i}>{error}</li>;
									})}
								</ul>
							</Popup>
							<Button
								type="submit"
								className="app-primary-button medium-button"
								/* disabled={
									form.new_password.length == 0 ||
									form.confirm_password.length == 0 ||
									passwordErrors.length > 0 ||
									passwordRetypeErrors.length > 0
								} */
							>
								Save
							</Button>
						</Form>
					</Grid.Column>
					<Grid.Column
						mobile="sixteen"
						tablet="tablet"
						computer="eight"
					></Grid.Column>
				</Grid.Row>
			</Grid>

			<Modal
				closeOnEscape={false}
				closeOnDimmerClick={false}
				onClose={() => setOpen(false)}
				onOpen={() => setOpen(true)}
				open={open}
				size="tiny"
				className="app-modal"
				// trigger={<Button>Show Modal</Button>}
			>
				<Modal.Content>
					<img
						src={`${process.env.REACT_APP_BASE_URL}/images/setupservice-complete.png`}
					/>
					<Header as="h3">Password saved!</Header>
					<p style={{ padding: '0 5rem' }}>
						Make sure to not share your password to keep your
						account secured
					</p>
					{/* <Button
						content="Okay"
						className="app-primary-button full-button"
						onClick={() => handleLoginLater(adminRole)}
					/> */}
					{/* <Button
						content="I will login later"
						className="app-primary-inverted-button full-button"
						onClick={() => handleLoginLater()}
					/> */}
				</Modal.Content>
			</Modal>
		</LoginMaster>
	);
};

SetupNewPassword.propTypes = {
	history: PropTypes.any,
	match: PropTypes.any,
};

export default SetupNewPassword;

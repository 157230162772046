import React, { useContext } from 'react';

import FileUpload from '../../../../../components/FileUpload/FileUpload';

// semantic-ui
import { Form, Message } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { ServiceTypes } from '../../../../../models/ServiceTypes';
import ParserContext from '../../../../../context/ParserContext/ParserContext';

const BulkUpload = ({ serviceType, sites }) => {
	const { fileError } = useContext(ParserContext);
	let serviceHtml = (
		<Form.Group grouped>
			<div>
				<div>
					<b>
						Upload list of bookable{' '}
						{ServiceTypes[serviceType.name]
							? ServiceTypes[serviceType.name].slotType2
							: null}
						s per site
					</b>
				</div>
				<div>
					Missed the{' '}
					{ServiceTypes[serviceType.name]
						? ServiceTypes[serviceType.name].slotType2
						: null}{' '}
					list template we shared?
				</div>
				<div>
					<a
						href={(() => {
							switch (serviceType.name) {
								case 'Workdesk':
									return `${process.env.REACT_APP_AWS_S3URL}/GAccess_WorkDesk_Template.xlsx`;
								case 'Parking':
									return `${process.env.REACT_APP_AWS_S3URL}/GAccess_Parking_Template.xlsx`;
								case 'Cafeteria':
									return `${process.env.REACT_APP_AWS_S3URL}/GAccess_Cafeteria_Template.xlsx`;
							}
						})()}
						className="btnLink"
					>
						Download template here{' '}
					</a>
				</div>
			</div>
			{sites.map(site => (
				<div className="booking-upload-field w-95" key={site.id}>
					<ul>
						<li>
							<strong>{site.name}</strong>
						</li>
					</ul>
					<FileUpload id={site.id} />
					{fileError.some(file => file.id === site.id) ? (
						<Message
							negative
							className="file-error-message"
							size="tiny"
						>
							{
								fileError.filter(file => file.id === site.id)[0]
									.fileError
							}
						</Message>
					) : null}
				</div>
			))}
		</Form.Group>
	);

	if (serviceType.name === 'Shuttle') {
		serviceHtml = (
			<>
				<Form.Group grouped>
					<div>
						<div>
							<b>Upload list of shuttle vehicles</b>
						</div>
						<div>Missed the template we shared?</div>
						<div>
							<a
								href={`${process.env.REACT_APP_AWS_S3URL}/GAccess_Vehicle_Template.xlsx`}
								className="btnLink"
							>
								Download template here
							</a>
						</div>
					</div>
					{sites.map(site => (
						<div
							className="booking-upload-field w-95"
							key={site.id}
						>
							<FileUpload id="upload-vehicles" />
							{fileError.some(
								file => file.id === 'upload-vehicles'
							) ? (
								<Message
									negative
									className="file-error-message"
									size="tiny"
								>
									{
										fileError.filter(
											file =>
												file.id === 'upload-vehicles'
										)[0].fileError
									}
								</Message>
							) : null}
						</div>
					))}
				</Form.Group>
				<Form.Group grouped>
					<div>
						<div>
							<b>Upload list of drivers</b>
						</div>
						<div>Missed the template we shared?</div>
						<div>
							<a
								href={`${process.env.REACT_APP_AWS_S3URL}/GAccess_Driver_Template.xlsx`}
								className="btnLink"
							>
								Download template here
							</a>
						</div>
					</div>
					{sites.map(site => (
						<div
							className="booking-upload-field w-95"
							key={site.id}
						>
							<FileUpload id="upload-drivers" />
							{fileError.some(
								file => file.id === 'upload-drivers'
							) ? (
								<Message
									negative
									className="file-error-message"
									size="tiny"
								>
									{
										fileError.filter(
											file => file.id === 'upload-drivers'
										)[0].fileError
									}
								</Message>
							) : null}
						</div>
					))}
				</Form.Group>
			</>
		);
	}

	return serviceHtml;
};

BulkUpload.propTypes = {
	serviceType: PropTypes.object,
	sites: PropTypes.array,
};

export default BulkUpload;

/**
 * Map 0-6 days into its initial or day name
 * Can be used in shuttles coding days or rest days
 */
export const Days = [
	{
		id: 0,
		shortname: 'Su',
		name: 'Sunday',
	},
	{
		id: 1,
		shortname: 'M',
		name: 'Monday',
	},
	{
		id: 2,
		shortname: 'T',
		name: 'Tuesday',
	},
	{
		id: 3,
		shortname: 'W',
		name: 'Wednesday',
	},
	{
		id: 4,
		shortname: 'Th',
		name: 'Thursday',
	},
	{
		id: 5,
		shortname: 'F',
		name: 'Friday',
	},
	{
		id: 6,
		shortname: 'S',
		name: 'Saturday',
	},
];

import gql from 'graphql-tag';

export const POST_USERS = gql`
	mutation($input: inputAccount) {
		postUser(input: $input) {
			message
		}
	}
`;

export const PATCH_HAS_LOGIN = gql`
	mutation($users_id: EncryptedString!) {
		patchHasLogin(users_id: $users_id) {
			status
			message
		}
	}
`;

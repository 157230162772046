// react lib
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
// components
// import FileUpload from '../../../../components/FileUpload/FileUpload';
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
// semantic ui component
import { Form, Grid, Header, Input, Select } from 'semantic-ui-react';
// contexts
import ParserContext from '../../../../context/ParserContext/ParserContext';
// less styling
import './Booking.css';
import BulkUpload from './Bulk-upload/Bulk-upload';

const advanceBookingOptions = [
	{ key: '1', text: 'A day before', value: 1 },
	{ key: '2', text: '2 days before', value: 2 },
	{ key: '3', text: 'A week before', value: 7 },
];

const advanceBookingLimit = [
	{ key: '1', text: 'per week', value: 'w' },
	{ key: '2', text: 'per month', value: 'm' },
];

// const limitedTime = [
// 	{ key: '1', text: 'minutes', value: 1 },
// 	{ key: '2', text: 'hours', value: 2 },
// ];

const Booking = ({
	onChangeValueHandler,
	form,
	onClickDayAvailable,
	onChangeTimeHandler,
	sites,
	serviceType,
	setFileToUpload,
}) => {
	const { fileError, excelFile } = useContext(ParserContext);
	// const [dayAvailability, setDayAvailability] = useState('selected');
	// const [timeAvailability, setTimeAvailability] = useState('different');
	// const [bookingLength, setBookingLength] = useState('limited');
	useEffect(() => {
		if (serviceType.name === 'Shuttle') {
			// if (
			// 	excelFile.filter(excel => excel.id === 'upload-vehicles')
			// 		.length > 0
			// ) {
			// 	setFileToUpload();
			// }

			// if (
			// 	excelFile.filter(excel => excel.id === 'upload-drivers')
			// 		.length > 0
			// ) {
			// }
			// let fileUploads = [];
			const fileUploads =
				excelFile.length > 0
					? excelFile.map(excel => {
							return `"${excel.id}": "${excel.file.name}"`;
					  })
					: [];
			setFileToUpload(`{${fileUploads.join()}}`);
		} else {
			setFileToUpload(excelFile.length > 0 ? excelFile[0].file.name : '');
		}
	}, [excelFile]);

	const [bookingStay, setBookingStay] = useState({
		is_whole_day:
			form.serviceable.is_whole_day == 1 ||
			form.serviceable.is_whole_day == 2
				? 1
				: 0,
		is_limited_time:
			form.serviceable.is_whole_day == 0 ||
			form.serviceable.is_whole_day == 2
				? 1
				: 0,
	});

	const handleChangeBookingStay = (key, value) => {
		setBookingStay({
			...bookingStay,
			[key]: value,
		});
	};

	useEffect(() => {
		let value = null;

		if (bookingStay.is_whole_day == 0 && bookingStay.is_limited_time == 1)
			value = 0;

		if (bookingStay.is_whole_day == 1 && bookingStay.is_limited_time == 0)
			value = 1;

		if (bookingStay.is_whole_day == 1 && bookingStay.is_limited_time == 1)
			value = 2;

		onChangeValueHandler('serviceable', 'is_whole_day', value);
	}, [bookingStay]);

	return (
		<div>
			<Grid centered>
				<Grid.Column
					mobile="sixteen"
					tablet="sixteen"
					computer="sixteen"
				>
					<div className="step-container">
						<Grid>
							<Grid.Column mobile={16} tablet={10} computer={9}>
								<Header as="h3">Booking form & controls</Header>
								<div className="form-group">
									<Form.Group grouped>
										<label>Booking schedule</label>
										<Form.Group className="input-check-container">
											<label className="checkcontainer booking-checkbox">
												<input
													type="checkbox"
													value={
														form.serviceable
															.is_same_day_booking
													}
													checked={
														form.serviceable
															.is_same_day_booking
													}
													onChange={evt => {
														onChangeValueHandler(
															'serviceable',
															'is_same_day_booking',
															evt.target.checked
																? 1
																: 0
														);
													}}
												/>
												<span>
													&nbsp; Allow same day
													booking
												</span>
												<span className="checkmark"></span>
											</label>
										</Form.Group>
										<Form.Group className="input-check-container">
											<label className="checkcontainer booking-checkbox">
												<input
													type="checkbox"
													value={
														form.serviceable
															.is_advance_booking
													}
													checked={
														form.serviceable
															.is_advance_booking
													}
													onChange={evt => {
														onChangeValueHandler(
															'serviceable',
															'is_advance_booking',
															evt.target.checked
																? 1
																: 0
														);
													}}
												/>
												<span>
													&nbsp; Allow advance booking
												</span>
												<span className="checkmark"></span>
											</label>
										</Form.Group>
									</Form.Group>
									{form.serviceable.is_advance_booking ? (
										<Form.Group grouped>
											<div
												className="subfields-group w-95 subfields-margin"
												style={{ paddingLeft: '35px' }}
											>
												<Form.Field
													control={Select}
													label="How advance can employees book?"
													options={
														advanceBookingOptions
													}
													value={parseInt(
														form.serviceable
															.advance_booking_days
													)}
													className="filter-select"
													icon="angle down"
													onChange={(
														e,
														{ value }
													) => {
														onChangeValueHandler(
															'serviceable',
															'advance_booking_days',
															parseInt(value)
														);
													}}
												/>
												<label>
													How many advance bookings
													allowed?
												</label>
												<Grid>
													<Grid.Column width={5}>
														<Form.Field
															control={Input}
															type="number"
															value={
																form.serviceable
																	.allowed_advance_booking
															}
															min={1}
															max={5}
															maxLength={1}
															pattern="[0-5]{0,5}"
															onChange={evt =>
																onChangeValueHandler(
																	'serviceable',
																	'allowed_advance_booking',
																	parseInt(
																		evt
																			.target
																			.value
																	)
																)
															}
														/>
													</Grid.Column>
													<Grid.Column width={11}>
														<Form.Field
															control={Select}
															options={
																advanceBookingLimit
															}
															value={
																form.serviceable
																	.advance_booking_threshold
															}
															onChange={(
																e,
																{ value }
															) => {
																onChangeValueHandler(
																	'serviceable',
																	'advance_booking_threshold',
																	value
																);
															}}
															className="filter-select"
															icon="angle down"
														/>
													</Grid.Column>
												</Grid>
											</div>
										</Form.Group>
									) : null}
								</div>
								<div className="form-group">
									<Form.Group
										grouped
										className="ungroup-fields"
									>
										<label>Days Available</label>
										<Grid className="booking-grid">
											<Grid.Column width={6}>
												<Form.Group className="input-check-container">
													<label className="checkcontainer">
														<input
															type="radio"
															name="selected-days"
															value={0}
															checked={
																!form
																	.serviceable
																	.is_every_day
															}
															onChange={evt =>
																onChangeValueHandler(
																	'serviceable',
																	'is_every_day',
																	parseInt(
																		evt
																			.target
																			.value
																	)
																)
															}
														/>
														<span className="custom-label-white-space">
															&nbsp;Selected days
															only
														</span>
														<span className="radiobtn"></span>
													</label>
												</Form.Group>
											</Grid.Column>
											<Grid.Column width={8}>
												<Form.Group className="input-check-container">
													<label className="checkcontainer">
														<input
															type="radio"
															name="selected-days"
															value={1}
															checked={
																form.serviceable
																	.is_every_day
															}
															onChange={evt =>
																onChangeValueHandler(
																	'serviceable',
																	'is_every_day',
																	parseInt(
																		evt
																			.target
																			.value
																	)
																)
															}
														/>
														<span>
															&nbsp;Everyday
														</span>
														<span className="radiobtn"></span>
													</label>
												</Form.Group>
											</Grid.Column>
										</Grid>
									</Form.Group>
									<div className="subfields-group w-95 subfields-margin">
										{!form.serviceable.is_every_day && (
											<>
												<Form.Group className="ml-0 mr-0">
													<label>
														<b>
															Select days service
															will be available
														</b>
													</label>
												</Form.Group>
												<Form.Group className="ml-0 mr-0">
													<div className="day-checkbox">
														{form.service.availabilities.map(
															day => {
																return (
																	<Form.Field
																		control="input"
																		type="checkbox"
																		primary
																		key={
																			day.label
																		}
																		label={
																			day.day ===
																				4 ||
																			day.day ===
																				7
																				? moment()
																						.day(
																							day.day
																						)
																						.format(
																							'dd'
																						)
																				: moment()
																						.day(
																							day.day
																						)
																						.format(
																							'dd'
																						)
																						.substr(
																							0,
																							1
																						)
																		}
																		name={
																			day.day
																		}
																		style={{
																			cursor:
																				'pointer',
																		}}
																		className={clsx(
																			'day-checkbox-item',
																			day.is_available
																				? 'checked'
																				: ''
																		)}
																		onClick={evt =>
																			onClickDayAvailable(
																				evt,
																				day
																			)
																		}
																		// onClick={e => {
																		// 	onChangeValueHandler(
																		// 		e,
																		// 		i
																		// 	);
																		// }}
																	/>
																);
															}
														)}
													</div>
												</Form.Group>
											</>
										)}
									</div>
								</div>
								<div className="form-group">
									<Form.Group
										grouped
										className="ungroup-fields"
									>
										<label>Time Available</label>
										{serviceType.name === 'Shuttle' && (
											<div className="grey-text white-space-pre">
												Trip scheduler will adapt the
												earliest start and the latest
												end time from this section
											</div>
										)}
										<Grid>
											<Grid.Column
												width={6}
												className="mt-0 mb-0"
											>
												<Form.Group className="input-check-container">
													<label className="checkcontainer">
														<input
															type="radio"
															name="time-available"
															value={0}
															checked={
																!form
																	.serviceable
																	.is_similar_across_days
															}
															onChange={() => {
																onChangeValueHandler(
																	'serviceable',
																	'is_similar_across_days',
																	0
																);
															}}
														/>
														<span>
															&nbsp;Varies per day
														</span>
														<span className="radiobtn"></span>
													</label>
												</Form.Group>
											</Grid.Column>
											<Grid.Column width={8}>
												<Form.Group className="input-check-container">
													<label className="checkcontainer">
														<input
															type="radio"
															name="time-available"
															value={1}
															checked={
																form.serviceable
																	.is_similar_across_days
															}
															onChange={() => {
																onChangeValueHandler(
																	'serviceable',
																	'is_similar_across_days',
																	1
																);
															}}
														/>
														<span>
															&nbsp;Similar across
															days
														</span>
														<span className="radiobtn"></span>
													</label>
												</Form.Group>
											</Grid.Column>
										</Grid>
									</Form.Group>
									<Form.Group
										grouped
										className="subfields-group"
									>
										{!form.serviceable
											.is_similar_across_days && (
											<VariesPerDay
												availabilities={
													form.service.availabilities
												}
												onChangeTimeHandler={
													onChangeTimeHandler
												}
											/>
										)}

										{form.serviceable
											.is_similar_across_days ? (
											<SimilarAcrossDays
												availabilities={
													form.service.availabilities
												}
												onChangeTimeHandler={
													onChangeTimeHandler
												}
											/>
										) : null}
									</Form.Group>
								</div>
								{serviceType.name !== 'Shuttle' && (
									<div className="form-group">
										<Form.Group grouped>
											<label>
												Length of booking stay
											</label>
											<Grid>
												<Grid.Column width={5}>
													<Grid.Column width={6}>
														<Form.Group className="input-check-container">
															<label className="checkcontainer booking-checkbox">
																<input
																	type="checkbox"
																	defaultChecked={
																		bookingStay.is_whole_day
																	}
																	onChange={evt => {
																		handleChangeBookingStay(
																			'is_whole_day',
																			evt
																				.target
																				.checked
																				? 1
																				: 0
																		);
																	}}
																/>
																<span>
																	&nbsp; Whole
																	day
																</span>
																<span className="checkmark"></span>
															</label>
														</Form.Group>
													</Grid.Column>
													<Form.Group className="input-check-container">
														<label className="checkcontainer booking-checkbox">
															<input
																type="checkbox"
																defaultChecked={
																	bookingStay.is_limited_time
																}
																onChange={evt => {
																	handleChangeBookingStay(
																		'is_limited_time',
																		evt
																			.target
																			.checked
																			? 1
																			: 0
																	);
																}}
															/>
															<span className="custom-label-white-space">
																&nbsp; Limited
																time
															</span>
															<span className="checkmark"></span>
														</label>
													</Form.Group>
													<br />
													{bookingStay.is_limited_time ==
													1 ? (
														<Grid.Column width={8}>
															<div className="subfields-group w-95 subfields-margin">
																<Input
																	className="w70"
																	label="hours"
																	labelPosition="right"
																	type="number"
																	step=".01"
																	value={
																		form
																			.serviceable
																			.max_stay
																	}
																	placeholder="0"
																	onChange={evt =>
																		onChangeValueHandler(
																			'serviceable',
																			'max_stay',
																			parseFloat(
																				evt
																					.target
																					.value
																			)
																		)
																	}
																></Input>
															</div>
														</Grid.Column>
													) : null}
												</Grid.Column>
											</Grid>
										</Form.Group>
									</div>
								)}
								<div className="form-group">
									<BulkUpload
										sites={sites}
										serviceType={serviceType}
										// serviceType={{
										// 	icon:
										// 		'http://localhost:3001/cptestbucket1/gaccess-images/dinner.svg',
										// 	id:
										// 		'dd3eb8afcc5a66e9bd9b817f6d066ddc',
										// 	name: 'Shuttle',
										// }}
									/>
									{/* <Form.Group grouped>
										<div>
											<div>
												<b>
													Upload list of bookable{' '}
													{ServiceTypes[
														serviceType.name
													]
														? ServiceTypes[
																serviceType.name
														  ].slotType2
														: null}
													s per site
												</b>
											</div>
											<div>
												Missed the{' '}
												{ServiceTypes[serviceType.name]
													? ServiceTypes[
															serviceType.name
													  ].slotType2
													: null}{' '}
												list template we shared?
											</div>
											<div>
												<a
													href={(() => {
														switch (
															serviceType.name
														) {
															case 'Workdesk':
																return `${process.env.REACT_APP_AWS_S3URL}/GAccess_WorkDesk_Template.xlsx`;
															case 'Parking':
																return `${process.env.REACT_APP_AWS_S3URL}/GAccess_Parking_Template.xlsx`;
															case 'Cafeteria':
																return `${process.env.REACT_APP_AWS_S3URL}/GAccess_Cafeteria_Template.xlsx`;
														}
													})()}
													className="btnLink"
												>
													Download template here{' '}
												</a>
											</div>
										</div>
										{sites.map(site => (
											<div
												className="booking-upload-field w-95"
												key={site.id}
											>
												<ul>
													<li>
														<strong>
															{site.name}
														</strong>
													</li>
												</ul>
												<FileUpload id={site.id} />
												{fileError.some(
													file => file.id === site.id
												) ? (
													<Message
														negative
														className="file-error-message"
														size="tiny"
													>
														{
															fileError.filter(
																file =>
																	file.id ===
																	site.id
															)[0].fileError
														}
													</Message>
												) : null}
											</div>
										))}
									</Form.Group> */}
								</div>
								<div className="form-group">
									<Form.Group grouped>
										<Form.Field>
											<b>Check-in option</b>
										</Form.Field>
										{serviceType.name !== 'Shuttle' ? (
											<>
												<Form.Group className="input-check-container">
													<label className="checkcontainer">
														<input
															type="radio"
															name="checkIn"
															value={1}
															checked={
																form.service
																	.is_qr_checkin
															}
															onChange={() =>
																onChangeValueHandler(
																	'service',
																	'is_qr_checkin',
																	1
																)
															}
														/>
														<span>
															&nbsp;QR scan
														</span>
														<span className="radiobtn"></span>
													</label>
												</Form.Group>
												<Form.Group className="input-check-container">
													<label className="checkcontainer">
														<input
															type="radio"
															name="checkIn"
															value={0}
															checked={
																!form.service
																	.is_qr_checkin
															}
															onChange={() =>
																onChangeValueHandler(
																	'service',
																	'is_qr_checkin',
																	0
																)
															}
														/>
														<span>
															&nbsp;In-app
															check-in button
														</span>
														<span className="radiobtn"></span>
													</label>
												</Form.Group>
											</>
										) : (
											<>
												<Form.Group className="input-check-container">
													<label className="checkcontainer">
														<input
															type="radio"
															name="checkIn"
															value={1}
															checked={
																form.service
																	.is_qr_checkin
															}
															onChange={() =>
																onChangeValueHandler(
																	'service',
																	'is_qr_checkin',
																	1
																)
															}
														/>
														<span>
															&nbsp;via QR scan
															and Driver Manual
															check-in
														</span>
														<span className="radiobtn"></span>
													</label>
												</Form.Group>
												{/* <Form.Group className="input-check-container">
													<label className="checkcontainer">
														<input
															type="radio"
															name="checkIn"
															value={0}
															checked={
																!form.service
																	.is_qr_checkin
															}
															onChange={() =>
																onChangeValueHandler(
																	'service',
																	'is_qr_checkin',
																	0
																)
															}
														/>
														<span>
															&nbsp;Manual app
															check-in
														</span>
														<span className="radiobtn"></span>
													</label>
												</Form.Group> */}
											</>
										)}
									</Form.Group>
									<Form.Group grouped>
										<Form.Field>
											<b>Check-out option</b>
											<div>
												Select additional way to
												check-out aside from time expiry
												(default)
											</div>
										</Form.Field>
										{serviceType.name === 'Shuttle' ? (
											<Form.Group className="input-check-container">
												<label className="checkcontainer">
													<input
														type="radio"
														name="checkOut"
														value={2}
														checked={
															form.service
																.is_qr_checkout ===
															2
														}
														onChange={() =>
															onChangeValueHandler(
																'service',
																'is_qr_checkout',
																2
															)
														}
													/>
													<span>
														&nbsp;Driver&rsquo;s
														control
													</span>
													{/* <br /> */}
													<div className="sub-text">
														( auto check-out to all
														passengers)
													</div>
													<span className="radiobtn"></span>
												</label>
											</Form.Group>
										) : (
											<>
												<Form.Group className="input-check-container">
													<label className="checkcontainer">
														<input
															type="radio"
															name="checkOut"
															value={1}
															checked={
																form.service
																	.is_qr_checkout
															}
															onChange={() =>
																onChangeValueHandler(
																	'service',
																	'is_qr_checkout',
																	1
																)
															}
														/>
														<span>
															&nbsp;QR scan
														</span>
														<span className="radiobtn"></span>
													</label>
												</Form.Group>
												<Form.Group className="input-check-container">
													<label className="checkcontainer">
														<input
															type="radio"
															name="checkOut"
															value={0}
															checked={
																!form.service
																	.is_qr_checkout
															}
															onChange={() =>
																onChangeValueHandler(
																	'service',
																	'is_qr_checkout',
																	0
																)
															}
														/>
														<span>
															&nbsp;In-app
															check-out button
														</span>
														<span className="radiobtn"></span>
													</label>
												</Form.Group>
											</>
										)}
									</Form.Group>
								</div>
							</Grid.Column>
						</Grid>
					</div>
				</Grid.Column>
			</Grid>
		</div>
	);
};

const VariesPerDay = ({ availabilities, onChangeTimeHandler }) => {
	const [openClock, setOpenClock] = useState({
		startime: null,
		endtime: null,
	});
	return (
		<Grid className="schedule-container w-95">
			<Grid.Row>
				<Grid.Column width={16}>
					<label>
						<b>Schedule per day</b>
					</label>
				</Grid.Column>
			</Grid.Row>
			<Grid.Row>
				<Grid.Column width={4} className="vertical-center">
					<label>
						<b>Day</b>
					</label>
				</Grid.Column>
				<Grid.Column width={12}>
					<label style={{ paddingLeft: 10 }}>
						<b>Set time</b>
					</label>
				</Grid.Column>
			</Grid.Row>
			{availabilities.map((day, i) => {
				if (day.is_available) {
					return (
						<Grid.Row
							key={day.label}
							style={{ paddingTop: '0', paddingBottom: '0' }}
						>
							<Grid.Column width={4} className="vertical-center">
								<label>
									{moment()
										.day(day.day)
										.format('dddd')}
								</label>
							</Grid.Column>
							<Grid.Column width={12}>
								<MuiPickersUtilsProvider utils={MomentUtils}>
									<Form.Field>
										<Form.Input
											placeholder="00:00 AM - 00:00 PM"
											className={
												'input-pointer time-picker'
											}
											value={
												day.startime == null &&
												day.endtime == null
													? null
													: `${
															moment(
																day.startime,
																'HH:mm'
															).isValid() == false
																? ''
																: moment(
																		day.startime,
																		'HH:mm'
																  )
																		.format(
																			'hh:mm A'
																		)
																		.toString()
													  } - ${
															moment(
																day.endtime,
																'HH:mm'
															).isValid() == false
																? ''
																: moment(
																		day.endtime,
																		'HH:mm'
																  )
																		.format(
																			'hh:mm A'
																		)
																		.toString()
													  }`
											}
											icon="clock outline"
											readOnly
											onClick={() =>
												setOpenClock({
													...openClock,
													startime: i,
												})
											}
										/>
									</Form.Field>
									<TimePicker
										value={moment(day.startime, 'HH:mm')}
										open={openClock.startime === i}
										className="timepicker"
										onOpen={() =>
											setOpenClock({
												...openClock,
												startime: i,
											})
										}
										onClose={() =>
											setOpenClock({
												startime: null,
												endtime: i,
											})
										}
										onChange={date => {
											onChangeTimeHandler({
												key: 'startime',
												day: day,
												value: moment(date).format(
													'HH:mm'
												),
											});
										}}
										TextFieldComponent={() => null}
									/>
									<TimePicker
										value={moment(day.endtime, 'HH:mm')}
										open={openClock.endtime === i}
										className="timepicker"
										onOpen={() =>
											setOpenClock({
												startime: null,
												endtime: i,
											})
										}
										onClose={() =>
											setOpenClock({
												startime: null,
												endtime: null,
											})
										}
										onChange={date =>
											onChangeTimeHandler({
												key: 'endtime',
												day: day,
												value: moment(date).format(
													'HH:mm'
												),
											})
										}
										// onChange={date =>
										// 	handleTimeAvailability(
										// 		false,
										// 		i,
										// 		'endtime',
										// 		moment(date).format('HH:mm')
										// 	)
										// }
										TextFieldComponent={() => null}
									/>
								</MuiPickersUtilsProvider>
							</Grid.Column>
						</Grid.Row>
					);
				}
			})}
		</Grid>
	);
};

const SimilarAcrossDays = ({ availabilities, onChangeTimeHandler }) => {
	const [isOpenClock, setIsOpenClock] = useState({
		startime: false,
		endtime: false,
	});
	return (
		<Grid>
			<Grid.Row style={{ paddingTop: '0' }}>
				<Grid.Column width={6}></Grid.Column>
				<Grid.Column
					width={10}
					className="similar-across-timer subfields-margin"
				>
					<MuiPickersUtilsProvider utils={MomentUtils}>
						<Form.Field>
							<Form.Input
								label="Set time"
								placeholder="00:00 AM - 00:00 PM"
								className={'input-pointer time-picker'}
								value={
									availabilities[0].startime == null &&
									availabilities[0].endtime == null
										? null
										: `${
												moment(
													availabilities[0].startime,
													'HH:mm'
												).isValid() == false
													? ''
													: moment(
															availabilities[0]
																.startime,
															'HH:mm'
													  )
															.format('hh:mm A')
															.toString()
										  } - ${
												moment(
													availabilities[0].endtime,
													'HH:mm'
												).isValid() == false
													? ''
													: moment(
															availabilities[0]
																.endtime,
															'HH:mm'
													  )
															.format('hh:mm A')
															.toString()
										  }`
								}
								icon="clock outline"
								readOnly
								onClick={() => {
									setIsOpenClock({
										...isOpenClock,
										startime: true,
									});
								}}
							/>
						</Form.Field>
						<TimePicker
							value={
								availabilities[0].startime == null
									? moment(new Date(), 'HH:mm')
									: moment(
											availabilities[0].startime,
											'HH:mm'
									  )
							}
							open={isOpenClock.startime}
							className="timepicker"
							onOpen={() =>
								setIsOpenClock({
									...isOpenClock,
									startime: true,
								})
							}
							onClose={() =>
								setIsOpenClock({
									...isOpenClock,
									startime: false,
									endtime: true,
								})
							}
							onChange={date =>
								onChangeTimeHandler({
									key: 'startime',
									day: availabilities[0],
									value: moment(date).format('HH:mm'),
								})
							}
							TextFieldComponent={() => null}
						/>
						<TimePicker
							value={moment(availabilities[0].endtime, 'HH:mm')}
							open={isOpenClock.endtime}
							className="timepicker"
							onOpen={() =>
								setIsOpenClock({
									...isOpenClock,
									endtime: true,
								})
							}
							onClose={() =>
								setIsOpenClock({
									...isOpenClock,
									endtime: false,
								})
							}
							onChange={date =>
								onChangeTimeHandler({
									key: 'endtime',
									day: availabilities[0],
									value: moment(date).format('HH:mm'),
								})
							}
							TextFieldComponent={() => null}
						/>
					</MuiPickersUtilsProvider>
				</Grid.Column>
			</Grid.Row>
		</Grid>
	);
};

SimilarAcrossDays.propTypes = {
	availabilities: PropTypes.array,
	onChangeTimeHandler: PropTypes.func,
};

VariesPerDay.propTypes = {
	onChangeTimeHandler: PropTypes.func,
	availabilities: PropTypes.array,
};

Booking.propTypes = {
	lineError: PropTypes.string,
	onChangeValueHandler: PropTypes.func,
	form: PropTypes.object,
	availabilities: PropTypes.array,
	onClickDayAvailable: PropTypes.func,
	onChangeTimeHandler: PropTypes.func,
	sites: PropTypes.array,
	uploadDisable: PropTypes.bool,
	activeStep: PropTypes.number,
	serviceType: PropTypes.object,
	setFileToUpload: PropTypes.any,
};

export default Booking;

import { useContext } from 'react';
import { ApolloLink, ApolloClient, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { createUploadLink } from 'apollo-upload-client';
import { onError } from 'apollo-link-error';
import { fromPromise } from 'apollo-link';
import { RetryLink } from 'apollo-link-retry';
import { GET_FRESH_TOKEN } from './graphql/query';
import { DELETE_TOKEN } from '../components/Nav/graphql/mutation';
import jwt_decode from 'jwt-decode';

let apolloClient;

const getNewToken = async () => {
	const user = jwt_decode(
		localStorage.getItem(process.env.REACT_APP_TOKEN_NAME)
	);
	const refresh_token = localStorage.getItem('refreshToken');
	const tokens = await apolloClient.query({
		query: GET_FRESH_TOKEN,
		variables: { users_id: user.user.id, refresh_token },
	});
	return tokens.data.getFreshToken;
};

const resetToken = async () => {
	const user = jwt_decode(
		localStorage.getItem(process.env.REACT_APP_TOKEN_NAME)
	);
	const refresh_token = localStorage.getItem('refreshToken');
	const deletedToken = await apolloClient.mutate({
		mutation: DELETE_TOKEN,
		variables: { users_id: user.user.id, refresh_token },
	});

	return deletedToken;
};

const setTokenLink = setContext(async (_, { headers }) => {
	const accessToken = await localStorage.getItem(
		process.env.REACT_APP_TOKEN_NAME
	);

	return {
		headers: {
			...headers,
			Authorization: accessToken ? `Bearer ${accessToken}` : '',
		},
	};
});

// errorLink for global error handler
const errorLink = onError(
	({ response, graphQLErrors, networkError, forward, operation }) => {
		if (graphQLErrors) {
			for (let err of graphQLErrors) {
				if (err.code === 'UNAUTHENTICATED') {
					switch (err.message) {
						case 'Unauthorized':
							fromPromise(
								resetToken().catch(error => {
									// Handle token refresh errors e.g clear stored tokens, redirect to login, ...
									return;
								})
							);
							localStorage.clear();
							window.location.href =
								process.env.REACT_APP_BASE_URL;
							break;
						case 'TokenExpiredError':
							// error code is set to UNAUTHENTICATED
							// when AuthenticationError thrown in resolver

							return fromPromise(
								getNewToken().catch(error => {
									// Handle token refresh errors e.g clear stored tokens, redirect to login, ...
									return;
								})
							)
								.filter(value => Boolean(value))
								.flatMap(tokens => {
									localStorage.setItem(
										process.env.REACT_APP_TOKEN_NAME,
										tokens.access_token
									);
									localStorage.setItem(
										'refreshToken',
										tokens.refresh_token
									);
									const oldHeaders = operation.getContext()
										.headers;
									// modify the operation context with a new token
									operation.setContext({
										headers: {
											...oldHeaders,
											Authorization: `Bearer ${tokens.access_token}`,
										},
									});

									// retry the request, returning the new observable
									return forward(operation);
								});
					}
				}
			}
		}
	}
);

// uploadlink
const uploadLink = createUploadLink({
	uri: `${process.env.REACT_APP_API_URL}/graphql`,
	credentials: 'same-origin',
});

// combination of uploadlink and errorlink
const link = ApolloLink.from([setTokenLink, errorLink, uploadLink]);

apolloClient = new ApolloClient({
	cache: new InMemoryCache({
		addTypename: false,
	}),
	link,
	ssrMode: false,
});

export default apolloClient;

// export const config = new ApolloClient({
// 	cache: new InMemoryCache({
// 		addTypename: false,
// 	}),
// 	link,
// 	ssrMode: false,
// });

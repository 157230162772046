import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { base64 } from './index';
import * as moment from 'moment';
/**
 * Excel helper that helps you to generate dynamic excel content
 * @param {excelHeaders} - array of strings, represents the header of excel
 * @param {excelDatas} - array of array for data per row
 */
export const generateExcelReport = ({
	excelHeaders,
	excelTitle,
	excelDatas,
	excelFilename,
	services = null,
}) => {
	let workbook = new Workbook();

	let headerRow;
	let worksheet = workbook.addWorksheet('sheet');
	const myBase64Image = base64; // add image to workbook by buffer
	const imageId2 = workbook.addImage({
		base64: myBase64Image,
		extension: 'png',
	});

	worksheet.addImage(imageId2, {
		tl: { col: 6.5, row: 0.1 },
		ext: { width: 131.52, height: 40 },
	});
	// eslint-disable-next-line prettier/prettier
	worksheet.mergeCells(`${services === 'Parking' || services.includes('Shuttle') ? 'A1:M1' : 'A1:L1'}`); // Extend cell over all column headers
	worksheet.getCell(`A1`).alignment = {
		horizontal: 'center',
		vertical: 'middle',
	}; // Horizontally center your text
	worksheet.getRow(1).height = 30;
	worksheet.getCell('A1').fill = {
		type: 'pattern',
		pattern: 'solid',
		fgColor: { argb: 'FFFFFF' },
	};
	worksheet.getCell('A2').value = excelTitle; // Assign title to cell A1 -- THIS IS WHAT YOU'RE LOOKING FOR.
	// eslint-disable-next-line prettier/prettier
	worksheet.mergeCells(`${services === 'Parking' || services.includes('Shuttle') ? 'A2:M2' : 'A2:L2'}`); // Extend cell over all column headers
	worksheet.getCell('A2').alignment = {
		horizontal: 'center',
		vertical: 'middle',
	}; // Horizontally center your text
	worksheet.getRow(2).height = 30;
	worksheet.getCell('A2').fill = {
		type: 'pattern',
		pattern: 'solid',
		fgColor: { argb: '1C4587' },
	};
	worksheet.getCell('A2').font = {
		size: 12,
		bold: true,
		color: { argb: 'FFFFFF' },
	};

	// width
	worksheet.getColumn(1).width = 15;
	worksheet.getColumn(2).width = 20;
	worksheet.getColumn(3).width = 15;
	worksheet.getColumn(4).width = 23;
	worksheet.getColumn(5).width = 15;
	worksheet.getColumn(6).width = 20;
	worksheet.getColumn(7).width = 20;
	worksheet.getColumn(8).width = 20;
	worksheet.getColumn(9).width = 20;
	worksheet.getColumn(10).width = 20;
	worksheet.getColumn(11).width = 25;
	worksheet.getColumn(12).width = 25;
	if (services === 'Parking') {
		worksheet.getColumn(13).width = 20;
	}

	// Insert row for header
	headerRow = worksheet.addRow(excelHeaders);

	// Cell Style : Fill and Border
	headerRow.eachCell(cell => {
		cell.fill = {
			type: 'pattern',
			pattern: 'solid',
			fgColor: { argb: 'C9DAF8' },
		};
		cell.font = {
			bold: true,
		};
	});

	// Add Data and Conditional Formatting
	excelDatas.forEach(d => {
		worksheet.addRow(d);
	});

	workbook.xlsx.writeBuffer().then(exportToXlxs => {
		let blob = new Blob([exportToXlxs], {
			type:
				'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
		});
		fs.saveAs(blob, excelFilename);
	});
};

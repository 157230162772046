import gql from 'graphql-tag';

//get company sites you're assigned to manage work desks
export const GET_SITE_WORKDESKS = gql`
	query($input: inputSiteWorkdesk, $paginate: paginate) {
		getSiteWorkdesk(input: $input, paginate: $paginate) {
			services {
				id
				booking_count
				slots_count
				setup_status
				site {
					id
					name
					abbrev
				}
				setup_logs {
					id
					status
				}
				service_type {
					id
					name
				}
				workdesk {
					id
					slots {
						id
					}
				}
			}
			total_services
		}
	}
`;

export const GET_COMPANY_GROUPS = gql`
	query($companies_id: EncryptedString!) {
		getCompanyGroups(companies_id: $companies_id) {
			id
			name
		}
	}
`;

/*
	Getting service instance details
*/
export const GET_SERVICE_INSTANCE = gql`
	query(
		$service_instances_ids: [EncryptedString!]
		$service_types_id: EncryptedString!
	) {
		getServiceInstance(
			service_instances_ids: $service_instances_ids
			service_types_id: $service_types_id
		) {
			id
			name
			is_all_employees
			is_default_icon
			description
			icon_url
			is_qr_checkin
			is_qr_checkout
			vehicles {
				id
			}
			drivers {
				id
			}
			groups {
				id
				name
			}
			workdesk {
				id
				is_same_day_booking
				is_advance_booking
				advance_booking_days
				allowed_advance_booking
				advance_booking_threshold
				is_every_day
				is_similar_across_days
				is_whole_day
				max_stay
				slots {
					id
				}
			}
			parking {
				id
				is_same_day_booking
				is_advance_booking
				advance_booking_days
				allowed_advance_booking
				advance_booking_threshold
				is_every_day
				is_similar_across_days
				is_whole_day
				max_stay
				amt
				slots {
					id
				}
			}
			cafeteria {
				id
				is_same_day_booking
				is_advance_booking
				advance_booking_days
				allowed_advance_booking
				advance_booking_threshold
				is_every_day
				is_similar_across_days
				is_whole_day
				max_stay
				slots {
					id
				}
			}
			shuttle {
				id
				is_same_day_booking
				is_advance_booking
				advance_booking_days
				allowed_advance_booking
				advance_booking_threshold
				is_every_day
				is_similar_across_days
				trip_schedules {
					id
				}
			}
			availabilities {
				id
				day
				is_available
				startime
				endtime
				day
			}
			setup_logs {
				id
				status
				step
			}
			policy {
				title
				content
			}
			service_type {
				id
				name
				icon
			}
			uploaded_files {
				id
				file_name
				type
			}
		}
	}
`;

/*
	Get Slots by service_intance_id
*/
export const GET_WORKDESK_SLOTS = gql`
	query($input: inputFilterSlot, $paginate: paginate, $sort: sortOrder) {
		getWorkdeskSlots(input: $input, paginate: $paginate, sort: $sort) {
			slots {
				id
				workdeskID
				name
				floor {
					id
					name
				}
				area
				state
				booking_status
				total_bookings
				total_checked_in
				enabled
			}
			total
		}
	}
`;

// get trip slots by service_instance_id
export const GET_TRIP_SLOTS = gql`
	query(
		$service_instances_id: EncryptedString!
		$tripDateFilter: tripDateFilter
		$paginate: paginate
		$tripFilter: tripFilter
		$sort: sortOrder
	) {
		getTripSlotsReport(
			service_instances_id: $service_instances_id
			tripDateFilter: $tripDateFilter
			paginate: $paginate
			tripFilter: $tripFilter
			sort: $sort
		) {
			trips {
				id
				idno
				total_bookings
				total_checked_in
				trip_date
				status
				route {
					site_origin {
						id
						name
					}
					site_destination {
						id
						name
					}
				}
				vehicle {
					id
					plate_number
					make
					model
				}
				driver {
					id
					user {
						profile {
							first_name
							last_name
						}
					}
				}
			}
			total_count
		}
	}
`;

// checking duplicate workdesks
export const CHECK_WORKDESKS = gql`
	query($siteSlots: inputSiteSlot) {
		checkWorkdeskSlots(siteSlots: $siteSlots) {
			status
			message
		}
	}
`;

/*
	Get site floors by sites id
*/
export const GET_SITE_FLOORS = gql`
	query(
		$service_instances_id: EncryptedString!
		$sites_id: EncryptedString!
		$filter: String
	) {
		getSiteFloors(
			service_instances_id: $service_instances_id
			sites_id: $sites_id
			filter: $filter
		) {
			id
			name
			enabled
		}
	}
`;

/*
	Get areas by service_instnaces_id
*/
export const GET_SERVICE_INSTANCES_AREAS = gql`
	query($service_instances_id: EncryptedString!, $filter: String) {
		getServiceInstanceAreas(
			service_instances_id: $service_instances_id
			filter: $filter
		) {
			area
		}
	}
`;

//checking duplicate slots
export const VERIFY_SLOTS = gql`
	query($input: inputVerifySlot) {
		verifyWorkdeskSlots(input: $input) {
			status
			message
		}
	}
`;

export const GET_FLOOR_BOOKINGS = gql`
	query($input: inputFloorBookings, $paginate: paginate, $sort: sortOrder) {
		getFloorBookings(input: $input, paginate: $paginate, sort: $sort) {
			bookings {
				id
				booking_start
				booking_end
				checkin_time
				checkout_time
				date_created
				booking_status
				time_hour
				time_minute
				payment {
					id
					ref_no
					amt
					status
				}
				slot {
					id
					name
					area
					workdeskID
					floor {
						name
					}
				}
				employee {
					id
					user {
						id
						email
						profile {
							first_name
							last_name
							suffix
						}
					}
				}
			}
			total_floor_bookings
		}
	}
`;

export const GET_TRIP_BOOKINGS = gql`
	query($input: inputFloorBookings, $paginate: paginate, $sort: sortOrder) {
		getTripSlotBookings(input: $input, paginate: $paginate, sort: $sort) {
			bookings {
				id
				idno
				booking_start
				booking_end
				checkin_time
				checkout_time
				date_created
				booking_status
				is_chance_passenger
				time_hour
				time_minute
				trip_slot {
					id
					idno
					trip_date
					route {
						id
						site_origin {
							name
						}
						site_destination {
							name
						}
					}
					driver {
						user {
							profile {
								first_name
								last_name
							}
						}
					}
					vehicle {
						plate_number
					}
				}
				employee {
					id
					user {
						id
						email
						profile {
							first_name
							last_name
							suffix
						}
					}
				}
			}
			total_floor_bookings
		}
	}
`;

export const GET_BOOKING_STATISTICS = gql`
	query($input: dateFilter, $service_types_id: EncryptedString!) {
		getBookingStatistic(
			input: $input
			service_types_id: $service_types_id
		) {
			bookings_count
			checked_in_count
			total_earnings
			monthly_statistics {
				month
				total
				total_cancelled
				total_checkedin
			}
		}
	}
`;

// get slots qrcodes
export const GET_SLOT_QRCODES = gql`
	query($inputSlotsIds: [inputSlotsIds]) {
		getSlotQRCode(inputSlotsIds: $inputSlotsIds) {
			qr
			id
			serviceName
		}
	}
`;

export const REPORT_WORKDESK_SLOTS = gql`
	query($input: inputReportWorkdeskSlot) {
		getReportWorkdeskSlots(input: $input) {
			slots {
				id
				workdeskID
				name
				area
				floor {
					id
					name
				}
				bookings {
					booking_start
					booking_end
					checkin_time
					checkout_time
					booking_status
					employee {
						user {
							email
							profile {
								first_name
								last_name
								suffix
							}
						}
					}
				}
			}
		}
	}
`;

export const GET_DRIVERS = gql`
	query(
		$input: inputDriver
		$service_instances_id: EncryptedString
		$paginate: paginate
	) {
		getDrivers(
			input: $input
			service_instances_id: $service_instances_id
			paginate: $paginate
		) {
			drivers {
				id
				idno
				status
				enabled
				rest_days {
					id
					restday
				}
				user {
					id
					email
					profile {
						first_name
						last_name
						suffix
						mobile_no
						birthdate
					}
				}
			}
			total
		}
	}
`;

export const GET_DRIVER = gql`
	query($input: inputDriver) {
		getDriver(input: $input) {
			id
			idno
			status
			enabled
			rest_days {
				restday
			}
			user {
				id
				email
				profile {
					first_name
					last_name
					suffix
					mobile_no
				}
			}
		}
	}
`;

// fetching drivers without conflict schedules
export const GET_AVAILABLE_DRIVERS = gql`
	query($input: inputAvailableDrivers) {
		getAvailableDrivers(input: $input) {
			id
			user {
				email
				profile {
					first_name
					last_name
				}
			}
			rest_days {
				id
				restday
			}
		}
	}
`;

// fetching vehicles in good condition and no conflict schedules
export const GET_VEHICLES = gql`
	query(
		$input: inputVehicle
		$service_instances_id: EncryptedString
		$paginate: paginate
	) {
		getVehicles(
			input: $input
			service_instances_id: $service_instances_id
			paginate: $paginate
		) {
			vehicles {
				id
				plate_number
				coding_day
				make
				model
				capacity
				status
				enabled
				status
				enabled
			}
			total
		}
	}
`;

// fetching vehicles in good condition and no conflict schedules
export const GET_VEHICLE = gql`
	query($input: inputVehicle) {
		getVehicle(input: $input) {
			id
			plate_number
			coding_day
			make
			model
			capacity
		}
	}
`;

// fetching vehicles in good condition and no conflict schedules
export const GET_AVAILABLE_VEHICLES = gql`
	query($input: inputAvailableVehicles) {
		getAvailableVehicles(input: $input) {
			id
			plate_number
			coding_day
			make
			model
			capacity
			status
		}
	}
`;

// get all routes
export const GET_ROUTES = gql`
	query($shuttles_id: EncryptedString, $filter: String) {
		getRoutes(shuttles_id: $shuttles_id, filter: $filter) {
			id
			site_origin {
				id
				name
			}
			site_destination {
				id
				name
			}
			enabled
		}
	}
`;

export const CHECK_DUPLICATE_DRIVERS = gql`
	query checkDuplicateDrivers(
		$service_instances_id: EncryptedString
		$drivers: [inputDrivers]
	) {
		checkDuplicateDrivers(
			service_instances_id: $service_instances_id
			drivers: $drivers
		) {
			status
			message
		}
	}
`;

export const CHECK_DUPLICATE_VEHICLES = gql`
	query checkDuplicateVehicle(
		$sites_id: EncryptedString
		$vehicles: [inputVehicles]
	) {
		checkDuplicateVehicle(sites_id: $sites_id, vehicles: $vehicles) {
			status
			message
		}
	}
`;

export const GET_TRIP_SCHEDULER = gql`
	query($input: inputTripScheduler) {
		getTripScheduler(input: $input) {
			tripSchedulerData {
				id
				enabled
				routes {
					id
					enabled
					site_destination {
						id
						name
					}
					site_origin {
						id
						name
					}
				}
				trip_schedules {
					id
					day
					time
					bookings_count
					route {
						id
					}
					vehicle {
						plate_number
						make
						model
						id
					}
					driver {
						id
						idno
						status
						user {
							email
							profile {
								first_name
								last_name
							}
						}
					}
				}
				availableTime {
					time
				}
			}
		}
	}
`;

export const GET_TRIPS_TODAY = gql`
	query($input: inputTripsToday, $paginate: paginate) {
		getTripsToday(input: $input, paginate: $paginate) {
			trips {
				id
				status
				idno
				trip_date
				total_bookings
				vehicle {
					id
					plate_number
					make
					model
				}
				driver {
					id
					idno
					user {
						id
						profile {
							first_name
							last_name
						}
					}
				}
				route {
					id
					site_origin {
						id
						name
					}
					site_destination {
						id
						name
					}
				}
				trip_schedule {
					id
					time
				}
			}
			total_records
			checkTripsToday
		}
	}
`;

export const GET_SHUTTLE_SERVICE = gql`
	query($users_id: EncryptedString!) {
		getUsersShuttleService(users_id: $users_id) {
			id
			site {
				id
				name
				abbrev
			}
			shuttle {
				id
			}
		}
	}
`;

export const GET_DISTINCT_MAKE = gql`
	query($service_instances_id: EncryptedString!, $filter: String) {
		getDistinctMake(
			service_instances_id: $service_instances_id
			filter: $filter
		) {
			name
		}
	}
`;

export const GET_DISTINCT_MODEL = gql`
	query($service_instances_id: EncryptedString!, $filter: String) {
		getDistinctModel(
			service_instances_id: $service_instances_id
			filter: $filter
		) {
			name
		}
	}
`;

export const GET_TRIP_ORIGINS = gql`
	query($sites_id: EncryptedString!, $keyword: String) {
		getTripOrigins(sites_id: $sites_id, keyword: $keyword) {
			id
			name
		}
	}
`;

export const GET_TRIP_DESTINATIONS = gql`
	query($sites_id: EncryptedString!, $keyword: String) {
		getTripDestinations(sites_id: $sites_id, keyword: $keyword) {
			id
			name
		}
	}
`;

export const GET_BOOKING_VEHICLES = gql`
	query($service_instances_id: EncryptedString!, $keyword: String) {
		getBookingVehicles(
			service_instances_id: $service_instances_id
			keyword: $keyword
		) {
			id
			plate_number
			make
			model
		}
	}
`;

export const GET_BOOKING_DRIVERS = gql`
	query($service_instances_id: EncryptedString!, $keyword: String) {
		getBookingDrivers(
			service_instances_id: $service_instances_id
			keyword: $keyword
		) {
			id
			user {
				profile {
					first_name
					last_name
				}
			}
		}
	}
`;

export const GET_TRIP_TODAY_DRIVERS = gql`
	query($service_instances_id: EncryptedString, $keyword: String) {
		getTripsTodayDrivers(
			service_instances_id: $service_instances_id
			keyword: $keyword
		) {
			id
			user {
				id
				email
				profile {
					first_name
					last_name
				}
			}
			rest_days {
				drivers_id
				restday
			}
		}
	}
`;

export const GET_TRIP_TODAY_VEHICLE = gql`
	query($service_instances_id: EncryptedString, $keyword: String) {
		getTripsTodayVehicles(
			service_instances_id: $service_instances_id
			keyword: $keyword
		) {
			id
			plate_number
			coding_day
			make
			model
			capacity
			status
		}
	}
`;

export const GET_AVAILABLE_ROUTES = gql`
	query($input: inputRoutesAvailable) {
		getAvailableRoutes(input: $input) {
			originData {
				id
				name
			}
			destinationData {
				id
				name
			}
		}
	}
`;

export const CHECK_SHARED_SLOTS = gql`
	query($input: inputCheckSlots) {
		checkDuplicateSharedSlot(input: $input) {
			status
			message
		}
	}
`;

export const GET_SHARED_SLOTS = gql`
	query($input: inputSharedSlot, $paginate: paginate, $sort: sortOrder) {
		getSharedSlots(input: $input, paginate: $paginate, sort: $sort) {
			slots {
				id
				workdeskID
				name
				area
				booking_status
				state
				floor {
					id
					name
				}
				user {
					id
					profile {
						first_name
						last_name
						suffix
					}
				}
				shared_slots {
					id
					date_start
					date_end
					date_created
					enabled
				}
			}
			total
		}
	}
`;

export const Driver = {
	id: '',
	user: {
		id: '',
		email: '',
		profile: {
			first_name: '',
			last_name: '',
			birthdate: '',
			mobile_no: '',
		},
	},
	rest_days: [
		{ restday: 1, is_available: 1 },
		{ restday: 2, is_available: 1 },
		{ restday: 3, is_available: 1 },
		{ restday: 4, is_available: 1 },
		{ restday: 5, is_available: 1 },
		{ restday: 6, is_available: 0 },
		{ restday: 0, is_available: 0 },
	],
};

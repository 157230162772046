// react lib
import React, { useState, useEffect, useContext, useMemo } from 'react';

// semantic ui components
import {
	Header,
	Form,
	Select,
	Button,
	Icon,
	Grid,
	Dropdown,
} from 'semantic-ui-react';

import Chart from 'react-google-charts';

// components
import SpinningKit from '../../../../components/SpinningKit/SpinningKit';

// moment - for date helper
import * as moment from 'moment';

// props mapping
import PropTypes from 'prop-types';

// queries
import {
	GET_BOOKING_STATISTICS,
	GET_FLOOR_BOOKINGS,
	GET_TRIP_BOOKINGS,
	GET_BOOKING_VEHICLES,
	GET_BOOKING_DRIVERS,
} from '../../graphql/query';

// apollo hooks
import { useApolloClient, useLazyQuery } from '@apollo/client';

import LoaderContext from '../../../../context/LoaderContext/LoaderContext';
import { ServiceTypes } from '../../../../models/ServiceTypes';

import {
	generateExcelReport,
	splitString,
	sorterByMonthAndYear,
	decrypt,
} from '../../../../utils';

import './Reports.css';
import ReportsTable from './Reports-Table/Reports-Table';
import useDebounce from '../../../../hooks/useDebounce';

require('moment-duration-format');

const downloadImg = process.env.REACT_APP_BASE_NAME + 'svgs/download.svg';

const Reports = ({
	slots,
	onChangeWorkdeskSort,
	workdeskSort,
	onNavigateToReportItem,
	floors,
	filter,
	handleChangeFilter,
	paginate,
	onPageChange,
	service,
	site,
	onFilterFloorsDropdowwn,
	floorLoading,
	ServiceTypes,
	tripFilter,
	handleTripFilterChange,
	tripOrigins,
	tripDestinations,
	setFilter,
	vehiclesKeyword,
	driversKeyword,
	onFilterVehiclePlateNumber,
	onFilterDriverName,
	searchFloorString,
	onFilterOrigin,
	originsKeyword,
	tripOriginLoading,
	tripDestinationLoading,
	destinationKeyword,
	onFilterDestination,
	vehiclesOptions,
	setVehiclesOptions,
	driversOptions,
	setDriversOptions,
}) => {
	let tableDatas;
	const service_type =
		ServiceTypes[
			JSON.parse(decrypt(localStorage.getItem('service_type'))).name
		];
	const client = useApolloClient();
	const { showLoading, hideLoading } = useContext(LoaderContext);
	const [monthOptions, setMonthOptions] = useState([]);
	const [yearOptions, setYearOptions] = useState([]);
	const [rangeOptions, setRangeOptions] = useState([]);
	const [statisticLoading, setStatisticLoading] = useState(false);
	// const [vehiclesOptions, setVehiclesOptions] = useState([]);
	// const [driversOptions, setDriversOptions] = useState([]);
	const [statistics, setStatistics] = useState({
		bookings_count: 0,
		checked_in_count: 0,
		monthly_statistics: [],
	});
	// const [vehiclesKeyword, setVehiclesKeyword] = useState(null);
	// const [driversKeyword, setDriversKeyword] = useState(null);

	const [dateFilter, setDateFilter] = useState({
		range: 13,
		year: parseInt(moment().format('YYYY')),
		month: parseInt(moment().format('M')),
	});

	// changes every 500ms of vehiclesKeyword changes
	const debouncedVehiclesKeyword = useDebounce(vehiclesKeyword, 500);
	// changes every 500ms of driversKeyword changes
	const debouncedDriversKeyword = useDebounce(driversKeyword, 500);

	// onComponentDidMount
	useEffect(() => {
		const months = [];
		const ranges = [];
		const years = [];
		for (let i = 0; i < parseInt(moment().format('M')); i++) {
			months.push({
				key: i,
				text: moment()
					.month(i)
					.format('MMMM'),
				value: i + 1,
			});
		}
		setMonthOptions(months);

		// looping year starting 2020 until today's year
		for (let i = 2020; i <= moment().format('YYYY'); i++) {
			years.push({
				key: i,
				text: i,
				value: i,
			});
		}

		// looping months comparison - 13 is max
		for (let i = 0; i < 13; i++) {
			const monthCount = i + 1;
			ranges.push({
				key: monthCount,
				text: `${monthCount} ${
					monthCount === 1 ? 'month' : 'months'
				} comparison`,
				value: monthCount,
			});
		}

		setMonthOptions(months);
		setYearOptions(years);
		setRangeOptions(ranges);

		return () => {
			setDateFilter({
				range: 13,
				year: parseInt(moment().format('YYYY')),
				month: parseInt(moment().format('M')),
			});

			// setFilter({
			// 	...filter,
			// 	year: parseInt(moment().format('YYYY')),
			// 	month: parseInt(moment().format('M')),
			// });
			setFilter({
				floors_id: null,
				area: null,
				booking_status: null, // 0-disabled, 1-enabled,
				state: null,
				year: parseInt(moment().format('YYYY')),
				month: parseInt(moment().format('M')),
			});
		};
	}, []);

	if (service_type.name === 'Shuttle') {
		useEffect(() => {
			getBookingVehicles({
				variables: {
					service_instances_id: service.id,
					keyword: vehiclesKeyword,
				},
			});
		}, [debouncedVehiclesKeyword]);

		useEffect(() => {
			getBookingDrivers({
				variables: {
					service_instances_id: service.id,
					keyword: debouncedDriversKeyword,
				},
			});
		}, [debouncedDriversKeyword]);
	}

	// run this function when year on dateFilter was changed
	useEffect(() => {
		const months = [];
		if (parseInt(dateFilter.year) === parseInt(moment().format('YYYY'))) {
			for (let i = 0; i < parseInt(moment().format('M')); i++) {
				months.push({
					key: i,
					text: moment()
						.month(i)
						.format('MMMM'),
					value: i + 1,
				});
			}
		} else {
			for (let i = 0; i < 12; i++) {
				months.push({
					key: i,
					text: moment()
						.month(i)
						.format('MMMM'),
					value: i + 1,
				});
			}
		}
		setMonthOptions(months);
	}, [dateFilter.year]);

	useEffect(() => {
		getBookingStatistics();
	}, [dateFilter, getBookingStatistics]);

	const [getBookingVehicles, { loading: vehiclesLoading }] = useLazyQuery(
		GET_BOOKING_VEHICLES,
		{
			onCompleted: data => {
				if (
					data &&
					data.getBookingVehicles &&
					data.getBookingVehicles.length > 0
				) {
					setVehiclesOptions(data.getBookingVehicles);
				}
			},
		}
	);

	const [getBookingDrivers, { loading: driversLoading }] = useLazyQuery(
		GET_BOOKING_DRIVERS,
		{
			onCompleted: data => {
				if (
					data &&
					data.getBookingDrivers &&
					data.getBookingDrivers.length > 0
				) {
					setDriversOptions(data.getBookingDrivers);
				}
			},
		}
	);

	// const getBookingVehicles = async () => {
	// 	if (service_type && service_type.name === 'Shuttle') {
	// 		const vehicles = await client.query({
	// 			query: GET_BOOKING_VEHICLES,
	// 			variables: {
	// 				service_instances_id: service.id,
	// 			},
	// 		});

	// 		const drivers = await client.query({
	// 			query: GET_BOOKING_DRIVERS,
	// 			variables: {
	// 				service_instances_id: service.id,
	// 			},
	// 		});

	// 		setVehiclesOptions(vehicles.data.getBookingVehicles);
	// 		setDriversOptions(drivers.data.getBookingDrivers);
	// 	}
	// };

	// function for fetching booking statistics
	const getBookingStatistics = async () => {
		setStatisticLoading(prev => !prev);
		showLoading();
		const statistics = await client.query({
			query: GET_BOOKING_STATISTICS,
			variables: {
				input: {
					...dateFilter,
					month: dateFilter.month,
					service_instances_id: service.id,
				},
				service_types_id:
					service && service.service_type
						? service.service_type.id
						: null,
			},
		});

		setStatistics({
			bookings_count: statistics.data.getBookingStatistic.bookings_count,
			checked_in_count:
				statistics.data.getBookingStatistic.checked_in_count,
			monthly_statistics: statistics.data.getBookingStatistic.monthly_statistics
				.map(statistic => {
					const month = splitString(statistic.month)[0];
					const year = parseInt(splitString(statistic.month)[1]);
					return {
						...statistic,
						year,
						month,
					};
				})
				.sort(sorterByMonthAndYear),
			total_earnings: statistics.data.getBookingStatistic.total_earnings,
		});

		hideLoading();

		setStatisticLoading(prev => !prev);
	};

	// onchange date filter
	const onChangeDateFilter = async (key, value) => {
		if (key === 'year') {
			if (parseInt(moment().format('YYYY')) === parseInt(value)) {
				setDateFilter({
					...dateFilter,
					[key]: value,
					month: parseInt(moment().format('M')),
				});
				setFilter({
					...filter,
					[key]: value,
					month: parseInt(moment().format('M')),
				});
			} else {
				setDateFilter({
					...dateFilter,
					[key]: value,
					month: 1,
				});
				setFilter({
					...filter,
					[key]: value,
					month: 1,
				});
			}
		} else {
			setDateFilter({
				...dateFilter,
				[key]: value,
			});
			setFilter({
				...filter,
				[key]: value,
			});
		}
	};

	// generate excel report
	const downloadExcelReport = async () => {
		let excelDatas = [];
		let excelHeaders = [];
		let excelFilename, excelTitle;
		let services;
		if (service_type.name !== 'Shuttle') {
			const result = await client.query({
				query: GET_FLOOR_BOOKINGS,
				fetchPolicy: 'network-only',
				variables: {
					input: {
						booking_status: null,
						month: parseInt(dateFilter.month).toString(),
						year: dateFilter.year.toString(),
						service_instances_id: service.id,
					},
					paginate: {
						page: 1,
						offset: null,
					},
				},
			});
			excelDatas =
				result.data &&
				result.data.getFloorBookings.bookings &&
				result.data.getFloorBookings.bookings.length > 0
					? result.data.getFloorBookings.bookings.map(booking => {
							const checkedIn = moment(booking.checkin_time);
							const checkedOut = moment(booking.checkout_time);
							const totalHours = moment
								.duration(checkedOut.diff(checkedIn))
								.asMinutes();
							let time_used;

							if (totalHours >= 1) {
								time_used = moment
									.duration(totalHours, 'minutes')
									.format('h [hours], m [minutes]', {
										trim: 'all',
									});
							} else if (totalHours < 1) {
								time_used = '0 minutes';
							} else {
								time_used = 'none';
							}

							return [
								booking.slot.floor.name,
								booking.slot.area,
								booking.slot.workdeskID,
								booking.slot.name,
								moment(booking.booking_start).format(
									'MMM D, YYYY'
								),
								moment(booking.booking_start).format('h:mm A'),

								booking.checkin_time
									? moment(booking.checkin_time).format(
											'MMM D, YYYY'
									  )
									: 'none',

								booking.checkin_time
									? moment(booking.checkin_time).format(
											'h:mm A'
									  )
									: 'none',

								booking.checkout_time
									? moment(booking.checkout_time).format(
											'h:mm A'
									  )
									: 'none',
								time_used,

								`${booking.employee.user.profile.first_name} ${
									booking.employee.user.profile.last_name
								} ${
									booking.employee.user.profile.suffix
										? booking.employee.user.profile.suffix.toLowerCase() ===
										  'n/a'
											? ''
											: booking.employee.user.profile
													.suffix
										: ''
								}`,

								booking.employee.user.email,
								booking.payment
									? booking.payment.status
										? `GCASH: ${booking.payment.ref_no}`
										: 'none'
									: 'none',
							];
					  })
					: [];
			excelHeaders = [
				'Floor #',
				'Area',
				service_type.site_dashboard_column_id,
				service_type.site_dashboard_column_name,
				'Booking date',
				'Booking time',
				'Check-in date',
				'Check-in time',
				'Check-out time',
				'Total time used',
				'Employee name',
				'E-mail address',
				service.name === 'Parking' ? 'Payment' : null,
			];
		} else {
			const result = await client.query({
				query: GET_TRIP_BOOKINGS,
				fetchPolicy: 'network-only',
				variables: {
					input: {
						booking_status: null,
						month: parseInt(dateFilter.month).toString(),
						year: dateFilter.year.toString(),
						service_instances_id: service.id,
					},
					paginate: {
						page: 1,
						offset: null,
					},
				},
			});
			excelDatas =
				result.data &&
				result.data.getTripSlotBookings.bookings &&
				result.data.getTripSlotBookings.bookings.length > 0
					? result.data.getTripSlotBookings.bookings.map(booking => {
							const checkedIn = moment(booking.checkin_time);
							const checkedOut = moment(booking.checkout_time);
							const totalHours = moment
								.duration(checkedOut.diff(checkedIn))
								.asMinutes();

							let time_used;

							if (totalHours >= 1) {
								time_used = moment
									.duration(totalHours, 'minutes')
									.format('h [hours], m [minutes]', {
										trim: 'all',
									});
							} else if (totalHours < 1) {
								time_used = '0 minutes';
							} else {
								time_used = 'none';
							}

							return [
								booking.trip_slot.idno,
								booking.trip_slot.vehicle.plate_number,
								`${booking.trip_slot.driver.user.profile.first_name} ${booking.trip_slot.driver.user.profile.last_name}`,
								`${booking.trip_slot.route.site_origin.name} - ${booking.trip_slot.route.site_destination.name}`,
								moment(booking.booking_start).format(
									'MMM D, YYYY'
								),
								moment(booking.booking_start).format('h:mm A'),

								booking.checkin_time
									? moment(booking.checkin_time).format(
											'MMM D, YYYY'
									  )
									: 'none',

								booking.checkin_time
									? moment(booking.checkin_time).format(
											'h:mm A'
									  )
									: 'none',

								booking.checkout_time
									? moment(booking.checkout_time).format(
											'h:mm A'
									  )
									: 'none',

								time_used,

								`${booking.employee.user.profile.first_name} ${
									booking.employee.user.profile.last_name
								} ${
									booking.employee.user.profile.suffix
										? booking.employee.user.profile.suffix.toLowerCase() ===
										  'n/a'
											? ''
											: booking.employee.user.profile
													.suffix
										: ''
								}`,

								booking.employee.user.email,
								booking.is_chance_passenger ? 'Yes' : 'No',
							];
					  })
					: [];

			excelHeaders = [
				'Trip ID',
				'Vehicle Plate',
				'Driver Name',
				'Route',
				'Booking date',
				'Booking time',
				'Check-in date',
				'Check-in time',
				'Check-out time',
				'Elapsed time',
				'Employee name',
				'E-mail address',
				'Chance Passenger',
			];
		}

		// [Servicename]:[Bldg abbreviation][Month][Year]_Report
		excelTitle = `${service_type.name}: ${site.name} - ${moment()
			.month(dateFilter.month - 1)
			.format('MMMM')} ${dateFilter.year} Report`;

		excelFilename = `${service_type.name}_${site.name}-${moment()
			.month(dateFilter.month - 1)
			.format('MMMM')} - ${dateFilter.year} Report.xlsx`;

		//service type
		services = service.name;

		generateExcelReport({
			excelFilename,
			excelHeaders,
			excelTitle,
			excelDatas,
			services,
		});
	};
	// table headers
	const tableHeaders = [
		{
			name:
				ServiceTypes[service.service_type.name]
					.site_dashboard_column_id,
		},
		...(service_type.name !== 'Shuttle'
			? [
					{
						name:
							ServiceTypes[service.service_type.name]
								.site_dashboard_column_name,
					},
			  ]
			: []),
		{
			name:
				service.service_type.name === 'Shuttle'
					? 'Date & Time'
					: 'Floor',
		},
		{
			name: service.service_type.name === 'Shuttle' ? 'Route' : 'Area',
		},
		{
			name:
				service.service_type.name === 'Shuttle' ? (
					'Vehicle'
				) : (
					<Button
						basic
						inverted
						className="btn-header"
						key="button"
						onClick={() => onChangeWorkdeskSort('total_bookings')}
					>
						Booking count&nbsp;&nbsp;
						<Icon
							name={
								!Object.prototype.hasOwnProperty.call(
									workdeskSort,
									'total_bookings'
								)
									? 'sort'
									: workdeskSort.total_bookings === true
									? 'sort down'
									: 'sort up'
							}
						/>
					</Button>
				),
			options: {
				...(service.service_type.name !== 'Shuttle'
					? {
							className: 'sorting-cell',
							style: {
								textAlign: 'right',
							},
					  }
					: {}),
			},
		},
		{
			name:
				service.service_type.name === 'Shuttle' ? (
					'Driver'
				) : (
					<Button
						basic
						inverted
						className="btn-header"
						key="button"
						onClick={() => onChangeWorkdeskSort('total_checked_in')}
					>
						Check-in count&nbsp;&nbsp;
						<Icon
							name={
								!Object.prototype.hasOwnProperty.call(
									workdeskSort,
									'total_checked_in'
								)
									? 'sort'
									: workdeskSort.total_checked_in === true
									? 'sort down'
									: 'sort up'
							}
						/>
					</Button>
				),
			options: {
				...(service.service_type.name !== 'Shuttle'
					? {
							className: 'sorting-cell',
							style: {
								textAlign: 'right',
							},
					  }
					: {}),
			},
		},
		{
			name:
				service.service_type.name === 'Shuttle' ? (
					<Button
						basic
						inverted
						className="btn-header"
						key="button"
						onClick={() => onChangeWorkdeskSort('total_bookings')}
					>
						Check-in&nbsp;/&nbsp;Booking&nbsp;&nbsp;
						<Icon
							name={
								!Object.prototype.hasOwnProperty.call(
									workdeskSort,
									'total_bookings'
								)
									? 'sort'
									: workdeskSort.total_bookings === true
									? 'sort down'
									: 'sort up'
							}
						/>
					</Button>
				) : (
					'Action'
				),
			options: {
				...(service.service_type.name === 'Shuttle'
					? {
							className: 'sorting-cell',
							style: {
								textAlign: 'right',
							},
					  }
					: {}),
			},
		},
		...(service.service_type.name === 'Shuttle' ? ['Action'] : []),
	];
	if (service_type.name === 'Shuttle') {
		// table datas
		tableDatas = slots.trips
			? slots.trips.map(trip => {
					return {
						tripID: {
							data: trip.idno,
							options: {
								width: 2,
							},
						},
						trip_date: {
							data: moment(trip.trip_date).format(
								'MMMM DD, YYYY hh:mm A'
							),
							options: {
								width: 3,
							},
						},
						route: {
							data: `${trip.route.site_origin.name} - ${trip.route.site_destination.name}`,
							options: {
								width: 3,
							},
						},
						vehicle: {
							data: `${trip.vehicle.make} ${trip.vehicle.model} ${trip.vehicle.plate_number}`,
							options: {
								width: 2,
							},
						},
						driver: {
							data: `${trip.driver.user.profile.first_name} ${trip.driver.user.profile.last_name}`,
							options: {
								width: 2,
							},
						},
						checkin_booking: {
							data: `${trip.total_checked_in} / ${trip.total_bookings}`,
							options: {
								textAlign: 'right',
								width: 1,
							},
						},
						action: {
							data: (
								<a
									className="btnLink"
									onClick={() => onNavigateToReportItem(trip)}
								>
									View
								</a>
							),
							options: {
								width: 1,
							},
						},
					};
			  })
			: [];
	} else {
		// table datas
		tableDatas = slots.slots
			? slots.slots.map(slot => {
					return {
						workdeskID: {
							data: slot.workdeskID,
							options: {
								className: 'w-200px',
							},
						},
						name: {
							data: slot.name,
							options: {
								className: 'w-200px',
							},
						},
						floor: {
							data: slot.floor.name,
							options: {
								className: 'w-150px',
							},
						},
						area: {
							data: slot.area,
							options: {
								className: 'w-150px',
							},
						},
						total_bookings: {
							data: slot.total_bookings,
							options: {
								textAlign: 'right',
								className: 'w-100px',
							},
						},
						total_checked_in: {
							data: slot.total_checked_in,
							options: {
								textAlign: 'right',
								className: 'w-200px',
							},
						},
						actions: {
							data: (
								<a
									className="btnLink"
									onClick={() => onNavigateToReportItem(slot)}
								>
									View
								</a>
							),
							options: {
								className: 'w-150px',
							},
						},
					};
			  })
			: [];
	}

	return (
		<div className="site-item">
			<div className="table-list">
				<div className="table-list-filter">
					<Form>
						<Form.Group inline>
							<label>Showing</label>
							<Form.Field>
								<Form.Field
									control={Select}
									options={monthOptions}
									value={dateFilter.month}
									onChange={(evt, { value }) =>
										onChangeDateFilter('month', value)
									}
								/>
							</Form.Field>
							<Form.Field>
								<Form.Field
									control={Select}
									options={yearOptions}
									value={dateFilter.year}
									onChange={(evt, { value }) =>
										onChangeDateFilter('year', value)
									}
								/>
							</Form.Field>
							<div className="table-list-actions">
								<a
									className="ui button app-primary-button"
									onClick={() => downloadExcelReport()}
								>
									<img
										src={downloadImg}
										className="download-icon "
									/>
									Download
								</a>
							</div>
						</Form.Group>
					</Form>
				</div>
			</div>
			<Grid>
				<Grid.Column mobile={16} table={6} computer={5}>
					<div className="report-card">
						<Header as="h5">
							{moment()
								.month(dateFilter.month - 1)
								.format('MMMM')}{' '}
							{dateFilter.year} summary
						</Header>
						<div className="report-row">
							<div className="report-row-item">
								{' '}
								<Icon
									name="circle"
									size="tiny"
									className="booking-bullet"
								/>
								<span className="count-label">Bookings</span>
							</div>
							<div className="report-row-item">
								{statisticLoading ? (
									<SpinningKit />
								) : (
									statistics.bookings_count
								)}
							</div>
						</div>
						<div className="report-row">
							<div className="report-row-item">
								{' '}
								<Icon
									name="circle"
									size="tiny"
									className="checkedIn-bullet"
								/>
								<span className="count-label">Checked-in</span>
							</div>
							<div className="report-row-item">
								{statisticLoading ? (
									<SpinningKit />
								) : (
									statistics.checked_in_count
								)}
							</div>
						</div>
						{service_type.name === 'Parking' && (
							<div className="report-row">
								<div className="report-row-item">
									{' '}
									<span className="count-label">
										Total earnings (PHP)
									</span>
								</div>
								<div className="report-row-item">
									{statisticLoading ? (
										<SpinningKit />
									) : statistics.total_earnings ? (
										statistics.total_earnings.toFixed(2)
									) : (
										'0.00'
									)}
								</div>
							</div>
						)}
					</div>
				</Grid.Column>
				<Grid.Column mobile={16} tablet={10} computer={11}>
					<div className="report-card">
						<div className="report-card-header">
							<div className="report-card-title">
								<Header as="h5" className="pt-2 pb-2">
									Monthly comparison
								</Header>
								<div className="report-card-subtitle">
									<div className="report-card-subtitle-item cancelled">
										Cancelled/no show
									</div>
									<div className="report-card-subtitle-item checkedin">
										Checked-in
									</div>
								</div>
							</div>
							<div className="report-card-action">
								<label>Range:</label>
								<Dropdown
									placeholder="Select comparison"
									className="range-options"
									fluid
									selection
									options={rangeOptions}
									value={dateFilter.range}
									onChange={(evt, { value }) =>
										onChangeDateFilter('range', value)
									}
								/>
							</div>
						</div>
						<div>
							{useMemo(() => {
								return statistics.monthly_statistics.length >
									0 ? (
									<Chart
										width={'100%'}
										height={'300px'}
										chartType="ColumnChart"
										loader={<SpinningKit />}
										data={[
											[
												'Month',
												'Checked-in',
												{
													role: 'tooltip',
													type: 'string',
													p: { html: true },
												},
												'Cancelled/No show',
												{
													role: 'tooltip',
													type: 'string',
													p: { html: true },
												},
											],
											...statistics.monthly_statistics.map(
												monthly_statistic => {
													return [
														moment()
															.month(
																monthly_statistic.month
															)
															.format('MMM'),
														monthly_statistic.total_checkedin,
														`<div class="chart-tooltip">
															<p>
																<b>${monthly_statistic.month} ${monthly_statistic.year}</b>
															</p>
															<div>
																<span>
																	Total booking: ${monthly_statistic.total}
																</span>
															</div>
															<div>
																<span>
																	Checked-in: ${monthly_statistic.total_checkedin}
																</span>
															</div>
															<div>
																<span>
																	Cancelled: ${monthly_statistic.total_cancelled}
																</span>
															</div>
														</div>`,
														monthly_statistic.total_cancelled,
														`<div class="chart-tooltip">
															<p>
																<b>${monthly_statistic.month} ${monthly_statistic.year}</b>
															</p>
															<div>
																<span>
																	Total booking: ${monthly_statistic.total}
																</span>
															</div>
															<div>
																<span>
																	Checked-in: ${monthly_statistic.total_checkedin}
																</span>
															</div>
															<div>
																<span>
																	Cancelled: ${monthly_statistic.total_cancelled}
																</span>
															</div>
														</div>`,
													];
												}
											),
										]}
										options={{
											title: 'Monthly comparison',
											isStacked: true,
											legend: { position: 'none' },
											bar: { groupWidth: '20%' },
											colors: ['#0056BF', '#D1504B'],
											chartArea: {
												left: 0,
												top: 0,
												width: '100%',
												height: '90%',
											},
											width: '100%',
											tooltip: {
												isHtml: true,
											},
											hAxis: {
												title: '',
												minValue: 0,
												textStyle: {
													fontSize: 12, // or the number you want
												},
											},
											vAxis: {
												// title: 'Attendees',
												textPosition: 'none',
											},
										}}
										// For tests
										rootProps={{ 'data-testid': '3' }}
									/>
								) : (
									<div className="text-center">
										<h5>
											No data available on the selected
											date
										</h5>
									</div>
								);
							}, [statistics.monthly_statistics])}
						</div>
					</div>
				</Grid.Column>
			</Grid>
			<div style={{ padding: '16px 0' }}>
				<h3>
					{service_type.name === 'Shuttle'
						? 'Booking list'
						: 'Booking details'}
				</h3>
			</div>
			<ReportsTable
				tableHeaders={tableHeaders}
				tableDatas={tableDatas}
				paginate={paginate}
				slots={slots}
				onPageChange={onPageChange}
				handleChangeFilter={handleChangeFilter}
				onFilterFloorsDropdowwn={onFilterFloorsDropdowwn}
				filter={filter}
				floorLoading={floorLoading}
				floors={floors}
				service={service}
				tripFilter={tripFilter}
				handleTripFilterChange={handleTripFilterChange}
				tripDestinations={tripDestinations}
				{...(service.service_type.name === 'Shuttle' && {
					vehicles: vehiclesOptions,
					drivers: driversOptions,
					driversLoading,
					vehiclesLoading,
					onFilterVehiclePlateNumber: onFilterVehiclePlateNumber,
					onFilterDriverName: onFilterDriverName,
					vehiclesKeyword: vehiclesKeyword,
					driversKeyword: driversKeyword,
					originsKeyword: originsKeyword,
					onFilterOrigin: onFilterOrigin,
					tripOrigins: tripOrigins,
					tripDestinations: tripDestinations,
					tripOriginLoading: tripOriginLoading,
					tripDestinationLoading: tripDestinationLoading,
					destinationKeyword: destinationKeyword,
					onFilterDestination: onFilterDestination,
				})}
				searchFloorString={searchFloorString}
			/>
		</div>
	);
};

Reports.propTypes = {
	slots: PropTypes.object,
	onChangeWorkdeskSort: PropTypes.func,
	workdeskSort: PropTypes.object,
	onNavigateToReportItem: PropTypes.func,
	floors: PropTypes.array,
	filter: PropTypes.object,
	handleChangeFilter: PropTypes.func,
	paginate: PropTypes.object,
	onPageChange: PropTypes.func,
	service: PropTypes.object,
	site: PropTypes.object,
	onFilterFloorsDropdowwn: PropTypes.func,
	floorLoading: PropTypes.bool,
	ServiceTypes: PropTypes.object,
	tripFilter: PropTypes.object,
	handleTripFilterChange: PropTypes.func,
	tripOrigins: PropTypes.array,
	tripDestinations: PropTypes.array,
	setFilter: PropTypes.any,
	vehiclesKeyword: PropTypes.string,
	driversKeyword: PropTypes.string,
	onFilterVehiclePlateNumber: PropTypes.func,
	onFilterDriverName: PropTypes.func,
	searchFloorString: PropTypes.string,
	onFilterOrigin: PropTypes.func,
	originsKeyword: PropTypes.string,
	tripOriginLoading: PropTypes.bool,
	tripDestinationLoading: PropTypes.bool,
	destinationKeyword: PropTypes.string,
	onFilterDestination: PropTypes.func,
	vehiclesOptions: PropTypes.array,
	setVehiclesOptions: PropTypes.any,
	driversOptions: PropTypes.array,
	setDriversOptions: PropTypes.any,
};

export default Reports;

import gql from 'graphql-tag';

export const GET_USER_SERVICETYPES = gql`
	query($users_id: EncryptedString!) {
		getUserServiceTypes(users_id: $users_id) {
			id
			name
		}
	}
`;

export const GET_SHUTTLE_SERVICE = gql`
	query($users_id: EncryptedString!) {
		getUsersShuttleService(users_id: $users_id) {
			id
			site {
				id
				name
			}
		}
	}
`;

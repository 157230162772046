import PropTypes from 'prop-types';
// react lib
import React, { useState, useEffect, useContext } from 'react';

// less (styling)
import './CompanySite.css';

// semantic ui components
import { Header, Grid, Modal, Button } from 'semantic-ui-react';

// layout
import LayoutPage from '../../../hoc/Layout/Admin/AdminMaster';
import { useQuery } from '@apollo/client';
import { GET_SITES } from '../graphql/query';
import GLoader from '../../../components/Loader/Loader';
// contexts
import AuthContext from '../../../context/AuthContext/AuthContext';
import Page404 from '../../Page404/Page404';

const addIcon = process.env.REACT_APP_BASE_NAME + 'images/more.svg';
const company_site =
	process.env.REACT_APP_BASE_NAME + 'images/company-site-icon.svg';

const CompanySite = props => {
	const [open, setOpen] = useState(false);
	const { user } = useContext(AuthContext);
	useEffect(() => {
		// checking successful account-setup
		if (window.localStorage.getItem('success')) {
			// set modal to true when success
			setOpen(true);
			// removed after modal shows
			window.localStorage.removeItem('success');
		}
	}, []);
	const { loading, error, data } = useQuery(GET_SITES, {
		fetchPolicy: 'network-only',
		variables: {
			companies_id: user.user.company.id,
			limit: null,
		},
	});

	const onClickView = site => {
		props.history.push('companysite-details/' + site.id, {
			breadcrumbs: [
				{
					label: 'Office sites',
					link: 'companysite',
					siteId: site.id,
				},
			],
		});
	};
	if (loading) {
		return <GLoader />;
	}

	if (error) {
		return <Page404 />;
	}

	const onCreateNewSite = () => {
		props.history.push('add-new-companysite/', {
			breadcrumbs: [
				{
					label: 'Office sites',
					link: 'companysite',
				},
				{
					label: 'Create new site',
					link: 'add-new-employee',
				},
			],
		});
	};

	const renderData = data.getSites.map(site => {
		return (
			<div key={site.id} className="company-site-item">
				<div className="item-content">
					<Grid>
						<Grid.Column width="two" className="item-icon">
							<img
								src={company_site}
								className="company-site-icon"
							/>
						</Grid.Column>
						<Grid.Column width="thirteen">
							<div className="site-title">
								<Header as="h3">{site.name}</Header>
							</div>
							<div className="site-title">
								{site.address ? site.address : 'Not specified'}
							</div>
							<div className="site-title">
								<a
									className="btnLink"
									onClick={() => onClickView(site)}
								>
									View site
								</a>
							</div>
						</Grid.Column>
					</Grid>
				</div>
				<div className="item-stat">
					<Grid columns="two">
						<Grid.Column>
							<div className="stat-item">
								({site.site_services_count}) services
							</div>
						</Grid.Column>
						<Grid.Column>
							<div className="stat-item">
								{site.employee_count.toLocaleString(undefined, {
									maximumFractionDigits: 1,
								}) + ' employees'}
							</div>
						</Grid.Column>
					</Grid>
				</div>
			</div>
		);
	});
	return (
		<LayoutPage>
			<div className="offices-header">
				<Header as="h1">Office sites</Header>
				Manage office sites where G Access is available in
			</div>
			<div className="company-site-button">
				<a
					onClick={() => {
						onCreateNewSite();
					}}
					className="ui button app-primary-button create-new-site"
				>
					<img
						src={addIcon}
						className="create-site-icon"
						alt="add icon"
					/>{' '}
					{/* <Icon name="add" /> */}
					<span className="create-site-text">
						&nbsp;Create new site
					</span>
				</a>
			</div>
			<div className="company-site-list">{renderData}</div>
			<Modal
				onClose={() => setOpen(false)}
				open={open}
				size="tiny"
				className="app-modal"
			>
				<Modal.Content>
					<img
						src={`${process.env.REACT_APP_BASE_URL}/images/stamp.png`}
					/>
					<Header as="h3">Account set up complete!</Header>
					<p>
						Learn how to navigate the platform through the
						onboarding
					</p>
					<Button
						content="Proceed"
						className="app-primary-button medium-button"
						onClick={() => setOpen(false)}
					/>
				</Modal.Content>
			</Modal>
		</LayoutPage>
	);
};

CompanySite.propTypes = {
	history: PropTypes.any,
	location: PropTypes.any,
};

export default CompanySite;

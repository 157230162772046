import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
// import * as serviceWorker from './serviceWorker';
import apolloClient from './config/config';
import { ApolloProvider } from '@apollo/client';
import { AuthProvider } from './context/AuthContext/AuthContext';
import { ToastProvider } from './context/ToastContext/ToastContext';

// apollo libraries
// import { createNetworkStatusNotifier } from 'react-apollo-network-status';
// import { createUploadLink } from 'apollo-upload-client';
// import { ApolloLink, ApolloClient, InMemoryCache } from '@apollo/client';
// import { setContext } from '@apollo/client/link/context';
// import Toast from './components/Toast/Toast';
// import Snackbar from '@material-ui/core/Snackbar';
// import MuiAlert from '@material-ui/lab/Alert';

// styles
import './index.css';
import './semantic-override.css';

ReactDOM.render(
	<React.StrictMode>
		<ApolloProvider client={apolloClient}>
			<AuthProvider>
				<App />
			</AuthProvider>
		</ApolloProvider>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();

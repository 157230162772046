import React, { useContext, useState, useEffect } from 'react';

// master layout
import LayoutPage from '../../../hoc/Layout/Admin/AdminMaster';

// style
import './EmployeeList.css';

// semantic ui components
import {
	Header,
	Input,
	Form,
	Select,
	Modal,
	Button,
	Grid,
} from 'semantic-ui-react';

import PropTypes from 'prop-types';

// components
import Table from '../../../components/Table/Table';
import { useApolloClient, useQuery, useMutation } from '@apollo/client';
import AuthContext from '../../../context/AuthContext/AuthContext';
import { GET_COMPANY_EMPLOYEES, GET_SITES } from '../graphql/query';
import GLoader from '../../../components/Loader/Loader';
import { PATCH_COMPANY_EMPLOYEE_STATUS } from '../graphql/mutation';
import LoaderContext from '../../../context/LoaderContext/LoaderContext';
import { GET_COMPANY_GROUPS } from '../../ServiceDashboard/graphql/query';
import useDebounce from '../../../hooks/useDebounce';
import moment from 'moment';
import FocusLock from 'react-focus-lock';
import { concatFullname, encrypt } from '../../../utils';
const searchImg = process.env.REACT_APP_BASE_NAME + 'svgs/search.svg';
const successImg =
	process.env.REACT_APP_BASE_NAME + 'images/setupservice-complete.png';
const waitImg = process.env.REACT_APP_BASE_NAME + 'svgs/wait.svg';

const EmployeeList = props => {
	let employeeStatus;
	const client = useApolloClient();
	const { user } = useContext(AuthContext);
	const { showLoading, hideLoading } = useContext(LoaderContext);
	const [paginate, setPaginate] = useState({
		page: 1,
		offset: 10,
	});

	const [employeeFilter, setEmployeeFilter] = useState({
		type_search: '',
		group: null,
		status: null,
	});
	const [companyEmployees, setCompanyEmployees] = useState([]);
	const [countEmployees, setCountEmployees] = useState([]);
	const [patchEmployeeData, setPatchEmployeeData] = useState({});

	const [patchUnsuccessfulPrompt, setPatchUnsuccessfulPrompt] = useState(
		false
	);
	const [serviceToRedirect, setServiceToRedirect] = useState({});
	const [changeStatusModal, setChangeStatusModal] = useState(false);
	const [selectedEmployee, setSelectedEmployee] = useState({});
	const [siteServiceList, setSiteServiceList] = useState([]);

	useEffect(() => {
		fetchCompanyEmployeeData();
	}, [paginate.page, useDebounce(employeeFilter, 500)]);

	useEffect(() => {
		if (Object.entries(patchEmployeeData).length > 0) {
			props.history.push('add-new-employee/', {
				breadcrumbs: [
					{
						label: 'Employee list',
						link: 'employeelist',
					},
					{
						label: 'Edit employee',
						link: 'add-new-employee',
					},
				],
				companySites,
				companies_id: user.user.company.id,
				patchEmployeeData,
				action: 'edit',
			});
		}
	}, [patchEmployeeData]);

	const { data: companySites, loading: companySiteLoading } = useQuery(
		GET_SITES,
		{
			fetchPolicy: 'network-only',
			variables: {
				companies_id: user.user.company.id,
				limit: null,
			},
		}
	);

	const { data: companyGroup, loading: companyGroupLoading } = useQuery(
		GET_COMPANY_GROUPS,
		{
			fetchPolicy: 'network-only',
			variables: {
				companies_id: user.user.company.id,
			},
		}
	);

	const groups =
		companyGroup &&
		companyGroup.getCompanyGroups.map(item => {
			return {
				text: item.name,
				value: item.id,
			};
		});
	const filterStatus = [
		{
			text: 'All statuses',
			value: 2,
			selected: false,
		},
		{ text: 'Active', value: 1 },
		{ text: 'Suspended', value: 0 },
	];

	const fetchCompanyEmployeeData = async () => {
		showLoading('Loading employee...');

		const result = await client.query({
			query: GET_COMPANY_EMPLOYEES,
			fetchPolicy: 'network-only',
			variables: {
				input: {
					companies_id: user.user.company.id,
					status: employeeFilter.status,
					group_id: employeeFilter.group,
					type_search: employeeFilter.type_search,
					regular_sort:
						props.history.location.state &&
						props.history.location.state.from_post == true &&
						props.history.action !== 'POP' /*history refreshed*/
							? false
							: true,
				},
				paginate,
			},
		});
		setCompanyEmployees(result.data.getCompanyEmployees.employees);
		setCountEmployees({
			total_active: result.data.getCompanyEmployees.total_active,
			total_employees: result.data.getCompanyEmployees.total_employees,
			total_suspended: result.data.getCompanyEmployees.total_suspended,
			total_count: result.data.getCompanyEmployees.total_count,
		});
		hideLoading();
	};
	const [patchEmployeeStatus] = useMutation(PATCH_COMPANY_EMPLOYEE_STATUS, {
		onCompleted: async data => {
			if (data.patchCompanyEmployeeStatus.status) {
				// some action here
			} else {
				setPatchUnsuccessfulPrompt(true);
				if (data.patchCompanyEmployeeStatus.service_instances.length) {
					setSiteServiceList(
						data.patchCompanyEmployeeStatus.service_instances
					);
					setServiceToRedirect(
						data.patchCompanyEmployeeStatus.service_to_redirect
					);
				}
			}
			setChangeStatusModal(false);
		},
	});

	const onPatchStatus = async (id, status) => {
		try {
			await patchEmployeeStatus({
				variables: {
					input: {
						id: id,
						status: status,
					},
				},
			});

			fetchCompanyEmployeeData();
			hideLoading();
		} catch (error) {
			// TODO: global error handler (toast) - INTERNAL_SERVER_ERROR

		}
	};

	const onPageChange = (evt, page) => {
		setPaginate({
			...paginate,
			page: page.activePage,
		});
	};

	const onClickNewEmployee = () => {
		props.history.push('add-new-employee/', {
			breadcrumbs: [
				{
					label: 'Employee list',
					link: 'employeelist',
				},
				{
					label: 'Add new employee',
					link: 'add-new-employee',
				},
			],
			companySites,
			companies_id: user.user.company.id,
		});
	};

	const onClickStatus = employee => {
		setSelectedEmployee(employee);
		if (employee.status) {
			setChangeStatusModal(true);
		} else {
			onPatchStatus(employee.id, true);
			showLoading('Updating record...');
		}
	};

	const onProceedChangeStatus = employee => {
		setSelectedEmployee(employee);

		onPatchStatus(employee.id, employee.status ? false : true);

		showLoading('Updating record...');
	};

	const onClickEdit = employee => {
		setPatchEmployeeData({
			...patchEmployeeData,
			id: employee.id,
			email: employee.email,
			group: employee.employee.group && employee.employee.group.name,
			site: employee.employee.site && employee.employee.site.id,
			date_hired:
				employee.profile.date_hired &&
				moment(employee.profile.date_hired).format('YYYY-MM-DD'),
			first_name: employee.profile.first_name,
			idno: employee.profile.idno && employee.profile.idno,
			last_name: employee.profile.last_name,
			mobile_no: employee.profile.mobile_no && employee.profile.mobile_no,
			position: employee.profile.position && employee.profile.position,
			suffix:
				(employee.profile.suffix &&
					employee.profile.suffix === 'n/a') ||
				employee.profile.suffix === null
					? ''
					: employee.profile.suffix,
			isEmail: employee.supervisor && employee.supervisor.email,
			// is:
			// 	employee.supervisor &&
			// 	employee.supervisor.first_name &&
			// 	employee.supervisor.last_name
			// 		? employee.supervisor &&
			// 		  `${employee.supervisor.profile.first_name} ${
			// 				employee.supervisor.profile.last_name
			// 		  } ${
			// 				employee.supervisor.profile.suffix === 'n/a' ||
			// 				employee.supervisor.profile.suffix === ''
			// 					? ''
			// 					: employee.supervisor.suffix
			// 		  }`
			// 		: '',
			is:
				employee.supervisor &&
				employee.supervisor.profile &&
				`${
					employee.supervisor.profile.first_name
						? employee.supervisor.profile.first_name
						: ''
				} ${
					employee.supervisor.profile.last_name
						? employee.supervisor.profile.last_name
						: ''
				} ${
					employee.supervisor.profile.suffix === 'n/a' ||
					employee.supervisor.profile.suffix === '' ||
					employee.supervisor.profile.suffix === null
						? ''
						: employee.supervisor.profile.suffix
				}`,
		});
	};
	const handleChange = (key, value) => {
		setPaginate({
			...paginate,
			page: 1,
		});
		setEmployeeFilter({
			...employeeFilter,
			[key]: value,
		});
	};

	if (companySiteLoading || companyGroupLoading) {
		return <GLoader />;
	}

	const header = [
		'Employee ID',
		'Employee name',
		'Email address',
		'Group',
		'Status',
		'Action',
	];

	const renderData = companyEmployees.map(employee => {
		if (employee.status) {
			employeeStatus = 'Active';
		} else {
			employeeStatus = 'Suspended';
		}

		return {
			idno: (
				<>
					<a className="btnLink">{employee.profile.idno}</a>
				</>
			),
			name: {
				data: employee.profile
					? // employee.profile.first_name &&
					  // employee.profile.last_name
					  `${
							employee.profile.first_name
								? employee.profile.first_name
								: ''
					  } ${
							employee.profile.last_name
								? employee.profile.last_name
								: ''
					  } ${
							employee.profile.suffix
								? employee.profile.suffix.toLowerCase() ===
								  'n/a'
									? ''
									: employee.profile.suffix
								: ''
					  }`
					: null,
				options: {
					className: 'w-300px',
				},
			},
			email: {
				data: employee.email,
				options: {
					className: 'w-300px',
				},
			},
			group: {
				data: employee.employee.group
					? employee.employee.group.name
					: '',
				options: {
					className: 'w-300px',
				},
			},
			status: {
				data: (
					<div className="employee-status">
						<div
							className={`employee-status-badge ${
								employeeStatus === 'Active'
									? 'employee-status-active'
									: 'employee-status-suspended'
							}`}
						></div>
						<span className="status-text">{employeeStatus}</span>
					</div>
				),
				options: {
					className: 'w-200px',
				},
			},
			action: (
				<>
					<a
						onClick={() => {
							onClickEdit(employee);
						}}
						className="btnLink custom-action-edit "
					>
						Edit
					</a>
					<a
						onClick={() => onProceedChangeStatus(employee)}
						className="btnLink custom-action-suspend"
					>
						{employeeStatus === 'Active' ? 'Suspend' : 'Unsuspend'}
					</a>
					<a
						className={`btnLink ${
							employeeStatus === 'Active'
								? ' custom-action-remove'
								: ''
						}`}
					>
						Remove
					</a>
				</>
			),
		};
	});

	const onNavigateBulkUpload = () => {
		props.history.push('/employeelist/bulk-upload', {
			breadcrumbs: [
				{
					label: 'Employee list',
					link: 'employeelist',
				},
			],
		});
	};

	return (
		<LayoutPage>
			<Header as="h4" className="page-header">
				<div className="offices-header">
					<Header as="h1">Employee list</Header>
					List of your company&apos;s employees who will have access
					to G Access
				</div>
				<Header
					as="h4"
					style={{
						marginBottom: '-5px',
					}}
				>
					Summary
				</Header>
			</Header>

			<div className="employee-list-summary">
				<div className="summary-item">
					<div className="summary-subtitle">Total employees</div>
					<Header as="h2">
						{' '}
						{countEmployees.total_employees
							? countEmployees.total_employees.toLocaleString(
									undefined,
									{
										maximumFractionDigits: 1,
									}
							  )
							: 0}
					</Header>
				</div>
				<div className="summary-item">
					<div className="summary-info active">
						Active:{' '}
						<b>
							{countEmployees.total_active
								? countEmployees.total_active.toLocaleString(
										undefined,
										{
											maximumFractionDigits: 1,
										}
								  )
								: 0}
						</b>
					</div>
					<div className="summary-info suspended">
						Suspended:{' '}
						<b>
							{' '}
							{countEmployees.total_suspended
								? countEmployees.total_suspended.toLocaleString(
										undefined,
										{
											maximumFractionDigits: 1,
										}
								  )
								: 0}
						</b>
					</div>
				</div>
			</div>
			<div className="table-list">
				<div className="table-list-filter">
					<Form>
						<Form.Group inline>
							<Form.Field>
								<Input
									icon={
										<img
											src={searchImg}
											className="filter-search-icon "
										/>
									}
									iconPosition="left"
									placeholder="Type search here"
									onChange={e =>
										handleChange(
											'type_search',
											e.target.value
										)
									}
									value={employeeFilter.type_search}
								/>
							</Form.Field>
							<p
								style={{
									color: '#707070',
									paddingLeft: 10,
									paddingRight: 7,
								}}
							>
								Filter by:
							</p>
							<Form.Field
								control={Select}
								selectOnBlur={false}
								forceSelection={false}
								{...(employeeFilter.group === null
									? {
											text: 'Group',
									  }
									: {})}
								options={[
									{
										id: 'all',
										text: 'All groups',
										value: 'all',
										selected: false,
									},
									...groups,
								]}
								style={{
									minWidth: '250px',
								}}
								placeholder="Group"
								onChange={(e, { value }) =>
									handleChange('group', value)
								}
								icon="angle down"
								className="filter-select"
								value={employeeFilter.group}
							/>
							<Form.Field
								control={Select}
								selectOnBlur={false}
								forceSelection={false}
								{...(employeeFilter.status === null
									? {
											text: 'Status',
									  }
									: {})}
								options={filterStatus}
								placeholder="Status"
								onChange={(e, { value }) =>
									handleChange('status', value)
								}
								icon="angle down"
								className="filter-select"
								value={employeeFilter.status}
							/>
							<div className="table-list-actions">
								<a
									onClick={onClickNewEmployee}
									className="ui button app-primary-inverted-button"
								>
									Add new employee
								</a>
								<a
									onClick={() => onNavigateBulkUpload()}
									className="ui button app-primary-button"
								>
									Bulk upload employees
								</a>
							</div>
						</Form.Group>
					</Form>
				</div>
				<div className="table-container">
					<Table
						headers={header}
						datas={renderData}
						page={paginate.page}
						totalRows={
							countEmployees.total_count
								? countEmployees.total_count
								: 0
						}
						rowsPerPage={paginate.offset}
						onPageChange={onPageChange}
					/>
				</div>
				{/* On change status confirmation prompt */}

				<Modal
					closeOnEscape={false}
					closeOnDimmerClick={false}
					onClose={() => setChangeStatusModal(false)}
					onOpen={() => setChangeStatusModal(true)}
					open={changeStatusModal}
					className="employee-form-modal"
					style={{ maxWidth: '515px' }}
				>
					<Modal.Content>
						<FocusLock
							disabled={!changeStatusModal}
							autoFocus={false}
						>
							<img
								src={`${process.env.REACT_APP_BASE_URL}/images/question-logo.png`}
							/>
							<Header as="h3">Suspend employee?</Header>
							<p className="modal-sub-header custom-sentence-break">
								{selectedEmployee && selectedEmployee.profile
									? concatFullname(
											selectedEmployee.profile.first_name,
											selectedEmployee.profile.last_name,
											selectedEmployee.profile.suffix
									  )
									: ''}{' '}
								will not be able to access the mobile app and
								admin portal (if applicable) once suspended. Are
								you sure you want to proceed?
							</p>
							<div className="modal-action">
								<Button
									onClick={event => {
										onProceedChangeStatus(selectedEmployee);
									}}
									className="app-primary-button custom-full-button"
								>
									Yes, proceed
								</Button>
								<Button
									className="app-primary-inverted-button custom-full-button"
									onClick={() => {
										setChangeStatusModal(false);
									}}
								>
									Cancel and go back
								</Button>
							</div>
						</FocusLock>
					</Modal.Content>
				</Modal>

				{/* Service admin suspend prompt */}
				<Modal
					onClose={() => setPatchUnsuccessfulPrompt(false)}
					open={patchUnsuccessfulPrompt}
					className="desks-modal bulk-completion-modal"
					size="small"
				>
					<Modal.Content>
						<div className="replace-service-admin-prompt">
							<img src={waitImg} alt="wait" />
							<h3 style={{ marginBottom: '1rem' }}>
								Replace service administrator
							</h3>
							<p>
								{selectedEmployee && selectedEmployee.profile
									? concatFullname(
											selectedEmployee.profile.first_name,
											selectedEmployee.profile.last_name,
											selectedEmployee.profile.suffix
									  )
									: ''}{' '}
								is an assigned service owner of the following:
							</p>
							<section className="site-service-list">
								<div>
									<ul>
										{siteServiceList &&
										siteServiceList.length
											? siteServiceList.map(list => {
													return (
														<li key={list.id}>
															{list.site.name} -{' '}
															{
																list
																	.service_type
																	.name
															}
														</li>
													);
											  })
											: null}
									</ul>
								</div>
							</section>
							<p>
								To suspend the user, assign a replacement first
								to make sure that no service will be
								interrupted.
							</p>
							<div className="modal-action">
								<Grid columns="two">
									<Grid.Row>
										<Grid.Column>
											<Button
												className="app-primary-inverted-button custom-full-button"
												onClick={() => {
													setPatchUnsuccessfulPrompt(
														false
													);
												}}
											>
												Cancel and go back
											</Button>
										</Grid.Column>
										<Grid.Column>
											<Button
												className="app-primary-button custom-full-button"
												onClick={evt => {
													props.history.push(
														`companysite-details/${serviceToRedirect.site.id}`,
														{
															breadcrumbs: [
																{
																	label:
																		'Office sites',
																	link: `companysite`,
																},
															],
														}
													);
												}}
												width={8}
											>
												Proceed
											</Button>
										</Grid.Column>
									</Grid.Row>
								</Grid>
							</div>
						</div>
					</Modal.Content>
				</Modal>
			</div>
		</LayoutPage>
	);
};

EmployeeList.propTypes = {
	history: PropTypes.any,
};

export default EmployeeList;

// grahpql queries
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, {
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState,
} from 'react';
import { Link } from 'react-router-dom';
// semantic ui component
import {
	Grid,
	Image,
	Menu,
	Popup,
	Checkbox,
	Modal,
	Header,
	Button,
} from 'semantic-ui-react';
import AuthContext from '../../context/AuthContext/AuthContext';
import { ServiceTypes } from '../../models/ServiceTypes';
import { decrypt, encrypt } from '../../utils';
import { GET_USER_SERVICETYPES } from '../graphql/query';
import { GET_GCHECK_IS_ENABLED } from './graphql/query';
import { DELETE_TOKEN, PATCH_GCHECK_IS_ENABLED } from './graphql/mutation';
import './Nav.css';

const defaultPicture =
	process.env.REACT_APP_BASE_NAME + 'images/app-logo-g-only.png';
const menuIcon = process.env.REACT_APP_BASE_NAME + 'svgs/menu.svg';

const Nav = () => {
	const picture = localStorage.getItem('picture');
	const client = useApolloClient();
	const menuRef = useRef(null);
	const { user, adminRole, name } = useContext(AuthContext);
	const [menuOpen, setMenuOpen] = useState(false);
	const googleToken = localStorage.getItem('googleToken');
	const [serviceTypes, setServiceTypes] = useState([]);
	const [gcheckToggle, setGcheckToggle] = useState(false);
	const [gcheckToggleModal, setGcheckToggleModal] = useState(false);

	// Patch enabling gcheck mutation instance
	const [patchGcheckIsEnabled] = useMutation(PATCH_GCHECK_IS_ENABLED);

	/**
	 * API Call to get initial status of gcheck
	 * This will call getGcheckIsEnabled in the backend side
	 * API Path: \backend\graphql\models\resolvers\gcheck_status.js
	 */
	const gcheckIsEnabled = useQuery(GET_GCHECK_IS_ENABLED, {
		onCompleted: () => {
			// Had to destructure API result to get enabled in API result
			const {
				data: {
					getGcheckIsEnabled: { enabled },
				},
			} = gcheckIsEnabled;
			// Set Initial value of gcheck toggle in component state
			setGcheckToggle(enabled);
		},
		fetchPolicy: 'network-only',
	});

	const { data } = useQuery(GET_USER_SERVICETYPES, {
		variables: {
			users_id: user.user.id,
		},
		onCompleted: () => {
			setServiceTypes(data.getUserServiceTypes);
		},
	});

	const signOut = useCallback(async () => {
		await client.mutate({
			mutation: DELETE_TOKEN,
			variables: {
				users_id: user.user.id,
				refresh_token: localStorage.getItem('refreshToken'),
			},
		});
		localStorage.clear();
		window.location.reload('/');
	});

	const onCloseMenuHandler = () => {
		setMenuOpen(prev => !prev);
	};

	const onClickNavLink = async serviceType => {
		localStorage.setItem(
			'service_type',
			encrypt(JSON.stringify(serviceType))
		);
		setMenuOpen(false);
	};

	/**
	 * API Call to patch initial status of gcheck toggle
	 * This will call patchGcheckIsEnabled in the backend side
	 * API Path: \backend\graphql\models\resolvers\gcheck_status.js
	 */
	const toggleGcheckHandler = async () => {
		// Flip the boolean value of current gcheck toggle state
		const currentState = !gcheckToggle;
		// Mutate component's gcheck toggle to currentState
		setGcheckToggle(currentState);
		// API Request to set current state of gcheck toggle to global vars
		await patchGcheckIsEnabled({
			variables: {
				enabled: currentState,
			},
		}).then(() => {
			// Open Gcheck Toggle Modal
			setGcheckToggleModal(true);
		});
	};

	return (
		<div className="top-header">
			<Grid>
				<Grid.Column width="eight" className="top-header-content">
					<div className="top-header-item">
						<img
							className="top-header-logo"
							alt="GAccess Logo"
							src={`${process.env.REACT_APP_BASE_URL}/images/GAccess-colored.png`}
						/>
					</div>
					<div
						className="top-header-item"
						style={{ padding: '1rem 0' }}
					>
						<div
							className="top-header-title"
							style={{ color: '#4F6171' }}
						>
							{adminRole && adminRole[0] ? adminRole[0].name : ''}
						</div>
					</div>
				</Grid.Column>
				<Grid.Column
					width="eight"
					className="top-header-content top-header-content-right"
				>
					<div className="top-header-item border-right top-header-profile">
						{/* <a href=""> */}
						<div className="nav-menu">
							<span className="top-header-text top-header-profile-name">
								{name ? name : ''}
							</span>
							<Popup
								trigger={
									<div className="nav-img-container">
										<img
											className="top-header-profile-image"
											src={
												picture !== '' &&
												picture != 'null'
													? picture
													: defaultPicture
											}
											alt=""
											style={{ cursor: 'pointer' }}
										></img>
									</div>
								}
								position="bottom left"
								on="click"
								style={{
									padding: '0',
									minWidth: '250px',
									zIndex: '2100',
								}}
							>
								<Menu
									fluid
									vertical
									style={{ border: 'none' }}
									className="profile-menu"
								>
									{!googleToken ? (
										<Menu.Item
											href=""
											target="_blank"
											onClick={e => e.preventDefault()}
										>
											<div
												style={{
													display: 'flex',
													alignItems: 'center',
												}}
											>
												<div
													style={{
														width: '10%',
														display: 'block',
														marginRight: '10px',
													}}
												>
													<Image
														src={`${process.env.REACT_APP_BASE_URL}/images/password.png`}
													/>
												</div>
												<div
													style={{
														width: '85%',
														display: 'block',
														marginRight: '10px',
													}}
												>
													<span>Change password</span>
												</div>
											</div>
										</Menu.Item>
									) : null}
									<Menu.Item onClick={signOut}>
										<div
											style={{
												display: 'flex',
												alignItems: 'center',
											}}
										>
											<div
												style={{
													width: '10%',
													display: 'block',
													marginRight: '10px',
												}}
											>
												<Image
													src={`${process.env.REACT_APP_BASE_URL}/images/logout.png`}
													style={{ height: '13px' }}
												/>
											</div>
											<div
												style={{
													width: '85%',
													display: 'block',
													marginRight: '10px',
												}}
											>
												<span>Logout</span>
											</div>
										</div>
									</Menu.Item>
								</Menu>
							</Popup>
						</div>
						{/* </a> */}
					</div>
					<div className="top-header-item border-right top-header-notification">
						<a>
							<i
								className="bell outline icon"
								onClick={e => e.preventDefault()}
							></i>
						</a>
					</div>
					{adminRole[0].code === 'ca' && (
						<div className="top-header-item border-right">
							<Modal
								open={gcheckToggleModal}
								className="healthcheck-modal"
							>
								<Modal.Content>
									<img
										src={`${process.env.REACT_APP_BASE_URL}/images/setupservice-complete.png`}
									/>
									<Header as="h3">
										Health Check is now
										{gcheckToggle
											? ' enabled'
											: ' disabled'}
									</Header>
									{gcheckToggle && (
										<p className="">
											GAccess Application will require all
											the user to do health assesment via
											GCheck before using in-app services.
										</p>
									)}
									{!gcheckToggle && (
										<p className="">
											GAccess Application will no longer
											require all the user to do health
											assesment via GCheck before using
											in-app services.
										</p>
									)}
									<Button
										className="app-primary-button gcheck-toggle-btn"
										onClick={() =>
											setGcheckToggleModal(false)
										}
									>
										Got it!
									</Button>
								</Modal.Content>
							</Modal>
							<div className="gcheck-toggle">
								<div className="gcheck-toggle-label">
									GCheck App
								</div>
								<div className="gcheck-toggle-checkbox-container">
									<Checkbox
										checked={gcheckToggle}
										onClick={toggleGcheckHandler}
										className="gcheck-toggle-checkbox"
										toggle
									/>
								</div>
							</div>
						</div>
					)}

					{adminRole[0].code === 'sa' ? (
						<Popup
							trigger={
								// <div className="nav-img-container">
								// 	<img
								// 		className="top-header-profile-image"
								// 		src={
								// 			picture && picture !== ''
								// 				? picture
								// 				: defaultPicture
								// 		}
								// 		alt=""
								// 		style={{ cursor: 'pointer' }}
								// 	></img>
								// </div>
								<div
									className="top-header-item border-right top-header-options"
									ref={menuRef}
								>
									<a
										className="cursor-pointer"
										// onClick={() => onCloseMenuHandler()}
									>
										{/* <i className="th icon"></i> */}
										<img
											src={menuIcon}
											className="menu icon"
										/>
									</a>
								</div>
							}
							position="bottom center"
							style={{ padding: '0', maxWidth: '350px' }}
							on="click"
						>
							<Menu
								fluid
								vertical
								style={{ border: 'none' }}
								className="profile-menu"
							>
								{serviceTypes.map(serviceType => (
									<Menu.Item
										className="service-menu cursor-pointer"
										key={serviceType.id}
									>
										<Link
											className={clsx(
												'service-menu-container text-center',
												serviceType.id ===
													JSON.parse(
														decrypt(
															localStorage.getItem(
																'service_type'
															)
														)
													).id
													? 'active'
													: null
											)}
											onClick={() =>
												onClickNavLink(serviceType)
											}
											to={`/serviceadministrator`}
										>
											<div className="service-menu-item">
												<Image
													src={
														process.env
															.REACT_APP_BASE_URL +
														ServiceTypes[
															serviceType.name
														].drawerIcon
													}
												/>
											</div>
											<span className="service-menu-title">
												{
													ServiceTypes[
														serviceType.name
													].name
												}
											</span>
										</Link>
									</Menu.Item>
								))
								// : null
								}
							</Menu>
						</Popup>
					) : null}
					<div className="top-header-item top-header-company-logo">
						<img
							src={`${process.env.REACT_APP_BASE_URL}/images/company-logo.png`}
							alt=""
							className="top-header-logo"
						/>
					</div>
				</Grid.Column>
			</Grid>
		</div>
	);
};

Nav.prototypes = {
	title: PropTypes.any,
};

export default Nav;

import crypto from 'crypto';

export const encrypt = function(toEncrypt) {
	try {
		const algorithm = 'aes-256-cbc';
		const password = process.env.REACT_APP_ENCRYPTION_KEY;
		let key = crypto
			.createHash('md5')
			.update(password, 'utf-8')
			.digest('hex')
			.toUpperCase();

		let iv = new Buffer.alloc(16);

		let encrypt = crypto.createCipheriv(algorithm, key, iv);
		let encrypted = encrypt.update(`${toEncrypt}`, 'utf8', 'hex');
		encrypted += encrypt.final('hex');
		return encrypted;
	} catch (err) {
		return false;
	}
};

export const encryptIds = function(args) {
	if (args && typeof args == 'object') {
		for (const prop in args) {
			if (typeof args[prop] == 'object') {
				encryptIds(args[prop]);
			} else {
				const pattern = /(?:^id$|_id$)/gm;

				if (pattern.test(prop)) args[prop] = encrypt(args[prop]);
			}
		}
	}

	return args;
};

// react lib
import React from 'react';

// hoc
import LayoutPage from '../../hoc/Layout/Admin/AdminMaster';

// styles
import './ServiceDashboard.css';

// prop-types for mapping props
import PropTypes from 'prop-types';

const ServiceDashboard = ({ children }) => {
	return (
		<LayoutPage title="Service Administrator">
			<div className="service-dashboard">{children}</div>
		</LayoutPage>
	);
};

ServiceDashboard.propTypes = {
	children: PropTypes.any,
};

export default ServiceDashboard;

import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import LayoutPage from '../../ServiceDashboard';
import Table from '../../../../components/Table/Table';
import QRCode from 'qrcode.react';

import {
	Form,
	Header,
	Button,
	Breadcrumb,
	Select,
	Icon,
	Grid,
} from 'semantic-ui-react';
import { useApolloClient } from '@apollo/client';
import {
	GET_FLOOR_BOOKINGS,
	GET_SLOT_QRCODES,
	GET_TRIP_BOOKINGS,
} from '../../graphql/query';
import moment from 'moment';
import LoaderContext from '../../../../context/LoaderContext/LoaderContext';
import useDebounce from '../../../../hooks/useDebounce';
import {
	savePdf,
	generateExcelReport,
	doubleDigitNumber,
} from '../../../../utils';
import PDFQRCode from '../../../../components/PDFQR/PDFQRCode';
import ParkingPDFQRCode from '../../../../components/PDFQR/ParkingPDFQRCode';
import './ReportItem.css';
import { ServiceTypes } from '../../../../models/ServiceTypes';

require('moment-duration-format');
const downloadImg = process.env.REACT_APP_BASE_NAME + 'svgs/download.svg';

const status = [
	{ key: '0', text: 'with Check-in', value: 0 },
	{ key: '1', text: 'none', value: 1 },
];

const ReportItem = props => {
	let service_name = props.location.state.service.service_type.name;
	const client = useApolloClient();
	const { showLoading, hideLoading } = useContext(LoaderContext);
	const [sortBooking, setSortBooking] = useState({});
	const [booking, setBooking] = useState({
		month: moment().format('MM'),
		year: moment().format('YYYY'),
		status: 0,
	});
	const [floorBookings, setFloorBookings] = useState([]);
	const [yearOptions, setYearOptions] = useState([]);
	const [monthOptions, setMonthOptions] = useState([]);
	const [paginate, setPaginate] = useState({
		offset: 10,
		page: 1,
	});
	const [totalFloorBookings, setTotalFloorBookings] = useState(0);
	const debouncedStatus = useDebounce(booking.status, 500);
	const debouncedMonth = useDebounce(booking.month, 500);
	const debouncedYear = useDebounce(booking.year, 500);

	useEffect(() => {
		const years = [];
		const months = [];

		for (let i = 0; i < moment().format('M'); i++) {
			months.push({
				key: i,
				text: moment()
					.month(i)
					.format('MMMM'),
				value: moment(i).format('MM'),
				// value: i,
			});
		}
		setMonthOptions(months);

		// looping year starting 2019 until today's year
		for (let i = 2020; i <= moment().format('YYYY'); i++) {
			years.push({
				key: i,
				text: i,
				value: i.toString(),
			});
		}

		setMonthOptions(months);
		setYearOptions(years);
	}, []);

	// run this function when year on dateFilter was changed
	useEffect(() => {
		const months = [];
		if (booking.year === moment().format('YYYY')) {
			for (let i = 0; i < moment().format('MM'); i++) {
				months.push({
					key: i,
					text: moment()
						.month(i)
						.format('MMMM'),
					value: doubleDigitNumber(i + 1, 2).toString(),
				});
			}
		} else {
			for (let i = 0; i < 12; i++) {
				months.push({
					key: i,
					text: moment()
						.month(i)
						.format('MMMM'),
					value: doubleDigitNumber(i + 1, 2).toString(),
				});
			}
		}
		setBooking({
			...booking,
			month: moment().format('MM'),
		});
		setMonthOptions(months);
	}, [booking.year]);

	useEffect(() => {
		fetchFloorBookingData();
	}, [
		paginate.page,
		sortBooking,
		debouncedStatus,
		debouncedMonth,
		debouncedYear,
	]);

	const fetchFloorBookingData = async () => {
		const sortOrder = Object.keys(sortBooking).map(sortKey => {
			if (sortBooking[sortKey] !== null) {
				return {
					column: sortKey,
					order: sortBooking[sortKey] ? 'asc' : 'desc',
				};
			}
		});
		// showLoading('Searching...');
		let input = {
			slots_id: props.match.params.encryptedId,
			month: booking.month,
			year: booking.year,
			booking_status: booking.status,
		};
		const result = await floorBookingQuery({
			input,
			paginate,
			sort: sortOrder[0],
		});
		setFloorBookings(result.bookings);
		setTotalFloorBookings(result.total_floor_bookings);

		// hideLoading();
	};

	const floorBookingQuery = async ({ input, paginate, sort }) => {
		let result;
		if (service_name !== 'Shuttle') {
			result = await client.query({
				query: GET_FLOOR_BOOKINGS,
				fetchPolicy: 'network-only',
				variables: {
					input: input,
					paginate,
					sort,
				},
			});
			return result.data.getFloorBookings;
		} else {
			input = {
				...input,
				service_instances_id:
					props.location.state.site[0].service_instances_id,
			};
			result = await client.query({
				query: GET_TRIP_BOOKINGS,
				fetchPolicy: 'network-only',
				variables: {
					input: {
						...input,
						booking_status: null,
					},
					paginate,
					sort,
				},
			});

			return result.data.getTripSlotBookings;
		}
	};

	const generateFloorBookingData = async () => {
		let excelDatas, excelHeaders, excelFilename, excelTitle, services;
		const sortOrder = Object.keys(sortBooking).map(sortKey => {
			if (sortBooking[sortKey] !== null) {
				return {
					column: sortKey,
					order: sortBooking[sortKey] ? 'asc' : 'desc',
				};
			}
		});
		const input = {
			slots_id: props.match.params.encryptedId,
			month: booking.month,
			year: booking.year,
			booking_status: booking.status,
		};
		const result = await floorBookingQuery({
			input,
			paginate: {
				page: 1,
				offset: null,
			},
			sort: sortOrder[0],
		});
		if (service_name === 'Shuttle') {
			excelDatas =
				result.bookings && result.bookings.length > 0
					? result.bookings.map(booking => {
							const checkedIn = moment(booking.checkin_time);
							const checkedOut = moment(booking.checkout_time);
							const totalHours = moment
								.duration(checkedOut.diff(checkedIn))
								.asMinutes();

							let time_used;

							if (totalHours >= 1) {
								time_used = moment
									.duration(totalHours, 'minutes')
									.format('h [hours], m [minutes]', {
										trim: 'all',
									});
							} else if (totalHours < 1) {
								time_used = '0 minutes';
							} else {
								time_used = 'none';
							}

							return [
								booking.trip_slot.idno,
								booking.trip_slot.vehicle.plate_number,
								`${booking.trip_slot.driver.user.profile.first_name} ${booking.trip_slot.driver.user.profile.last_name}`,
								`${booking.trip_slot.route.site_origin.name} - ${booking.trip_slot.route.site_destination.name}`,
								moment(booking.booking_start).format(
									'MMM D, YYYY'
								),
								moment(booking.booking_start).format('hh:mm A'),

								booking.checkin_time
									? moment(booking.checkin_time).format(
											'MMM D, YYYY'
									  )
									: 'none',

								booking.checkin_time
									? moment(booking.checkin_time).format(
											'hh:mm A'
									  )
									: 'none',

								booking.checkout_time
									? moment(booking.checkout_time).format(
											'hh:mm A'
									  )
									: 'none',

								time_used,

								`${booking.employee.user.profile.first_name} ${
									booking.employee.user.profile.last_name
								} ${
									booking.employee.user.profile.suffix
										? booking.employee.user.profile.suffix.toLowerCase() ===
										  'n/a'
											? ''
											: booking.employee.user.profile
													.suffix
										: ''
								}`,

								booking.employee.user.email,
							];
					  })
					: [];

			excelHeaders = [
				'Trip ID',
				'Vehicle Plate',
				'Driver Name',
				'Route',
				'Booking date',
				'Booking time',
				'Check-in date',
				'Check-in time',
				'Check-out time',
				'Elapsed time',
				'Employee name',
				'E-mail address',
			];

			// Shuttle: [Building name here]-[Month] [Year] Report
			excelFilename = `${ServiceTypes[service_name].name}_${
				props.location.state.site[0].name
			}_${moment(props.location.state.slot[0].trip_date).format(
				'MMMM_YYYY'
			)}_Report.xlsx`;
			// excel title
			// excelTitle = `${ServiceTypes[service_name].name}: ${
			// 	props.location.state.site[0].name
			// } - ${moment(booking.month).format('MMMM')} - ${moment(
			// 	booking.year
			// ).format('YYYY')} Report`;

			excelTitle = `${ServiceTypes[service_name].name}: ${
				props.location.state.site[0].name
			} - ${moment(props.location.state.slot[0].trip_date).format(
				'MMMM - YYYY'
			)} Report`;
		} else {
			excelDatas = result
				? result.bookings.map(booking => {
						const checkedIn = moment(booking.checkin_time);
						const checkedOut = moment(booking.checkout_time);
						const totalHours = moment
							.duration(checkedOut.diff(checkedIn))
							.asMinutes();
						let time_used;

						if (totalHours >= 1) {
							time_used = moment
								.duration(totalHours, 'minutes')
								.format('h [hours], m [minutes]', {
									trim: 'all',
								});
						} else if (totalHours < 1) {
							time_used = '0 minutes';
						} else {
							time_used = 'none';
						}
						return [
							booking.slot.floor.name,
							booking.slot.area,
							booking.slot.workdeskID,
							booking.slot.name,
							moment(booking.booking_start).format('MMM D, YYYY'),
							moment(booking.booking_start).format('h:mm A'),

							booking.checkin_time
								? moment(booking.checkin_time).format(
										'MMM D, YYYY'
								  )
								: 'none',

							booking.checkin_time
								? moment(booking.checkin_time).format('hh:mm A')
								: 'none',

							booking.checkout_time
								? moment(booking.checkout_time).format(
										'hh:mm A'
								  )
								: 'none',

							time_used,

							`${booking.employee.user.profile.first_name} ${
								booking.employee.user.profile.last_name
							} ${
								booking.employee.user.profile.suffix
									? booking.employee.user.profile.suffix.toLowerCase() ===
									  'n/a'
										? ''
										: booking.employee.user.profile.suffix
									: ''
							}`,

							booking.employee.user.email,
							booking.payment
								? booking.payment.status
									? `GCASH: ${booking.payment.ref_no}`
									: 'none'
								: 'none',
						];
				  })
				: [];

			excelHeaders = [
				'Floor #',
				'Area',
				ServiceTypes[service_name].site_dashboard_column_id,
				ServiceTypes[service_name].site_dashboard_column_name,
				'Booking date',
				'Booking time',
				'Check-in date',
				'Check-in time',
				'Check-out time',
				'Total time used',
				'Employee name',
				'E-mail address',
				service_name === 'Parking' ? 'Payment' : null,
			];

			excelFilename = `${ServiceTypes[service_name].name}_${
				props.location.state.site[0].abbrev
			}_${props.location.state.site[0].name}_${moment()
				.month(booking.month - 1)
				.format('MMMM')}_${moment(booking.year).format(
				'YYYY'
			)}_Report.xlsx`;
			excelTitle = `${ServiceTypes[service_name].name}: ${
				props.location.state.site[0].name
			} - ${moment(booking.month).format('MMMM')} - ${moment(
				booking.year
			).format('YYYY')} Report`;

			//service type
			services = service_name;
		}

		generateExcelReport({
			excelFilename,
			excelHeaders,
			excelTitle,
			excelDatas,
			services,
		});
	};

	const onPageChange = (evt, page) => {
		setPaginate({
			...paginate,
			page: page.activePage,
		});
	};

	const handleChange = (key, value) => {
		setBooking({
			...booking,
			[key]: value,
		});
		setPaginate({
			...paginate,
			page: 1,
		});
	};

	const onChangeSortOrder = key => {
		setSortBooking(prev => {
			return {
				[key]: !prev[key],
			};
		});
	};

	const onClickPrintQr = async () => {
		showLoading('Printing QR Code...');
		const qrcode = await client.query({
			query: GET_SLOT_QRCODES,
			variables: {
				inputSlotsIds: [
					{
						id: props.match.params.encryptedId,
						serviceName: props.location.state.slot[0].name,
					},
				],
			},
		});
		if (service_name && service_name === 'Parking') {
			const filename = `ParkingQR_${props.location.state.site[0].abbrev}_${props.location.state.site[0].name}`;
			await savePdf(
				<ParkingPDFQRCode
					slots={qrcode.data.getSlotQRCode}
					service="Parking"
				/>,
				`${filename}.pdf`
			);
		} else {
			const filename = `WorkdeskQR_${props.location.state.site[0].abbrev}_${props.location.state.site[0].name}`;
			await savePdf(
				<PDFQRCode
					slots={qrcode.data.getSlotQRCode}
					service="Workdesk"
				/>,
				`${filename}.pdf`
			);
		}

		// await savePdf(
		// 	<PDFQRCode slots={qrcode.data.getSlotQRCode} />,
		// 	`Workdesks.pdf`
		// );

		hideLoading();
	};

	let header = [];
	let renderData = [];

	if (service_name === 'Parking') {
		// parking service

		// parking's table headers
		header = [
			{
				name: (
					<Button
						basic
						inverted
						className="btn-header"
						key="button"
						onClick={() => onChangeSortOrder('booking_start')}
					>
						Booking date & time&nbsp;&nbsp;
						<Icon
							name={
								!Object.prototype.hasOwnProperty.call(
									sortBooking,
									'booking_start'
								)
									? 'sort'
									: sortBooking.booking_start === true
									? 'sort down'
									: 'sort up'
							}
						/>
					</Button>
				),
				width: 1,
			},
			'Employee name',
			'Payment',
			{
				name: (
					<Button
						basic
						inverted
						className="btn-header"
						key="button"
						onClick={() => onChangeSortOrder('checkin_time')}
					>
						Check-in date & time&nbsp;&nbsp;
						<Icon
							name={
								!Object.prototype.hasOwnProperty.call(
									sortBooking,
									'checkin_time'
								)
									? 'sort'
									: sortBooking.checkin_time === true
									? 'sort down'
									: 'sort up'
							}
						/>
					</Button>
				),
			},
			{
				name: (
					<Button
						basic
						inverted
						className="btn-header"
						key="button"
						onClick={() => onChangeSortOrder('checkout_time')}
					>
						Check-out date & time&nbsp;&nbsp;
						<Icon
							name={
								!Object.prototype.hasOwnProperty.call(
									sortBooking,
									'checkout_time'
								)
									? 'sort'
									: sortBooking.checkout_time === true
									? 'sort down'
									: 'sort up'
							}
						/>
					</Button>
				),
			},
			service_name !== 'Shuttle' ? 'Time used' : 'Trip duration',
		];

		// parking's table data, iterate bookings.
		renderData = floorBookings.map(booking => {
			const checkedIn = moment(booking.checkin_time);
			const checkedOut = moment(booking.checkout_time);
			const totalHours = moment
				.duration(checkedOut.diff(checkedIn))
				.asMinutes();

			let checkout_time;
			let checkin_time;
			let duration;
			let employee_suffix;

			// if employee has suffix
			if (booking.employee.user.profile.suffix) {
				employee_suffix = booking.employee.user.profile.suffix;

				// override value of employee_suffix if suffix = "n/a";
				if (
					booking.employee.user.profile.suffix.toLowerCase() === 'n/a'
				) {
					employee_suffix = '';
				}
			} else {
				// goes here when employee has no suffix
				employee_suffix = '';
			}

			// booking has checkout time | format the checkout time
			if (booking.checkout_time) {
				checkout_time = moment(booking.checkout_time).format(
					'MMM D, YYYY | h:mm A'
				);
			} else if (
				!booking.checkout_time &&
				booking.status === 3 &&
				service_name === 'Shuttle'
			) {
				// for shuttle, if no checkout time, mark as "No show"
				checkout_time = 'No show';
			} else {
				// For workdesks, cafeteria, parking with no checkout time, mark as "none"
				checkout_time = 'none';
			}

			// booking with check-in time
			if (booking.checkin_time) {
				checkin_time = moment(booking.checkin_time).format(
					'MMM D, YYYY | h:mm A'
				);
			} else if (
				!booking.checkin_time &&
				booking.status === 3 &&
				service_name === 'Shuttle'
			) {
				// shuttle booking with no checkout time, mark as "No show"
				checkin_time = 'No show';
			} else {
				// cafeteria, parking, workdesks with no check-in time
				checkin_time = 'none';
			}

			// computation of total duration
			if (totalHours >= 1) {
				duration = moment
					.duration(totalHours, 'minutes')
					.format('h [hours], m [minutes]', { trim: 'all' });
			} else if (
				(totalHours <= 0 && service_name === 'Shuttle') ||
				(isNaN(totalHours) && service_name === 'Shuttle')
			) {
				// for shuttle with no check-in and/or checkout, mark duration as "-"
				duration = '-';
			} else if (totalHours < 1) {
				duration = '0 minutes';
			} else {
				// for workdesks, parkings, cafeterias, with no check-in and/or checkout, mark duration as none
				duration = 'none';
			}
			return {
				booking_time: moment(booking.booking_start).format(
					'MMM D, YYYY | h:mm A'
				),
				name: {
					data: (
						<>
							<div className="employee-name">
								{`${booking.employee.user.profile.first_name} ${booking.employee.user.profile.last_name} ${employee_suffix}`}
							</div>
							<div className="sub-name">
								{booking.employee.user.email}
							</div>
						</>
					),
				},
				payment: {
					data:
						booking.payment && booking.payment.ref_no
							? `GCASH: ${booking.payment.ref_no}`
							: 'none',
					options: {
						className: 'w-300px',
					},
				},
				checkin_time: checkin_time,
				checkout_time: checkout_time,
				time_used: duration,
			};
		});
	} else {
		// workdesk, cafeteria, shuttle's booking headers
		header = [
			'Employee name',
			'Email',
			{
				name: (
					<Button
						basic
						inverted
						className="btn-header"
						key="button"
						onClick={() => onChangeSortOrder('booking_start')}
					>
						Booking date & time&nbsp;&nbsp;
						<Icon
							name={
								!Object.prototype.hasOwnProperty.call(
									sortBooking,
									'booking_start'
								)
									? 'sort'
									: sortBooking.booking_start === true
									? 'sort down'
									: 'sort up'
							}
						/>
					</Button>
				),
				width: 1,
			},
			{
				name: (
					<Button
						basic
						inverted
						className="btn-header"
						key="button"
						onClick={() => onChangeSortOrder('checkin_time')}
					>
						Check-in date & time&nbsp;&nbsp;
						<Icon
							name={
								!Object.prototype.hasOwnProperty.call(
									sortBooking,
									'checkin_time'
								)
									? 'sort'
									: sortBooking.checkin_time === true
									? 'sort down'
									: 'sort up'
							}
						/>
					</Button>
				),
			},
			{
				name: (
					<Button
						basic
						inverted
						className="btn-header"
						key="button"
						onClick={() => onChangeSortOrder('checkout_time')}
					>
						Check-out date & time&nbsp;&nbsp;
						<Icon
							name={
								!Object.prototype.hasOwnProperty.call(
									sortBooking,
									'checkout_time'
								)
									? 'sort'
									: sortBooking.checkout_time === true
									? 'sort down'
									: 'sort up'
							}
						/>
					</Button>
				),
			},
			service_name !== 'Shuttle' ? 'Time used' : 'Trip duration',
		];

		renderData = floorBookings.map(booking => {
			const checkedIn = moment(booking.checkin_time);
			const checkedOut = moment(booking.checkout_time);
			const totalHours = moment
				.duration(checkedOut.diff(checkedIn))
				.asHours();

			let checkout_time;
			let checkin_time;
			let duration;
			// booking has checkout time | format the checkout time
			if (booking.checkout_time) {
				checkout_time = moment(booking.checkout_time).format(
					'MMM D, YYYY | h:mm A'
				);
			} else if (
				!booking.checkout_time &&
				booking.status === 3 &&
				service_name === 'Shuttle'
			) {
				// for shuttle, if no checkout time, mark as "No show"
				checkout_time = 'No show';
			} else {
				// For workdesks, cafeteria, parking with no checkout time, mark as "none"
				checkout_time = 'none';
			}

			// booking with check-in time
			if (booking.checkin_time) {
				checkin_time = moment(booking.checkin_time).format(
					'MMM D, YYYY | h:mm A'
				);
			} else if (
				!booking.checkin_time &&
				booking.status === 3 &&
				service_name === 'Shuttle'
			) {
				// shuttle booking with no checkout time, mark as "No show"
				checkin_time = 'No show';
			} else {
				// cafeteria, parking, workdesks with no check-in time
				checkin_time = 'none';
			}

			// computation of total duration
			if (totalHours > 0) {
				duration = moment
					.duration(totalHours, 'hours')
					.format('h [hours], m [minutes]', { trim: 'all' });
			} else if (
				(totalHours <= 0 && service_name === 'Shuttle') ||
				(isNaN(totalHours) && service_name === 'Shuttle')
			) {
				// for shuttle with no check-in and/or checkout, mark duration as "-"
				duration = '-';
			} else {
				// for workdesks, parkings, cafeterias, with no check-in and/or checkout, mark duration as none
				duration = 'none';
			}
			return {
				name: `${booking.employee.user.profile.first_name} ${
					booking.employee.user.profile.last_name
				} ${
					booking.employee.user.profile.suffix
						? booking.employee.user.profile.suffix.toLowerCase() ===
						  'n/a'
							? ''
							: booking.employee.user.profile.suffix
						: ''
				}`,
				email: {
					data: booking.employee.user.email,
					options: {
						className: 'w-300px',
					},
				},
				booking_time: moment(booking.booking_start).format(
					'MMM D, YYYY | h:mm A'
				),
				checkin_time: checkin_time,
				checkout_time: checkout_time,
				time_used: duration,
			};
		});
	}

	const redirectTab = () => {
		if (service_name === 'Shuttle') {
			return 3;
		} else if (service_name === 'Parking') {
			return 2;
		} else {
			return 1;
		}
	};

	return (
		<LayoutPage>
			<Breadcrumb>
				<Breadcrumb.Section
					onClick={() => {
						props.history.push('/serviceadministrator');
					}}
				>
					...
				</Breadcrumb.Section>
				<Breadcrumb.Divider icon="right chevron" />
				<Breadcrumb.Section
					onClick={() => {
						props.history.push('/service-dashboard-per-site', {
							site: props.location.state.site,
							tab: 0,
						});
					}}
				>
					{props.location.state.site[0].name}
				</Breadcrumb.Section>
				<Breadcrumb.Divider icon="right chevron" />
				<Breadcrumb.Section
					onClick={() => {
						localStorage.setItem('tab', redirectTab());
						props.history.push('/service-dashboard-per-site', {
							site: props.location.state.site,
						});
					}}
				>
					Reports
				</Breadcrumb.Section>
				<Breadcrumb.Divider icon="right chevron" />
				<Breadcrumb.Section active>
					{service_name === 'Shuttle'
						? props.location.state.slot[0].idno
						: props.location.state.slot[0].name}
				</Breadcrumb.Section>
			</Breadcrumb>
			<Header as="h4">
				<Header as="h1">
					{service_name === 'Shuttle'
						? props.location.state.slot[0].idno
						: props.location.state.slot[0].name}
				</Header>
				{service_name !== 'Shuttle' ? (
					<>
						{props.location.state.slot[0].floor &&
						props.location.state.slot[0].floor.name
							? props.location.state.slot[0].floor.name
							: null}
						,&nbsp;
						{props.location.state.slot[0].area}
					</>
				) : (
					<>
						{/* <div>
							<span className="color-subheader">
								Trip schedule:&nbsp;
							</span>{' '}
							{moment(
								props.location.state.slot[0].trip_date
							).format('hh:mm')}
						</div>
						<div>
							<span className="color-subheader">
								Route:&nbsp;
							</span>{' '}
							{
								props.location.state.slot[0].trip_schedule.route
									.site_origin.name
							}{' '}
							-{' '}
							{
								props.location.state.slot[0].trip_schedule.route
									.site_destination.name
							}
						</div>
						<div>
							<span className="color-subheader">
								Vehicle:&nbsp;
							</span>{' '}
							{`${props.location.state.slot[0].vehicle.make} ${props.location.state.slot[0].vehicle.model} ${props.location.state.slot[0].vehicle.plate_number}`}
						</div>
						<div>
							<span className="color-subheader">
								Driver:&nbsp;
							</span>{' '}
							{`${props.location.state.slot[0].driver.user.profile.first_name} ${props.location.state.slot[0].driver.user.profile.last_name}`}
						</div> */}
						<Grid className="mt-2">
							<Grid.Row>
								<Grid.Column width={10}>
									<Grid>
										<Grid.Row>
											<Grid.Column width={3}>
												<div className="color-subheader pt-1 pb-1 fw-400">
													Trip schedule:
												</div>
												<div className="color-subheader pt-1 pb-1 fw-400">
													Route:{' '}
												</div>
												<div className="color-subheader pt-1 pb-1 fw-400">
													Vehicle:{' '}
												</div>
												<div className="color-subheader pt-1 pb-1 fw-400">
													Driver:{' '}
												</div>
											</Grid.Column>
											<Grid.Column width={13}>
												<div className="pt-1 pb-1 fw-400">
													{moment(
														props.location.state
															.slot[0].trip_date
													).format(
														'MMMM DD, YYYY | hh:mm A'
													)}
												</div>
												<div className="pt-1 pb-1 fw-400">
													{
														props.location.state
															.slot[0].route
															.site_origin.name
													}{' '}
													-{' '}
													{
														props.location.state
															.slot[0].route
															.site_destination
															.name
													}
												</div>
												<div className="pt-1 pb-1 fw-400">
													{`${props.location.state.slot[0].vehicle.make} ${props.location.state.slot[0].vehicle.model} ${props.location.state.slot[0].vehicle.plate_number}`}
												</div>
												<div className="pt-1 pb-1 fw-400">
													{`${props.location.state.slot[0].driver.user.profile.first_name} ${props.location.state.slot[0].driver.user.profile.last_name}`}
												</div>
											</Grid.Column>
										</Grid.Row>
									</Grid>
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</>
				)}
			</Header>
			{(props.location.state.service.is_qr_checkin &&
				service_name !== 'Shuttle') ||
			(props.location.state.service.is_qr_checkout &&
				service_name !== 'Shuttle') ? (
				<div className="qrcode-container">
					<div className="qrcode">
						<QRCode
							value="sample"
							style={{
								width: '100%',
								height: 'auto',
							}}
						/>
					</div>
					<a
						className="btnLink cursor-pointer printqr-anchor"
						onClick={onClickPrintQr}
					>
						Print QR
					</a>
				</div>
			) : null}
			<div className="table-list">
				<div className="table-list-filter">
					<Form>
						<Form.Group inline>
							{service_name === 'Shuttle' ? (
								<span className="passenger-list-label">
									List of passengers
								</span>
							) : (
								<>
									<label>Showing</label>
									<Form.Field>
										<Form.Field
											control={Select}
											options={monthOptions}
											onChange={(e, { value }) =>
												handleChange('month', value)
											}
											value={booking.month}
										/>
									</Form.Field>
									<Form.Field>
										<Form.Field
											control={Select}
											options={yearOptions}
											onChange={(e, { value }) =>
												handleChange('year', value)
											}
											value={booking.year}
										/>
									</Form.Field>
									<Form.Field>
										<Form.Field
											control={Select}
											options={status}
											// defaultValue={status[0].value}
											className="select-status"
											onChange={(e, { value }) =>
												handleChange('status', value)
											}
											value={booking.status}
										/>
									</Form.Field>
								</>
							)}
							<div className="table-list-actions">
								<Button
									className="ui button app-primary-button"
									onClick={() => {
										generateFloorBookingData();
									}}
								>
									{/* <Icon name="download" /> */}
									<img
										src={downloadImg}
										className="download-icon "
									/>
									Download
								</Button>
							</div>
						</Form.Group>
					</Form>
				</div>
				<Table
					headers={header}
					datas={renderData}
					page={paginate.page}
					totalRows={totalFloorBookings}
					rowsPerPage={paginate.offset}
					onPageChange={onPageChange}
				/>
			</div>
		</LayoutPage>
	);
};

ReportItem.propTypes = {
	match: PropTypes.any,
	history: PropTypes.any,
	location: PropTypes.any,
};

export default ReportItem;

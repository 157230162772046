// react lib
import React, { useState, useContext, useCallback } from 'react';

// less (styling)
import './AddNewService.css';

// semantic ui component
import { Header, Grid, Dropdown, Button, Modal, Form } from 'semantic-ui-react';

// model state
import { Profile } from '../../../models';

// layout
import LayoutPage from '../../../hoc/Layout/Admin/AdminMaster';

// components
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';

// prop-types for mapping props
import PropTypes from 'prop-types';

// apollo-hooks
import { useQuery, useMutation } from '@apollo/client';

// graphql(queries & mutations)
import { GET_COMPANY_USERS, GET_SITE_SERVICETYPES } from '../graphql/query';
import { POST_SERVICE_INSTANCE } from '../graphql/mutation';
import jwt_decode from 'jwt-decode';

import LoaderContenxt from '../../../context/LoaderContext/LoaderContext';

// react hooks
import useDebounce from '../../../hooks/useDebounce';

// imported images
const searchImg = process.env.REACT_APP_BASE_NAME + 'svgs/search.svg';

// react-form-hooks
// import useForm from '../../../hooks/useForm';

// images declaration
const questionMark =
	process.env.REACT_APP_BASE_NAME + 'images/question-logo.png';

function modalReducer(state, action) {
	switch (action.type) {
		case 'close':
			return { open: false };
		case 'open':
			return { open: true, size: action.size };
		default:
			throw new Error('Unsupported action...');
	}
}

const Accordion = ({ number, title, children }) => {
	const [isOpen, setOpen] = useState(true);
	return (
		<div className="accordion-wrapper">
			<div
				className={`accordion-title ${isOpen ? 'open' : ''}`}
				onClick={() => setOpen(!isOpen)}
			>
				<span className="accordion-number">
					{number}
					<span className="spacing"></span>
					{title}
				</span>
			</div>
			<div className={`accordion-item ${!isOpen ? 'collapsed' : ''}`}>
				<div className="accordion-content">{children}</div>
			</div>
		</div>
	);
};

const AddNewService = ({ history, match }) => {
	const sitesId = match.params.sites_id;
	const { showLoading, hideLoading } = useContext(LoaderContenxt);
	const decodedUser = window.localStorage.getItem(
		process.env.REACT_APP_TOKEN_NAME
	)
		? jwt_decode(
				window.localStorage.getItem(process.env.REACT_APP_TOKEN_NAME)
		  )
		: null;
	const [serviceState, setServiceState] = useState({
		Workdesk: false,
	});
	const [searchString, setSearchString] = useState({
		service: null,
		query: null,
	});
	const [serviceForm, setServiceForm] = useState({});
	const [users, setUsers] = useState([]);
	const [state, dispatch] = React.useReducer(modalReducer, {
		open: false,
		size: 'tiny',
	});
	const { open, size } = state;
	const debouncedSearchString = useDebounce(searchString.query, 500);

	const { data: serviceTypes, refetch: refetchServiceTypes } = useQuery(
		GET_SITE_SERVICETYPES,
		{
			variables: {
				sites_id: sitesId,
			},
		}
	);

	// TODO: infinite scroll
	const { loading: companyUserLoading } = useQuery(GET_COMPANY_USERS, {
		variables: {
			filter: debouncedSearchString,
			companies_id:
				decodedUser && decodedUser.user
					? decodedUser.user.company.id
					: null,
		},
		fetchPolicy: 'network-only',
		onCompleted: fetchedUsers => {
			// format user to meet select requirements for data
			const resultUsers = fetchedUsers.getCompanyUsers.map(user => {
				return {
					key: user.id,
					text: [
						user.email,
						' ',
						user.profile.first_name + ' ' + user.profile.last_name,
					],
					value: user,
					content: (
						<Header
							as="h5"
							content={`${
								user.profile.first_name
									? user.profile.first_name
									: ''
							} ${
								user.profile.last_name
									? user.profile.last_name
									: ''
							}`}
							subheader={user.email}
						/>
					),
					selected: false,
				};
			});
			setUsers(resultUsers);
		},
	});

	// init post service
	const [postService] = useMutation(POST_SERVICE_INSTANCE);

	// onchange service checkbox
	const onChangeServiceHandler = service => {
		if (!serviceState[service.name]) {
			setServiceForm({
				...serviceForm,
				[service.name]: {
					users_id: null,
					email: null,
					id: service.id,
					profile: {
						...Profile,
					},
				},
			});
		} else {
			// setServiceForm({});
			delete serviceForm[service.name];
		}

		setServiceState({
			...serviceState,
			[service.name]: !serviceState[service.name],
		});
	};

	// when typing and searching on option
	const onFilterCompanyUsers = (evt, service) => {
		setUsers([]);
		setSearchString({
			query: evt.target.value,
			service,
		});
	};

	const disabledSaveHandler = useCallback(() => {
		if (
			Object.keys(serviceForm).length &&
			Object.keys(serviceForm).filter(service => {
				return serviceForm[service].users_id !== null;
			}).length == Object.keys(serviceForm).length
		) {
			return false;
		}

		return true;
	}, [serviceForm]);

	// onchange users options value
	const onChangeUserHandler = (ev, user, service) => {
		// reset searches
		setSearchString({
			service: null,
			query: null,
		});

		// deep spread
		setServiceForm({
			...serviceForm,
			[service]: {
				...serviceForm[service],
				email: user.value.email,
				users_id: user.value.id,
				profile: {
					...user.value.profile,
				},
			},
		});
	};

	// onsave service handler
	const onSaveServiceHandler = async () => {
		// store data on variable
		// TODO: Multiple services, multiple data to be handle
		showLoading();
		const services = Object.keys(serviceForm).map(serviceKey => {
			return {
				service_types_id: serviceForm[serviceKey].id,
				sites_id: sitesId,
				users_id: serviceForm[serviceKey].users_id,
			};
		});

		// perform service insert
		await postService({
			variables: {
				inputServices: services,
			},
		});

		// refetch service types
		await refetchServiceTypes();

		// redirect to company site when success
		history.goBack();
		hideLoading();
	};

	// breadcrumbs navigation
	const breadcrumbNavigation = breadcrumb => {
		const updatedBreadcrumbs = [...history.location.state.breadcrumbs];
		const updatedVal = updatedBreadcrumbs.filter(
			breadcrumbData => breadcrumbData.label !== breadcrumb.label
		);
		history.push(`/${breadcrumb.link}`, {
			breadcrumbs: updatedVal,
		});
	};
	return (
		<LayoutPage className="new-service">
			<Breadcrumbs
				breadcrumbs={[
					...history.location.state.breadcrumbs,
					{ label: 'Add new service' },
				]}
				breadcrumbNavigation={breadcrumbNavigation}
			/>
			<Header as="h1">Service</Header>
			<Grid>
				<Grid.Column mobile="sixteen" tablet="sixteen" computer="ten">
					<div className="element-container element-container-white option-container">
						Select which service/s you&apos;d like to add in{' '}
						<b>{history.location.state.breadcrumbs[1].label}</b>
						<div className="service-options">
							{serviceTypes &&
							serviceTypes.getSiteServiceTypes.length > 0
								? serviceTypes.getSiteServiceTypes.map(
										serviceType => {
											return (
												<Form.Group
													className="input-check-container service-checkbox"
													key={serviceType.id}
												>
													<label className="checkcontainer">
														<input
															type="checkbox"
															// disabled={
															// 	!serviceType.status
															// }
															checked={
																serviceState[
																	serviceType
																		.name
																]
															}
															onChange={() =>
																onChangeServiceHandler(
																	serviceType
																)
															}
														/>
														<span>
															&nbsp;{' '}
															{serviceType.name}
														</span>
														<span className="checkmark"></span>
													</label>
												</Form.Group>
											);
										}
								  )
								: null}
						</div>
					</div>
					<div>
						<Header as="h3">Your selections:</Header>
						<div className="selection-note">
							Make sure to assign a service owner per service
							selected.
						</div>
						<div className="selection-note">
							Upon saving, assigned service owner will receive an
							email with their access to the service administrator
							portal.
						</div>
					</div>
					{Object.keys(serviceForm).map((service, serviceIndex) => {
						return (
							<Accordion
								number={serviceIndex + 1}
								title={`${service}`}
								key={serviceIndex}
							>
								<Grid className="assignee-grid">
									<Grid.Column
										mobile="sixteen"
										tablet="seven"
										computer="seven"
										className="assignee-padding assignee-headers"
									>
										<span>Search employee</span>
									</Grid.Column>
									<Grid.Column
										mobile="sixteen"
										tablet="one"
										computer="one"
										className="assignee-padding assignee-headers"
									/>
									<Grid.Column
										mobile="sixteen"
										tablet="seven"
										computer="seven"
										className="assignee-padding assignee-headers"
									>
										<span>Assigned service owner</span>
									</Grid.Column>
									<Grid.Column
										mobile="sixteen"
										tablet="seven"
										computer="seven"
										className="assignee-padding"
									>
										<Dropdown
											icon={
												<img
													src={searchImg}
													className="filter-searchIcon"
												/>
											}
											iconPosition="right"
											placeholder="Type name/email address"
											fluid
											search
											selection
											options={users}
											selectOnBlur={false}
											forceSelection={false}
											value=""
											className="dropdown-users"
											onBlur={() =>
												setSearchString({
													query: null,
													service,
												})
											}
											onSearchChange={ev =>
												onFilterCompanyUsers(
													ev,
													service
												)
											}
											onChange={(ev, data) =>
												onChangeUserHandler(
													ev,
													data,
													service
												)
											}
										>
											{searchString.service === service &&
											companyUserLoading ? (
												<div className="sk-chase search-loader">
													<div className="sk-chase-dot"></div>
													<div className="sk-chase-dot"></div>
													<div className="sk-chase-dot"></div>
													<div className="sk-chase-dot"></div>
													<div className="sk-chase-dot"></div>
													<div className="sk-chase-dot"></div>
												</div>
											) : null}
										</Dropdown>
									</Grid.Column>
									<Grid.Column
										mobile="sixteen"
										tablet="one"
										computer="one"
										className="assignee-padding"
									/>
									<Grid.Column
										mobile="sixteen"
										tablet="seven"
										computer="seven"
										className="assignee-padding"
									>
										<div>
											<i>
												{serviceForm[service].email ||
												(serviceForm[service].profile &&
													serviceForm[service].profile
														.first_name) ? (
													<div>
														<strong>{`${
															serviceForm[service]
																.profile
																.first_name
																? serviceForm[
																		service
																  ].profile
																		.first_name
																: ''
														} ${
															serviceForm[service]
																.profile
																.last_name
																? serviceForm[
																		service
																  ].profile
																		.last_name
																: ''
														}`}</strong>
														<div className="color-707070">
															{`${
																serviceForm[
																	service
																].email
															}${
																serviceForm[
																	service
																].profile
																	.mobile_no
																	? ', ' +
																	  serviceForm[
																			service
																	  ].profile
																			.mobile_no
																	: ''
															}`}
														</div>
													</div>
												) : (
													<div className="color-707070 sub-name">
														No one assigned yet
													</div>
												)}
											</i>
										</div>
									</Grid.Column>
								</Grid>
							</Accordion>
						);
					})}
					{/* <Accordion
						number="2"
						title="What's It Like Inside Jupiter?"
					>
						It&apos;s really hot inside Jupiter! No one knows
						exactly how hot, but scientists think it could be about
						43,000°F (24,000°C) near Jupiter&apos;s center, or core.
					</Accordion>
					<Accordion number="3" title="What Is a Black Hole?">
						A black hole is an area of such immense gravity that
						nothing -- not even light -- can escape from it.
					</Accordion> */}
				</Grid.Column>
				<Grid.Column width="sixteen" className="actions">
					<Button
						content="Cancel"
						className="app-primary-inverted-button"
						onClick={() => history.goBack()}
					/>
					<Button
						content="Save"
						disabled={disabledSaveHandler()}
						className="app-primary-button medium-button"
						onClick={() => dispatch({ type: 'open', size: 'tiny' })}
					/>
				</Grid.Column>
			</Grid>
			<Modal
				size={size}
				open={open}
				onClose={() => dispatch({ type: 'close' })}
				className="new-service-modal"
			>
				<Modal.Content>
					<div className="message-content">
						<div className="modal-image-container">
							<img
								src={questionMark}
								className="modal-image"
								alt="Question Mark"
							/>
						</div>
						<Header as="h3">
							Are you sure you want to add new service/s?
						</Header>
						<p>
							Service/s will be added to this site and assigned
							service owners will receive their access to their
							service admin account
						</p>
					</div>
					<Button
						content="Yes, proceed"
						className="app-primary-button full-button"
						onClick={() => onSaveServiceHandler()}
					/>
					<Button
						content="Cancel and go back"
						className="full-button app-primary-inverted-button"
						onClick={() => dispatch({ type: 'close' })}
					/>
				</Modal.Content>
			</Modal>
		</LayoutPage>
	);
};

AddNewService.propTypes = {
	history: PropTypes.any,
	match: PropTypes.any,
};

Accordion.propTypes = {
	number: PropTypes.number,
	title: PropTypes.string,
	children: PropTypes.any,
};

export default AddNewService;

// react lib
import React, { useState, useContext } from 'react';

// hoc
import LoginMaster from '../../hoc/Layout/Login/LoginMaster';

import { Link } from 'react-router-dom';

// less(styling)
import './Login.css';

// semantic ui component
import { Form, Button, Message, Icon, Grid } from 'semantic-ui-react';

// context
import AuthContext from '../../context/AuthContext/AuthContext';
import jwt_decode from 'jwt-decode';

// google single-sign-on
import GoogleLogin from 'react-google-login';

// router
import { Redirect } from 'react-router-dom';

import useForm from '../../hooks/useForm';

const googleLogo = process.env.REACT_APP_BASE_NAME + 'images/google.svg';
const errorAlert = process.env.REACT_APP_BASE_NAME + 'svgs/light-alert.svg';

const Login = () => {
	const [formFields, setFormFields] = useState({
		email: '',
		password: '',
		googleToken: null,
	});

	const { register, errors, handleSubmit } = useForm();

	let { loginHandler, user, authError } = useContext(AuthContext);

	if (user) {
		if (!(user instanceof Object)) {
			user = jwt_decode(user);
		}
		// const decodedUser = jwt_decode(user);
		// const { roles } = user.user;

		// determine w/c admin role the user has
		// const admin_role = roles.filter(role => {
		// 	return role.code == 'pa' || role.code == 'ca' || role.code == 'sa';
		// });

		return <Redirect to="/welcome" />;
	}

	const handleChange = (key, value) => {
		setFormFields({
			...formFields,
			[key]: value,
		});
	};

	// on success google login
	const onSuccessGoogleLogin = async response => {
		const { tokenId, profileObj } = response;
		setFormFields({
			...formFields,
			googleToken: tokenId,
			googleAccessToken: response.accessToken,
			email: profileObj.email,
		});
		loginHandler({
			...formFields,
			googleToken: tokenId,
			googleAccessToken: response.accessToken,
			email: profileObj.email,
		});
	};

	// onfailed google login
	const onFailedGoogleLogin = () => {};

	return (
		<LoginMaster>
			<Form onSubmit={handleSubmit(loginHandler)}>
				<Grid className="login-container">
					<div style={{ marginTop: '5px' }}>&nbsp;</div>
					<Grid.Row>
						{authError && authError.status === 0 ? (
							<Grid.Column
								mobile="sixteen"
								tablet="sixteen"
								computer="six"
							>
								<Message
									negative
									className="error-message w-100"
								>
									<img src={errorAlert} alt="error alert" />
									{authError.message}
								</Message>
							</Grid.Column>
						) : null}
					</Grid.Row>
					<Grid.Row className="form-grid-row">
						<Grid.Column
							mobile="sixteen"
							tablet="sixteen"
							computer="six"
						>
							<Form.Field>
								<label htmlFor="">E-mail address</label>
								<input
									className={
										errors.email ? 'input-error' : ''
									}
									type="text"
									placeholder="Enter your e-mail address here"
									name="email"
									autoFocus
									ref={register({
										required: 'Email is required.',
										pattern: {
											value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
											message: 'Invalid email address.',
										},
									})}
									onChange={e =>
										handleChange('email', e.target.value)
									}
								/>
							</Form.Field>
						</Grid.Column>
						<Grid.Column
							mobile="sixteen"
							tablet="sixteen"
							computer="six"
						>
							{errors.email && errors.email.message ? (
								<Message size="mini" className="error-message">
									<img src={errorAlert} alt="error alert" />
									<span>{errors.email.message}</span>
								</Message>
							) : null}
						</Grid.Column>
					</Grid.Row>
					<Grid.Row className="form-grid-row">
						<Grid.Column
							mobile="sixteen"
							tablet="sixteen"
							computer="six"
						>
							<Form.Field>
								<label htmlFor="">Password</label>
								<input
									type="password"
									autoComplete="off"
									className={
										errors.password ? 'input-error' : ''
									}
									placeholder="Enter password here"
									name="password"
									ref={register({
										required: 'Password is required.',
										min: {
											value: 8,
											message:
												'Password must be atleast 8 characters.',
										},
									})}
									onChange={e =>
										handleChange('password', e.target.value)
									}
								/>
							</Form.Field>
						</Grid.Column>
						<Grid.Column
							mobile="sixteen"
							tablet="sixteen"
							computer="seven"
						>
							{errors.password && errors.password.message && (
								<Message size="mini" className="error-message">
									<img src={errorAlert} alt="error alert" />
									<span>{errors.password.message}</span>
								</Message>
							)}
						</Grid.Column>
					</Grid.Row>
					<p style={{ margin: '10px 0 24px 0', width: '100%' }}>
						<Link to="forgot-password">
							<u>Forgot your password?</u>
						</Link>
					</p>
					<Grid.Column
						mobile="sixteen"
						tablet="sixteen"
						computer="six"
					>
						<Grid>
							<Grid.Column
								mobile="sixteen"
								tablet="sixteen"
								computer="sixteen"
							>
								<Button
									type="submit"
									className="app-primary-button full-button"
								>
									Login
								</Button>
								<GoogleLogin
									clientId={
										process.env.REACT_APP_GOOGLE_CLIENT_ID
									}
									onSuccess={onSuccessGoogleLogin}
									onFailure={onFailedGoogleLogin}
									scope={
										'https://www.googleapis.com/auth/admin.directory.user.readonly'
									}
									render={renderProps => (
										<Button
											onClick={renderProps.onClick}
											className="google-button full-button"
											disabled={renderProps.disabled}
										>
											<div className="google-button-logo">
												<img
													src={googleLogo}
													alt="Google Logo"
												/>
											</div>
											<div
												style={{ paddingLeft: '50px' }}
											>
												Login with Google account
											</div>
										</Button>
									)}
									buttonText="Login"
									cookiePolicy={'single_host_origin'}
								/>
							</Grid.Column>
						</Grid>
					</Grid.Column>
				</Grid>
			</Form>
		</LoginMaster>
	);
};

export default Login;

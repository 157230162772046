export const Workdesk = {
	is_same_day_booking: 1,
	is_advance_booking: 0,
	advance_booking_days: 1,
	is_every_day: 0,
	is_similar_across_days: 0,
	is_whole_day: 1,
	max_stay: null,
	allowed_advance_booking: 1,
	advance_booking_threshold: 'w',
	with_pay: 0,
	amt: null,
	// slots: [],
};

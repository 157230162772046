import gql from 'graphql-tag';

export const PATCH_BASIC_INFO = gql`
	mutation(
		$users_id: EncryptedString!
		$service_instances_id: EncryptedString!
		$input: inputBasicInfo
	) {
		patchBasicInfo(
			users_id: $users_id
			service_instances_id: $service_instances_id
			input: $input
		) {
			service_instance {
				name
				description
			}
			status
			message
		}
	}
`;

// export const POST_POLICY = gql`
// 	mutation(
// 		$users_id: EncryptedString!
// 		$service_instances_id: [EncryptedString!]
// 		$input: inputPolicy
// 	) {
// 		postPolicy(
// 			users_id: $users_id
// 			service_instances_id: $service_instances_id
// 			input: $input
// 		) {
// 			status
// 			message
// 		}
// 	}
// `;

export const POST_SERVICE_SETUP = gql`
	mutation($input: inputServiceSetup) {
		postServiceSetup(input: $input) {
			message
		}
	}
`;

export const POST_SINGLE_WORKDESK_SLOT = gql`
	mutation($service_instances_id: EncryptedString!, $input: inputSingleSlot) {
		postSingleWorkdeskSlot(
			service_instances_id: $service_instances_id
			input: $input
		) {
			message
			status
		}
	}
`;

export const POST_WORKDESKS = gql`
	mutation($input: inputBookableDeskSlot) {
		postWorkdeskSLot(input: $input) {
			message
		}
	}
`;

export const PATCH_BOOKING_SLOT_STATE = gql`
	mutation($input: inputBookingSlotStatus) {
		patchBookingSlotState(input: $input) {
			message
		}
	}
`;

export const DELETE_BOOKING_SLOT = gql`
	mutation($input: inputBookingDelete) {
		deleteBookingSlot(input: $input) {
			message
		}
	}
`;

export const POST_ROUTE = gql`
	mutation($input: inputRoute) {
		postRoute(input: $input) {
			message
			status
		}
	}
`;

export const POST_VEHICLES = gql`
	mutation(
		$service_instances_id: EncryptedString!
		$vehicles: [inputBulkVehicle]
	) {
		postVehicles(
			service_instances_id: $service_instances_id
			vehicles: $vehicles
		) {
			status
			message
		}
	}
`;

export const POST_TRIP_SCHEDULER = gql`
	mutation($input: inputPostTripScheduler) {
		postTripScheduler(input: $input) {
			message
			status
		}
	}
`;

export const EDIT_TRIP_SCHEDULER = gql`
	mutation($input: inputEditTripScheduler) {
		editTripScheduler(input: $input) {
			message
			status
		}
	}
`;

export const POST_DRIVERS = gql`
	mutation(
		$service_instances_id: EncryptedString!
		$drivers: [inputDrivers]
	) {
		postDrivers(
			service_instances_id: $service_instances_id
			drivers: $drivers
		) {
			message
			status
		}
	}
`;

export const POST_SINGLE_DRIVER = gql`
	mutation($input: inputDriver, $service_instances_id: EncryptedString) {
		postSingleDriver(
			input: $input
			service_instances_id: $service_instances_id
		) {
			message
			status
		}
	}
`;

export const PATCH_DRIVER_STATUS = gql`
	mutation($input: inputDriverStatus) {
		patchDriverStatus(input: $input) {
			message
			status
		}
	}
`;

export const DELETE_DRIVER = gql`
	mutation($input: inputDriverDisabled) {
		deleteDriver(input: $input) {
			message
			status
		}
	}
`;

export const PATCH_VEHICLE_STATUS = gql`
	mutation($input: inputVehicleStatus) {
		patchVehicleStatus(input: $input) {
			message
			status
		}
	}
`;

export const DELETE_VEHICLE = gql`
	mutation($input: inputVehicleDisabled) {
		deleteVehicle(input: $input) {
			message
			status
		}
	}
`;

export const POST_SINGLE_VEHICLE = gql`
	mutation($input: inputVehicle, $service_instances_id: EncryptedString!) {
		postSingleVehicle(
			input: $input
			service_instances_id: $service_instances_id
		) {
			message
			status
		}
	}
`;

export const PATCH_DRIVER = gql`
	mutation($input: inputPatchDriver) {
		patchDriver(input: $input) {
			message
			status
		}
	}
`;

export const DELETE_TRIP_SCHEDULER = gql`
	mutation($id: EncryptedString) {
		deleteTripScheduler(id: $id) {
			message
			status
		}
	}
`;

export const PATCH_TRIP_SCHEDULER = gql`
	mutation($input: inputPatchTrips) {
		patchTripScheduler(input: $input) {
			message
			status
		}
	}
`;

export const DELETE_TRIP_SLOTS = gql`
	mutation($id: EncryptedString) {
		deleteTripSlot(id: $id) {
			message
		}
	}
`;

export const POST_SHARED_SLOTS = gql`
	mutation PostSharedSlots($input: inputPostSharedSlots) {
		postSharedSlots(input: $input) {
			status
			message
		}
	}
`;

// react lib
import React, { useState, useEffect, useContext } from 'react';
import { Redirect } from 'react-router-dom';

import PropTypes from 'prop-types';

// grahpql queries
import { useMutation, useLazyQuery } from '@apollo/client';
import { GET_USER } from '../graphql/query'; // from parent
import { PATCH_CONFIRM_EMAIL } from '../graphql/mutation';
// hoc
import LoginMaster from '../../../hoc/Layout/Login/LoginMaster';

// semantic ui component
import { Header, Form, Button, Message, Icon, Grid } from 'semantic-ui-react';

// context
import AuthContext from '../../../context/AuthContext/AuthContext';
import GLoader from '../../../components/Loader/Loader';

// helpers
import { decrypt, encrypt } from '../../../utils';

// component
import Page404 from '../../Page404/Page404';

import useForm from '../../../hooks/useForm';

const errorAlert = process.env.REACT_APP_BASE_NAME + 'svgs/light-alert.svg';

const FirstLogin = props => {
	const email = decrypt(props.match.params.encryptedEmail);
	const [role, setRole] = useState(null);
	const [redirectTo, setRedirectTo] = useState(null);
	const [is404, set404] = useState(null);
	const [patchError, setPatchError] = useState({});
	const { user } = useContext(AuthContext);
	const { register, errors, handleSubmit, setValue } = useForm();

	useEffect(() => {
		if (user) {
			setRedirectTo('/');
			return;
		}
		if (!email) {
			set404(true);
			return;
		}
		getUser({
			variables: {
				input: {
					email,
				},
			},
		});
	}, []);

	const [getUser, { loading: isUserLoading }] = useLazyQuery(GET_USER, {
		onError: () => {},
		onCompleted: async data => {
			if (data.getUser.status == 0) {
				set404(true);
				return;
			}

			const { roles } = data.getUser;

			// check roles if has (2=CA,3=SA)
			const admin_role = roles.filter(role => {
				return role.code == 'ca' || role.code == 'sa';
			});

			// if not an admin user
			if (!admin_role.length) {
				set404(true);
				return;
			}

			setRole(admin_role[0]);

			// set 404 if user has date_confirmed (possibly via sso)
			if (
				data.getUser &&
				data.getUser.date_confirmed !== null &&
				data.getUser.hasSetPassword == null
			) {
				setRedirectTo('/');
				return;
			}

			// redirect to login if date_confirmed and password have been updated already
			if (data.getUser.date_confirmed && data.getUser.hasSetPassword) {
				setRedirectTo('/');
				return;
			}

			set404(false);
		},
	});

	const [patchConfirmEmail, { loading: isPatchLoading }] = useMutation(
		PATCH_CONFIRM_EMAIL,
		{
			onError: () => {},
			onCompleted: async data => {
				if (data.patchConfirmEmail.status === 1) {
					const encryptedEmail = props.match.params.encryptedEmail;
					const encryptedCompanyCode = await encrypt(
						formFields.companyCode
					);
					setRedirectTo(
						`/setup-password/${encryptedEmail}/${encryptedCompanyCode}`
					);
				} else if (data.patchConfirmEmail.status === 0) {
					setPatchError({ ...data.patchConfirmEmail });
					setValue('companyCode', data.patchConfirmEmail.companyCode);
				}
			},
		}
	);

	const [formFields, setFormFields] = useState({
		companyCode: null,
	});

	const handleChange = React.useCallback((key, value) => {
		setFormFields({
			...formFields,
			[key]: value,
		});
	});

	const onSubmitFunction = React.useCallback(() => {
		patchConfirmEmail({
			variables: {
				input: {
					email: email,
					companyCode: formFields.companyCode,
				},
			},
		});
	});

	if (isUserLoading || (is404 == null && redirectTo == null)) {
		return <GLoader />;
	}

	if (is404 || !email) {
		return <Page404 />;
	}

	if (redirectTo !== null) {
		return <Redirect to={redirectTo} />;
	}

	return (
		<LoginMaster handleRole={'hello'}>
			<Grid>
				<div style={{ marginTop: '5px' }}>&nbsp;</div>
				<Grid.Row>
					<Grid.Column
						mobile="sixteen"
						tablet="sixteen"
						computer="sixteen"
					>
						<Header as="h2">{role ? role.name : ''}</Header>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row>
					<Grid.Column
						mobile="sixteen"
						tablet="tablet"
						computer="sixteen"
					>
						<Form onSubmit={handleSubmit(onSubmitFunction)}>
							<Grid>
								<Grid.Row>
									<Grid.Column
										mobile="sixteen"
										tablet="sixteen"
										computer="six"
									>
										{patchError &&
										patchError.status === 0 ? (
											<Message
												negative
												className="error-message w-100"
											>
												<img
													src={errorAlert}
													alt="error alert"
												/>
												{patchError.message}
											</Message>
										) : null}
									</Grid.Column>
								</Grid.Row>
								<Grid.Row className="form-grid-row">
									<Grid.Column
										mobile="sixteen"
										tablet="sixteen"
										computer="six"
									>
										<Form.Field>
											<label htmlFor="">
												Company code
											</label>
											<input
												className={
													errors.companyCode
														? 'input-error'
														: ''
												}
												style={{
													fontSize: '15px',
													fontFamily: 'FS Elliot Pro',
												}}
												type="text"
												placeholder="Enter company code here"
												name="companyCode"
												ref={register({
													required:
														'Company code is required.',
												})}
												autoFocus
												onChange={e =>
													handleChange(
														'companyCode',
														e.target.value
													)
												}
											/>
										</Form.Field>
									</Grid.Column>
									<Grid.Column
										mobile="sixteen"
										tablet="sixteen"
										computer="six"
									>
										{errors.companyCode ? (
											<Message
												size="mini"
												className="error-message"
											>
												<img
													src={errorAlert}
													alt="error alert"
												/>
												<span>
													{errors.companyCode.message}
												</span>
											</Message>
										) : null}
									</Grid.Column>
								</Grid.Row>
								<Grid.Column
									mobile="sixteen"
									tablet="sixteen"
									computer="six"
								>
									<Button
										type="submit"
										className="app-primary-button medium-button"
									>
										Submit
									</Button>
								</Grid.Column>
								<Grid.Row></Grid.Row>
							</Grid>
						</Form>
					</Grid.Column>
					<Grid.Column
						mobile="sixteen"
						tablet="tablet"
						computer="ten"
					></Grid.Column>
				</Grid.Row>
			</Grid>
		</LoginMaster>
	);
};

FirstLogin.propTypes = {
	match: PropTypes.any,
};

export default FirstLogin;

// react lib
import React from 'react';

// semantic ui component
import { Grid, Header, Form } from 'semantic-ui-react';

import PropTypes from 'prop-types';

// Quill Rich Text Editor
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';

// less styling
import './Policies.css';

const icons = ReactQuill.Quill.import('ui/icons');
icons[
	'undo'
] = `<img src="${process.env.REACT_APP_BASE_URL}/images/redo.svg" className="toolbar-property-icon" alt="undo" />`;

icons[
	'redo'
] = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="10.557" viewBox="0 0 24 10.557"><defs><style>.a{opacity:0.661;}.b{fill:#fff;}</style></defs><g class="a" transform="translate(0 -122.24)"><g transform="translate(0 122.24)"><path class="b" d="M19.771,126.463A12.278,12.278,0,0,0,0,131.882l2.774.915a9.333,9.333,0,0,1,14.909-4.24l-4.24,4.24H24V122.24Z" transform="translate(0 -122.24)"/></g></g></svg>`;

let Size = Quill.import('attributors/style/size');
Size.whitelist = [
	'14px',
	'15px',
	'16px',
	'17px',
	'18px',
	'19px',
	'20px',
	'21px',
];
Quill.register(Size, true);

const toolbarOptions = [
	['undo'],
	['redo'],
	[{ size: Size.whitelist }],
	['bold', 'italic', 'underline'], // toggled buttons

	[{ align: [] }],
	[{ list: 'ordered' }, { list: 'bullet' }],
];
let reactQuillRef = null;

const undoHandler = () => {
	return reactQuillRef.getEditor().history.undo();
};

const redoHandler = () => {
	return reactQuillRef.getEditor().history.redo();
};

const toolbarModules = {
	toolbar: {
		container: toolbarOptions,
		handlers: {
			undo: undoHandler,
			redo: redoHandler,
		},
	},
};

const Policies = ({ form, onChangeValueHandler }) => {
	return (
		<Grid centered>
			<Grid.Column mobile="sixteen" tablet="fourteen" computer="sixteen">
				<div className="step-container">
					<Header as="h3">Policies</Header>
					<Form.Field>
						<Form.Input
							label="Title"
							placeholder="Enter policy title here"
							defaultValue={form.policy.title}
							onChange={e => {
								onChangeValueHandler(
									'policy',
									'title',
									e.target.value
								);
							}}
						/>
					</Form.Field>
					<Form.Field>
						<label>Content</label>
						<ReactQuill
							theme="snow"
							ref={el => {
								reactQuillRef = el;
							}}
							// ref={quillRef}
							value={form.policy.content}
							placeholder="Enter your service policies here"
							modules={toolbarModules}
							onChange={(content, delta, source, editor) => {
								onChangeValueHandler(
									'policy',
									'content',
									editor.getText().replace(/\s/g, '') !== ''
										? content
										: null
								);
							}}
						/>
					</Form.Field>
				</div>
			</Grid.Column>
		</Grid>
	);
};

Policies.propTypes = {
	form: PropTypes.object,
	onChangeValueHandler: PropTypes.func,
	activeStep: PropTypes.number,
};

export default Policies;

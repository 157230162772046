import React from 'react';
import './Loader.css';

const GLoader = () => {
	return (
		<>
			<div className="loader-container">
				<div className="img-container">
					<img
						src={`${process.env.REACT_APP_BASE_URL}/images/GAccess-colored.png`}
						alt="GAccess Logo"
						style={{ width: '347px' }}
					/>
				</div>
				<div className="loader-animation">
					<div className="sk-chase">
						<div className="sk-chase-dot"></div>
						<div className="sk-chase-dot"></div>
						<div className="sk-chase-dot"></div>
						<div className="sk-chase-dot"></div>
						<div className="sk-chase-dot"></div>
						<div className="sk-chase-dot"></div>
					</div>
				</div>
			</div>
		</>
	);
};

export default GLoader;

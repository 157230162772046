// import Table from '../../../../components/Table/Table';
// import Table from '../../../../components/Table/Table';
import { useApolloClient, useLazyQuery, useMutation } from '@apollo/client';
import { Collapse } from '@material-ui/core';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import FocusLock from 'react-focus-lock';
import {
	Button,
	Dropdown,
	Form,
	Grid,
	Header,
	Icon,
	Message,
	Modal,
	Popup,
	Select,
} from 'semantic-ui-react';
import AuthContext from '../../../../context/AuthContext/AuthContext';
import LoaderContext from '../../../../context/LoaderContext/LoaderContext';
import useDebounce from '../../../../hooks/useDebounce';
import { wordEllipsis } from '../../../../utils';
import { GET_SITES } from '../../../CompanyDashboard/graphql/query';
import {
	DELETE_TRIP_SCHEDULER,
	POST_ROUTE,
	POST_TRIP_SCHEDULER,
	EDIT_TRIP_SCHEDULER,
} from '../../graphql/mutation';
import {
	GET_AVAILABLE_DRIVERS,
	GET_AVAILABLE_ROUTES,
	GET_AVAILABLE_VEHICLES,
	GET_TRIP_SCHEDULER,
	GET_TRIP_SLOTS,
} from '../../graphql/query';
import './TripScheduler.css';

const addIcon = process.env.REACT_APP_BASE_NAME + 'svgs/more-white.svg';
const errorAlert = process.env.REACT_APP_BASE_NAME + 'svgs/light-alert.svg';
const moreImg = process.env.REACT_APP_BASE_NAME + 'svgs/more.svg';
const moreImgTrips = process.env.REACT_APP_BASE_NAME + 'svgs/more-trips.svg';
const originImg = process.env.REACT_APP_BASE_NAME + 'svgs/origin-web.svg';
const destinationImg =
	process.env.REACT_APP_BASE_NAME + 'svgs/destination-web.svg';
const successImg =
	process.env.REACT_APP_BASE_NAME + 'images/setupservice-complete.png';

const time = [
	{ key: '1', text: 'Morning', value: 'morning' },
	{ key: '2', text: 'Afternoon', value: 'afternoon' },
];

const day = [
	{ key: '0', text: 'Monday', value: 1 },
	{ key: '1', text: 'Tuesday', value: 2 },
	{ key: '2', text: 'Wednesday', value: 3 },
	{ key: '3', text: 'Thursday', value: 4 },
	{ key: '4', text: 'Friday', value: 5 },
	{ key: '3', text: 'Saturday', value: 6 },
	{ key: '4', text: 'Sunday', value: 7 },
];

const TripScheduler = ({ service, fetchTripSlots }) => {
	const client = useApolloClient();
	const { user } = useContext(AuthContext);
	const { showLoading, hideLoading } = useContext(LoaderContext);
	const [routeForm, setRouteForm] = useState(false);
	const [actionModal, setActionModal] = useState(false);
	const [confirmationModal, setConfirmationModal] = useState(false);
	const [deleteWithBookingModal, setDeleteWithBookingModal] = useState(false);
	const [postAction, setPostAction] = useState('');
	const [collapse, setCollapse] = useState({
		isOpen: false,
	});
	const [assignDriverVehicleForm, setAssignDriverVehicleForm] = useState({
		isOpen: null,
	});
	const [editDriverVehicleForm, setEditDriverVehicleForm] = useState({
		isOpen: null,
	});
	const [postError, setPostError] = useState({
		isError: false,
		message: '',
		isErrorPostTrips: false,
	});
	const [tripSchedulerData, setTripSchedulerData] = useState({
		trips_id: '',
		origin: '',
		destination: '',
		driver: null,
		vehicle: null,
		time: '',
		day: '',
		routes_id: '',
	});
	const [tripSchedulerFilter, setTripSchedulerFilter] = useState({
		type: moment().format('HH:mm') < '12:00' ? 'morning' : 'afternoon',
		day: moment().day() === 0 ? 7 : moment().day(),
		sites_id: '',
	});
	const [routes, setRoutes] = useState([]);
	const [tripScheduler, setTripScheduler] = useState([]);
	const [availableTime, setAvailableTime] = useState([]);
	const [originData, setOriginData] = useState([]);
	const [destinationData, setDestinationData] = useState([]);
	const [vehicleKeyword, setVehicleKeyword] = useState(null);
	const [driverKeyword, setDriverKeyword] = useState(null);
	const [availableVehicles, setAvailableVehicles] = useState([]);
	const [availableDrivers, setAvailableDrivers] = useState([]);
	const [sites, setSites] = useState([]);
	// const [sitesFilterQuery, setSitesFilterQuery] = useState('');
	const [sitesSearchQuery, setSitesSearchQuery] = useState('');

	const debouncedVehicleKeyword = useDebounce(vehicleKeyword, 500);
	const debouncedDriverKeyword = useDebounce(driverKeyword, 500);
	const debouncedSitesSearchQuery = useDebounce(sitesSearchQuery, 500);

	const [disabledScroll, setDisabledScroll] = useState(false);
	const [formPosition, setFormPosition] = useState('');
	const [formStyle, setFormStyle] = useState('');
	const [existingTripScheduler, setExistingTripScheduler] = useState(false);

	useEffect(() => {
		fetchTripScheduler();
	}, [tripSchedulerFilter, fetchTripScheduler]);

	useEffect(() => {
		fetchAvailableRoutes();
	}, [
		tripSchedulerData.origin,
		tripSchedulerData.destination,
		fetchAvailableRoutes,
	]);

	// when typing and searching on option
	const onFilterVehiclePlateNumber = evt => {
		setVehicleKeyword(evt.target.value);
	};

	// when typing and searching on driver option
	const onFilterDriverName = evt => {
		setDriverKeyword(evt.target.value);
	};

	const fetchTripScheduler = async () => {
		if (postAction !== 'delete') {
			showLoading('Loading trip scheduler...');
		}

		const result = await client.query({
			query: GET_TRIP_SCHEDULER,
			fetchPolicy: 'network-only',
			variables: {
				input: {
					shuttles_id: service.shuttle.id,
					service_instances_id: service.id,
					type: tripSchedulerFilter.type,
					day: tripSchedulerFilter.day,
					sites_id: tripSchedulerFilter.sites_id,
				},
			},
		});
		setAvailableTime(
			result.data.getTripScheduler.tripSchedulerData.availableTime
		);
		setTripScheduler(
			result.data.getTripScheduler.tripSchedulerData.trip_schedules
		);
		setRoutes(result.data.getTripScheduler.tripSchedulerData.routes);
		hideLoading();
	};
	const fetchAvailableRoutes = async () => {
		const result = await client.query({
			query: GET_AVAILABLE_ROUTES,
			fetchPolicy: 'network-only',
			variables: {
				input: {
					companies_id: user.user.companies_id,
					origin: tripSchedulerData.origin,
					destination: tripSchedulerData.destination,
				},
			},
		});
		setOriginData(
			result.data.getAvailableRoutes.originData.map(origin => {
				return {
					text: origin.name,
					value: origin.id,
				};
			})
		);
		setDestinationData(
			result.data.getAvailableRoutes.destinationData.map(destination => {
				return {
					text: destination.name,
					value: destination.id,
				};
			})
		);
	};

	const [postRoute] = useMutation(POST_ROUTE, {
		onCompleted: () => {
			fetchTripScheduler();
			hideLoading();
		},
	});

	const [postTripScheduler] = useMutation(POST_TRIP_SCHEDULER, {
		onCompleted: () => {
			fetchTripScheduler();
			hideLoading();
		},
	});

	const [editTripScheduler] = useMutation(EDIT_TRIP_SCHEDULER, {
		onCompleted: () => {
			fetchTripScheduler();
			hideLoading();
		},
	});

	const [deleteTripScheduler] = useMutation(DELETE_TRIP_SCHEDULER, {
		variables: {
			id: tripSchedulerData.trips_id,
		},
		onCompleted: async data => {
			if (data.deleteTripScheduler.status) {
				fetchTripScheduler();
				setTripSchedulerData({});
				setConfirmationModal(true);
				setTimeout(() => {
					setConfirmationModal(false);
				}, 2000);
			} else {
				setDeleteWithBookingModal(true);
				setTimeout(() => {
					setDeleteWithBookingModal(false);
				}, 2000);
			}
			setActionModal(false);
			fetchTripSlots();
			hideLoading();
		},
	});

	useEffect(() => {
		getSites({
			variables: {
				companies_id: user.user.company.id,
				filter: sitesSearchQuery,
			},
		});
	}, [debouncedSitesSearchQuery]);

	const [getSites, { loading: sitesLoading }] = useLazyQuery(GET_SITES, {
		fetchPolicy: 'network-only',
		onCompleted: ({ getSites }) => {
			setSites(getSites);
		},
	});

	const [getAvailableDrivers, { loading: driversLoading }] = useLazyQuery(
		GET_AVAILABLE_DRIVERS,
		{
			fetchPolicy: 'network-only',
			onCompleted: data => {
				setAvailableDrivers(
					data.getAvailableDrivers.map(driver => {
						return {
							text: `${driver.user.profile.first_name} ${driver.user.profile.last_name}`,
							value: driver.id,
							description: `${driver.user.email}`,
							id: driver.id,
						};
					})
				);
			},
		}
	);

	const [getAvailableVehicles, { loading: vehicleLoading }] = useLazyQuery(
		GET_AVAILABLE_VEHICLES,
		{
			fetchPolicy: 'network-only',
			onCompleted: data => {
				// setAvailableVehicles(
				// 	data && data.getAvailableVehicles.length > 0
				// 		? data.getAvailableVehicles
				// 		: []
				// );
				setAvailableVehicles(
					data.getAvailableVehicles.map(vehicle => {
						return {
							text: vehicle.plate_number,
							value: vehicle.id,
							id: vehicle.id,
						};
					})
				);
			},
		}
	);

	// useEffect for calling available vehicles
	useEffect(() => {
		getAvailableVehicles({
			variables: {
				input: {
					service_instances_id: service.id,
					day: tripSchedulerFilter.day,
					time: tripSchedulerData.time ? tripSchedulerData.time : '',
					keyword: debouncedVehicleKeyword,
				},
			},
		});
	}, [debouncedVehicleKeyword, tripSchedulerData.time]);

	// useEffect for calling available drivers
	useEffect(() => {
		getAvailableDrivers({
			variables: {
				input: {
					service_instances_id: service.id,
					day: tripSchedulerFilter.day,
					time: tripSchedulerData.time ? tripSchedulerData.time : '',
					keyword: debouncedDriverKeyword,
				},
			},
		});
	}, [debouncedDriverKeyword, tripSchedulerData.time]);
	const renderSites =
		sites &&
		sites.map(site => {
			return {
				key: site.id,
				text: site.name,
				value: site.id,
			};
		});

	const disableSaveRouteButtonHandler = useCallback(() => {
		if (!tripSchedulerData.origin || !tripSchedulerData.destination) {
			return true;
		}

		return false;
	});

	const handleChange = (
		key,
		value,
		driverTrip = null,
		vehicleTrip = null
	) => {
		if (key === 'vehicle') {
			// get vehicle index in an array
			const vehicleIndex = availableVehicles.findIndex(
				vehicle => vehicle.value === value
			);
			// get vehicle inside array
			const vehicle = availableVehicles[vehicleIndex];

			if (!vehicle) {
				setVehicleKeyword(vehicleTrip.text);
			}
			if (vehicle) {
				setVehicleKeyword(vehicle.text);
			}
		}
		if (key === 'driver') {
			const driverIndex = availableDrivers.findIndex(
				driver => driver.value === value
			);
			const driver = availableDrivers[driverIndex];

			if (!driver) {
				setDriverKeyword(driverTrip.text);
			}
			if (driver) {
				setDriverKeyword(driver.text);
			}
		}
		setTripSchedulerData({
			...tripSchedulerData,
			[key]: value,
		});
	};

	const handleSitesSearchChange = (e, { searchQuery }) => {
		setSitesSearchQuery(searchQuery);
	};

	const handleChangeFilter = (key, value) => {
		if (key == `sites_id` && value !== '') {
			const siteIndex = renderSites.findIndex(
				site => site.value === value
			);
			const site = renderSites[siteIndex];
			setSitesSearchQuery(site.text);
		}

		if (value == '') {
			setSitesSearchQuery('');
		}

		setTripSchedulerFilter({
			...tripSchedulerFilter,
			[key]: value,
		});
		setAssignDriverVehicleForm({
			isOpen: null,
		});
		setEditDriverVehicleForm({
			isOpen: null,
		});
		handleClear();
	};

	const onSubmitRoute = () => {
		setActionModal(true);
		setPostAction('route');
	};

	const onSaveAssignDriverVehicle = async () => {
		showLoading('Assign driver & vehicle...');
		const result = await postTripScheduler({
			variables: {
				input: {
					routes_id: tripSchedulerData.routes_id,
					shuttles_id: service.shuttle.id,
					drivers_id: tripSchedulerData.driver,
					vehicles_id: tripSchedulerData.vehicle,
					day: tripSchedulerData.day,
					time: tripSchedulerData.time,
				},
			},
		});

		if (result.data.postTripScheduler.status === 0) {
			setPostError({
				isErrorPostTrips: true,
				message: result.data.postTripScheduler.message,
			});
		} else {
			setAssignDriverVehicleForm({
				isOpen: null,
			});
			handleClear();
		}
	};

	const onEditAssignDriverVehicle = async trip => {
		showLoading('Assign driver & vehicle...');

		const result = await editTripScheduler({
			variables: {
				input: {
					id: trip.id,
					drivers_id: tripSchedulerData.driver
						? tripSchedulerData.driver
						: trip.driver.id,
					vehicles_id: tripSchedulerData.vehicle
						? tripSchedulerData.vehicle
						: trip.vehicle.id,
				},
			},
		});

		if (result.data.editTripScheduler.status === 0) {
			setPostError({
				isErrorPostTrips: true,
				message: result.data.editTripScheduler.message,
			});
		} else {
			setEditDriverVehicleForm({
				isOpen: null,
			});
			handleClear();
		}
	};

	const onClickProceed = async () => {
		if (postAction === 'route') {
			showLoading('Saving route...');
			const result = await postRoute({
				variables: {
					input: {
						origin: tripSchedulerData.origin,
						destination: tripSchedulerData.destination,
						shuttles_id: service.shuttle.id,
					},
				},
			});

			if (result.data.postRoute.status === 0) {
				setPostError({
					isError: true,
					message: result.data.postRoute.message,
				});
				setActionModal(false);
			} else {
				setRouteForm(false);
				setActionModal(false);
				setConfirmationModal(true);
				setTimeout(() => {
					setConfirmationModal(false);
				}, 2000);
				handleClear();
			}
		} else {
			showLoading('Deleting driver & vehicle...');
			deleteTripScheduler();
		}
	};

	const onCreateRoute = () => {
		setRouteForm(true);
		setAssignDriverVehicleForm({
			isOpen: null,
		});
		setEditDriverVehicleForm({
			isOpen: null,
		});
		handleClear();
	};

	const handleClear = () => {
		setTripSchedulerData({
			trips_id: '',
			origin: '',
			destination: '',
			driver: null,
			vehicle: null,
			time: '',
			day: '',
			routes_id: '',
		});
		setPostError({});
		setVehicleKeyword(null);
		setDriverKeyword(null);
	};

	const onClickCancelActionForm = () => {
		setActionModal(false);
	};

	const handlePost = (time, route) => {
		setTripSchedulerData({
			...tripSchedulerData,
			routes_id: route.id,
			day: tripSchedulerFilter.day,
			time: moment(time.time).format('HH:mm'),
		});
		if (postError.isErrorPostTrips) {
			handleClear();
		}
	};

	const handleDeleteTrips = (time, route, trip) => {
		setTripSchedulerData({
			...tripSchedulerData,
			trips_id: trip.id,
			time: moment(time.time).format('h:mm a'),
			origin: route.site_origin.name,
			destination: route.site_destination.name,
			driver: `${trip.driver.user.profile.first_name} ${trip.driver.user.profile.last_name}`,
			vehicle: `${trip.vehicle.make} - ${trip.vehicle.plate_number}`,
		});
		setActionModal(true);
		setAssignDriverVehicleForm({
			isOpen: null,
		});
		setEditDriverVehicleForm({
			isOpen: null,
		});
		setPostAction('delete');
	};

	const header = routes.map(route => {
		return (
			<th className="table-header-routes" key={route.id}>
				{routes.length >= 1 ? (
					<div>
						<span>
							<img src={originImg} className="route-icon" />
							{route.site_origin.name}
						</span>
						<div className="line-text"></div>

						<span>
							<img src={destinationImg} className="route-icon" />
							{route.site_destination.name}
						</span>
					</div>
				) : (
					''
				)}
			</th>
		);
	});
	/* useEffect(() => {
		if (disabledScroll) {
			let body = document.body;
			body.classList.add('overflow-hidden');
		} else {
			let body = document.body;
			body.classList.remove('overflow-hidden');
		}
	}, [disabledScroll]); */
	const datas = availableTime.map(time => {
		return (
			<tr key={time.id}>
				<td className="sticky-col first-col custom-table-padding">
					<span
						className="custom-span-icon"
						onClick={() => {
							setCollapse({
								isOpen:
									time.time === collapse.isOpen
										? null
										: time.time,
							});
							setAssignDriverVehicleForm({
								isOpen: null,
							});
						}}
					>
						{' '}
						{moment(time.time).format('hh:mm a')}
						{routes.map(route => {
							return tripScheduler.filter(
								trips =>
									moment(trips.time, 'hh:mm a').format(
										'hh:mm a'
									) === moment(time.time).format('hh:mm a') &&
									route.id === trips.route.id
							).length >= 3 ? (
								collapse.isOpen === time.time ? (
									<Icon className="ml-10" name="angle up" />
								) : (
									(tripScheduler.filter(
										trips =>
											moment(
												trips.time,
												'hh:mm a'
											).format('hh:mm a') ===
											moment(time.time).format('hh:mm a')
									),
									(
										<Icon
											className="ml-10"
											name="angle down"
										/>
									))
								)
							) : null;
						})}
					</span>
				</td>
				{routes.length >= 1 ? (
					routes.map((route, index) => {
						return (
							<td key={index} className="pb-0">
								<div
									className={
										tripScheduler.find(
											trips =>
												moment(
													trips.time,
													'hh:mm a'
												).format('hh:mm a') ===
												moment(time.time).format(
													'hh:mm a'
												)
										)
											? 'custom-width-td'
											: 'custom-collapse-td'
									}
									key={time.time}
								>
									<Popup
										trigger={
											tripScheduler.find(
												trips =>
													moment(
														trips.time,
														'hh:mm a'
													).format('hh:mm') ===
														moment(
															time.time
														).format('hh:mm') &&
													route.id === trips.route.id
											) ? (
												<img
													src={moreImgTrips}
													className="cross-icon"
												/>
											) : (
												<span
													onClick={e => {
														let halfWidth =
															window.innerWidth /
															2;
														setFormPosition(
															e.clientX >
																halfWidth
																? 'left center'
																: 'right center'
														);
														setFormStyle(
															e.clientX >
																halfWidth
																? 'custom-popup-left-style'
																: 'custom-popup-right-style'
														);
													}}
													className="add-site btnLink f-size-13"
												>
													<img
														src={moreImg}
														className="h-10"
													/>
													Assign vehicle & driver
												</span>
											)
										}
										// offset={[180, 20]}
										pinned
										on="click"
										position={formPosition}
										open={
											assignDriverVehicleForm.isOpen ===
											route.id + time.time
										}
										onOpen={() => {
											setAssignDriverVehicleForm({
												isOpen: route.id + time.time,
											});
											handlePost(time, route);
										}}
										onMount={() => {
											setDisabledScroll(true);
										}}
										onUnmount={() => {
											setDisabledScroll(false);
										}}
										size="huge"
										className={`trip-scheduler-popup-min-width ${formStyle}`}
										content={
											<div className="p-1-5rem">
												<Form>
													{postError.isErrorPostTrips && (
														<Form.Group>
															<Message
																negative
																className="error-message w-100"
															>
																<img
																	src={
																		errorAlert
																	}
																	alt="error alert"
																/>
																{
																	postError.message
																}
															</Message>
														</Form.Group>
													)}
													<div className="sub-title-shuttle-date">
														<p>
															{`${moment(
																time.time
															).format(
																'hh:mm'
															)} ${
																tripSchedulerFilter.type ===
																'morning'
																	? 'am'
																	: 'pm'
															}: Assign vehicle & driver`}
														</p>
													</div>
													<Form.Field>
														Vehicle
														<Dropdown
															className="slot-search-filter"
															noResultsMessage={
																vehicleLoading &&
																availableVehicles.length <=
																	0
																	? 'Searching...'
																	: 'No results found.'
															}
															icon={
																vehicleLoading ? (
																	<div className="sk-chase search-loader">
																		<div className="sk-chase-dot"></div>
																		<div className="sk-chase-dot"></div>
																		<div className="sk-chase-dot"></div>
																		<div className="sk-chase-dot"></div>
																		<div className="sk-chase-dot"></div>
																		<div className="sk-chase-dot"></div>
																	</div>
																) : (
																	'angle down'
																)
															}
															selectOnBlur={false}
															forceSelection={
																false
															}
															iconPosition="right"
															placeholder="Select vehicle"
															{...(tripSchedulerData.vehicle &&
															tripSchedulerData.vehicle ===
																null
																? {
																		text:
																			'Select vehicle',
																  }
																: {})}
															fluid
															search
															selection
															options={[
																...availableVehicles,
															]}
															value={
																tripSchedulerData.vehicle &&
																tripSchedulerData.vehicle !==
																	null
																	? tripSchedulerData.vehicle
																	: null
															}
															onSearchChange={
																onFilterVehiclePlateNumber
															}
															{...(vehicleKeyword
																? {
																		searchQuery: `${vehicleKeyword}`,
																  }
																: {})}
															onChange={(
																e,
																{ value }
															) => {
																handleChange(
																	'vehicle',
																	value
																);
															}}
														></Dropdown>
													</Form.Field>{' '}
													<Form.Field>
														Driver
														<Dropdown
															className="slot-search-filter"
															noResultsMessage={
																driversLoading &&
																availableDrivers.length <=
																	0
																	? 'Searching...'
																	: 'No results found.'
															}
															icon={
																driversLoading ? (
																	<div className="sk-chase search-loader">
																		<div className="sk-chase-dot"></div>
																		<div className="sk-chase-dot"></div>
																		<div className="sk-chase-dot"></div>
																		<div className="sk-chase-dot"></div>
																		<div className="sk-chase-dot"></div>
																		<div className="sk-chase-dot"></div>
																	</div>
																) : (
																	'angle down'
																)
															}
															selectOnBlur={false}
															forceSelection={
																false
															}
															{...(driverKeyword
																? {
																		searchQuery: `${driverKeyword}`,
																  }
																: {})}
															iconPosition="right"
															placeholder="Select driver"
															{...(tripSchedulerData.driver &&
															tripSchedulerData.driver ===
																null
																? {
																		text:
																			'Select driver',
																  }
																: {})}
															fluid
															search
															selection
															options={[
																...availableDrivers,
															]}
															value={
																tripSchedulerData.driver
															}
															onSearchChange={
																onFilterDriverName
															}
															onChange={(
																e,
																{ value }
															) => {
																handleChange(
																	'driver',
																	value
																);
															}}
														></Dropdown>
													</Form.Field>
													<div className="modal-action">
														<Button
															disabled={
																!tripSchedulerData.driver ||
																!tripSchedulerData.vehicle
																	? true
																	: false
															}
															className="app-primary-button full-button"
															onClick={() => {
																onSaveAssignDriverVehicle();
															}}
														>
															Save
														</Button>
														<Button
															className="app-primary-inverted-button full-button"
															onClick={() => {
																// handleClearAssign();
																handleClear();
																setAssignDriverVehicleForm(
																	!assignDriverVehicleForm
																);
															}}
														>
															Cancel
														</Button>
													</div>
												</Form>
											</div>
										}
									></Popup>
									<Collapse
										in={collapse.isOpen === time.time}
										collapsedHeight={64}
										className="pb-10"
									>
										{tripScheduler.map(trip => {
											return moment(time.time).format(
												'hh:mm'
											) ===
												moment(
													trip.time,
													'hh:mm'
												).format('hh:mm') &&
												route.id === trip.route.id ? (
												<Popup
													trigger={
														<label className="label-assign-driver-vehicle tag-color">
															<span className="bullet-custom-style">
																&#8226;
															</span>
															<span className="text-elipsis">
																{wordEllipsis(
																	`${trip.vehicle.plate_number}, ${trip.driver.user.profile.first_name} ${trip.driver.user.profile.last_name}`,
																	22
																)}{' '}
																<span
																	hidden={
																		trip.bookings_count >=
																		1
																			? true
																			: false
																	}
																	onClick={() => {
																		handleDeleteTrips(
																			time,
																			route,
																			trip
																		);
																	}}
																	className="close-icon"
																>
																	&#10005;
																</span>
																<Popup
																	trigger={
																		tripScheduler.find(
																			trips =>
																				moment(
																					trips.time,
																					'hh:mm a'
																				).format(
																					'hh:mm'
																				) ===
																					moment(
																						time.time
																					).format(
																						'hh:mm'
																					) &&
																				route.id ===
																					trips
																						.route
																						.id
																		) ? (
																			<span
																				hidden={
																					trip.bookings_count >=
																						1 ||
																					tripSchedulerFilter.day <=
																						moment().day()
																						? true
																						: false
																				}
																				className="close-icon mr-2"
																			>
																				&#9998;
																			</span>
																		) : (
																			<span
																				onClick={e => {
																					let halfWidth =
																						window.innerWidth /
																						2;
																					setFormPosition(
																						e.clientX >
																							halfWidth
																							? 'left center'
																							: 'right center'
																					);
																					setFormStyle(
																						e.clientX >
																							halfWidth
																							? 'custom-popup-left-style'
																							: 'custom-popup-right-style'
																					);
																				}}
																				className="add-site btnLink f-size-13"
																			>
																				<img
																					src={
																						moreImg
																					}
																					className="h-10"
																				/>
																				Assign
																				vehicle
																				&
																				driver
																			</span>
																		)
																	}
																	// offset={[180, 20]}
																	pinned
																	on="click"
																	position={
																		formPosition
																	}
																	open={
																		editDriverVehicleForm.isOpen ===
																		trip
																			.driver
																			.id +
																			time.time
																	}
																	onOpen={() => {
																		setEditDriverVehicleForm(
																			{
																				isOpen:
																					trip
																						.driver
																						.id +
																					time.time,
																			}
																		);
																		handlePost(
																			time,
																			route
																		);
																		setExistingTripScheduler(
																			true
																		);
																	}}
																	onMount={() => {
																		setDisabledScroll(
																			true
																		);
																	}}
																	onUnmount={() => {
																		setDisabledScroll(
																			false
																		);
																	}}
																	size="huge"
																	className={`trip-scheduler-popup-min-width ${formStyle}`}
																	content={
																		<div className="p-1-5rem">
																			<Form>
																				{postError.isErrorPostTrips && (
																					<Form.Group>
																						<Message
																							negative
																							className="error-message w-100"
																						>
																							<img
																								src={
																									errorAlert
																								}
																								alt="error alert"
																							/>
																							{
																								postError.message
																							}
																						</Message>
																					</Form.Group>
																				)}
																				<div className="sub-title-shuttle-date">
																					<p>
																						{`${moment(
																							time.time
																						).format(
																							'hh:mm'
																						)} ${
																							tripSchedulerFilter.type ===
																							'morning'
																								? 'am'
																								: 'pm'
																						}: Assign vehicle & driver`}
																					</p>
																				</div>
																				<Form.Field>
																					Vehicle
																					<Dropdown
																						className="slot-search-filter"
																						noResultsMessage={
																							vehicleLoading &&
																							availableVehicles.length <=
																								0
																								? 'Searching...'
																								: 'No results found.'
																						}
																						icon={
																							vehicleLoading ? (
																								<div className="sk-chase search-loader">
																									<div className="sk-chase-dot"></div>
																									<div className="sk-chase-dot"></div>
																									<div className="sk-chase-dot"></div>
																									<div className="sk-chase-dot"></div>
																									<div className="sk-chase-dot"></div>
																									<div className="sk-chase-dot"></div>
																								</div>
																							) : (
																								'angle down'
																							)
																						}
																						selectOnBlur={
																							false
																						}
																						forceSelection={
																							false
																						}
																						iconPosition="right"
																						placeholder="Select vehicle"
																						{...(tripSchedulerData.vehicle &&
																						tripSchedulerData.vehicle ===
																							null
																							? {
																									text:
																										'Select vehicle',
																							  }
																							: {})}
																						fluid
																						search
																						selection
																						options={[
																							{
																								text:
																									trip
																										.vehicle
																										.plate_number,
																								value:
																									trip
																										.vehicle
																										.id,
																								id:
																									trip
																										.vehicle
																										.id,
																							},
																							...availableVehicles,
																						]}
																						value={
																							tripSchedulerData.vehicle &&
																							tripSchedulerData.vehicle !==
																								null
																								? tripSchedulerData.vehicle
																								: trip
																										.vehicle
																										.id
																						}
																						onSearchChange={
																							onFilterVehiclePlateNumber
																						}
																						{...(vehicleKeyword
																							? {
																									searchQuery: `${vehicleKeyword}`,
																							  }
																							: {})}
																						onChange={(
																							e,
																							{
																								value,
																							}
																						) => {
																							handleChange(
																								'vehicle',
																								value,
																								null,
																								{
																									text:
																										trip
																											.vehicle
																											.plate_number,
																									value:
																										trip
																											.vehicle
																											.id,
																									id:
																										trip
																											.vehicle
																											.id,
																								}
																							);
																						}}
																					></Dropdown>
																				</Form.Field>{' '}
																				<Form.Field>
																					Driver
																					<Dropdown
																						className="slot-search-filter"
																						noResultsMessage={
																							driversLoading &&
																							availableDrivers.length <=
																								0
																								? 'Searching...'
																								: 'No results found.'
																						}
																						icon={
																							driversLoading ? (
																								<div className="sk-chase search-loader">
																									<div className="sk-chase-dot"></div>
																									<div className="sk-chase-dot"></div>
																									<div className="sk-chase-dot"></div>
																									<div className="sk-chase-dot"></div>
																									<div className="sk-chase-dot"></div>
																									<div className="sk-chase-dot"></div>
																								</div>
																							) : (
																								'angle down'
																							)
																						}
																						selectOnBlur={
																							false
																						}
																						forceSelection={
																							false
																						}
																						{...(driverKeyword
																							? {
																									searchQuery: `${driverKeyword}`,
																							  }
																							: {})}
																						iconPosition="right"
																						placeholder="Select driver"
																						{...(tripSchedulerData.driver &&
																						tripSchedulerData.driver ===
																							null
																							? {
																									text:
																										'Select driver',
																							  }
																							: {})}
																						fluid
																						search
																						selection
																						options={[
																							{
																								text: `${trip.driver.user.profile.first_name} ${trip.driver.user.profile.last_name}`,
																								value:
																									trip
																										.driver
																										.id,
																								description: `${trip.driver.user.email}`,
																								id:
																									trip
																										.driver
																										.id,
																							},
																							...availableDrivers,
																						]}
																						value={
																							tripSchedulerData.driver &&
																							tripSchedulerData.driver !==
																								null
																								? tripSchedulerData.driver
																								: trip
																										.driver
																										.id
																						}
																						onSearchChange={
																							onFilterDriverName
																						}
																						onChange={(
																							e,
																							{
																								value,
																							}
																						) => {
																							handleChange(
																								'driver',
																								value,
																								{
																									text: `${trip.driver.user.profile.first_name} ${trip.driver.user.profile.last_name}`,
																									value:
																										trip
																											.driver
																											.id,
																									description: `${trip.driver.user.email}`,
																									id:
																										trip
																											.driver
																											.id,
																								},
																								null
																							);
																						}}
																					></Dropdown>
																				</Form.Field>
																				<div className="modal-action">
																					<Button
																						disabled={
																							(!tripSchedulerData.driver ||
																								!tripSchedulerData.vehicle) &&
																							!existingTripScheduler
																								? true
																								: false
																						}
																						className="app-primary-button full-button"
																						onClick={() => {
																							onEditAssignDriverVehicle(
																								trip
																							);
																						}}
																					>
																						Save
																					</Button>
																					<Button
																						className="app-primary-inverted-button full-button"
																						onClick={() => {
																							// handleClearAssign();
																							handleClear();
																							setEditDriverVehicleForm(
																								!editDriverVehicleForm
																							);
																						}}
																					>
																						Cancel
																					</Button>
																				</div>
																			</Form>
																		</div>
																	}
																></Popup>
															</span>
														</label>
													}
													content={`${trip.vehicle.plate_number}, ${trip.driver.user.profile.first_name} ${trip.driver.user.profile.last_name}`}
													position="top left"
												/>
											) : (
												''
											);
										})}
									</Collapse>
								</div>
							</td>
						);
					})
				) : (
					<td className="custom-table-padding"></td>
				)}
			</tr>
		);
	});
	return (
		<div>
			<div className="sub-title-shuttle-date">
				<p>Trip scheduler</p>
			</div>
			<div className={`custom-table-list`}>
				<div className="table-list-filter workdesks-filter">
					<Form>
						<Grid>
							<Grid.Row>
								<Grid.Column
									mobile={16}
									tablet={12}
									computer={8}
								>
									<Form.Group inline>
										<label>Showing</label>
										<Form.Field>
											<Form.Field
												control={Select}
												tabIndex={-1}
												selectOnBlur={false}
												forceSelection={false}
												options={day}
												className="filter-select"
												icon="angle down"
												placeholder="Select day"
												name="day"
												onChange={(e, { value }) =>
													handleChangeFilter(
														'day',
														value
													)
												}
												value={tripSchedulerFilter.day}
											/>
										</Form.Field>
										<Form.Field>
											<Form.Field
												control={Select}
												tabIndex={-1}
												selectOnBlur={false}
												forceSelection={false}
												options={time}
												className="filter-select"
												icon="angle down"
												placeholder="Select type"
												name="type"
												onChange={(e, { value }) =>
													handleChangeFilter(
														'type',
														value
													)
												}
												value={tripSchedulerFilter.type}
											/>
										</Form.Field>
										<Form.Field width={3}>
											<Dropdown
												className="slot-search-filter"
												icon="angle down"
												selectOnBlur={false}
												forceSelection={false}
												iconPosition="right"
												defaultValue={'all'}
												placeholder="All sites"
												fluid
												search
												selection
												{...(tripSchedulerFilter.sites_id
													? {
															searchQuery: `${sitesSearchQuery}`,
													  }
													: {})}
												options={[
													{
														key: 'all_sites',
														text: 'All sites',
														value: '',
													},
													...(sites.length > 0
														? sites.map(site => {
																return {
																	key:
																		site.id,
																	text:
																		site.name,
																	value:
																		site.id,
																};
														  })
														: []),
												]}
												onSearchChange={
													handleSitesSearchChange
												}
												name="sites"
												onChange={(e, { value }) =>
													handleChangeFilter(
														'sites_id',
														value
													)
												}
											>
												{sitesLoading ? (
													<div className="sk-chase search-loader">
														<div className="sk-chase-dot"></div>
														<div className="sk-chase-dot"></div>
														<div className="sk-chase-dot"></div>
														<div className="sk-chase-dot"></div>
														<div className="sk-chase-dot"></div>
														<div className="sk-chase-dot"></div>
													</div>
												) : null}
											</Dropdown>
										</Form.Field>
										{/* <Form.Field>
											<Form.Field
												control={Select}
												tabIndex={-1}
												selectOnBlur={false}
												forceSelection={false}
												{...(tripSchedulerFilter.sites_id ===
												''
													? {
															text: 'All sites',
													  }
													: {})}
												options={[
													{
														id: 'all',
														text: 'All sites',
														value: '',
														selected: false,
													},
													...(renderSites &&
													renderSites.length > 0
														? renderSites
														: []),
												]}
												className="filter-select trip-scheduler-filter-select"
												icon="angle down"
												placeholder="All sites"
												name="time"
												onChange={(e, { value }) =>
													handleChangeFilter(
														'sites_id',
														value
													)
												}
												value={
													tripSchedulerFilter.sites_id
												}
											/>
											</Form.Field>*/}
									</Form.Group>
								</Grid.Column>
								<Grid.Column
									mobile={16}
									tablet={12}
									computer={8}
								>
									<div className="table-list-actions f-right">
										<Button
											className="ui button app-primary-button"
											onClick={() => {
												onCreateRoute();
												setPostAction('');
											}}
										>
											<img
												src={addIcon}
												className="btn-icon"
											/>{' '}
											Create new route
										</Button>
									</div>
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</Form>
				</div>

				<div className="view">
					<div
						className={`wrapper ${
							disabledScroll ? 'overflow-hidden' : ''
						}`}
						id="wrapperId"
					>
						<table className="semantic-table">
							<thead>
								<tr>
									<th className="sticky-col first-col custom-table-time">
										Time
									</th>

									{header.length >= 1 ? (
										header
									) : (
										<th className="custom-table-header">
											No route created yet
										</th>
									)}
								</tr>
							</thead>

							<tbody>
								{datas.length >= 1 ? (
									datas
								) : (
									<tr>
										<td
											colSpan={
												routes.length
													? routes.length + 1
													: 2
											}
											className="custom-table-padding v-middle"
										>
											No data found!
										</td>
									</tr>
								)}
							</tbody>
						</table>
					</div>
				</div>
			</div>
			{/* Modal section */}
			<Modal
				onClose={() => setRouteForm(false)}
				open={routeForm}
				className="desks-modal single-first-modal"
			>
				<Modal.Content>
					<FocusLock disabled={!routeForm} autoFocus={false}>
						<Header as="h3">Create new route</Header>
						<Form
							onSubmit={() => {
								onSubmitRoute();
							}}
						>
							{postError.isError && (
								<Form.Group>
									<Message
										negative
										className="error-message w-100"
									>
										<img
											src={errorAlert}
											alt="error alert"
										/>
										{postError.message}
									</Message>
								</Form.Group>
							)}
							<Form.Field>
								<Form.Field
									label="Origin"
									control={Select}
									selectOnBlur={false}
									forceSelection={false}
									options={originData}
									className="filter-select"
									icon={{
										name: 'angle down',
										className: 'icon-tripScheduler-form',
									}}
									placeholder="Select office site"
									name="origin"
									onChange={(e, { value }) => {
										handleChange('origin', value);
									}}
									value={tripSchedulerData.origin}
								/>
							</Form.Field>{' '}
							<Form.Field>
								<Form.Field
									label="Destination"
									control={Select}
									selectOnBlur={false}
									forceSelection={false}
									options={destinationData}
									className="filter-select"
									icon={{
										name: 'angle down',
										className: 'icon-tripScheduler-form',
									}}
									placeholder="Select office site"
									name="destination"
									onChange={(e, { value }) => {
										handleChange('destination', value);
									}}
									value={tripSchedulerData.destination}
								/>
							</Form.Field>
							<div className="modal-action">
								<Button
									disabled={disableSaveRouteButtonHandler()}
									className="app-primary-button full-button"
								>
									Save
								</Button>
								<Button
									className="app-primary-inverted-button full-button"
									onClick={() => {
										handleClear();
										setRouteForm(false);
									}}
								>
									Cancel
								</Button>
							</div>
						</Form>
					</FocusLock>
				</Modal.Content>
			</Modal>

			{/* remove modal */}

			<Modal
				onClose={() => setActionModal(false)}
				onOpen={() => setActionModal(true)}
				open={actionModal}
				className="employee-form-modal"
			>
				<Modal.Content>
					<img
						src={`${process.env.REACT_APP_BASE_URL}/images/question-logo.png`}
					/>
					<Header as="h3">
						{postAction === 'route'
							? 'Save changes?'
							: 'Delete trip?'}
					</Header>
					<div
						className={`modal-sub-header custom-sentence-break ${
							postAction === 'route'
								? 'mb-1rem'
								: ' delete-trip-modal-content'
						}`}
					>
						{postAction === 'route' ? (
							<p>
								Are you sure you want to save the changes made
								to <br></br>the trip point details?
							</p>
						) : (
							<div>
								{' '}
								<p>Time: {tripSchedulerData.time}</p>
								<p>
									Route:{' '}
									{`${tripSchedulerData.origin} - ${tripSchedulerData.destination}`}
								</p>
								<p>Driver: {tripSchedulerData.driver}</p>
								<p>Vehicle: {tripSchedulerData.vehicle} </p>
							</div>
						)}
					</div>
					{postAction === 'route' ? null : (
						<p className="note-delete-trip custom-sentence-break">
							Passenger will no longer see this trip in the
							booking
							<br></br>form. Are you sure you want to proceed?
						</p>
					)}
					<div className="modal-action">
						<Button
							onClick={() => {
								onClickProceed();
							}}
							className="app-primary-button custom-full-button"
						>
							Yes, proceed
						</Button>
						<Button
							className="app-primary-inverted-button custom-full-button"
							onClick={() => {
								onClickCancelActionForm();
							}}
						>
							Cancel and go back
						</Button>
					</div>
				</Modal.Content>
			</Modal>
			<Modal
				onClose={() => setConfirmationModal(false)}
				open={confirmationModal}
				className="desks-modal bulk-completion-modal"
			>
				<Modal.Content>
					<div className="bulk-completion-modal-icon">
						<img src={successImg} alt="success" />
						<Header as="h3">
							{postAction === 'route'
								? 'Change saved!'
								: 'Trip deleted!'}
						</Header>
						<p>
							{postAction === 'route'
								? 'Changes made to the trip point details were saved successfully'
								: 'Trip was successfully removed from the booking form'}
						</p>
					</div>
				</Modal.Content>
			</Modal>

			{/* If deleting a trip scheduler with connected bookings */}
			<Modal
				onClose={() => setDeleteWithBookingModal(false)}
				open={deleteWithBookingModal}
				className="desks-modal bulk-completion-modal"
			>
				<Modal.Content>
					<div className="bulk-completion-modal-icon">
						<img src={successImg} alt="success" />
						<Header as="h3">Trip not deleted!</Header>
						<p>Action will affect the connected trips today</p>
					</div>
				</Modal.Content>
			</Modal>
		</div>
	);
};

TripScheduler.propTypes = {
	service: PropTypes.any,
	fetchTripSlots: PropTypes.func,
};

export default TripScheduler;

import React from 'react';

import PropTypes from 'prop-types';

import { NavLink, useHistory } from 'react-router-dom';

// semantic ui components
import { Container } from 'semantic-ui-react';

import './MenuBar.css';

const MenuBar = ({ navLinks }) => {
	const { location } = useHistory();

	// to disable navigation to unavailable link
	const disabledNavLink = (event, navLink) => {
		if (
			navLink.to === '/gaccess-services' ||
			navLink.to === '/app-branding' ||
			navLink.to === '/app-reports'
		) {
			event.preventDefault();
		}
	};
	return navLinks.some(
		navLink =>
			location.pathname.includes(navLink.to) ||
			location.pathname.includes('/new-service') ||
			location.pathname.includes('/add-new-employee') ||
			location.pathname.includes('/add-new-companysite') ||
			location.pathname.includes('/edit-service')
	) ? (
		<div className="company-dashboard">
			<div className="navigation">
				<Container className="navigation-content">
					{navLinks.map((navLink, navLinkIndex) => {
						return (
							<NavLink
								onClick={e => disabledNavLink(e, navLink)}
								to={navLink.to}
								key={navLinkIndex}
								isActive={() =>
									location.pathname.includes(navLink.to) ||
									(location.pathname.includes(
										'/new-service'
									) &&
										navLink.to.includes('/companysite')) ||
									(location.pathname.includes(
										'/add-new-employee'
									) &&
										navLink.to.includes('/employeelist')) ||
									(location.pathname.includes(
										'/add-new-companysite'
									) &&
										navLink.to.includes('/companysite'))
								}
								className="navigation-item"
								activeClassName="navigation-item--active"
							>
								{navLink.label}
							</NavLink>
						);
					})}
				</Container>
			</div>
		</div>
	) : null;
};

MenuBar.propTypes = {
	navLinks: PropTypes.array,
};

export default MenuBar;

import PropTypes from 'prop-types';
import React, { createContext, useState } from 'react';

const LoaderContext = createContext({});

export const LoaderProvider = ({ children }) => {
	const [loading, setLoading] = useState({
		state: false,
		message: '',
	});

	const [gloader, setGloader] = useState(false);

	const showLoading = message => {
		setLoading({
			state: true,
			message: message,
		});
	};

	const hideLoading = () => {
		setLoading({
			state: false,
		});
	};

	const showGloader = () => {
		setGloader(true);
	};

	const hideGloader = () => {
		setGloader(false);
	};

	return (
		<LoaderContext.Provider
			value={{
				showLoading,
				hideLoading,
				loading,
				showGloader,
				hideGloader,
				gloader,
			}}
		>
			{children}
		</LoaderContext.Provider>
	);
};

LoaderProvider.propTypes = {
	children: PropTypes.any,
};

export default LoaderContext;

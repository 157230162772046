import React from 'react';
import { Header, Grid, Form, Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import moment from 'moment';
import clsx from 'clsx';
import { ServiceTypes } from '../../../../../models/ServiceTypes';

export const Booking = ({
	service,
	serviceTypeName,
	sortedAvailabilities,
	bookingStay,
	service_type,
}) => {
	return (
		<>
			<Header as="h3">Booking form & controls</Header>
			<div className="service-builder-item">
				<div className="item-title">Booking schedule</div>
				<div className="item-content">
					{service[serviceTypeName] &&
					service[serviceTypeName].is_same_day_booking == 1 ? (
						<>
							<span>Same day booking</span>
							<br />
						</>
					) : null}

					{service[serviceTypeName] &&
					service[serviceTypeName].is_advance_booking == 1 ? (
						<span>Advance booking</span>
					) : null}
				</div>
			</div>
			<div className="service-builder-item">
				<div className="item-title">Days available</div>
				<div className="item-content">
					{service[serviceTypeName] &&
					service[serviceTypeName].is_every_day == 1 ? (
						'Everyday'
					) : (
						<>
							<span>Selected days only</span>
							<Grid style={{ marginTop: '10px' }}>
								<Grid.Row>
									<Grid.Column width={8}>
										<div className="day-checkbox">
											{sortedAvailabilities.map(
												(day, i) => {
													return (
														<Form.Field
															control="input"
															type="checkbox"
															key={i}
															label={
																day.day === 4 ||
																day.day === 7
																	? moment()
																			.day(
																				day.day
																			)
																			.format(
																				'dd'
																			)
																	: moment()
																			.day(
																				day.day
																			)
																			.format(
																				'dd'
																			)
																			.substr(
																				0,
																				1
																			)
															}
															name={day.day}
															style={{
																cursor: 'none',
															}}
															className={clsx(
																'day-checkbox-item',
																day.is_available ===
																	1
																	? 'checked'
																	: ''
															)}
														/>
													);
												}
											)}
										</div>
									</Grid.Column>
								</Grid.Row>
							</Grid>
						</>
					)}
				</div>
			</div>
			<div className="service-builder-item">
				<div className="item-title">Time available</div>
				<div className="item-content">
					{service[serviceTypeName] &&
					service[serviceTypeName].is_similar_across_days == 1 ? (
						<span>Similar across days</span>
					) : (
						<span>Varies per day</span>
					)}
				</div>
				<div className="item-content">
					{service[serviceTypeName] &&
					service[serviceTypeName].is_similar_across_days == 1 ? (
						<>
							<Grid style={{ paddingTop: '0' }}>
								<Grid.Row>
									<Grid.Column>
										<span>
											{service.availabilities[0]
												.startime == null &&
											service.availabilities[0].endtime ==
												null
												? null
												: `${
														moment(
															service
																.availabilities[0]
																.startime,
															'HH:mm'
														).isValid() == false
															? ''
															: moment(
																	service
																		.availabilities[0]
																		.startime,
																	'HH:mm'
															  )
																	.format(
																		'hh:mm A'
																	)
																	.toString()
												  } - ${
														moment(
															service
																.availabilities[0]
																.endtime,
															'HH:mm'
														).isValid() == false
															? ''
															: moment(
																	service
																		.availabilities[0]
																		.endtime,
																	'HH:mm'
															  )
																	.format(
																		'hh:mm A'
																	)
																	.toString()
												  }`}
										</span>
									</Grid.Column>
								</Grid.Row>
							</Grid>
						</>
					) : (
						<>
							<Grid style={{ marginTop: '0' }}>
								{sortedAvailabilities.map(day => {
									if (day.is_available == 1) {
										return (
											<Grid.Row
												key={day.id}
												style={{
													paddingTop: '0',
													paddingBottom: '0',
												}}
											>
												<Grid.Column width={5}>
													<label>
														{moment()
															.day(day.day)
															.format('dddd')}
													</label>
												</Grid.Column>
												<Grid.Column width={5}>
													<span>
														{day.startime == null &&
														day.endtime == null
															? null
															: `${
																	moment(
																		day.startime,
																		'HH:mm'
																	).isValid() ==
																	false
																		? ''
																		: moment(
																				day.startime,
																				'HH:mm'
																		  )
																				.format(
																					'hh:mm A'
																				)
																				.toString()
															  } - ${
																	moment(
																		day.endtime,
																		'HH:mm'
																	).isValid() ==
																	false
																		? ''
																		: moment(
																				day.endtime,
																				'HH:mm'
																		  )
																				.format(
																					'hh:mm A'
																				)
																				.toString()
															  }`}
													</span>
												</Grid.Column>
												<Grid.Column
													width={6}
												></Grid.Column>
											</Grid.Row>
										);
									}
								})}
							</Grid>
						</>
					)}
				</div>
			</div>
			{service_type.name !== 'Shuttle' && (
				<div className="service-builder-item">
					<div className="item-title">Length of booking stay</div>
					<div className="item-content">{bookingStay()}</div>
				</div>
			)}
			{service_type.name !== 'Shuttle' ? (
				<div className="service-builder-item">
					<div className="item-title">
						List of bookable{' '}
						{ServiceTypes[service_type.name]
							? ServiceTypes[service_type.name].slotType2
							: null}
						s
					</div>
					{service.uploaded_files && service.uploaded_files.length > 0
						? service.uploaded_files.map((file, index) => {
								return (
									<a className="item-attachment" key={index}>
										<Icon name="copy outline" />
										{file.file_name}
									</a>
								);
						  })
						: 'No uploaded files yet'}
				</div>
			) : (
				<>
					<div className="service-builder-item">
						<div className="item-title">
							List of shuttle vehicles
						</div>
						{service.uploaded_files &&
						service.uploaded_files.filter(
							vehicle_file => vehicle_file.type === 2
						).length > 0
							? service.uploaded_files
									.filter(
										vehicle_file => vehicle_file.type === 2
									)
									.map((file, index) => {
										return (
											<a
												className="item-attachment"
												key={index}
											>
												<Icon name="copy outline" />
												{file.file_name}
											</a>
										);
									})
							: 'No uploaded files yet'}
					</div>
					<div className="service-builder-item">
						<div className="item-title">List of drivers</div>
						{service.uploaded_files &&
						service.uploaded_files.filter(
							driver_file => driver_file.type === 3
						).length > 0
							? service.uploaded_files
									.filter(
										driver_file => driver_file.type === 3
									)
									.map((file, index) => {
										return (
											<a
												className="item-attachment"
												key={index}
											>
												<Icon name="copy outline" />
												{file.file_name}
											</a>
										);
									})
							: 'No uploaded files yet'}
					</div>
				</>
			)}
			<div className="service-builder-inline">
				<div className="service-builder-item">
					<div className="item-title">Check-in option</div>
					<div className="item-content">
						{service[serviceTypeName] &&
						service.is_qr_checkin == 1 ? (
							<span>
								{service &&
								service.service_type &&
								service.service_type.name === 'Shuttle'
									? 'QR scan or driver manual check-in'
									: 'Qr code'}
							</span>
						) : (
							<span>
								{service &&
								service.service_type &&
								service.service_type.name === 'Shuttle'
									? 'Manual app check-in'
									: 'In-app check-in button'}
							</span>
						)}
					</div>
				</div>
				<div className="service-builder-item">
					<div className="item-title">Check-out option</div>
					<div className="item-content">
						{service &&
						service.service_type &&
						service.service_type.name !== 'Shuttle' ? (
							service[serviceTypeName] &&
							service.is_qr_checkout == 1 ? (
								<span>Qr code</span>
							) : (
								<span>In-app check-out button</span>
							)
						) : (
							<>
								<span>Driver&rsquo;s control</span>
								<div className="sub-text">
									( auto check-out to all passengers)
								</div>
							</>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

Booking.propTypes = {
	service: PropTypes.object,
	serviceTypeName: PropTypes.string,
	sortedAvailabilities: PropTypes.array,
	bookingStay: PropTypes.func,
	service_type: PropTypes.object,
};
export default Booking;

// state models
import { Days } from '../models';

/**
 * Map given day of week to Days Model
 * @param args
 * @returns Day object
 */
export const mapDays = dayInt => {
	return Days.filter(day => dayInt == day.id)[0];
};

import React, { useMemo } from 'react';
import { Icon, Table as SemanticTable, Pagination } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import './Table.css';
/**
	* Dynamic table
	@param {data} - this will be an array of object (data to be populated on table body) [{ name: "Ryan", age: "13", location: "Makati City"}]
	@param {headers} - array of string (label of table header) ["Name", "Age", "Location"]
	@param {page} - this will be an integer (current page of the table - 1 initial value) 
	@param {totalRows} - this will be an integer (the total number of data - 0 initial value as there's no fetched data)
	@param {rowsPerPage} - this will be an integer (the number of rows to be display in table - 10 initial value)
*/

// TODO: Dynamic pagination

// * This will be rerendered when the params given here was changed
const Table = ({
	datas,
	headers,
	page,
	totalRows,
	rowsPerPage,
	className,
	noDataMsg,
	onPageChange,
}) =>
	useMemo(() => {
		if (!datas && !headers) {
			return <></>;
		}
		return (
			<>
				<div className="table-container">
					<SemanticTable
						celled
						className={clsx(className, 'semantic-table')}
					>
						<SemanticTable.Header>
							<SemanticTable.Row>
								{headers && headers.length > 0
									? headers.map((header, headerIndex) => {
											return (
												<SemanticTable.HeaderCell
													key={headerIndex}
													{...(typeof header ===
													'object'
														? header.options
														: {})}
												>
													{typeof header === 'object'
														? header.name
														: header}
												</SemanticTable.HeaderCell>
											);
									  })
									: 'No header being passed'}
							</SemanticTable.Row>
						</SemanticTable.Header>
						<SemanticTable.Body>
							{datas && datas.length > 0 ? (
								datas.map((tableRow, tableRowIndex) => {
									return (
										<SemanticTable.Row key={tableRowIndex}>
											{Object.keys(tableRow).map(
												(tableCol, tableColIndex) => (
													<SemanticTable.Cell
														key={`${tableRowIndex}.${tableColIndex}`}
														{...(typeof tableRow[
															tableCol
														] === 'object' &&
														tableRow[tableCol] &&
														tableRow[tableCol]
															.options
															? {
																	...tableRow[
																		tableCol
																	].options,
															  }
															: {})}
													>
														{tableRow &&
														tableRow[tableCol] &&
														typeof tableRow[
															tableCol
														] === 'object' &&
														Object.prototype.hasOwnProperty.call(
															tableRow[tableCol],
															'data'
														)
															? tableRow[tableCol]
																	.data
															: tableRow &&
															  tableRow[tableCol]
															? tableRow[tableCol]
															: ''}
													</SemanticTable.Cell>
												)
											)}
										</SemanticTable.Row>
									);
								})
							) : (
								<SemanticTable.Row>
									<SemanticTable.Cell
										colSpan={
											headers && headers.length
												? headers.length
												: 5
										}
									>
										{noDataMsg
											? noDataMsg
											: 'No data found!'}
									</SemanticTable.Cell>
								</SemanticTable.Row>
							)}
						</SemanticTable.Body>
					</SemanticTable>
				</div>
				{totalRows > 10 ? (
					<Pagination
						floated="right"
						// defaultActivePage={page}
						activePage={page}
						onPageChange={(evt, page) => onPageChange(evt, page)}
						ellipsisItem={{
							content: <Icon name="ellipsis horizontal" />,
							icon: true,
						}}
						firstItem={
							Math.ceil(totalRows / 10) > 5
								? {
										content: (
											<Icon name="angle double left" />
										),
										icon: true,
								  }
								: null
						}
						lastItem={
							Math.ceil(totalRows / 10) > 5
								? {
										content: (
											<Icon name="angle double right" />
										),
										icon: true,
								  }
								: null
						}
						prevItem={{
							content: <Icon name="angle left" />,
							icon: true,
						}}
						nextItem={{
							content: <Icon name="angle right" />,
							icon: true,
						}}
						totalPages={Math.ceil(totalRows / 10)}
					/>
				) : null}
			</>
		);
	}, [datas, headers, page, totalRows, rowsPerPage]);

Table.propTypes = {
	datas: PropTypes.array,
	headers: PropTypes.array,
	page: PropTypes.number,
	totalRows: PropTypes.number,
	rowsPerPage: PropTypes.number,
	className: PropTypes.string,
	noDataMsg: PropTypes.string,
	onPageChange: PropTypes.func,
};

export default Table;

import gql from 'graphql-tag';

export const GET_SHUTTLE_SERVICE = gql`
	query($users_id: EncryptedString!) {
		getUsersShuttleService(users_id: $users_id) {
			id
			site {
				id
				name
			}
		}
	}
`;
/**
 * Get enabling gcheck query request body
 */
export const GET_GCHECK_IS_ENABLED = gql`
	query {
		getGcheckIsEnabled {
			enabled
		}
	}
`;

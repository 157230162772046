import React, { useState, useContext, useEffect } from 'react';
import { useLocation } from 'react-router';
import {
	Button,
	Dropdown,
	Form,
	Grid,
	Header,
	Icon,
	Message,
	Modal,
	Select,
} from 'semantic-ui-react';
import Table from '../../../../components/Table/Table';
import ParkingPDFQRCode from '../../../../components/PDFQR/ParkingPDFQRCode';
// pdf qrcode
import PDFQRCode from '../../../../components/PDFQR/PDFQRCode';
import FileUpload from '../../../../components/FileUpload/FileUpload';
import PropTypes from 'prop-types';
import ParserContext from '../../../../context/ParserContext/ParserContext';
import { useApolloClient, useQuery, useMutation } from '@apollo/client';
import {
	GET_SERVICE_INSTANCES_AREAS,
	GET_SITE_FLOORS,
	CHECK_SHARED_SLOTS,
	GET_SHARED_SLOTS,
	GET_SLOT_QRCODES,
} from '../../graphql/query';
import useDebounce from '../../../../hooks/useDebounce';
import {
	ordinalSort,
	chunkArray,
	arraySort,
	isEmail,
	mapOrder,
	savePdf,
} from '../../../../utils';
import LoaderContext from '../../../../context/LoaderContext/LoaderContext';
import { POST_SHARED_SLOTS } from '../../graphql/mutation';
import './Share-a-parking.css';
import moment from 'moment';
// semantic ui component
import { Container, Dimmer, Loader } from 'semantic-ui-react';

const addIcon = process.env.REACT_APP_BASE_NAME + 'svgs/more-white.svg';
const downloadImg = process.env.REACT_APP_BASE_NAME + 'svgs/download.svg';

const shared_status = [
	{ key: 'active', text: 'Active', value: '0' },
	{ key: 'inactive', text: 'Inactive', value: '1' },
];

const booking_status = [
	{ key: 'occupied', text: 'Occupied', value: '1' },
	{ key: 'free', text: 'Free', value: '0' },
];

const Shareaparking = ({ service, sites_id }) => {
	const client = useApolloClient();
	const location = useLocation();
	const { showLoading, hideLoading } = useContext(LoaderContext);
	const {
		fileError,
		setFileError,
		fileData,
		excelFile,
		setFileData,
		setError,
		setExcelFile,
	} = useContext(ParserContext);
	const [isOpenBulkUpload, setIsOpenBulkUpload] = useState(false);
	const [parsedSlots, setParsedSlots] = useState([]);
	const [fileToUpload, setFileToUpload] = useState(null);

	const [floors, setFloors] = useState([]);
	const [areas, setAreas] = useState([]);
	const [checkedSharedSlots, setCheckedSharedSlots] = useState([]);
	const [workdeskIDs, setWorkdeskIDs] = useState([]);
	const [filter, setFilter] = useState({
		floors_id: '',
		area: '',
		shared_status: '',
		booking_status: '',
	});
	const [paginate, setPaginate] = useState({
		page: 1,
		offset: 10,
	});

	const [searchAreaString, setSearchAreaString] = useState(null);
	const [parkingSort, setParkingSort] = useState({});
	const [searchFloorString, setSearchFloorString] = useState(null);
	const debouncedSearchFloorString = useDebounce(searchFloorString, 500);
	const [areaSearchQuery, setAreaSearchQuery] = useState('');
	const [floorSearchQuery, setFloorSearchQuery] = useState('');
	const debouncedSearchAreaString = useDebounce(searchAreaString, 500);
	const [postSharedSlots] = useMutation(POST_SHARED_SLOTS);

	const sort_obj = Object.keys(parkingSort).map(sortKey => {
		if (parkingSort[sortKey] !== null) {
			return {
				column: sortKey,
				order: parkingSort[sortKey] ? 'asc' : 'desc',
			};
		}
	});
	const { data: sharedSlots, loading: sharedSlotsLoading } = useQuery(
		GET_SHARED_SLOTS,
		{
			variables: {
				input: {
					...filter,
					service_instances_id:
						location.state.site[0].service_instances_id,
					area: filter.area === 0 ? null : filter.area,
					floors_id: filter.floors_id === 0 ? null : filter.floors_id,
					booking_status:
						filter.booking_status === 0
							? null
							: filter.booking_status,

					shared_status: filter.shared_status,
				},
				sort: sort_obj[0],
				paginate,
			},
		}
	);

	const { data: serviceInstanceArea, loading: areaLoading } = useQuery(
		GET_SERVICE_INSTANCES_AREAS,
		{
			variables: {
				service_instances_id: service.id,
				filter: debouncedSearchAreaString,
			},
		}
	);

	useEffect(() => {
		let siteAreas = [];
		siteAreas.push({
			key: 'all',
			text: 'All areas',
			value: 'all',
			selected: false,
		});

		serviceInstanceArea &&
			serviceInstanceArea.getServiceInstanceAreas.map((slot, i) => {
				if (!siteAreas.some(siteArea => siteArea.value === slot.area)) {
					siteAreas.push({
						key: i,
						text: slot.area,
						value: slot.area,
					});
				}
			});

		setAreas(siteAreas);
	}, [serviceInstanceArea]);

	const { data: siteFloors, loading: floorLoading } = useQuery(
		GET_SITE_FLOORS,
		{
			variables: {
				service_instances_id: service.id,
				sites_id: sites_id,
				filter: debouncedSearchFloorString,
			},
		}
	);

	useEffect(() => {
		let floorsArr = [];
		floorsArr.push({
			key: 'all',
			text: 'All floors',
			value: 'all',
			selected: false,
		});

		siteFloors &&
			siteFloors.getSiteFloors.map((floor, i) => {
				if (
					!floorsArr.some(
						floorsArr => floorsArr.value === floor.floors
					)
				) {
					floorsArr.push({
						key: i,
						text: floor.name,
						value: floor.id,
					});
				}
			});

		setFloors(floorsArr);
	}, [siteFloors]);

	const onChangeParkingSorting = key => {
		setParkingSort(prev => {
			return {
				[key]: !prev[key],
			};
		});
	};
	const onPageChange = (evt, page) => {
		setPaginate({
			...paginate,
			page: page.activePage,
		});
	};
	const handleChangeFilter = (key, value) => {
		setFilter({
			...filter,
			[key]: value,
		});
		setPaginate({
			...paginate,
			page: 1,
		});
	};

	// when typing and searching on option
	const onFilterAreasDropdowwn = evt => {
		setSearchAreaString(evt.target.value);
	};

	// when typing and searching on option
	const onFilterFloorsDropdowwn = evt => {
		setSearchFloorString(evt.target.value);
	};

	const handleFloorSearchChange = (e, { searchQuery }) => {
		setFloorSearchQuery(searchQuery);
		onFilterFloorsDropdowwn(e);
	};

	const handleAreaSearchChange = (e, { searchQuery }) => {
		setAreaSearchQuery(searchQuery);
		onFilterAreasDropdowwn(e);
	};

	const formatParseData = async () => {
		let slotArr = [];
		let floorArr = [];
		let err = null;
		let updatedSlots;
		const fileIndex = fileData.length - 1;
		const parsedData = fileData[fileIndex];
		if (parsedData.data && parsedData.data.length > 1) {
			for (let i = 0; i < parsedData.data.length; i++) {
				// parsed data per column
				const cols = parsedData.data[i];
				// parsing sheet starting on row 3
				if (err === null && i > 2) {
					// check if columns are exactly 8
					if (cols.length != 8) {
						err = 'We found error/s on your uploaded file';
					}

					// validation for empty / null values of each cell
					if (i >= 3) {
						// null / empty string checker
						if (
							cols.includes('') ||
							cols.includes(null) ||
							cols.includes(undefined) ||
							cols.map(row => row.trim()).includes('')
						) {
							err = 'We found error/s on your uploaded file';
						}
					}
				}
			}
			// returned empty rows when no data found starting row 4
			if (parsedData.data.length <= 3) {
				err = 'File must have at least one row/line data';
			}

			// finding index for repopulate if existing
			const parsedIndex = parsedSlots.findIndex(
				parsed => parsed.id === parsedData.id
			);

			if (parsedIndex < 0) {
				// updating parsed desks
				updatedSlots = [
					...parsedSlots,
					{
						id: parsedData.id,
						slots: slotArr,
						floors: floorArr,
					},
				];
			} else {
				const updatedDesks = [...parsedSlots];
				updatedDesks[parsedIndex] = {
					id: parsedData.id,
					slots: slotArr,
					floors: floorArr,
				};
				updatedSlots = updatedDesks;
			}
		} else {
			// return error when sheet is empty
			updatedSlots = parsedSlots.filter(
				desk => desk.id !== 'share-a-parking'
			);
			err = 'We found error/s on your uploaded file';
		}

		if (!err) {
			// per cell validation
			parsedData.data.forEach((rows, index) => {
				// starts on row 4
				if (index > 2) {
					// valid email checker | if not valid return error
					if (!isEmail(rows[5])) {
						err = 'We found error/s on your uploaded file';
						return false;
					}

					// valid parking type checking | if not valid return error
					if (
						rows[3].trim() !== 'RENTED' &&
						rows[3].trim() !== 'ASSIGNED'
					) {
						err = 'We found error/s on your uploaded file';
						return false;
					}

					// duplicate parking slot details error
					if (
						slotArr.filter(
							slot =>
								slot.area === rows[0] &&
								slot.floor === rows[1] &&
								slot.name === rows[2]
						).length > 0
					) {
						err = 'Found duplicate errors';
						return false;
					}

					// duplicate slot owner error
					if (
						slotArr.filter(slot => slot.email === rows[5]).length >
						0
					) {
						err = 'Found duplicate errors';
						return false;
					}

					// push to array parsed datas when no error found!
					slotArr.push({
						area: rows[0],
						floor: rows[1],
						name: rows[2],
						slotType: rows[3].trim() === 'RENTED' ? 0 : 1,
						employeeId: rows[4],
						email: rows[5],
						firstname: rows[6],
						lastname: rows[7],
					});

					floorArr.push({
						name: rows[1],
					});
				}
			});

			showLoading('Validating data...');
			// backend related duplicate validations
			const duplicateSlots = await client.query({
				query: CHECK_SHARED_SLOTS,
				// fetchPolicy: 'network-only',
				variables: {
					input: {
						sites_id: location.state.site[0].id,
						slots: slotArr,
					},
				},
			});

			hideLoading();

			// if backend returned false, return duplicate error
			if (
				duplicateSlots &&
				duplicateSlots.data &&
				!duplicateSlots.data.checkDuplicateSharedSlot.status
			) {
				err = 'Found duplicate errors';
			}
		}

		if (err) {
			// clearForm();
			setFileError([
				...fileError,
				{
					id: parsedData.id,
					fileError: err,
				},
			]);
		} else {
			setParsedSlots(updatedSlots);
		}
	};

	useEffect(() => {
		if (
			fileData &&
			fileData.length > 0 &&
			fileData.filter(file => file.id === 'share-a-parking').length > 0
		) {
			formatParseData();
		}

		if (fileData && fileData.length <= 0) {
			setParsedSlots([]);
		}
	}, [fileData]);

	useEffect(() => {
		setFileToUpload(excelFile.length ? excelFile[0].file.name : '');
	}, [excelFile]);

	const postSharedSlotHandler = async () => {
		const chunkedSlots = chunkArray(parsedSlots[0].slots, 1000);
		// const chunkedFloors = chunkArray(parseDesk[0].floors, 5000);
		for (let i = 0; i < chunkedSlots.length; i++) {
			showLoading(
				`Inserting parking slots...${
					chunkedSlots.length > 1 ? i + '/' + chunkedSlots.length : ''
				}`
			);
			await postSharedSlots({
				variables: {
					input: {
						service_instances_id:
							location.state.site[0].service_instances_id,
						sites_id: location.state.site[0].id,
						slots: chunkedSlots[i].sort(arraySort('floor')),
						file_to_upload: fileToUpload,
					},
				},
				awaitRefetchQueries: true,
				refetchQueries: [
					{
						query: GET_SHARED_SLOTS,
						fetchPolicy: 'network-only',
						variables: {
							input: {
								...filter,
								service_instances_id:
									location.state.site[0].service_instances_id,
								area: filter.area === 0 ? null : filter.area,
								floors_id:
									filter.floors_id === 0
										? null
										: filter.floors_id,
								booking_status:
									filter.booking_status === 0
										? null
										: filter.booking_status,

								shared_status: filter.shared_status,
							},
							sort: sort_obj[0],
							paginate,
						},
					},
					{
						query: GET_SITE_FLOORS,
						fetchPolicy: 'network-only',
						variables: {
							service_instances_id: service.id,
							sites_id: sites_id,
							filter: debouncedSearchFloorString,
						},
					},
					{
						query: GET_SERVICE_INSTANCES_AREAS,
						fetchPolicy: 'network-only',
						variables: {
							service_instances_id: service.id,
							filter: debouncedSearchAreaString,
						},
					},
				],
			});
			setParkingSort({
				'slots.id': false,
			});
			hideLoading();
			setIsOpenBulkUpload(false);
		}
	};
	useEffect(() => {
		// clean up fileuploaded on bulk modal trigger
		setFileError([]);
		setError([]);
		// setFileData(prev => prev.filter(parse => parse.id));
		if (fileData && fileData.length > 0) {
			setFileData([]);
		}
		setExcelFile([]);
		setParsedSlots([]);
	}, [isOpenBulkUpload]);

	useEffect(() => {
		if (!sharedSlotsLoading) {
			const updatedWorkdeskIDs = sharedSlots.getSharedSlots.slots.map(
				slot => slot.id
			);
			setWorkdeskIDs([...workdeskIDs, ...updatedWorkdeskIDs]);
		}
	}, [sharedSlots]);

	const onCheckDeskHandler = (evt, slot) => {
		if (evt.target.checked) {
			const updatedWorkdesks = [...checkedSharedSlots, { ...slot }];
			const orderedArray = mapOrder(updatedWorkdesks, workdeskIDs, 'id');
			setCheckedSharedSlots(orderedArray);
		} else {
			setCheckedSharedSlots(prevWorkdesks => {
				return prevWorkdesks.filter(
					workdesk => workdesk.id !== slot.id
				);
			});
		}
	};
	const onCheckAllHandler = evt => {
		if (evt.target.checked) {
			const newCheckedSharedSlot =
				!sharedSlotsLoading &&
				sharedSlots.getSharedSlots.slots.filter(slot => {
					return !checkedSharedSlots.some(
						workdesk => slot.id === workdesk.id
					);
				});
			const orderedArray = mapOrder(
				[...checkedSharedSlots, ...newCheckedSharedSlot],
				workdeskIDs,
				'id'
			);
			setCheckedSharedSlots([...orderedArray]);
		} else {
			const newCheckedSharedSlot =
				!sharedSlotsLoading &&
				sharedSlots.getSharedSlots.slots.filter(slot => {
					return !checkedSharedSlots.some(
						workdesk => slot.id === workdesk.id
					);
				});
			setCheckedSharedSlots(newCheckedSharedSlot);
		}
	};

	// onCLick bulk print qr
	const onPrintQRCode = async (single_slot = null) => {
		showLoading('Printing QR Codes...');
		const serviceSite = location.state.site[0];
		const filename = !single_slot
			? `${service.service_type.name}QR_${
					serviceSite.abbrev
			  }_${moment().format('MMDDYY')}-${moment().format('HHmm')}`
			: `${service.service_type.name}QR_${serviceSite.abbrev}_${serviceSite.name}`;
		const inputSlotsIds = single_slot
			? [
					{
						id: single_slot.id,
						serviceName: single_slot.name,
					},
			  ]
			: checkedSharedSlots.map(workdesk => {
					return {
						id: workdesk.id,
						serviceName: workdesk.name,
					};
			  });
		const qrcodes = await client.query({
			query: GET_SLOT_QRCODES,
			variables: {
				inputSlotsIds,
			},
		});
		if (service.service_type && service.service_type.name === 'Parking') {
			await savePdf(
				<ParkingPDFQRCode
					slots={qrcodes.data.getSlotQRCode}
					service={service.service_type.name}
				/>,
				`${filename}.pdf`
			);
		} else {
			await savePdf(
				<PDFQRCode
					slots={qrcodes.data.getSlotQRCode}
					service={service.service_type.name}
				/>,
				`${filename}.pdf`
			);
		}
		hideLoading();
	};

	const header = [
		...((service && service.is_qr_checkin) ||
		(service && service.is_qr_checkout)
			? [
					{
						name: (
							<>
								<label className="checkcontainer booking-checkbox">
									<input
										type="checkbox"
										onChange={evt => onCheckAllHandler(evt)}
										checked={
											!sharedSlotsLoading &&
											sharedSlots.getSharedSlots.slots
												.length ===
												sharedSlots.getSharedSlots.slots.filter(
													slot => {
														return checkedSharedSlots.some(
															workdesk =>
																workdesk.id ===
																slot.id
														);
													}
												).length &&
											checkedSharedSlots.length !== 0
										}
									/>
									<span className="checkmark"></span>
								</label>
							</>
						),
						width: 2,
					},
			  ]
			: []),
		{
			name: (
				<Button
					basic
					inverted
					className="btn-header"
					key="button"
					onClick={() => onChangeParkingSorting('workdeskID')}
				>
					Parking ID&nbsp;&nbsp;
					<Icon
						name={
							!Object.prototype.hasOwnProperty.call(
								parkingSort,
								'workdeskID'
							)
								? 'sort'
								: parkingSort.workdeskID === true
								? 'sort down'
								: 'sort up'
						}
					/>
				</Button>
			),
		},
		'Parking slot name',
		{
			name: (
				<Button
					basic
					inverted
					className="btn-header"
					key="button"
					onClick={() => onChangeParkingSorting('floor.name')}
				>
					Floor&nbsp;&nbsp;
					<Icon
						name={
							!Object.prototype.hasOwnProperty.call(
								parkingSort,
								'floor.name'
							)
								? 'sort'
								: parkingSort['floor.name'] === true
								? 'sort down'
								: 'sort up'
						}
					/>
				</Button>
			),
		},
		'Area',
		'Share status',
		'Booking status',
		'Action',
	];

	if (sharedSlotsLoading) {
		return (
			<Dimmer active={sharedSlotsLoading} className="overlay">
				<Loader indeterminate>Loading parking slots...</Loader>
			</Dimmer>
		);
	}

	const shared_slots_data = !sharedSlotsLoading
		? sharedSlots.getSharedSlots.slots.map(slot => {
				let status_shared;
				let active;
				let today = moment().format('YYYY-MM-DD');

				if (slot.shared_slots && slot.shared_slots.length >= 1) {
					let date_start = moment(
						slot.shared_slots[0].date_start
					).format('YYYY-MM-DD');

					let date_end = moment(slot.shared_slots[0].date_end).format(
						'YYYY-MM-DD'
					);
					// upcoming
					if (date_start > today) {
						status_shared = `Sharing on ${moment(date_start).format(
							'MMM DD, YYYY'
						)}`;
						active = 'Inactive';
					}
					// ended
					else if (date_start < today && date_end < today) {
						status_shared = '';
						active = 'Inactive';
					}
					// ongoing
					else if (date_start <= today && date_end >= today) {
						status_shared =
							date_start === date_end
								? //same date start and date end
								  `${moment(date_start).format('MMM DD, YYYY')}`
								: `${moment(date_start).format(
										'MMM DD, YYYY'
								  )}-${moment(date_end).format(
										'MMM DD, YYYY'
								  )}`;
						active = 'Active';
					}
				}
				// no shared_slot record
				else {
					status_shared = '';
					active = 'Inactive';
				}

				return {
					...((service && service.is_qr_checkin) ||
					(service && service.is_qr_checkout)
						? {
								checkbox: {
									data: (
										<>
											<label className="checkcontainer booking-checkbox">
												<input
													type="checkbox"
													id={slot.id}
													checked={checkedSharedSlots.some(
														workdesk =>
															workdesk.id ===
															slot.id
													)}
													onChange={evt =>
														onCheckDeskHandler(
															evt,
															slot
														)
													}
												/>
												<span className="checkmark"></span>
											</label>
										</>
									),
									options: {
										width: 1,
									},
								},
						  }
						: {}),
					parkingID: {
						data: slot.workdeskID,
						option: {
							width: 2,
						},
					},
					name: {
						data: (
							<>
								<label>{slot.name}</label>
								<br></br>
								<label className="shared-slot-name">
									{`${slot.user.profile.first_name} ${
										slot.user.profile.last_name
									} ${
										slot.user.profile.suffix === 'n/a' ||
										slot.user.profile.suffix === null
											? ''
											: slot.user.profile.suffix
									}`}
								</label>
							</>
						),
						options: {
							width: 3,
						},
					},
					floor: {
						data: slot.floor.name,
						options: {
							width: 2,
						},
					},
					area: {
						data: slot.area,
						options: {
							width: 2,
						},
					},
					shared_status: {
						data: (
							<>
								<div className="status-row status-div">
									<div
										className={`status-badge ${
											active === 'Active'
												? 'active-shared-status'
												: 'status-inactive'
										} `}
									></div>
									<label>{active}</label>
								</div>
								<label className="shared-slot-name margin-left-shared-label">
									{status_shared}
								</label>
							</>
						),
						options: {
							width: 3,
						},
					},
					booking_status: {
						data: (
							<>
								<div className="status-row status-div">
									{active === 'Active' ? (
										<>
											<div
												className={`status-badge ${
													slot.state === 1
														? slot.booking_status ===
														  1
															? 'status-occupied'
															: 'status-free'
														: '-'
												}`}
											></div>
											<span className="status-text">
												{slot.state === 1
													? slot.booking_status === 1
														? 'Occupied'
														: 'Free'
													: ''}
											</span>
										</>
									) : (
										''
									)}
								</div>
							</>
						),
						options: {
							width: 2,
						},
					},
					action: {
						data: (
							<>
								{(service && service.is_qr_checkin) ||
								(service && service.is_qr_checkout) ? (
									<a
										className="btnLink cursor-pointer custom-margin-right"
										onClick={() => onPrintQRCode(slot)}
									>
										Print QR
									</a>
								) : null}
							</>
						),
						options: {
							width: 2,
						},
					},
				};
		  })
		: [];

	return (
		<div className="site-item">
			<div className="table-list">
				<div className="table-list-filter workdesks-filter">
					<Form>
						<Grid>
							<Grid.Row>
								<Grid.Column
									mobile={16}
									tablet={12}
									computer={10}
								>
									<Form.Group inline>
										<label className="font-weight-normal">
											Showing
										</label>
										<Form.Field className="custom-width-filter">
											<Dropdown
												className="slot-search-filter"
												icon="angle down"
												selectOnBlur={false}
												forceSelection={false}
												iconPosition="right"
												placeholder="All areas"
												{...(filter.area === null
													? {
															text: 'All areas',
													  }
													: {})}
												// clearable
												fluid
												search
												selection
												options={areas}
												value={
													filter.area
														? filter.area
														: null
												}
												onSearchChange={
													handleAreaSearchChange
												}
												{...(filter.area
													? {
															searchQuery: areaSearchQuery,
													  }
													: {})}
												onChange={(e, { value }) => {
													handleChangeFilter(
														'area',
														value
													);
													setAreaSearchQuery(
														value == 'all'
															? ''
															: e.target
																	.textContent
													);
												}}
											>
												{areaLoading ? (
													<div className="sk-chase search-loader">
														<div className="sk-chase-dot"></div>
														<div className="sk-chase-dot"></div>
														<div className="sk-chase-dot"></div>
														<div className="sk-chase-dot"></div>
														<div className="sk-chase-dot"></div>
														<div className="sk-chase-dot"></div>
													</div>
												) : null}
											</Dropdown>
										</Form.Field>
										<Form.Field className="custom-width-filter">
											<Dropdown
												className="slot-search-filter"
												// icon={
												// 	floorLoading ? (
												// 		<div className="sk-chase search-loader">
												// 			<div className="sk-chase-dot"></div>
												// 			<div className="sk-chase-dot"></div>
												// 			<div className="sk-chase-dot"></div>
												// 			<div className="sk-chase-dot"></div>
												// 			<div className="sk-chase-dot"></div>
												// 			<div className="sk-chase-dot"></div>
												// 		</div>
												// 	) : (
												// 		'angle down'
												// 	)
												// }

												icon="angle down"
												selectOnBlur={false}
												forceSelection={false}
												iconPosition="right"
												placeholder="All floors"
												{...(filter.floors_id === null
													? {
															text: 'All floors',
													  }
													: {})}
												// // clearable
												fluid
												search
												selection
												// options={[
												// 	{
												// 		id: 'all',
												// 		text: 'All floors',
												// 		value: '0',
												// 		selected: false,
												// 	},
												// 	...floors,
												// ]}

												options={floors}
												value={
													filter.floors_id
														? filter.floors_id
														: null
												}
												onSearchChange={
													handleFloorSearchChange
												}
												{...(filter.floors_id
													? {
															searchQuery: floorSearchQuery,
													  }
													: {})}
												onChange={(e, { value }) => {
													handleChangeFilter(
														'floors_id',
														value
													);

													setFloorSearchQuery(
														value == 'all'
															? ''
															: e.target
																	.textContent
													);
												}}
											>
												{floorLoading ? (
													<div className="sk-chase search-loader">
														<div className="sk-chase-dot"></div>
														<div className="sk-chase-dot"></div>
														<div className="sk-chase-dot"></div>
														<div className="sk-chase-dot"></div>
														<div className="sk-chase-dot"></div>
														<div className="sk-chase-dot"></div>
													</div>
												) : null}
											</Dropdown>
										</Form.Field>

										<Form.Field>
											<Form.Field
												control={Select}
												selectOnBlur={false}
												forceSelection={false}
												{...(filter.shared_status ===
												null
													? {
															text:
																'All share status',
													  }
													: {})}
												options={[
													{
														id: 'all',
														text:
															'All share status',
														value: 'all',
														selected: false,
													},
													...shared_status,
												]}
												className="filter-select"
												icon="angle down"
												placeholder="All share status"
												name="shared_status"
												onChange={(e, { value }) =>
													handleChangeFilter(
														'shared_status',
														value
													)
												}
												value={filter.shared_status}
											/>
										</Form.Field>
										<Form.Field>
											<Form.Field
												control={Select}
												selectOnBlur={false}
												forceSelection={false}
												{...(filter.booking_status ===
												null
													? {
															text:
																'All booking status',
													  }
													: {})}
												options={[
													{
														id: 'all',
														text:
															'All booking status',
														value: 'all',
														selected: false,
													},
													...booking_status,
												]}
												className="filter-select"
												icon="angle down"
												placeholder="All booking status"
												name="status"
												onChange={(e, { value }) =>
													handleChangeFilter(
														'booking_status',
														value
													)
												}
												value={filter.booking_status}
											/>
										</Form.Field>
									</Form.Group>
								</Grid.Column>
								<Grid.Column
									mobile={16}
									tablet={12}
									computer={6}
								>
									<div
										className="table-list-actions"
										style={{ float: 'right' }}
									>
										{service.is_qr_checkin ||
										service.is_qr_checkout ? (
											<Button
												className="ui button app-primary-button btn-bulk-print"
												onClick={() =>
													onPrintQRCode(null)
												}
												size="small"
												disabled={
													checkedSharedSlots.length <=
													1
												}
											>
												<Icon name="print" />
												Bulk print QR
											</Button>
										) : null}
										{/* </Transition> */}
										<Button
											className="ui button app-primary-button"
											onClick={() =>
												setIsOpenBulkUpload(true)
											}
										>
											{/* <Icon name="upload" /> */}
											<img
												src={downloadImg}
												className="btn-icon btn-download"
											/>{' '}
											Bulk upload
										</Button>
										<Button
											className="ui button app-primary-button"
											style={{
												height: '51px !important',
											}}
											// onClick={() => {
											// 	setFirstOpenSingle(true);
											// 	clearForm();
											// }}
											disabled
										>
											<img
												src={addIcon}
												className="btn-icon"
											/>{' '}
											Add new listing
										</Button>
									</div>
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</Form>
				</div>
				<Table
					headers={header}
					noDataMsg="No employee with assign/rented parking slot has been listed yet"
					datas={shared_slots_data}
					page={paginate.page}
					totalRows={
						!sharedSlotsLoading && sharedSlots.getSharedSlots.total
					}
					rowsPerPage={10}
					onPageChange={onPageChange}
				/>
			</div>
			<Modal
				onClose={() => {
					setIsOpenBulkUpload(false);
				}}
				open={isOpenBulkUpload}
				className="desks-modal bulk-first-modal"
			>
				<Modal.Content>
					{/* <FocusLock disabled={!bulkUploadModal} autoFocus={false}> */}
					<Header as="h3">
						Bulk upload share-a-parking listings
					</Header>
					<p style={{ marginBottom: '5px' }}>
						Upload list
						<div style={{ color: '#bdbdbd' }}>
							<i>
								File must be less than 3MB of .csv or .xlsx file
							</i>
						</div>
						<p style={{ marginBottom: '10px' }}>
							<a
								className="btnLink"
								href={`${process.env.REACT_APP_AWS_S3URL}/GAccess_Share-a-parking_Template.xlsx`}
							>
								Download template here
							</a>
						</p>
					</p>
					<FileUpload id="share-a-parking" />
					{fileError.some(file => file.id === 'share-a-parking') ? (
						<Message
							negative
							className="file-error-message"
							size="tiny"
						>
							{
								fileError.filter(
									file => file.id === 'share-a-parking'
								)[0].fileError
							}
						</Message>
					) : null}
					<div className="modal-action">
						<Button
							className="app-primary-button full-button"
							disabled={
								fileError.length > 0 || parsedSlots.length <= 0
							}
							onClick={() => {
								postSharedSlotHandler();
							}}
						>
							Save
						</Button>
						<Button
							className="app-primary-inverted-button full-button"
							onClick={() => {
								setIsOpenBulkUpload(false);
							}}
						>
							Cancel
						</Button>
					</div>
					{/* </FocusLock> */}
				</Modal.Content>
			</Modal>
		</div>
	);
};

Shareaparking.propTypes = {
	service: PropTypes.object,
	sites_id: PropTypes.string,
};

export default Shareaparking;

import React from 'react';
import PropTypes from 'prop-types';
import { Header } from 'semantic-ui-react';

export const BasicInformation = ({ service }) => {
	return (
		<>
			<Header as="h3">Basic Information</Header>
			<div className="service-builder-item">
				<div className="item-title">Service name</div>
				<div className="item-content">{service.service_type.name}</div>
			</div>
			<div className="service-builder-item">
				<div className="item-title">Short description</div>
				<div className="item-content">{service.description}</div>
			</div>
			<div className="service-builder-item">
				<div className="item-title">Service is available to</div>
				<div className="item-content">
					{service.is_all_employees == 1 ? (
						<span>All employees</span>
					) : (
						<>
							<span>Specific group/s:</span>
							<ul>
								{service.groups && service.groups.length > 0
									? service.groups.map(group => (
											<li key={group.id}>{group.name}</li>
									  ))
									: null}
							</ul>
						</>
					)}
				</div>
			</div>
			<div className="service-builder-item">
				<div className="item-title">Service icon</div>
				<div className="item-content">
					<div className="service-icon">
						<div className="service-icon-image">
							<img
								src={
									service.is_default_icon == 1
										? service.service_type.icon
										: service.icon_url
								}
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
BasicInformation.propTypes = {
	service: PropTypes.object,
};

export default BasicInformation;

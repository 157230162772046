// react lib
import React, { useContext, useState, useEffect } from 'react';

// master layout
import LayoutPage from '../ServiceDashboard';

// style
import './WorkDesk.css';

// semantic ui components
import {
	Header,
	Input,
	Form,
	Select,
	Button,
	Popup,
	Transition,
	Tab,
} from 'semantic-ui-react';

import PropTypes from 'prop-types';

// components
import Table from '../../../components/Table/Table';
import { useApolloClient } from '@apollo/client';
import { GET_SITE_WORKDESKS, GET_SHUTTLE_SERVICE } from '../graphql/query';
import LoaderContext from '../../../context/LoaderContext/LoaderContext';
import AuthContext from '../../../context/AuthContext/AuthContext';
import useDebounce from '../../../hooks/useDebounce';
import { Link, Redirect } from 'react-router-dom';

import { decrypt } from '../../../utils';

import { ServiceTypes } from '../../../models/ServiceTypes';
// import Vehicles from '../ServiceDashboardPerSite/Desks/Shuttle/Vehicles/Vehicles';
// import Drivers from '../ServiceDashboardPerSite/Desks/Shuttle/Drivers/Drivers';
// import TripsToday from '../ServiceDashboardPerSite/Desks/Shuttle/TripsToday/TripsToday';

const searchImg = process.env.REACT_APP_BASE_NAME + 'svgs/search.svg';

const WorkDesk = props => {
	const serviceType = JSON.parse(
		decrypt(localStorage.getItem('service_type'))
	);
	let status;
	const client = useApolloClient();
	const { showLoading, hideLoading, showGloader, hideGloader } = useContext(
		LoaderContext
	);
	const { user } = useContext(AuthContext);
	const [workdesk, setWorkdesk] = useState({
		siteName: '',
		status: null,
	});
	const [siteWorkdesk, setSiteWorkdesk] = useState([]);
	const [paginate, setPaginate] = useState({
		page: 1,
		offset: 10,
	});
	const [checkedWorkdesks, setCheckedWorkdesks] = useState([]);
	const [totalServices, setTotalServices] = useState(0);
	const [defaultActiveIndex, setDefaultActiveIndex] = useState(0);

	const setupStatusOptions = [
		{ key: '4', text: 'All statuses', value: '4', selected: false },
		{ key: '0', text: 'Done', value: '0' },
		{ key: '1', text: 'Started', value: '1' },
		{ key: '2', text: 'To do', value: '2' },
	];

	const debouncedSitename = useDebounce(workdesk.siteName, 500);
	const debouncedStatus = useDebounce(workdesk.status, 500);

	const getShuttleService = async () => {
		showGloader();
		if (serviceType.name === 'Shuttle') {
			const shuttle_service = await client.query({
				query: GET_SHUTTLE_SERVICE,
				fetchPolicy: 'network-only',
				variables: {
					users_id: user.user.id,
				},
			});

			const shuttle = shuttle_service.data.getUsersShuttleService;
			if (shuttle.shuttle && shuttle.shuttle.id) {
				// redirect to trips today if initially setup this service
				props.history.push('/service-dashboard-per-site', {
					site: [
						{
							abbrev: shuttle.site.abbrev,
							id: shuttle.site.id,
							service_instances_id: shuttle.id,
							name: shuttle.site.name,
						},
					],
				});
			} else {
				// redirect to service setup if there's no initial setup
				props.history.push('/setupservice', {
					site: [
						{
							abbrev: shuttle.site.abbrev,
							id: shuttle.site.id,
							service_instances_id: shuttle.id,
							name: shuttle.site.name,
						},
					],
					action: 'setup',
					breadcrumbs: [
						{
							label: 'Shuttle',
							link: `/serviceadministrator`,
						},
					],
				});
			}
		}

		hideGloader();
	};

	getShuttleService();

	// componentDidMount
	useEffect(() => {
		if (localStorage.getItem('tab')) {
			setDefaultActiveIndex(localStorage.getItem('tab'));
			localStorage.removeItem('tab');
		}
	}, []);

	// filter state onChange
	useEffect(() => {
		fetchSiteWorkdeskData();
	}, [
		fetchSiteWorkdeskData,
		debouncedSitename,
		paginate.page,
		debouncedStatus,
		localStorage.getItem('service_type'),
	]);

	// resetting pagination on debounced filter changes
	useEffect(() => {
		setPaginate({
			...paginate,
			page: 1,
		});
	}, [debouncedSitename, debouncedStatus]);

	const fetchSiteWorkdeskData = async () => {
		showLoading('Loading sites...');
		const result = await client.query({
			query: GET_SITE_WORKDESKS,
			fetchPolicy: 'network-only',
			variables: {
				input: {
					users_id: user.user.id,
					company_site: workdesk.siteName,
					setup_status: workdesk.status,
					companies_id: user.user.company.id,
					service_types_id: serviceType.id,
				},
				paginate,
			},
		});
		setTotalServices(result.data.getSiteWorkdesk.total_services);
		setSiteWorkdesk(
			result.data.getSiteWorkdesk.services.map(service => {
				return {
					id: service.id,
					slots_count: service.slots_count,
					booking_count: service.booking_count,
					setup_logs: service.setup_logs,
					setup_status: service.setup_status,
					site: service.site,
					service_type: service.service_type,
				};
			})
		);
		hideLoading();
	};
	const onCheckTickboxHandler = (evt, service) => {
		if (evt.target.checked) {
			setCheckedWorkdesks([...checkedWorkdesks, { ...service }]);
		} else {
			const updatedWorkdesks = checkedWorkdesks.filter(
				workdesk => workdesk.id !== service.id
			);
			setCheckedWorkdesks(updatedWorkdesks);
		}
	};

	const onSelectAllService = evt => {
		let updatedWorkdesks = [];
		if (evt.target.checked) {
			const undoneWorkdesks = siteWorkdesk.filter(workdesk => {
				return workdesk.setup_logs.filter(log => log.status).length < 4;
			});
			updatedWorkdesks = [...checkedWorkdesks, ...undoneWorkdesks];
		} else {
			updatedWorkdesks = siteWorkdesk.filter(availableWorkdesk => {
				if (
					availableWorkdesk.setup_logs.filter(log => log.status)
						.length < 4
				) {
					return !checkedWorkdesks.some(
						workdesk => availableWorkdesk.id === workdesk.id
					);
				}
				return false;
			});
		}
		setCheckedWorkdesks(updatedWorkdesks);
	};

	const handleChange = (key, value) => {
		setWorkdesk({
			...workdesk,
			[key]: value,
		});
	};

	const handleBulkSetup = () => {
		const result = checkedWorkdesks.map(service => {
			return {
				...service.site,
				service_instances_id: service.id,
			};
		});
		props.history.push('/setupservice', {
			site: result,
			action: 'setup',
			breadcrumbs: [
				{
					label: `${ServiceTypes[serviceType.name].name}`,
					link: '/serviceadministrator',
				},
			],
		});
	};

	const onPageChange = (evt, page) => {
		setPaginate({
			...paginate,
			page: page.activePage,
		});
	};
	const renderData = siteWorkdesk.map(service => {
		if (service.setup_logs.length === 0) {
			status = 'To do';
		} else if (
			service.setup_logs.length >= 4 &&
			service.setup_logs.filter(logs => logs.status === false).length <= 0
		) {
			status = 'Done';
		} else {
			status = 'Started';
		}
		return {
			checkbox: {
				data: (
					<>
						<label className="checkcontainer booking-checkbox">
							<input
								id={service.id}
								type="checkbox"
								checked={checkedWorkdesks.some(
									workdesk => workdesk.id === service.id
								)}
								disabled={status === 'Done' ? true : false}
								onChange={evt =>
									onCheckTickboxHandler(evt, service)
								}
							/>
							<span className="checkmark"></span>
						</label>
					</>
				),
				options: {
					className: 'w-50px',
				},
			},
			site_name: {
				data: (
					<Link
						to={{
							pathname: '/service-dashboard-per-site',
							state: {
								site: [
									{
										...service.site,
										service_instances_id: service.id,
									},
								],
							},
						}}
						className="btnLink color-textlink"
					>
						<strong>{service.site.name}</strong>
						{/* <a href="#x" className="btnLink color-textlink"> */}
						{/* </a> */}
					</Link>
				),
				options: {
					className: 'w-500px',
				},
			},
			bookable_desks: {
				data:
					service.slots_count > 0
						? service.slots_count.toLocaleString(undefined, {
								maximumFractionDigits: 1,
						  }) +
						  ' ' +
						  ServiceTypes[serviceType.name].slotType1
						: '',
				options: {
					className: 'w-350px',
				},
			},
			setup_status: {
				data: (
					<>
						<Popup
							disabled={true}
							trigger={
								<div className="status-row status-div">
									<div
										className={`status-badge ${
											status === 'Done'
												? 'status-done'
												: 'status-not-done'
										}`}
									></div>
									<span className="status-text">
										{status}
									</span>
								</div>
							}
						/>
					</>
				),
				options: {
					className: 'w-350px',
				},
			},
			action: (
				<>
					<a
						style={{ cursor: 'pointer' }}
						onClick={() =>
							props.history.push('/setupservice', {
								site: [
									{
										...service.site,
										service_instances_id: service.id,
									},
								],
								action: 'setup',
								breadcrumbs: [
									{
										label: service.service_type.name,
										link: `/serviceadministrator`,
									},
								],
							})
						}
						className="btnLink"
					>
						{(() => {
							switch (status) {
								case 'Done':
									return 'Manage Service';
								case 'Started':
									return 'Continue set up';
								default:
									return 'Set up service';
							}
						})()}
					</a>
				</>
			),
		};
	});

	const header = [
		{
			name: (
				<>
					<label className="checkcontainer booking-checkbox">
						<input
							type="checkbox"
							checked={
								siteWorkdesk.filter(workdesk => {
									return (
										workdesk.setup_logs.filter(
											setup_log => setup_log.status
										).length < 4
									);
								}).length ===
									siteWorkdesk.filter(workdesk => {
										return checkedWorkdesks.some(
											checkedWorkdesk =>
												checkedWorkdesk.id ===
												workdesk.id
										);
									}).length && checkedWorkdesks.length > 0
							}
							disabled={
								siteWorkdesk.filter(workdesk => {
									return (
										workdesk.setup_logs.filter(
											setup_log => setup_log.status
										).length < 4
									);
								}).length === 0
							}
							onChange={onSelectAllService}
						/>
						<span className="checkmark"></span>
					</label>
				</>
			),
			width: 2,
		},
		{
			name: 'Company site',
			width: 4,
		},
		{
			name: `${ServiceTypes[serviceType.name].columnName}`,
			width: 2,
		},
		{
			name: 'Setup status',
			width: 2,
		},
		{
			name: 'Action',
			width: 3,
		},
	];

	// const tabPanes = [
	// 	{
	// 		menuItem: 'Trips today',
	// 		render: () => (
	// 			<Tab.Pane attached={false} style={{ padding: '0px' }}>
	// 				<TripsToday />
	// 			</Tab.Pane>
	// 		),
	// 	},
	// 	{
	// 		menuItem: 'Trip scheduler',
	// 	},
	// 	{
	// 		menuItem: 'Vehicles',
	// 		render: () => (
	// 			<Tab.Pane attached={false} style={{ padding: '0px' }}>
	// 				<Vehicles />
	// 			</Tab.Pane>
	// 		),
	// 	},
	// 	{
	// 		menuItem: 'Drivers',
	// 		render: () => (
	// 			<Tab.Pane attached={false} style={{ padding: '0px' }}>
	// 				<Drivers />
	// 			</Tab.Pane>
	// 		),
	// 	},
	// 	{
	// 		menuItem: 'Reports',
	// 	},
	// 	{
	// 		menuItem: 'Service builder',
	// 	},
	// ];

	return (
		<LayoutPage>
			<div className="page-header">
				<Header as="h1" className="page-title">
					{ServiceTypes[serviceType.name].name}
				</Header>
				<p className="page-subtitle">
					{ServiceTypes[serviceType.name].dashboard_spiel}
				</p>
			</div>
			<div className="table-list">
				<div className="table-list-filter">
					<Form>
						<Form.Group inline>
							<Form.Field>
								<Transition
									visible={
										checkedWorkdesks.filter(
											service =>
												service.setup_logs.length <= 0
										).length >= 2 &&
										checkedWorkdesks.filter(
											service =>
												service.setup_logs.length > 0
										).length <= 0
									}
									duration={200}
									divided
									size="huge"
									verticalAlign="middle"
								>
									<Button
										className="app-primary-button medium-button"
										onClick={handleBulkSetup}
									>
										Bulk set up
									</Button>
								</Transition>
							</Form.Field>
							<Form.Field className="m-r20">
								<Input
									className="input-class filter-searchContainer"
									icon={
										<img
											src={searchImg}
											className="filter-searchIcon"
										/>
									}
									iconPosition="left"
									placeholder="Type search here"
									onChange={e =>
										handleChange('siteName', e.target.value)
									}
									value={workdesk.siteName}
								/>
							</Form.Field>
							<Form.Field>
								<Form.Field
									control={Select}
									label="Filter by:"
									selectOnBlur={false}
									// forceSelection={false}
									{...(workdesk.status === null
										? {
												text: 'Select setup status',
										  }
										: {})}
									style={{
										minWidth: '210px',
									}}
									options={setupStatusOptions}
									className="filter-select"
									icon="angle down"
									placeholder="Select setup status"
									name="status"
									onChange={(e, { value }) =>
										handleChange('status', value)
									}
									value={workdesk.status}
								/>
							</Form.Field>
						</Form.Group>
					</Form>
				</div>
				<Table
					headers={header}
					datas={renderData}
					page={paginate.page}
					totalRows={totalServices}
					rowsPerPage={10}
					onPageChange={onPageChange}
				/>
			</div>
		</LayoutPage>
	);
};

WorkDesk.propTypes = {
	history: PropTypes.any,
};

export default WorkDesk;

import React from 'react';
import { Header } from 'semantic-ui-react';
import PropTypes from 'prop-types';

export const Policy = ({ service }) => {
	return (
		<>
			<Header as="h3">Policies</Header>
			<Header as="h5">
				{service.policy && service.policy.title
					? service.policy.title
					: null}
			</Header>
			<div
				dangerouslySetInnerHTML={{
					__html:
						service.policy && service.policy.content
							? service.policy.content
							: null,
				}}
				className="ql-editor overflow-none"
			></div>
		</>
	);
};

Policy.propTypes = {
	service: PropTypes.object,
};
export default Policy;

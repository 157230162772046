import React from 'react';
import { Header } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { ServiceTypes } from '../../../../../models/ServiceTypes';

export const Workflow = ({ service_type }) => {
	return (
		<>
			<Header as="h3">Workflow</Header>
			<div className="service-builder-item">
				<div className="item-title">Without approval workflow</div>
				<div className="workflow">
					<div className="workflow-item">
						<div className="workflow-content">
							<div className="workflow-title">Submit form</div>
							Employee submits booking form
						</div>
					</div>
					<div className="workflow-item">
						<div className="workflow-content">
							<div className="workflow-title">Allocate</div>
							Employee is allocated a{' '}
							{ServiceTypes[service_type.name]
								? ServiceTypes[service_type.name].slotType2
								: null}
						</div>
					</div>
					<div className="workflow-item">
						<div className="workflow-content">
							<div className="workflow-title">Check-in</div>
							Employee checks in
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

Workflow.propTypes = {
	service_type: PropTypes.object,
};
export default Workflow;

// react lib
import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Redirect } from 'react-router-dom';

// hoc
import LoginMaster from '../../../hoc/Layout/Login/LoginMaster';

// less(styling)
import '../Login.css';

// grahpql queries
import { useMutation, useLazyQuery } from '@apollo/client';
import {
	CHECK_PASSWORD_RESET_CODE,
	FORGOT_PASSWORD,
} from '../graphql/mutation';

// semantic ui component
import { Form, Button, Header, Message, Icon, Grid } from 'semantic-ui-react';

// context
import LoaderContext from '../../../context/LoaderContext/LoaderContext';

// helpers
import { encrypt, decrypt } from '../../../utils';

// component
import Page404 from '../../Page404/Page404';

import useForm from '../../../hooks/useForm';

const VerificationCode = props => {
	const [email, setEmail] = useState(
		decrypt(props.match.params.encryptedEmail)
	);
	const [code, setCode] = useState('');
	const [error, setError] = useState({});
	const [is404, set404] = useState(false);
	const [redirectTo, setRedirectTo] = useState(null);

	const { showGloader, hideGloader } = useContext(LoaderContext);

	const { register, errors, handleSubmit, setValue } = useForm();

	useEffect(() => {
		if (!email) {
			set404(true);
			return;
		}
	}, []);

	const [
		checkPasswordResetCode,
		{
			data: checkPasswordResetCodeResponse,
			errors: patchErrors,
			loading: isPatchLoading,
		},
	] = useMutation(CHECK_PASSWORD_RESET_CODE, {
		onError: error => {},
		onCompleted: data => {
			hideGloader();
			if (data.checkPasswordResetCode.status == 1) {
				const encryptedEmail = props.match.params.encryptedEmail;
				const encryptedCode = encrypt(code);
				setRedirectTo(
					`/setup-new-password/${encryptedEmail}/${encryptedCode}`
				);
			} else if (data.checkPasswordResetCode.status == 0) {
				setError({ ...data.checkPasswordResetCode });
			}
		},
	});

	const onSubmitHandler = () => {
		showGloader();
		checkPasswordResetCode({
			variables: {
				input: {
					email,
					code,
				},
			},
		});
	};

	const [
		forgotPassword,
		{
			data: SetupPasswordResponse,
			errors: forgotPasswordErrors,
			loading: forgotPasswordLoading,
		},
	] = useMutation(FORGOT_PASSWORD, {
		onError: error => {},
		onCompleted: data => {},
	});

	const handleResendCode = e => {
		e.preventDefault();
		forgotPassword({
			variables: {
				email,
			},
		});
	};

	if (!email || is404) {
		return <Page404 />;
	}

	if (redirectTo !== null) {
		return <Redirect to={redirectTo} />;
	}

	return (
		<LoginMaster>
			<div className="login-header">
				<Header as="h2">We just sent you a verification code</Header>
				<p>
					We just sent a verification code to your email. If you don’t
					see our email in your inbox, check your spam folder.
				</p>
			</div>
			<Grid>
				<Grid.Row>
					<Grid.Column
						mobile="sixteen"
						tablet="tablet"
						computer="six"
					>
						{error && error.status === 0 ? (
							<Message negative>
								<Icon
									className="error-icon"
									name="exclamation circle"
								/>
								{error.message}
							</Message>
						) : null}
						<Form onSubmit={handleSubmit(onSubmitHandler)}>
							<Form.Field /* className={'login-input-small'} */>
								<label htmlFor="">Verification code</label>
								<input
									className={errors.code ? 'input-error' : ''}
									type="text"
									placeholder="Enter verification code here"
									name="code"
									ref={register({
										required:
											'Verification code is required*',
									})}
									autoFocus
									onChange={e => setCode(e.target.value)}
								/>
								{errors.code && (
									<small className="input-error-message">
										{errors.code.message}
									</small>
								)}
							</Form.Field>
							<Button
								type="submit"
								className="app-primary-button medium-button"
							>
								Submit
							</Button>
						</Form>
						<div className="login-bottom-note">
							Didn&apos;t receive the code?{' '}
							<a href="" onClick={e => handleResendCode(e)}>
								Resend
							</a>
						</div>
					</Grid.Column>
					<Grid.Column
						mobile="sixteen"
						tablet="tablet"
						computer="eight"
					></Grid.Column>
				</Grid.Row>
			</Grid>
		</LoginMaster>
	);
};

export default VerificationCode;

import React, { useMemo } from 'react';
import { Breadcrumb, Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import './Breadcrumbs.css';

/**
	* Reusable breadcrumbs
	@param {breadcrumnbs} - an array of object with properties of label and value inside an object
*/

// TODO: Links that can navigate to breadcrumb item
const Breadcrumbs = ({ breadcrumbs, breadcrumbNavigation }) =>
	useMemo(() => {
		return breadcrumbs && breadcrumbs.length > 0
			? breadcrumbs.map((breadcrumb, breadcrumbIndex) => {
					return (
						<Breadcrumb key={breadcrumbIndex}>
							<Breadcrumb.Section
								link={breadcrumbIndex < breadcrumbs.length - 1}
								active={
									breadcrumbIndex === breadcrumbs.length - 1
								}
								onClick={() =>
									breadcrumbIndex < breadcrumbs.length - 1
										? breadcrumbNavigation(
												breadcrumb,
												breadcrumbIndex + 1
										  )
										: undefined
								}
								className={
									breadcrumbIndex === breadcrumbs.length - 1
										? 'color-gray cursor-default'
										: null
								}
								style={
									breadcrumbIndex > 0
										? { fontWeight: '700' }
										: null
								}
							>
								{breadcrumb.label}
							</Breadcrumb.Section>
							{breadcrumbIndex < breadcrumbs.length - 1 ? (
								<Icon
									style={{ color: '#6E7C9D' }}
									name="angle right"
								/>
							) : null}
						</Breadcrumb>
					);
			  })
			: null;
	}, [breadcrumbs]);

Breadcrumbs.propTypes = {
	breadcrumbs: PropTypes.array,
	breadcrumbNavigation: PropTypes.func,
};

export default Breadcrumbs;

import { useApolloClient, useLazyQuery, useMutation } from '@apollo/client';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import FocusLock from 'react-focus-lock';
import {
	Button,
	Dropdown,
	Form,
	Header,
	Input,
	Message,
	Modal,
	Select,
} from 'semantic-ui-react';
import Table from '../../../../components/Table/Table';
import LoaderContext from '../../../../context/LoaderContext/LoaderContext';
import useDebounce from '../../../../hooks/useDebounce';
import { sanitizeFilter } from '../../../../utils';
import {
	DELETE_TRIP_SLOTS,
	PATCH_TRIP_SCHEDULER,
} from '../../graphql/mutation';
import {
	GET_ROUTES,
	GET_TRIPS_TODAY,
	GET_TRIP_TODAY_DRIVERS,
	GET_TRIP_TODAY_VEHICLE,
	GET_TRIP_SLOTS,
} from '../../graphql/query';
// style
import './TripsToday.css';

const searchImg = process.env.REACT_APP_BASE_NAME + 'svgs/search.svg';
const successImg =
	process.env.REACT_APP_BASE_NAME + 'images/setupservice-complete.png';
const errorAlert = process.env.REACT_APP_BASE_NAME + 'svgs/light-alert.svg';

const type = [
	{ key: '1', text: 'Morning', value: 'morning' },
	{ key: '2', text: 'Afternoon', value: 'afternoon' },
];

const TripsToday = ({ service, fetchTripSlots }) => {
	const client = useApolloClient();
	const { showLoading, hideLoading } = useContext(LoaderContext);
	const [totalRecords, setTotalRecords] = useState(0);
	const [checkTripsToday, setCheckTripsToday] = useState(0);
	const [actionModal, setActionModal] = useState(false);
	const [confirmationModal, setConfirmationModal] = useState(false);
	const [editModal, setEditModal] = useState(false);
	const [postAction, setPostAction] = useState('');
	const [postError, setPostError] = useState({
		isError: false,
		message: '',
	});
	const [paginate, setPaginate] = useState({
		page: 1,
		offset: 10,
	});
	const [tripsTodayFilter, setTripsTodayFilter] = useState({
		type_search: '',
		type: 'morning',
		routes_id: '',
	});
	const [tripSchedulerData, setTripSchedulerData] = useState({
		id: '',
		idno: '',
		origin: '',
		destination: '',
		driver: null,
		vehicle: null,
		trip_date: '',
		day: '',
		routes_id: '',
		trips_id: '',
	});
	const [tripsToday, setTripsToday] = useState([]);
	const [availableVehicles, setAvailableVehicles] = useState([]);
	const [availableDrivers, setAvailableDrivers] = useState([]);
	const [vehicleKeyword, setVehicleKeyword] = useState(null);
	const [driverKeyword, setDriverKeyword] = useState(null);
	// const [routesFilterQuery, setRoutesFilterQuery] = useState('');
	const [routes, setRoutes] = useState([]);
	const [routesSearchQuery, setRoutesSearchQuery] = useState('');

	const debouncedVehicleKeyword = useDebounce(vehicleKeyword, 500);
	const debouncedDriverKeyword = useDebounce(driverKeyword, 500);
	const debouncedRoutesSearchQuery = useDebounce(routesSearchQuery, 500);

	useEffect(() => {
		fetchTripsToday();
	}, [paginate.page, useDebounce(tripsTodayFilter, 500)]);

	useEffect(() => {
		getAvailableVehicles({
			variables: {
				service_instances_id: service.id,
				keyword: debouncedVehicleKeyword,
			},
		});
	}, [debouncedVehicleKeyword]);

	useEffect(() => {
		getAvailableDrivers({
			variables: {
				service_instances_id: service.id,
				keyword: debouncedDriverKeyword,
			},
		});
	}, [debouncedDriverKeyword]);

	useEffect(() => {
		getRoutes({
			variables: {
				shuttles_id:
					service && service.shuttle ? service.shuttle.id : null,
				filter: routesSearchQuery,
			},
		});
	}, [debouncedRoutesSearchQuery]);

	// when typing and searching on option
	const onFilterVehiclePlateNumber = evt => {
		setVehicleKeyword(evt.target.value);
	};

	// when typing and searching on driver option
	const onFilterDriverName = evt => {
		setDriverKeyword(evt.target.value);
	};

	const handleRoutesSearchChange = (e, { searchQuery }) => {
		setRoutesSearchQuery(searchQuery);
		// setRoutesFilterQuery(e);
	};

	const fetchTripsToday = async () => {
		if (postAction !== 'delete') {
			showLoading('Loading trips today...');
		}

		const result = await client.query({
			query: GET_TRIPS_TODAY,
			fetchPolicy: 'network-only',
			variables: {
				input: {
					routes_id: tripsTodayFilter.routes_id,
					type: tripsTodayFilter.type,
					type_search: tripsTodayFilter.type_search,
					shuttles_id:
						service && service.shuttle ? service.shuttle.id : null,
				},
				paginate,
			},
		});
		if (result.data.getTripsToday.total_records > 0) {
			// get available vehicles if there are trips today datas
			getAvailableVehicles({
				variables: {
					service_instances_id: service.id,
				},
			});

			// get available drivers if there are trips today datas
			getAvailableDrivers({
				variables: {
					service_instances_id: service.id,
				},
			});
		}
		setTripsToday(result.data.getTripsToday.trips);
		setTotalRecords(result.data.getTripsToday.total_records);
		setCheckTripsToday(result.data.getTripsToday.checkTripsToday);
		hideLoading();
	};

	const [getRoutes, { loading: routesLoading }] = useLazyQuery(GET_ROUTES, {
		fetchPolicy: 'network-only',
		onCompleted: ({ getRoutes }) => {
			setRoutes(getRoutes);
		},
	});
	const [deleteTripSlots] = useMutation(DELETE_TRIP_SLOTS, {
		onCompleted: data => {
			fetchTripSlots();
			fetchTripsToday();
			setTripSchedulerData({});
			setActionModal(false);
			hideLoading();
			setConfirmationModal(true);
			if (tripsToday.length <= 1) {
				setPaginate({
					page: 1,
					offset: 10,
				});
			}
			setTimeout(() => {
				setConfirmationModal(false);
			}, 2000);
		},
	});

	const [patchTripScheduler] = useMutation(PATCH_TRIP_SCHEDULER, {
		onCompleted: () => {
			fetchTripsToday();
			hideLoading();
		},
	});

	const [getAvailableDrivers, { loading: driversLoading }] = useLazyQuery(
		GET_TRIP_TODAY_DRIVERS,
		{
			// fetchPolicy: 'network-only',
			onCompleted: data => {
				if (
					data &&
					data.getTripsTodayDrivers &&
					data.getTripsTodayDrivers.length > 0
				) {
					setAvailableDrivers(
						data.getTripsTodayDrivers.map(driver => {
							return {
								text: `${driver.user.profile.first_name} ${driver.user.profile.last_name}`,
								value: driver.id,
							};
						})
					);
				}
			},
		}
	);

	// const { data: availableVehicles } = useQuery(GET_TRIP_TODAY_VEHICLE, {
	// 	fetchPolicy: 'network-only',
	// 	variables: {
	// 		service_instances_id: service.id,
	// 	},
	// });
	const [getAvailableVehicles, { loading: vehicleLoading }] = useLazyQuery(
		GET_TRIP_TODAY_VEHICLE,
		{
			onCompleted: data => {
				if (
					data &&
					data.getTripsTodayVehicles &&
					data.getTripsTodayVehicles.length > 0
				) {
					setAvailableVehicles(
						data.getTripsTodayVehicles.map(vehicle => {
							return {
								text: vehicle.plate_number,
								value: vehicle.id,
							};
						})
					);
				}
			},
		}
	);

	// const renderAvailableVehicles =
	// 	availableVehicles &&
	// 	availableVehicles.getTripsTodayVehicles.map(vehicle => {
	// 		return {
	// 			text: `${vehicle.make} - ${vehicle.plate_number}`,
	// 			value: vehicle.id,
	// 		};
	// 	});

	// const renderAvailableDrivers =
	// 	availableDrivers &&
	// 	availableDrivers.getTripsTodayDrivers.map(driver => {
	// 		return {
	// 			text: `${driver.user.profile.first_name} ${driver.user.profile.last_name}`,
	// 			value: driver.id,
	// 		};
	// 	});

	const renderRoutes =
		routes &&
		routes.map(route => {
			return {
				key: route.id,
				text: `${route.site_origin.name} - ${route.site_destination.name}`,
				value: route.id,
			};
		});

	const onPatchTrip = async () => {
		const result = await patchTripScheduler({
			variables: {
				input: {
					id: tripSchedulerData.id,
					drivers_id: tripSchedulerData.driver,
					vehicles_id: tripSchedulerData.vehicle,
					trip_date: tripSchedulerData.trip_date,
					// shuttles_id: service.shuttle.id,
					// day: moment().day(),
				},
			},
		});
		if (result.data.patchTripScheduler.status === 0) {
			setActionModal(false);
			setEditModal(true);
			setConfirmationModal(false);

			setPostError({
				isErrorPostTrips: true,
				message: result.data.patchTripScheduler.message,
			});
		} else {
			handleClear();
			setActionModal(false);
			setEditModal(false);
			setConfirmationModal(true);
			setTimeout(() => {
				setConfirmationModal(false);
			}, 2000);
		}
	};

	const onClickProceed = () => {
		if (postAction === 'edit') {
			showLoading('Updating trips today...');
			onPatchTrip();
		} else {
			showLoading('Deleting trips today...');
			deleteTripSlots({
				variables: {
					id: tripSchedulerData.id,
				},
			});
		}
	};

	const handleChangeFilter = (key, value) => {
		if (key == `routes_id` && value !== '') {
			const routeIndex = renderRoutes.findIndex(
				site => site.value === value
			);
			const route = renderRoutes[routeIndex];
			setRoutesSearchQuery(route.text);
		}

		if (value == '') {
			setRoutesSearchQuery('');
		}
		setPaginate({
			...paginate,
			page: 1,
		});
		setTripsTodayFilter({
			...tripsTodayFilter,
			[key]: value,
		});
	};

	const handleChange = (key, value) => {
		setTripSchedulerData({
			...tripSchedulerData,
			[key]: value,
		});
	};

	const handleClear = () => {
		setPostError({});
		setTripSchedulerData({});
	};

	const onPageChange = (evt, page) => {
		setPaginate({
			...paginate,
			page: page.activePage,
		});
	};

	const onClickCancelActionForm = () => {
		// setTripSchedulerData({});
		setActionModal(false);
	};

	const handleEditTrips = trip => {
		setTripSchedulerData({
			...tripSchedulerData,
			id: trip.id,
			trips_id: trip.trip_schedule.id,
			idno: trip.idno,
			trip_date: trip.trip_date,
			origin: trip.route.site_origin.name,
			destination: trip.route.site_destination.name,
			driver: trip.driver.id,
			vehicle: trip.vehicle.id,
		});
		setPostAction('edit');
		setEditModal(true);
	};

	const handleDeleteTrips = trip => {
		setTripSchedulerData({
			...tripSchedulerData,
			id: trip.id,
			trips_id: trip.trip_schedule.id,
			trip_date: trip.trip_date,
			origin: trip.route.site_origin.name,
			destination: trip.route.site_destination.name,
			driver: `${trip.driver.user.profile.first_name} ${trip.driver.user.profile.last_name}`,
			vehicle: `${trip.vehicle.make} - ${trip.vehicle.plate_number}`,
		});
		setPostAction('delete');
		setActionModal(true);
	};

	const header = ['Trip ID', 'Time', 'Route', 'Driver', 'Vehicle', 'Action'];

	const datas = tripsToday.map(trip => {
		return {
			idno: {
				data: trip.idno,
				options: {
					width: 2,
				},
			},
			time: {
				data: moment(trip.trip_date).format('h:mm a'),
				options: {
					className: 'space-no-wrap',
					width: 1,
				},
			},
			route: {
				data: `${trip.route.site_origin.name} - ${trip.route.site_destination.name}`,
				options: {
					width: 3,
				},
			},
			driver: {
				data: `${trip.driver.user.profile.first_name} ${trip.driver.user.profile.last_name}`,
				options: {
					width: 3,
				},
			},
			vehicle: {
				data: `${trip.vehicle.make} ${trip.vehicle.model} - ${trip.vehicle.plate_number}`,
				options: {
					width: 3,
				},
			},
			action: {
				data: (
					<>
						<a
							className={`${
								trip.status >= 2 ? 'disable-anchor-tag' : ''
							} btnLink custom-action-edit`}
							onClick={() => {
								setPostError({});
								handleEditTrips(trip);
							}}
						>
							Edit
						</a>
						<a
							className={` ${
								trip.total_bookings >= 1
									? 'disable-anchor-tag'
									: 'btnLink custom-action-suspend'
							}`}
							onClick={() => {
								setPostError({});
								handleDeleteTrips(trip);
							}}
						>
							Delete
						</a>
					</>
				),
				options: {
					width: 1,
				},
			},
		};
	});

	return (
		<div>
			<div className="sub-title-shuttle-date">
				<p>{moment(Date()).format('MMMM D, YYYY | dddd')}</p>
			</div>
			{checkTripsToday < 1 && !confirmationModal ? (
				<div>
					<p className="custom-no-available-trips">
						You haven&apos;t created any trips yet.<br></br> Go to{' '}
						<b>Trip scheduler</b> tab start.
					</p>
				</div>
			) : (
				<div className="table-list">
					<div className="table-list-filter workdesks-filter">
						<Form>
							<Form.Group inline>
								<Form.Field>
									<Input
										icon={
											<img
												src={searchImg}
												className="filter-search-icon "
											/>
										}
										iconPosition="left"
										placeholder="Type search here"
										onChange={e =>
											handleChangeFilter(
												'type_search',
												e.target.value
											)
										}
										value={tripsTodayFilter.type_search}
									/>
								</Form.Field>
								<label>Showing</label>
								<Form.Field
									control={Select}
									selectOnBlur={false}
									options={type}
									style={{
										minWidth: '150px',
									}}
									placeholder="Select time"
									onChange={(e, { value }) =>
										handleChangeFilter('type', value)
									}
									icon="angle down"
									className="filter-select"
									value={tripsTodayFilter.type}
								/>
								<Form.Field width={2}>
									<Dropdown
										className="slot-search-filter"
										icon="angle down"
										selectOnBlur={false}
										forceSelection={false}
										iconPosition="right"
										defaultValue={'all'}
										placeholder="All routes"
										fluid
										search
										selection
										{...(tripsTodayFilter.routes_id
											? {
													searchQuery: `${routesSearchQuery}`,
											  }
											: {})}
										options={[
											{
												key: 'all_routes',
												text: 'All routes',
												value: '',
											},
											...(renderRoutes &&
											renderRoutes.length > 0
												? renderRoutes
												: []),
										]}
										onSearchChange={
											handleRoutesSearchChange
										}
										name="routes"
										onChange={(e, { value }) =>
											handleChangeFilter(
												'routes_id',
												value
											)
										}
									>
										{routesLoading ? (
											<div className="sk-chase search-loader">
												<div className="sk-chase-dot"></div>
												<div className="sk-chase-dot"></div>
												<div className="sk-chase-dot"></div>
												<div className="sk-chase-dot"></div>
												<div className="sk-chase-dot"></div>
												<div className="sk-chase-dot"></div>
											</div>
										) : null}
									</Dropdown>
								</Form.Field>
							</Form.Group>
						</Form>
					</div>
					<Table
						headers={header}
						datas={datas}
						page={paginate.page}
						totalRows={totalRecords}
						rowsPerPage={10}
						onPageChange={onPageChange}
					/>

					{/* remove modal */}

					<Modal
						onClose={() => setActionModal(false)}
						onOpen={() => setActionModal(true)}
						open={actionModal}
						className="employee-form-modal"
					>
						<Modal.Content>
							<img
								src={`${process.env.REACT_APP_BASE_URL}/images/question-logo.png`}
							/>
							<Header as="h3">
								{postAction === 'edit'
									? 'Save changes to the trip?'
									: 'Delete trip?'}
							</Header>
							<div
								className={`modal-sub-header custom-sentence-break ${
									postAction === 'edit'
										? 'mb-1rem'
										: ' delete-trip-modal-content'
								}`}
							>
								{postAction === 'edit' ? (
									<div>
										<p>
											Are you sure you want to change the
											driver/car<br></br>assigned for this
											trip? Please take note of the
											following:
										</p>
									</div>
								) : (
									<div>
										{' '}
										<p>
											Time:{' '}
											{moment(
												tripSchedulerData.trip_date
											).format('h:mm a')}
										</p>
										<p>
											Route:{' '}
											{`${tripSchedulerData.origin} - ${tripSchedulerData.destination}`}
										</p>
										<p>
											Driver: {tripSchedulerData.driver}
										</p>
										<p>
											Vehicle: {tripSchedulerData.vehicle}{' '}
										</p>
									</div>
								)}
							</div>
							<div
								className={`note-delete-trip custom-sentence-break  ${
									postAction === 'edit'
										? 'edit-trip-modal-content'
										: ''
								}`}
							>
								{postAction === 'edit' ? (
									<p>
										If there are booked passengers for the
										trip, changes will<br></br>
										show when the passengers checks the
										booking details. <br></br>
										<br></br>
										Change will only apply today. Should the
										change need<br></br>
										to be permanent, edit the Trip scheduler
									</p>
								) : (
									<p>
										Passenger will no longer see this trip
										in the booking
										<br></br>form. Are you sure you want to
										proceed?
									</p>
								)}
							</div>
							<div className="modal-action">
								<Button
									onClick={() => {
										onClickProceed();
									}}
									className="app-primary-button custom-full-button"
								>
									Yes, proceed
								</Button>
								<Button
									className="app-primary-inverted-button custom-full-button"
									onClick={() => {
										onClickCancelActionForm();
									}}
								>
									Cancel and go back
								</Button>
							</div>
						</Modal.Content>
					</Modal>
					<Modal
						onClose={() => setConfirmationModal(false)}
						open={confirmationModal}
						className="desks-modal bulk-completion-modal"
					>
						<Modal.Content>
							<div className="bulk-completion-modal-icon">
								<img src={successImg} alt="success" />
								<Header as="h3">
									{postAction === 'edit'
										? 'Changes saved!'
										: 'Trip deleted!'}
								</Header>
								<p>
									{postAction === 'edit'
										? 'Changes will reflect to the driver and booked passengers'
										: '	Trip was successfully removed from the booking form'}
								</p>
							</div>
						</Modal.Content>
					</Modal>

					{/* edit modal */}

					<Modal
						onClose={() => setEditModal(false)}
						open={editModal}
						className="desks-modal single-first-modal"
					>
						<Modal.Content>
							<FocusLock disabled={!editModal} autoFocus={false}>
								{postError.isErrorPostTrips && (
									<Form.Group>
										<Message
											negative
											className="error-message w-100"
										>
											<img
												src={errorAlert}
												alt="error alert"
											/>
											{postError.message}
										</Message>
									</Form.Group>
								)}
								<h3 className="mb-1rem">
									Edit: Trip ID {tripSchedulerData.idno}
								</h3>

								<p>
									Time:{' '}
									{moment(tripSchedulerData.trip_date).format(
										'h:mm a'
									)}
								</p>
								<p>
									Route:{' '}
									{`${tripSchedulerData.origin} - ${tripSchedulerData.destination}`}
								</p>
								<br></br>
								<Form
									onSubmit={() => {
										setActionModal(true);
										setEditModal(false);
									}}
								>
									<Form.Field>
										{/* <Form.Field
											label="Driver"
											control={Select}
											selectOnBlur={false}
											forceSelection={false}
											options={availableDrivers}
											className="filter-select"
											icon={{
												name: 'angle down',
												className:
													'icon-tripScheduler-form',
											}}
											placeholder="Select driver"
											name="driver"
											onChange={(e, { value }) => {
												handleChange('driver', value);
											}}
											value={tripSchedulerData.driver}
										/> */}
										Driver
										<Dropdown
											className="slot-search-filter"
											noResultsMessage={
												driversLoading &&
												availableDrivers.length <= 0
													? 'Searching...'
													: 'No results found.'
											}
											icon={
												driversLoading ? (
													<div className="sk-chase search-loader">
														<div className="sk-chase-dot"></div>
														<div className="sk-chase-dot"></div>
														<div className="sk-chase-dot"></div>
														<div className="sk-chase-dot"></div>
														<div className="sk-chase-dot"></div>
														<div className="sk-chase-dot"></div>
													</div>
												) : (
													'angle down'
												)
											}
											selectOnBlur={false}
											iconPosition="right"
											placeholder="Select driver"
											{...(tripSchedulerData.driver ===
											null
												? {
														text: 'Select driver',
												  }
												: {})}
											// clearable
											fluid
											search
											selection
											options={[...availableDrivers]}
											value={tripSchedulerData.driver}
											onSearchChange={onFilterDriverName}
											onChange={(e, { value }) => {
												handleChange('driver', value);
											}}
										></Dropdown>
									</Form.Field>{' '}
									<Form.Field>
										{/* <Form.Field
											label="Vehicle"
											control={Select}
											selectOnBlur={false}
											forceSelection={false}
											options={availableVehicles}
											className="filter-select"
											icon={{
												name: 'angle down',
												className:
													'icon-tripScheduler-form',
											}}
											placeholder="Select vehicle"
											name="vehicle"
											onChange={(e, { value }) => {
												handleChange('vehicle', value);
											}}
											value={tripSchedulerData.vehicle}
										/> */}
										Vehicle
										<Dropdown
											className="slot-search-filter"
											noResultsMessage={
												vehicleLoading &&
												availableVehicles.length <= 0
													? 'Searching...'
													: 'No results found.'
											}
											icon={
												vehicleLoading ? (
													<div className="sk-chase search-loader">
														<div className="sk-chase-dot"></div>
														<div className="sk-chase-dot"></div>
														<div className="sk-chase-dot"></div>
														<div className="sk-chase-dot"></div>
														<div className="sk-chase-dot"></div>
														<div className="sk-chase-dot"></div>
													</div>
												) : (
													'angle down'
												)
											}
											selectOnBlur={false}
											iconPosition="right"
											placeholder="Select vehicle"
											{...(tripSchedulerData.vehicle ===
											null
												? {
														text: 'Select vehicle',
												  }
												: {})}
											// clearable
											fluid
											search
											selection
											options={[...availableVehicles]}
											value={tripSchedulerData.vehicle}
											onSearchChange={
												onFilterVehiclePlateNumber
											}
											onChange={(e, { value }) => {
												handleChange('vehicle', value);
											}}
										></Dropdown>
									</Form.Field>
									<div className="modal-action">
										<Button className="app-primary-button full-button">
											Save
										</Button>
										<Button
											className="app-primary-inverted-button full-button"
											onClick={() => {
												handleClear();
												setEditModal(false);
											}}
										>
											Cancel
										</Button>
									</div>
								</Form>
							</FocusLock>
						</Modal.Content>
					</Modal>
				</div>
			)}
		</div>
	);
};

TripsToday.propTypes = {
	service: PropTypes.any,
	fetchTripSlots: PropTypes.func,
};

export default TripsToday;

// react lib
import React, { useState, useEffect, useCallback } from 'react';

// master layout
import LayoutPage from '../../../hoc/Layout/Admin/AdminMaster';

// style
import './EmployeeList.css';

// semantic ui components
import {
	Header,
	Input,
	Form,
	Grid,
	Button,
	Modal,
	Select,
} from 'semantic-ui-react';

import PropTypes from 'prop-types';
import { useMutation, useApolloClient } from '@apollo/client';
import {
	POST_COMPANY_EMPLOYEE,
	PATCH_COMPANY_EMPLOYEE,
} from '../graphql/mutation';
import { splittedName } from '../../../utils';
import { VERIFY_EMPLOYEE } from '../graphql/query';
import useDebounce from '../../../hooks/useDebounce';
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';
import { useForm } from 'react-hook-form';
import moment from 'moment';

const NewEmployee = props => {
	let sameEmail = false;
	let sameEmailMessage;
	let patchEmployeeData = props.location.state.patchEmployeeData;

	let now = moment().format('YYYY-MM-DD');
	const { register, errors, handleSubmit } = useForm({
		mode: 'onBlur',
		reValidateMode: 'onChange',
		defaultValues: {},
		resolver: undefined,
		context: undefined,
		criteriaMode: 'firstError',
		shouldFocusError: true,
		shouldUnregister: true,
	});

	const client = useApolloClient();
	const [checkEmployee, setCheckEmployee] = useState([]);
	const [employee, setEmployee] = useState({
		id: patchEmployeeData ? patchEmployeeData.id : '',
		idno: patchEmployeeData ? patchEmployeeData.idno : '',
		first_name: patchEmployeeData ? patchEmployeeData.first_name : '',
		last_name: patchEmployeeData ? patchEmployeeData.last_name : '',
		suffix: patchEmployeeData ? patchEmployeeData.suffix : '',
		email: patchEmployeeData ? patchEmployeeData.email : '',
		mobile: patchEmployeeData ? patchEmployeeData.mobile_no : '',
		date_hired: patchEmployeeData ? patchEmployeeData.date_hired : '',
		position: patchEmployeeData ? patchEmployeeData.position : '',
		is: patchEmployeeData ? patchEmployeeData.is : '',
		isEmail: patchEmployeeData ? patchEmployeeData.isEmail : '',
		group: patchEmployeeData ? patchEmployeeData.group : '',
		site: patchEmployeeData ? patchEmployeeData.site : '',
	});
	const [open, setOpen] = useState(false);
	const renderSite = props.location.state.companySites.getSites.map(site => {
		return {
			text: site.name,
			value: site.id,
		};
	});

	const [disableProceedBtn, setDisableProceedBtn] = useState(false);

	const useDebounceEmail = useDebounce(employee.email, 500);
	const useDebounceIdno = useDebounce(employee.idno, 500);
	useEffect(() => {
		verifyEmail();
	}, [useDebounceEmail, useDebounceIdno]);

	const verifyEmail = async () => {
		const result = await client.query({
			query: VERIFY_EMPLOYEE,
			fetchPolicy: 'network-only',
			variables: {
				input: {
					email: employee.email,
					idno: employee.idno,
				},
			},
		});
		setCheckEmployee(result.data.verifyEmployee);
	};

	const [patchCompanyEmployee] = useMutation(PATCH_COMPANY_EMPLOYEE, {
		onCompleted: data => {
			setDisableProceedBtn(false);
		},
	});

	const onPatchHandler = async () => {
		await setDisableProceedBtn(true);
		await patchCompanyEmployee({
			variables: {
				input: {
					id: employee.id,
					companies_id: props.location.state.companies_id,
					site: {
						id: employee.site,
					},
					group: {
						name: employee.group,
					},
					supervisor: {
						is_email: employee.isEmail,
						profile: {
							first_name: splittedName(employee.is)[0],
							last_name: splittedName(employee.is)[1],
						},
					},
					user: {
						email: employee.email,
						profile: {
							mobile_no: employee.mobile,
							position: employee.position,
							date_hired: employee.date_hired,
						},
					},
				},
			},
		});

		await props.history.push({
			pathname: '/employeelist',
			state: {},
		});
	};
	const [postCompanyEmployee] = useMutation(POST_COMPANY_EMPLOYEE, {
		onCompleted: data => {
			setDisableProceedBtn(false);
		},
	});
	const onPostHandler = async () => {
		await setDisableProceedBtn(true);
		await postCompanyEmployee({
			variables: {
				input: {
					companies_id: props.location.state.companies_id,
					site: {
						id: employee.site,
					},
					group: {
						name: employee.group,
					},
					supervisor: {
						is_email: employee.isEmail,
						profile: {
							first_name: splittedName(employee.is)[0],
							last_name: splittedName(employee.is)[1],
						},
					},
					user: {
						email: employee.email,
						profile: {
							idno: employee.idno,
							first_name: employee.first_name,
							last_name: employee.last_name,
							suffix: employee.suffix ? employee.suffix : 'n/a',
							mobile_no: employee.mobile,
							position: employee.position,
							date_hired: employee.date_hired,
						},
					},
				},
			},
		});

		await props.history.push({
			pathname: '/employeelist',
			state: {
				from_post: true,
			},
		});
	};

	const onClickProceed = () => {
		if (props.location.state.action === 'edit') {
			onPatchHandler();
		} else {
			onPostHandler();
		}
	};

	const handleDisable = useCallback(() => {
		if (props.location.state.action === 'edit') {
			if (
				employee.mobile === '' ||
				!employee.mobile ||
				employee.date_hired === '' ||
				!employee.date_hired ||
				employee.position === '' ||
				!employee.position ||
				employee.is === '' ||
				!employee.is ||
				employee.isEmail === '' ||
				!employee.isEmail ||
				employee.group === '' ||
				!employee.group ||
				employee.site === '' ||
				!employee.site ||
				errors.mobile
			) {
				return true;
			}
		} else {
			if (
				employee.idno === '' ||
				employee.first_name === '' ||
				employee.first_name.trim() === '' ||
				employee.last_name === '' ||
				employee.last_name.trim() === '' ||
				employee.email === '' ||
				employee.mobile === '' ||
				employee.date_hired === '' ||
				employee.position === '' ||
				employee.is === '' ||
				employee.is.trim() === '' ||
				employee.isEmail === '' ||
				employee.group === '' ||
				employee.site === '' ||
				errors.email ||
				errors.idno ||
				errors.mobile ||
				checkEmployee.idnoMessage ||
				checkEmployee.emailMessage ||
				sameEmail
			) {
				return true;
			}
		}

		return false;
	});

	const breadcrumbNavigation = breadcrumb => {
		props.history.push(`/${breadcrumb.link}`, {
			breadcrumbs: props.history.location.state.breadcrumbs,
		});
	};

	const handleChange = (key, value) => {
		setEmployee({
			...employee,
			[key]: value,
		});
	};

	const onCancel = e => {
		props.history.goBack();
	};

	if (employee.email === employee.isEmail) {
		sameEmail = true;
		sameEmailMessage = 'Email and IS email should not be the same';
	}
	if (employee.email === '' && employee.isEmail === '') {
		sameEmail = false;
		sameEmailMessage = '';
	}

	return (
		<LayoutPage>
			<Breadcrumbs
				breadcrumbs={[...props.history.location.state.breadcrumbs]}
				breadcrumbNavigation={breadcrumbNavigation}
			/>
			<Header as="h1">
				{props.location.state.action === 'edit'
					? 'Edit employee form'
					: 'New employee form'}
			</Header>
			<Grid>
				<Grid.Column width={11}>
					<Form onSubmit={handleSubmit(() => setOpen(true))}>
						<div className="content-container employee-form">
							<Header as="h4">Employee details</Header>
							<div className="employee-form-group">
								<Form.Group>
									<Form.Field width={4}>
										<label>ID number</label>
										<input
											className={
												props.location.state.action ===
												'edit'
													? 'custom-input-employee'
													: errors.idno ||
													  checkEmployee.idnoMessage
													? 'input-error input-error-color'
													: 'custom-input-employee'
											}
											name="idno"
											disabled={
												props.location.state.action ===
												'edit'
													? true
													: false
											}
											placeholder="Enter ID number"
											onChange={e =>
												handleChange(
													'idno',
													e.target.value
												)
											}
											ref={register({
												pattern: {
													value: /^[-A-Za-z0-9 ]+$/,
													message:
														'Not allowed special character.',
												},
											})}
											value={employee.idno}
										/>
										{props.location.state.action ===
										'edit' ? (
											''
										) : errors.idno ||
										  checkEmployee.idnoMessage ? (
											<small className="input-error-message">
												{errors.idno &&
												errors.idno.message
													? errors.idno.message
													: checkEmployee.idnoMessage}
											</small>
										) : null}
									</Form.Field>
								</Form.Group>
								<Form.Group>
									<Form.Field width={6}>
										<label>First name</label>
										<input
											placeholder="Enter first name here"
											className="custom-input-employee"
											onChange={e =>
												handleChange(
													'first_name',
													e.target.value
												)
											}
											value={employee.first_name}
											disabled={
												props.location.state.action ===
												'edit'
													? true
													: false
											}
										/>
									</Form.Field>
									<Form.Field width={4}>
										<label>Last name</label>
										<input
											placeholder="Enter last name here"
											className="custom-input-employee"
											onChange={e =>
												handleChange(
													'last_name',
													e.target.value
												)
											}
											value={employee.last_name}
											disabled={
												props.location.state.action ===
												'edit'
													? true
													: false
											}
										/>
									</Form.Field>
									<Form.Field width={4}>
										<label>Suffix</label>
										<input
											placeholder="Enter suffix here"
											className="custom-input-employee"
											onChange={e =>
												handleChange(
													'suffix',
													e.target.value
												)
											}
											value={employee.suffix}
											disabled={
												props.location.state.action ===
												'edit'
													? true
													: false
											}
										/>
									</Form.Field>
								</Form.Group>
								<Form.Group>
									<Form.Field width={6}>
										<label>E-mail address</label>
										<input
											className={
												props.location.state.action ===
												'edit'
													? 'custom-input-employee'
													: errors.email ||
													  checkEmployee.emailMessage ||
													  sameEmail
													? 'input-error input-error-color'
													: 'custom-input-employee'
											}
											name="email"
											placeholder="Enter e-mail address here"
											onChange={e => {
												handleChange(
													'email',
													e.target.value
												);
											}}
											ref={register({
												pattern: {
													value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
													message:
														'Invalid email address',
												},
											})}
											value={employee.email}
											disabled={
												props.location.state.action ===
												'edit'
													? true
													: false
											}
										/>
										{props.location.state.action ===
										'edit' ? (
											''
										) : errors.email ||
										  checkEmployee.emailMessage ? (
											<small className="input-error-message">
												{errors.email &&
												errors.email.message
													? errors.email.message
													: checkEmployee.emailMessage}
											</small>
										) : null}
										{sameEmail && (
											<small className="input-error-message">
												{sameEmailMessage}
											</small>
										)}
									</Form.Field>
									<Form.Field width={6}>
										<label>Mobile number</label>
										<input
											className={
												errors.mobile
													? 'input-error input-error-color'
													: 'custom-input-employee'
											}
											name="mobile"
											placeholder="Enter mobile number here"
											onChange={e =>
												handleChange(
													'mobile',
													e.target.value
												)
											}
											ref={register({
												pattern: {
													value: /^(\d+-?)+\d+$/,
													message:
														'Number only allowed.',
												},
											})}
											value={employee.mobile}
										/>
										{errors.mobile && (
											<small className="input-error-message">
												{errors.mobile.message}
											</small>
										)}
									</Form.Field>
								</Form.Group>
							</div>
							<div className="employee-form-group">
								<Form.Group>
									<Form.Field
										control={Input}
										label="Date hired"
										type="date"
										format="YYYY-MM-DD"
										placeholder="Enter date hired here"
										width={6}
										max={now}
										onChange={e =>
											handleChange(
												'date_hired',
												e.target.value
											)
										}
										value={employee.date_hired}
									/>
									<Form.Field
										control={Input}
										label="Work position"
										placeholder="Enter work position here"
										width={6}
										onChange={e =>
											handleChange(
												'position',
												e.target.value
											)
										}
										value={employee.position}
									/>
								</Form.Group>
								<Form.Group>
									<Form.Field
										control={Input}
										label="Immediate supervisor (IS)"
										placeholder="Enter name of I.S. here"
										width={6}
										onChange={e =>
											handleChange('is', e.target.value)
										}
										value={
											employee && employee.is
												? employee.is
												: ''
										}
									/>
									<Form.Field width={6}>
										<label>IS e-mail address</label>
										<input
											className={
												errors.isEmail || sameEmail
													? 'input-error input-error-color'
													: 'custom-input-employee'
											}
											name="isEmail"
											placeholder="Enter e-mail address here"
											width={6}
											onChange={e =>
												handleChange(
													'isEmail',
													e.target.value
												)
											}
											ref={register({
												pattern: {
													value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
													message:
														'Invalid email address*',
												},
											})}
											value={employee.isEmail}
										/>
										{errors.isEmail && (
											<small className="input-error-message">
												{errors.isEmail.message}
											</small>
										)}
										{sameEmail && (
											<small className="input-error-message">
												{sameEmailMessage}
											</small>
										)}
									</Form.Field>
								</Form.Group>
								<Form.Group>
									<Form.Field
										control={Input}
										label="Group"
										placeholder="Enter group here"
										width={6}
										onChange={e =>
											handleChange(
												'group',
												e.target.value
											)
										}
										value={employee.group}
									/>
								</Form.Group>
								<Form.Group>
									<Form.Field
										control={Select}
										options={renderSite}
										label="Office site assignment"
										placeholder="Enter site assignment"
										width={12}
										onChange={(e, { value }) => {
											handleChange('site', value);
										}}
										value={employee.site}
									/>
								</Form.Group>
							</div>
						</div>
						<div className="employee-form-actions">
							<Button
								onClick={e => {
									onCancel(e);
								}}
								className="app-primary-inverted-button"
								size="large"
								type="button"
							>
								Cancel
							</Button>
							<Button
								className="app-primary-button medium-button"
								disabled={handleDisable()}
								type="submit"
							>
								Save
							</Button>
						</div>
						<Modal
							onClose={() => setOpen(false)}
							onOpen={() => setOpen(true)}
							open={open}
							className="employee-form-modal"
						>
							<Modal.Content>
								<img
									src={`${process.env.REACT_APP_BASE_URL}/images/question-logo.png`}
								/>
								<Header as="h3">
									{(() => {
										switch (props.location.state.action) {
											case 'edit':
												return `Save changes made to employee details`;
											default:
												return `Are you sure you want to add new employee?`;
										}
									})()}
								</Header>
								<p className="modal-sub-header">
									{(() => {
										switch (props.location.state.action) {
											case 'edit':
												return `Employee details will be updated. Are you sure you want to proceed?`;
											default:
												return `New employee will be created. Once saved, employee will be able to use G Access app`;
										}
									})()}
								</p>
								<div className="modal-action">
									<Button
										onClick={() => {
											onClickProceed();
										}}
										className="app-primary-button custom-full-button"
										disabled={disableProceedBtn}
									>
										Yes, proceed
									</Button>
									<Button
										className="app-primary-inverted-button custom-full-button"
										onClick={() => setOpen(false)}
									>
										Cancel and go back
									</Button>
								</div>
							</Modal.Content>
						</Modal>
					</Form>
				</Grid.Column>
			</Grid>
		</LayoutPage>
	);
};

NewEmployee.propTypes = {
	history: PropTypes.any,
	location: PropTypes.any,
};

export default NewEmployee;

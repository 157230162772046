// react lib
import React, { useContext } from 'react';

// less (styling)
import './BasicInformation.css';

// semantic ui component
import { Grid, Header, Form, Image } from 'semantic-ui-react';

import PropTypes from 'prop-types';

// context
import ImageContext from '../../../../context/ImageContext/ImageContext';

// components
import ImageUpload from '../../../../components/FileUpload/ImageUpload';
import CharCount from '../../../../components/CharCount/CharCount';

const BasicInformation = ({
	onChangeValueHandler,
	form,
	groups,
	onCheckHandler,
	serviceType,
}) => {
	const { imageSrc } = useContext(ImageContext);

	return (
		<div className="basic-information">
			<Grid centered>
				<Grid.Column
					mobile="sixteen"
					tablet="fourteen"
					computer="sixteen"
				>
					<div className="step-container">
						<Header as="h3">Basic information</Header>
						<Grid>
							<Grid.Column mobile={16} table={8} computer={8}>
								<strong>Service name</strong>
								<div style={{ marginTop: '5px' }}>
									{form.service.name}
								</div>
							</Grid.Column>
							<Grid.Column width={16}>
								<strong>Short description</strong>
								<Form.TextArea
									// label="Short description"
									name="serviceDesc"
									placeholder="Enter short description here"
									className="character-count-input"
									rows={2}
									style={{
										resize: 'none',
										fontFamily: 'FS Elliot Pro',
									}}
									maxLength="160"
									value={form.service.description}
									onChange={evt =>
										onChangeValueHandler(
											'service',
											'description',
											evt.target.value
										)
									}
								/>
								<CharCount
									// current={serviceDescCount}
									current={
										form.service.description
											? form.service.description.length
											: '0'
									}
									total={160}
								/>
							</Grid.Column>
							{serviceType.name === 'Parking' && (
								<Grid.Column width={16}>
									<Form.Group grouped>
										<label>Service fee</label>
										<Form.Group className="input-check-container">
											<label className="checkcontainer">
												<input
													type="radio"
													name="paymentRadio"
													value={0}
													checked={
														!form.serviceable
															.with_pay
													}
													onChange={evt => {
														onChangeValueHandler(
															'serviceable',
															'with_pay',
															parseInt(
																evt.target.value
															)
														);
													}}
												/>
												<span>&nbsp;Free</span>
												<span className="radiobtn"></span>
											</label>
										</Form.Group>
										<Form.Group className="input-check-container">
											<label className="checkcontainer">
												<input
													type="radio"
													name="paymentRadio"
													value={1}
													checked={
														form.serviceable
															.with_pay
													}
													onChange={evt => {
														onChangeValueHandler(
															'serviceable',
															'with_pay',
															parseInt(
																evt.target.value
															)
														);
													}}
												/>
												<span>&nbsp;With charge</span>
												<span className="radiobtn"></span>
												{form.serviceable.with_pay ===
													1 && (
													<div className="payment-div">
														<label className="php-label">
															Php
														</label>
														<Form.Input
															className="input-payment"
															placeholder="0.00"
															type="number"
															value={
																form.serviceable
																	.amt
															}
															onChange={evt => {
																onChangeValueHandler(
																	'serviceable',
																	'amt',
																	evt.target
																		.value ===
																		null ||
																		evt
																			.target
																			.value ===
																			undefined
																		? null
																		: evt.target.value
																				.toString()
																				.split(
																					'.'
																				)
																				.map(
																					(
																						el,
																						i
																					) =>
																						i
																							? el
																									.split(
																										''
																									)
																									.slice(
																										0,
																										2
																									)
																									.join(
																										''
																									)
																							: el
																				)
																				.join(
																					'.'
																				)
																);
															}}
														/>
													</div>
												)}
											</label>
										</Form.Group>
									</Form.Group>
								</Grid.Column>
							)}
							<Grid.Column width={16}>
								<Form.Group grouped>
									<label>
										Select who can avail this service
									</label>
									<Form.Group className="input-check-container">
										<label className="checkcontainer">
											<input
												type="radio"
												name="employeesRadio"
												value={1}
												checked={
													form.service
														.is_all_employees
												}
												onChange={evt => {
													onChangeValueHandler(
														'service',
														'is_all_employees',
														parseInt(
															evt.target.value
														)
													);
												}}
											/>
											<span>&nbsp;All employees</span>
											<span className="radiobtn"></span>
										</label>
									</Form.Group>
									{/* <Form.Field
										label="Specific group/s"
										control="input"
										className="service-radio"
										type="radio"
										value={0}
										checked={!form.service.is_all_employees}
										name="employeesRadio"
										onChange={evt =>
											onChangeValueHandler(
												'service',
												'is_all_employees',
												parseInt(evt.target.value)
											)
										}
									/> */}
									<Form.Group className="input-check-container">
										<label className="checkcontainer">
											<input
												type="radio"
												name="employeesRadio"
												value={0}
												checked={
													!form.service
														.is_all_employees
												}
												onChange={evt =>
													onChangeValueHandler(
														'service',
														'is_all_employees',
														parseInt(
															evt.target.value
														)
													)
												}
											/>
											<span>&nbsp;Specific group/s</span>
											<span className="radiobtn"></span>
										</label>
									</Form.Group>
								</Form.Group>
							</Grid.Column>
							{!form.service.is_all_employees && (
								<Grid.Column width={16}>
									<Form.Group
										grouped
										className="group-options"
									>
										<label
											style={{
												display: 'block',
												paddingTop: '2rem',
												marginTop: '10px',
												fontFamily: 'FS Elliot Pro',
												fontWeight: '500',
												fontSize: '15px',
												marginLeft: '10px',
											}}
										>
											Select group/s:
										</label>
										<Grid
											style={{
												paddingLeft: '1.7rem',
												paddingTop: '1.5rem',
											}}
											stackable
											columns={2}
										>
											{groups.length > 0 ? (
												groups.map(group => (
													<Grid.Column
														style={{ padding: '0' }}
														key={group.id}
													>
														{/* <Form.Field
															className="service-radio"
															type="checkbox"
															label={group.name}
															value={group.id}
															control="input"
															checked={form.service.groups.some(
																formGroup =>
																	formGroup.id ===
																	group.id
															)}
															onChange={evt =>
																onCheckHandler(
																	evt,
																	'groups',
																	group
																)
															}
														/> */}
														<Form.Group className="input-check-container">
															<label className="checkcontainer">
																<input
																	type="checkbox"
																	name="employeesRadio"
																	value={0}
																	checked={form.service.groups.some(
																		formGroup =>
																			formGroup.id ===
																			group.id
																	)}
																	onChange={evt =>
																		onCheckHandler(
																			evt,
																			'groups',
																			group
																		)
																	}
																/>
																<span>
																	&nbsp;
																	{group.name}
																</span>
																<span className="checkmark"></span>
															</label>
														</Form.Group>
													</Grid.Column>
												))
											) : (
												<div>No groups available!</div>
											)}
										</Grid>
									</Form.Group>
								</Grid.Column>
							)}
							<Grid.Column width={16}>
								<Form.Group grouped>
									<label>Service icon</label>
									<Grid>
										<Grid.Column
											mobile={16}
											tablet={5}
											computer={5}
										>
											{/* <Form.Field>
												<Radio
													label="Use default icon"
													name="iconRadio"
													value="default"
													primary
													checked={
														basicInfo.is_default_icon ===
														1
													}
													onChange={e => {
														// setIconRadio('default');
														handleChangeBasicInfo(
															'is_default_icon',
															1
														);
													}}
												/>
											</Form.Field> */}
											{/* <Form.Field
												label="Use default icon"
												name="defaultIconRadio"
												value={1}
												control="input"
												type="radio"
												className="service-radio"
												checked={
													form.service.is_default_icon
												}
												onChange={evt =>
													onChangeValueHandler(
														'service',
														'is_default_icon',
														parseInt(
															evt.target.value
														)
													)
												}
												primary
											/> */}
											<Form.Group className="input-check-container">
												<label className="checkcontainer">
													<input
														type="radio"
														name="iconRadio"
														value={1}
														checked={
															form.service
																.is_default_icon
														}
														onChange={evt =>
															onChangeValueHandler(
																'service',
																'is_default_icon',
																parseInt(
																	evt.target
																		.value
																)
															)
														}
													/>
													<span>
														&nbsp;Use default icon
													</span>
													<span className="radiobtn"></span>
												</label>
											</Form.Group>
											{
												<label>
													<div className="icon-holder default-icon-option">
														<div className="icon-image">
															<img
																src={`${serviceType.icon}`}
															/>
														</div>
													</div>
												</label>
											}
										</Grid.Column>
										<Grid.Column
											mobile={16}
											tablet={11}
											computer={11}
										>
											{/* <Form.Field
												label="&nbsp;Upload custom icon"
												className="service-radio"
												type="radio"
												control="input"
												name="defaultIconRadio"
												value={0}
												primary
												checked={
													!form.service
														.is_default_icon
												}
												onChange={evt =>
													onChangeValueHandler(
														'service',
														'is_default_icon',
														parseInt(
															evt.target.value
														)
													)
												}
											/> */}
											<Form.Group className="input-check-container">
												<label className="checkcontainer">
													<input
														type="radio"
														name="iconRadio"
														value={0}
														checked={
															!form.service
																.is_default_icon
														}
														onChange={evt =>
															onChangeValueHandler(
																'service',
																'is_default_icon',
																parseInt(
																	evt.target
																		.value
																)
															)
														}
													/>
													<span>
														&nbsp;Upload custom icon
													</span>
													<span className="radiobtn"></span>
												</label>
											</Form.Group>
											{!form.service.is_default_icon ? (
												<label>
													<div className="label-title"></div>
													<div className="default-icon-option">
														<div className="label-subtitle">
															- File must be less
															than 3MB of .svg or
															.png file
														</div>
														<div className="label-subtitle">
															- 250 pixels x 250
															pixels, with
															transparent
															background
														</div>
														<div className="custom-icon-upload">
															<Grid className="image-uploader-container">
																<Grid.Column
																	mobile="sixteen"
																	tablet="fourteen"
																	computer="twelve"
																>
																	<ImageUpload />
																</Grid.Column>
																<Grid.Column
																	mobile="sixteen"
																	tablet="fourteen"
																	computer="four"
																>
																	<div className="preview-icon">
																		<small>
																			Preview:
																		</small>
																		<div className="icon-holder">
																			{imageSrc ? (
																				<Image
																					src={
																						imageSrc
																					}
																					fluid
																				/>
																			) : (
																				<div className="icon-image"></div>
																			)}
																		</div>
																	</div>
																</Grid.Column>
															</Grid>
														</div>
													</div>
												</label>
											) : null}
										</Grid.Column>
									</Grid>
								</Form.Group>
							</Grid.Column>
						</Grid>
					</div>
				</Grid.Column>
			</Grid>
		</div>
	);
};

BasicInformation.propTypes = {
	onChangeValueHandler: PropTypes.func,
	form: PropTypes.object,
	groups: PropTypes.array,
	onCheckHandler: PropTypes.func,
	activeStep: PropTypes.number,
	serviceType: PropTypes.object,
};

export default BasicInformation;

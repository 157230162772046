import PropTypes from 'prop-types';
import React, { createContext, useState } from 'react';
import { useLazyQuery, useApolloClient } from '@apollo/client';
import { LOGIN_USER, REFETCH_TOKEN } from './graphql/query';
import jwt_decode from 'jwt-decode';

// import { updateObject } from '../../utils/helper';
// import { LOGIN, LOGOUT } from '';

const AuthContext = createContext({});
const getRole = user => {
	const { roles } = user.user;

	// determine w/c admin role the user has
	const admin_role = roles.filter(role => {
		return role.code == 'pa' || role.code == 'ca' || role.code == 'sa';
	});

	return admin_role;
};

const concatName = user => {
	return user.user.profile.first_name + ' ' + user.user.profile.last_name;
};

export const AuthProvider = ({ children }) => {
	const client = useApolloClient();
	const [user, setUser] = useState(
		localStorage.getItem(process.env.REACT_APP_TOKEN_NAME)
			? jwt_decode(localStorage.getItem(process.env.REACT_APP_TOKEN_NAME))
			: null
	);
	const [authError, setAuthError] = useState({});
	const [adminRole, setAdminRole] = useState(
		user instanceof Object ? getRole(user) : null
	);
	const [name, setName] = useState(
		user instanceof Object ? concatName(user) : ''
	);

	const [
		loginUser,
		{
			// data: authUserResponse,
			// error: loginError,
			loading: isAuthenticating,
		},
	] = useLazyQuery(LOGIN_USER, {
		onCompleted: data => {
			if (data.signinUser.status == 1) {
				localStorage.setItem(
					process.env.REACT_APP_TOKEN_NAME,
					data.signinUser.accessToken
				);

				localStorage.setItem(
					'refreshToken',
					data.signinUser.refreshToken
				);

				localStorage.setItem('picture', data.signinUser.picture);

				const decodedToken = jwt_decode(data.signinUser.accessToken);
				setUser(decodedToken);
				setAdminRole(getRole(decodedToken));
				setName(concatName(decodedToken));
			} else {
				setAuthError({ ...data.signinUser });
			}
		},
		fetchPolicy: 'network-only',
	});

	const loginHandler = async formFields => {
		// setting google token to localstorage
		if (formFields.googleToken) {
			localStorage.setItem('googleToken', formFields.googleToken);
		}

		// post request to login user
		loginUser({
			variables: {
				input: formFields,
				isMobile: false,
			},
		});
	};

	// refetching token function
	// * To update user's token saved on localstorage
	const refetchTokenHandler = async () => {
		const userObject = await client.query({
			query: REFETCH_TOKEN,
			variables: {
				users_id: user.user ? user.user.id : null,
				googleToken: localStorage.getItem('googleToken'),
			},
		});
		localStorage.setItem(
			process.env.REACT_APP_TOKEN_NAME,
			userObject.data.refetchToken.accessToken
		);
	};

	return (
		<AuthContext.Provider
			value={{
				loginHandler,
				isAuthenticating,
				user,
				setUser,
				authError,
				adminRole,
				setAdminRole,
				name,
				setName,
				refetchTokenHandler,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};

AuthProvider.propTypes = {
	children: PropTypes.any,
};

export default AuthContext;

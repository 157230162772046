import React, { useState, useCallback, useContext, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';

// hoc
import LayoutPage from '../../ServiceDashboard/ServiceDashboard';

// styling
import './ServiceSetup.css';

// Semantic Ui Components
import {
	Form,
	Modal,
	Header,
	Popup,
	Button,
	Grid,
	List,
} from 'semantic-ui-react';

// grahpql queries
import { useApolloClient } from '@apollo/client';
import {
	GET_COMPANY_GROUPS,
	GET_SERVICE_INSTANCE,
	CHECK_WORKDESKS,
	GET_SITE_WORKDESKS,
	CHECK_DUPLICATE_DRIVERS,
	CHECK_DUPLICATE_VEHICLES,
} from '../graphql/query'; // from parent
import {
	POST_SERVICE_SETUP,
	POST_WORKDESKS,
	POST_VEHICLES,
	POST_DRIVERS,
} from '../graphql/mutation';

// state models
import { ServiceInstance, Policy, Workdesk } from '../../../models';

// components
import BasicInformation from './BasicInformation/BasicInformation';
import Policies from './Policies/Policies';
import Booking from './Booking/Booking';
import Workflow from './Workflow/Workflow';

// context
// import AuthContext from '../../../context/AuthContext/AuthContext';
import ImageContext from '../../../context/ImageContext/ImageContext';
import LoaderContext from '../../../context/LoaderContext/LoaderContext';

// Material UI Components
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
// import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ParserContext from '../../../context/ParserContext/ParserContext';
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';
import SetupPreview from '../../../components/SetupPreview/SetupPreview';
import clsx from 'clsx';

import { Days } from '../../../models';

import {
	updateObject,
	arraySort,
	// stringSanitize,
	chunkArray,
	decrypt,
	splittedName,
	isEmail,
	stringSanitizeNoLowerCase,
	isPersonName,
	isAlphaNumSpace,
	isMobileNum,
} from '../../../utils';

import jwt_decode from 'jwt-decode';
import { ServiceTypes } from '../../../models/ServiceTypes';

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%',
	},
	backButton: {
		marginRight: theme.spacing(1),
	},
	instructions: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
}));

function getSteps() {
	return [
		'Basic information',
		'Policies',
		'Booking form & controls',
		'Workflow',
	];
}

const ServiceSetup = props => {
	const client = useApolloClient();
	const {
		imageFile,
		setImageSrc,
		setImageFile,
		fileError: imageFileError,
	} = useContext(ImageContext);
	const {
		fileData,
		setFileError,
		fileError,
		setFileData,
		setExcelFile,
		error: excelError,
	} = useContext(ParserContext);
	const { showLoading, hideLoading, showGloader, hideGloader } = useContext(
		LoaderContext
	);
	const user = jwt_decode(
		localStorage.getItem(process.env.REACT_APP_TOKEN_NAME)
	);
	const service_type = JSON.parse(
		decrypt(localStorage.getItem('service_type'))
	);
	const classes = useStyles();
	const [activeStep, setActiveStep] = useState(0);
	const [groups, setGroups] = useState([]);
	const [redirectTo, setRedirectTo] = useState(null);
	const [slots, setSlots] = useState([]);
	const [vehicles, setVehicles] = useState([]);
	const [drivers, setDrivers] = useState([]);
	const steps = getSteps();
	const [serviceType, setServiceType] = useState({});
	const [completeSetup, setCompleteSetup] = useState(false);
	const [form, setForm] = useState({
		service: {
			...ServiceInstance,
		},
		policy: {
			...Policy,
		},
		serviceable: {
			...Workdesk,
		},
	});
	// const [parsedWorkdesks, setParsedWorkdesks] = useState([]);
	// const [parsedDrivers, setParsedDrivers] = useState([]);
	// const [parsedVehicles, setParsedVehicles] = useState([]);
	const [parsedDatas, setParsedDatas] = useState({});
	const [fileToUpload, setFileToUpload] = useState('');
	// onComponentDidMount
	useEffect(() => {
		getServiceInstance();
	}, [getServiceInstance]);

	useEffect(() => {
		if (
			!imageFile &&
			!imageFileError &&
			form.service &&
			form.service.icon_url
		) {
			setImageSrc(form.service.icon_url);
		}
	}, [imageFile, imageFileError]);

	const getServiceInstance = async () => {
		// showLoading('Rendering data...');
		showGloader();
		let setupIndex = 0;
		// let service_type;
		// if (service_type.name === 'Parking') {
		// 	service_type = 'parking';
		// } else {
		// 	service_type = 'workdesk';
		// }
		const serviceInstanceIds = props.history.location.state.site.map(
			site => site.service_instances_id
		);
		let updatedForm = { ...form };
		const serviceInstance = await client.query({
			query: GET_SERVICE_INSTANCE,
			fetchPolicy: 'network-only',
			variables: {
				service_instances_ids: serviceInstanceIds,
				service_types_id: service_type.id,
			},
		});
		const serviceInstanceData = serviceInstance.data.getServiceInstance;

		if (serviceInstanceData.is_default_icon === 1) {
			setImageFile(null);
			setImageSrc(null);
		} else {
			setImageFile(null);
			setImageSrc(serviceInstanceData.icon_url);
		}

		if (
			serviceInstanceData.setup_logs &&
			serviceInstanceData.setup_logs.length > 0
		) {
			const updatedActiveStep = serviceInstanceData.setup_logs.filter(
				setupLog => setupLog.status === false
			);

			setCompleteSetup(
				serviceInstanceData.setup_logs.filter(
					setupLog => setupLog.status === false
				).length <= 0
			);

			if (updatedActiveStep.length > 0) {
				setupIndex = updatedActiveStep[0].step - 1;
			}

			setActiveStep(setupIndex);
		}

		if (serviceInstanceData.service_type) {
			setServiceType(serviceInstanceData.service_type);
		}

		updatedForm = updateObject(updatedForm, {
			service: updateObject(updatedForm.service, {
				name: serviceInstanceData.name
					? serviceInstanceData.name
					: ServiceTypes[service_type.name].service_name,
				description: serviceInstanceData.description,
				icon_url: serviceInstanceData.icon_url,
				is_all_employees: serviceInstanceData.is_all_employees,
				is_default_icon: serviceInstanceData.is_default_icon,
				is_qr_checkin:
					service_type.name === 'Shuttle'
						? 1
						: serviceInstanceData.is_qr_checkin,
				is_qr_checkout:
					serviceInstanceData.service_type &&
					serviceInstanceData.service_type.name === 'Shuttle'
						? 2
						: serviceInstanceData.is_qr_checkout,
				groups: serviceInstanceData.groups,
			}),
		});

		if (
			serviceInstanceData.availabilities &&
			serviceInstanceData.availabilities.length > 0
		) {
			updatedForm = updateObject(updatedForm, {
				service: updateObject(updatedForm.service, {
					availabilities: serviceInstanceData.availabilities
						.map(availabilityDay => {
							return {
								day: availabilityDay.day,
								startime: availabilityDay.startime,
								endtime: availabilityDay.endtime,
								is_available: availabilityDay.is_available,
							};
						})
						.sort(arraySort('day')),
				}),
			});
		}

		if (serviceInstanceData.groups) {
			updatedForm = updateObject(updatedForm, {
				service: updateObject(updatedForm.service, {
					groups: serviceInstanceData.groups,
				}),
			});
		}

		if (serviceInstanceData.drivers) {
			setDrivers(serviceInstanceData.drivers);
		}

		if (serviceInstanceData.vehicles) {
			setVehicles(serviceInstanceData.vehicles);
		}

		if (serviceInstanceData[service_type.name.toLowerCase()]) {
			// checking if slots property is available in workdesk
			if (
				serviceInstanceData[service_type.name.toLowerCase()].slots &&
				serviceInstanceData[service_type.name.toLowerCase()].slots
					.length > 0
			) {
				setSlots(
					serviceInstanceData[service_type.name.toLowerCase()].slots
				);
			}
			// update workdesk object on state
			updatedForm = updateObject(updatedForm, {
				serviceable: updateObject(updatedForm.serviceable, {
					advance_booking_days: serviceInstanceData[
						service_type.name.toLowerCase()
					].advance_booking_days
						? serviceInstanceData[service_type.name.toLowerCase()]
								.advance_booking_days
						: 1,
					advance_booking_threshold: serviceInstanceData[
						service_type.name.toLowerCase()
					].advance_booking_threshold
						? serviceInstanceData[service_type.name.toLowerCase()]
								.advance_booking_threshold
						: 'w',
					allowed_advance_booking: serviceInstanceData[
						service_type.name.toLowerCase()
					].allowed_advance_booking
						? serviceInstanceData[service_type.name.toLowerCase()]
								.allowed_advance_booking
						: 1,
					is_advance_booking:
						serviceInstanceData[service_type.name.toLowerCase()]
							.is_advance_booking != null
							? serviceInstanceData[
									service_type.name.toLowerCase()
							  ].is_advance_booking
							: 0,
					is_every_day:
						serviceInstanceData[service_type.name.toLowerCase()]
							.is_every_day,
					is_same_day_booking:
						serviceInstanceData[service_type.name.toLowerCase()]
							.is_same_day_booking != null
							? serviceInstanceData[
									service_type.name.toLowerCase()
							  ].is_same_day_booking
							: 1,
					is_similar_across_days:
						serviceInstanceData[service_type.name.toLowerCase()]
							.is_similar_across_days,
					is_whole_day:
						serviceInstanceData[service_type.name.toLowerCase()]
							.is_whole_day != null
							? serviceInstanceData[
									service_type.name.toLowerCase()
							  ].is_whole_day
							: Workdesk.is_whole_day,
					max_stay:
						serviceInstanceData[service_type.name.toLowerCase()]
							.is_whole_day != null
							? serviceInstanceData[
									service_type.name.toLowerCase()
							  ].max_stay %
									60 ==
							  0 // if w/o fraction just divide into minutes
								? serviceInstanceData[
										service_type.name.toLowerCase()
								  ].max_stay / 60
								: (
										serviceInstanceData[
											service_type.name.toLowerCase()
										].max_stay / 60
								  ).toFixed(2) // else fix decimal numbers to two decimal places
							: null,
					amt: !serviceInstanceData[service_type.name.toLowerCase()]
						.amt
						? '0.00'
						: serviceInstanceData[
								service_type.name.toLowerCase()
						  ].amt.toFixed(2),
					with_pay:
						serviceInstanceData[service_type.name.toLowerCase()]
							.amt > 0
							? 1
							: 0,
				}),
			});
		}

		if (serviceInstanceData.policy) {
			updatedForm.policy = updateObject(updatedForm.policy, {
				title: serviceInstanceData.policy.title,
				content: serviceInstanceData.policy.content,
			});
		}
		setForm(updatedForm);
		hideGloader();
	};

	// next button handler
	const handleNext = () => {
		setActiveStep(prevActiveStep => prevActiveStep + 1);
	};

	// stepper previous handler
	const handleBack = () => {
		setActiveStep(prevActiveStep => prevActiveStep - 1);
	};

	const getStepContent = useCallback(
		stepIndex => {
			switch (stepIndex) {
				case 0:
					return (
						<BasicInformation
							onChangeValueHandler={onChangeValueHandler}
							form={form}
							groups={groups}
							onCheckHandler={onCheckHandler}
							serviceType={serviceType}
						/>
					);
				case 1:
					return (
						<Policies
							onChangeValueHandler={onChangeValueHandler}
							form={form}
						/>
					);
				case 2:
					return (
						<Booking
							onChangeValueHandler={onChangeValueHandler}
							form={form}
							onClickDayAvailable={onClickDayAvailable}
							sites={props.history.location.state.site}
							onChangeTimeHandler={onChangeTimeHandler}
							serviceType={serviceType}
							setFileToUpload={setFileToUpload}
						/>
					);
				case 3:
					return (
						<Workflow
							form={form}
							onChangeValueHandler={onChangeValueHandler}
							serviceType={serviceType}
						/>
					);
				default:
					return 'Unknown stepIndex';
			}
		},
		[form, activeStep, groups]
	);
	const [open, setOpen] = useState(false);

	useEffect(() => {
		let updatedDays;
		if (form.serviceable.is_every_day) {
			updatedDays = form.service.availabilities.map(availability => {
				return {
					...availability,
					is_available: 1,
				};
			});
		} else {
			updatedDays = form.service.availabilities.map(
				(availability, availabilityIndex) => {
					return availabilityIndex < 5
						? availability
						: { ...availability, is_available: 0 };
				}
			);
		}
		const updatedForm = updateObject(form, {
			service: updateObject(form.service, {
				availabilities: updatedDays,
			}),
		});
		setForm(updatedForm);
	}, [form.serviceable.is_every_day]);

	useEffect(() => {
		if (form.serviceable.is_similar_across_days) {
			const updatedAvailabilities = form.service.availabilities.map(
				(availability, availabilityIndex) => {
					return {
						...availability,
						day: availabilityIndex + 1,
						startime:
							form.service &&
							form.service.availabilities &&
							form.service.availabilities.length > 0
								? form.service.availabilities[0].startime
								: '08:00',
						endtime:
							form.service &&
							form.service.availabilities &&
							form.service.availabilities.length > 0
								? form.service.availabilities[0].endtime
								: '17:00',
					};
				}
			);
			const updatedForm = updateObject(form, {
				service: updateObject(form.service, {
					availabilities: updatedAvailabilities,
				}),
			});
			setForm(updatedForm);
		}
	}, [form.serviceable.is_similar_across_days]);

	// onClick days checkbox
	const onClickDayAvailable = (evt, day) => {
		// get selected availabilities
		const selected = form.service.availabilities.filter(availability => {
			return availability.is_available == 1;
		});

		// check if available length is 1 and the selected day is from is_available = 1
		// updating of availabilties won't push through
		if (selected.length == 1 && day.is_available == 1) {
			return;
		}

		const updatedAvailabilities = form.service.availabilities.map(
			availability => {
				return availability.day === day.day
					? { ...day, is_available: day.is_available ? 0 : 1 }
					: availability;
			}
		);
		const updatedForm = updateObject(form, {
			service: updateObject(form.service, {
				availabilities: updatedAvailabilities,
			}),
		});

		setForm(updatedForm);
	};

	/* onchange inputs & textarea handler */
	const onChangeValueHandler = (parentKey, key, value) => {
		// don't allow value with greater than 5
		if (key === 'allowed_advance_booking' && value > 5) {
			value = 5;
		}

		// don't allow negative value
		if (key === 'allowed_advance_booking' && value <= 0) {
			value = 1;
		}

		if (key === 'max_stay') {
			// don't allow value with greater than 8
			if (parseFloat(value) > 8) value = 8;

			// don't allow negative and empty value
			if (parseFloat(value) <= 0) value = 1;

			let pattern = /^\s*(?=.*[1-9])\d*(?:\.\d{1,2})?\s*$/;
			if (pattern.test(value) == false) {
				value = value.toFixed(2);
			}
		}

		if (key === 'amt' && value.length > 9) {
			return false;
		}

		const updatedForm = updateObject(form, {
			[parentKey]: updateObject(form[parentKey], {
				[key]: value,
			}),
		});

		setForm(updatedForm);
	};

	const onChangeTimeHandler = ({ key, day, value }) => {
		let updatedForm;
		if (form.serviceable.is_similar_across_days) {
			const updatedAvailabilities = form.service.availabilities.map(
				(availability, availabilityIndex) => {
					return {
						...availability,
						day: availabilityIndex + 1,
						[key]: value,
					};
				}
			);
			updatedForm = updateObject(form, {
				service: updateObject(form.service, {
					availabilities: updatedAvailabilities,
				}),
			});
		} else {
			const dayIndex = form.service.availabilities.findIndex(
				availability => availability.day === day.day
			);
			const updatedDay = {
				...day,
				[key]: value,
			};

			updatedForm = { ...form };
			updatedForm.service.availabilities[dayIndex] = updatedDay;
		}
		setForm(updatedForm);
	};

	// for global checkbox onchange handler
	const onCheckHandler = (evt, key, value) => {
		if (evt.target.checked) {
			setForm({
				...form,
				service: {
					...form.service,
					[key]: [...form.service[key], value],
				},
			});
		} else {
			const updatedGroups = form.service.groups.filter(group => {
				return group.id !== value.id;
			});
			const updatedForm = updateObject(form, {
				service: updateObject(form.service, {
					[key]: updatedGroups,
				}),
			});
			setForm(updatedForm);
		}
	};

	useEffect(() => {
		getCompanyGroups();

		// clean up
		return () => {
			setFileError([]);
			setExcelFile([]);
			clearForm();
		};
	}, []);

	useEffect(() => {
		const updatedForm = updateObject(form, {
			serviceable: updateObject(form.serviceable, {
				amt:
					form.serviceable.with_pay === 0
						? null
						: form.serviceable.amt,
			}),
		});

		setForm(updatedForm);
	}, [form.serviceable.with_pay]);

	const getCompanyGroups = async () => {
		const groups = await client.query({
			query: GET_COMPANY_GROUPS,
			fetchPolicy: 'network-only',
			variables: {
				companies_id:
					user.user && user.user.company
						? user.user.company.id
						: null,
			},
		});

		setGroups(groups.data.getCompanyGroups);
	};

	useEffect(() => {
		formatParsedData();
	}, [fileData]);

	const formatParsedData = async () => {
		// showLoading('Parsing data...');
		let parsed_datas = [];
		let err = null;
		let updatedParsedData;

		if (fileData && fileData.length > 0) {
			const fileIndex = fileData.length - 1;
			const parsedData = fileData[fileIndex];

			if (parsedData.data.length <= 3) {
				err = 'File must have at least one row/line data';
			}
			if (parsedData.data && parsedData.data.length > 0) {
				for (let i = 0; i < parsedData.data.length; i++) {
					const rows = parsedData.data[i];
					if (err === null) {
						// checking of workdesks/slots/tables length
						if (i >= 3) {
							if (
								rows.includes('') ||
								rows.includes(null) ||
								rows.includes(undefined)
							) {
								err = 'We found error/s on your uploaded file';
								break;
							}

							// validate desks/slots/tables
							if (serviceType.name !== 'Shuttle') {
								if (rows.length != 3) {
									err =
										'We found error/s on your uploaded file';
									break;
								}
								parsed_datas.push({
									area: rows[0],
									floor: rows[1],
									name: rows[2],
								});
							} else {
								// check if uploaded file was vehicle then validate vehicles file otherwise validate drivers file
								if (parsedData.id === 'upload-vehicles') {
									// check row length
									// if capacity is not a number
									// or if capacity is less than or equal to zero
									// or coding_day value is not parseable in moment
									if (
										rows.length !== 5 ||
										isAlphaNumSpace(rows[0]) == false ||
										isNaN(rows[3]) ||
										rows[3] <= 0 ||
										!Days.some(
											day =>
												day.name.toLowerCase() ==
												rows[4].trim().toLowerCase()
										) ||
										['sunday', 'saturday'].includes(
											rows[4].toLowerCase()
										)
									) {
										err =
											'We found error/s on your uploaded file';
										break;
									}

									parsed_datas.push({
										plate_number: rows[0].trim(),
										make: rows[1],
										model: rows[2],
										capacity: rows[3],
										coding_day: rows[4].trim(),
									});
								} else {
									if (rows.length !== 7) {
										err =
											'We found error/s on your uploaded file';

										break;
									}

									let invalidRestDays;
									let rest_days = stringSanitizeNoLowerCase(
										rows[5] ? rows[5] : ''
									).split(',');

									rest_days.forEach((restday, i) => {
										invalidRestDays = Days.filter(
											day =>
												day.name.toLowerCase() ==
												restday.toLowerCase()
										);
									});

									if (
										// isPersonName(rows[1]) == false ||
										// isPersonName(rows[2]) == false ||
										moment(
											rows[3],
											'DD/MM/YYYY',
											true
										).isValid() == false ||
										isMobileNum(rows[4]) == false ||
										isEmail(rows[6]) == false ||
										invalidRestDays.length == 0
									) {
										err =
											'We found error/s on your uploaded file';
										break;
									}

									parsed_datas.push({
										driver_id: rows[0],
										last_name: rows[1],
										first_name: rows[2],
										birthdate: rows[3],
										mobile_no: rows[4],
										rest_days: rows[5],
										email_address: rows[6],
									});
								}
							}
						}
					}
				}

				// if there's no error encountered while parsing
				if (!err) {
					let chunkedDatas = [];
					chunkedDatas = chunkArray(parsed_datas, 1000);
					for (
						let chunkedIndex = 0;
						chunkedIndex < chunkedDatas.length;
						chunkedIndex++
					) {
						showLoading(
							`Parsing ${service_type.name.toLowerCase()}...${
								chunkedDatas.length > 1
									? chunkedIndex + '/' + chunkedDatas.length
									: ''
							}`
						);

						let duplicateDataEntry = null;
						if (serviceType.name !== 'Shuttle') {
							const duplicateSlotsQuery = await client.query({
								query: CHECK_WORKDESKS,
								fetchPolicy: 'network-only',
								variables: {
									siteSlots: {
										sites_id: parsedData.id,
										slots: chunkedDatas[chunkedIndex],
									},
								},
							});
							duplicateDataEntry =
								duplicateSlotsQuery.data.checkWorkdeskSlots;
						} else {
							if (parsedData.id === 'upload-vehicles') {
								const vehiclesArray = chunkedDatas[
									chunkedIndex
								].map(vehicle => {
									return {
										plate_number: vehicle.plate_number,
										make: vehicle.make,
										model: vehicle.model,
									};
								});
								const duplicateVehiclesQuery = await client.query(
									{
										query: CHECK_DUPLICATE_VEHICLES,
										fetchPolicy: 'network-only',
										variables: {
											sites_id:
												props.location.state.site[0].id,
											vehicles: vehiclesArray,
										},
									}
								);
								duplicateDataEntry =
									duplicateVehiclesQuery.data
										.checkDuplicateVehicle;
							} else {
								const duplicateDriversQuery = await client.query(
									{
										query: CHECK_DUPLICATE_DRIVERS,
										fetchPolicy: 'network-only',
										variables: {
											service_instances_id:
												props.location.state.site[0]
													.service_instances_id,
											drivers: chunkedDatas[chunkedIndex],
										},
									}
								);
								duplicateDataEntry =
									duplicateDriversQuery.data
										.checkDuplicateDrivers;
							}
						}

						if (!duplicateDataEntry.status) {
							err = 'We found error/s on your uploaded file';
							setFileError([
								...fileError,
								{
									id: parsedData.id,
									fileError: err,
								},
							]);
							hideLoading();
							return false;
						}

						hideLoading();
					}
				}
				if (
					Object.prototype.hasOwnProperty.call(
						parsedDatas,
						parsedData.id
					)
				) {
					updatedParsedData = {
						[parsedData.id]: parsed_datas,
					};
				} else {
					updatedParsedData = {
						...parsedDatas,
						[parsedData.id]: parsed_datas,
					};
				}
			} else {
				updatedParsedData = {
					[parsedData.id]: [],
				};

				err = 'We found error/s on your uploaded file';
			}
			if (err) {
				// empty updated parsed if there's an error
				updatedParsedData = {
					[parsedData.id]: [],
				};
				setFileError([
					...fileError,
					{
						id: parsedData.id,
						fileError: err,
					},
				]);
			}

			Object.keys(updatedParsedData).map(parsedDataKey => {
				const parsedDataIndex = fileData.findIndex(
					data => data.id === parsedDataKey
				);
				if (parsedDataIndex < 0) {
					delete updatedParsedData[parsedDataKey];
				}
			});

			setParsedDatas(updatedParsedData);
		} else {
			setParsedDatas({});
		}
	};

	// disabled button handler for each steps
	const disableButtonHandler = useCallback(() => {
		if (activeStep === 0) {
			if (imageFileError) {
				return true;
			}
			// when description is empty disable next button
			if (
				form.service.description === null ||
				form.service.description === ''
			) {
				return true;
			}

			// when custom group radio is selected & there's no group selected
			if (
				!form.service.is_all_employees &&
				form.service.groups &&
				form.service.groups.length <= 0
			) {
				return true;
			}

			// if custom icon selected & image is null
			if (
				!form.service.is_default_icon &&
				!imageFile &&
				form.service.icon_url == null
			) {
				return true;
			}

			if (
				(form.serviceable.with_pay && form.serviceable.amt <= 0) ||
				(form.serviceable.with_pay && form.serviceable.amt === null)
			) {
				return true;
			}
		}

		if (activeStep === 2) {
			if (fileError.length > 0 || excelError.length > 0) {
				return true;
			}
			// check available days if has invalid startime and/or endtime
			// hasInvalidTimeFields if:
			// both fields are null/empty and is not valid time
			// endtime is before startime
			// both fields are similar
			const hasInvalidTimeFields = form.service.availabilities.filter(
				day => {
					const start = moment(day.startime, 'HH:mm');
					const end = moment(day.endtime, 'HH:mm');

					const endIsBeforeStart = end.isBefore(start);
					const isSimilar = start.isSame(end);
					const timeDiff = end.diff(start, 'minutes');

					return (
						!day.startime ||
						!day.endtime ||
						!start.isValid() ||
						!end.isValid() ||
						endIsBeforeStart ||
						isSimilar ||
						timeDiff < 10
					);
				}
			);
			// if hasInvalidTimeFields array has elem return true
			if (hasInvalidTimeFields.length > 0) {
				return true;
			}

			// if (
			// 	serviceType.name !== "Shuttle" && fileData.length !=
			// 		props.history.location.state.site.length &&
			// 	slots.length <= 0 ||
			// 	serviceType.name === "Shuttle" &&
			// 	fileData.length !=
			// 		props.history.location.state.site.length
			// ) {
			// 	return true;
			// }

			// check the length of fileData if match on the length of sites in workdesks, parking & cafeteria
			if (
				serviceType.name !== 'Shuttle' &&
				Object.keys(fileData).length !=
					props.history.location.state.site.length &&
				slots.length <= 0
			) {
				return true;
			}

			if (
				serviceType.name === 'Shuttle' &&
				Object.prototype.hasOwnProperty.call(
					parsedDatas,
					'upload-drivers'
				) &&
				parsedDatas['upload-drivers'].length <= 0
			) {
				return true;
			}

			if (
				serviceType.name === 'Shuttle' &&
				!Object.prototype.hasOwnProperty.call(
					parsedDatas,
					'upload-drivers'
				) &&
				drivers.length <= 0
			) {
				return true;
			}

			if (
				serviceType.name === 'Shuttle' &&
				Object.prototype.hasOwnProperty.call(
					parsedDatas,
					'upload-vehicles'
				) &&
				parsedDatas['upload-vehicles'].length <= 0
			) {
				return true;
			}

			if (
				serviceType.name === 'Shuttle' &&
				!Object.prototype.hasOwnProperty.call(
					parsedDatas,
					'upload-vehicles'
				) &&
				vehicles.length <= 0
			) {
				return true;
			}

			if (
				!form.serviceable.is_same_day_booking &&
				!form.serviceable.is_advance_booking
			) {
				return true;
			}

			if (
				form.serviceable.is_whole_day == null ||
				((form.serviceable.is_whole_day == 0 ||
					form.serviceable.is_whole_day == 2) &&
					form.serviceable.max_stay <= 0) ||
				((form.serviceable.is_whole_day == 0 ||
					form.serviceable.is_whole_day == 2) &&
					isNaN(form.serviceable.max_stay)) ||
				((form.serviceable.is_whole_day == 0 ||
					form.serviceable.is_whole_day == 2) &&
					form.serviceable.max_stay === null)
			) {
				return true;
			}

			if (
				form.serviceable.is_advance_booking &&
				form.serviceable.advance_booking_days <= 0
			) {
				return true;
			}
		}
		return false;
	}, [
		activeStep,
		form,
		imageFile,
		fileData,
		fileError,
		imageFileError,
		parsedDatas,
		vehicles,
		drivers,
	]);

	if (redirectTo !== null) {
		return <Redirect to={redirectTo} />;
	}

	const renderCompanySite = () => {
		const result = props.location.state.site.map(site => {
			return <li key={site.id}>{site.name}</li>;
		});
		return result;
	};

	const onFinishHandler = async finished => {
		const serviceForm = { ...form };
		if (!finished && fileError.length > 0) {
			return false;
		}
		showLoading('Saving...');
		const steps = [];
		// iterate steps to check one by one if all required fields are filled-out
		for (let i = 0; i < getSteps().length; i++) {
			steps.push({
				step: i + 1,
				status: !finished ? activeStep > i : finished,
			});
		}
		// check if all employees was selected then select all groups
		let updatedFormData = [];
		let serviceGroups = [];
		if (serviceForm.service.is_all_employees) {
			serviceGroups = [];
		} else {
			serviceGroups = serviceForm.service.groups.map(group => {
				return group.id;
			});
		}
		// iterate service for populating form data
		for (let i = 0; i < props.history.location.state.site.length; i++) {
			delete serviceForm.serviceable.with_pay;
			// let newFloors = [];

			// // delegate workdesks to the service where it belongs
			// if (parsedWorkdesks.length > 0) {
			// 	if (
			// 		parsedWorkdesks[i] &&
			// 		parsedWorkdesks[i].workdesks &&
			// 		parsedWorkdesks[i].workdesks.length > 0
			// 	) {
			// 		parsedWorkdesks[i].workdesks.map(slot => {
			// 			if (
			// 				!newFloors.some(
			// 					newFloor => newFloor.name === slot.floor
			// 				)
			// 			) {
			// 				newFloors.push({
			// 					name: slot.floor,
			// 					sites_id:
			// 						props.history.location.state.site[i].id,
			// 				});
			// 			}
			// 		});
			// 	}
			// }

			// updating form data
			updatedFormData = [
				...updatedFormData,
				{
					...serviceForm,
					service_instances_id:
						props.history.location.state.site[i]
							.service_instances_id,
					service: {
						...serviceForm.service,
						name: ServiceTypes[serviceType.name].service_name,
						groups: serviceGroups,
						icon_url: imageFile,
					},
					serviceable: {
						...serviceForm.serviceable,
						advance_booking_threshold: serviceForm.serviceable
							.is_advance_booking
							? serviceForm.serviceable.advance_booking_threshold
							: null,
						advance_booking_days: serviceForm.serviceable
							.is_advance_booking
							? serviceForm.serviceable.advance_booking_days
							: null,
						allowed_advance_booking: serviceForm.serviceable
							.is_advance_booking
							? serviceForm.serviceable.allowed_advance_booking
							: null,
						// convert hours max_stay to minutes
						max_stay: serviceForm.serviceable.max_stay
							? Math.round(serviceForm.serviceable.max_stay * 60)
							: null,
						amt:
							serviceForm.serviceable.amt === null
								? 0
								: parseFloat(serviceForm.serviceable.amt),
					},
				},
			];
		}

		// post request for insert
		await client.mutate({
			mutation: POST_SERVICE_SETUP,
			variables: {
				input: {
					users_id: user.user.id,
					service_instance: updatedFormData,
					step: steps,
					file_to_upload: fileToUpload,
				},
			},
		});

		hideLoading();

		for (let i = 0; i < props.history.location.state.site.length; i++) {
			// chunkedParsedDatas = chunkArray(
			// 	parsedWorkdesks.length > 0 &&
			// 		parsedWorkdesks.filter(
			// 			workdesk =>
			// 				workdesk.id ===
			// 				props.history.location.state.site[i].id
			// 		).length > 0
			// 		? parsedWorkdesks.filter(
			// 				workdesk =>
			// 					workdesk.id ===
			// 					props.history.location.state.site[i].id
			// 		  )[0].workdesks
			// 		: [],
			// 	1000
			// );
			// bulk upload of desks/slots/tables
			if (
				Object.prototype.hasOwnProperty.call(
					parsedDatas,
					props.history.location.state.site[i].id
				)
			) {
				const chunkedSlots = chunkArray(
					parsedDatas[props.history.location.state.site[i].id],
					1000
				);
				for (
					let chunkedIndex = 0;
					chunkedIndex < chunkedSlots.length;
					chunkedIndex++
				) {
					showLoading(
						`Saving ${service_type.name}...${
							chunkedSlots.length > 1
								? chunkedIndex + '/' + chunkedSlots.length
								: ''
						}`
					);

					await client.mutate({
						mutation: POST_WORKDESKS,
						variables: {
							input: {
								service_instances_id:
									props.history.location.state.site[i]
										.service_instances_id,
								sites_id:
									props.history.location.state.site[i].id,
								slots: chunkedSlots[chunkedIndex],
							},
						},
						awaitRefetchQueries: true,
						refetchQueries: [
							{
								query: GET_SITE_WORKDESKS,
								fetchPolicy: 'network-only',
								variables: {
									input: {
										users_id: user.user.id,
										company_site: '',
										setup_status: null,
										companies_id: user.user.company.id,
										service_types_id: serviceType.id,
									},
									paginate: { page: 1, offset: 10 },
								},
							},
						],
					});

					hideLoading();
				}
			}
			// bulk upload of vehicles
			if (
				Object.prototype.hasOwnProperty.call(
					parsedDatas,
					'upload-vehicles'
				)
			) {
				const chunkedVehicles = chunkArray(
					parsedDatas['upload-vehicles'],
					1000
				);
				for (
					let chunkedIndex = 0;
					chunkedIndex < chunkedVehicles.length;
					chunkedIndex++
				) {
					showLoading(
						`Saving ${service_type.name}...${
							chunkedVehicles.length > 1
								? chunkedIndex + '/' + chunkedVehicles.length
								: ''
						}`
					);

					await client.mutate({
						mutation: POST_VEHICLES,
						variables: {
							service_instances_id:
								props.history.location.state.site[i]
									.service_instances_id,
							vehicles: chunkedVehicles[chunkedIndex],
						},
						awaitRefetchQueries: true,
						refetchQueries: [
							{
								query: GET_SITE_WORKDESKS,
								fetchPolicy: 'network-only',
								variables: {
									input: {
										users_id: user.user.id,
										company_site: '',
										setup_status: null,
										companies_id: user.user.company.id,
										service_types_id: serviceType.id,
									},
									paginate: { page: 1, offset: 10 },
								},
							},
						],
					});

					hideLoading();
				}
			}
			// bulk upload of drivers
			if (
				Object.prototype.hasOwnProperty.call(
					parsedDatas,
					'upload-drivers'
				)
			) {
				const chunkedDrivers = chunkArray(
					parsedDatas['upload-drivers'],
					1000
				);
				for (
					let chunkedIndex = 0;
					chunkedIndex < chunkedDrivers.length;
					chunkedIndex++
				) {
					showLoading(
						`Saving ${service_type.name}...${
							chunkedDrivers.length > 1
								? chunkedIndex + '/' + chunkedDrivers.length
								: ''
						}`
					);

					await client.mutate({
						mutation: POST_DRIVERS,
						variables: {
							service_instances_id:
								props.history.location.state.site[i]
									.service_instances_id,
							drivers: chunkedDrivers[chunkedIndex],
						},
						awaitRefetchQueries: true,
						refetchQueries: [
							{
								query: GET_SITE_WORKDESKS,
								fetchPolicy: 'network-only',
								variables: {
									input: {
										users_id: user.user.id,
										company_site: '',
										setup_status: null,
										companies_id: user.user.company.id,
										service_types_id: serviceType.id,
									},
									paginate: { page: 1, offset: 10 },
								},
							},
						],
					});

					hideLoading();
				}
			}
		}

		// showing gloader
		showGloader();

		// resetting all datas to default
		clearForm();

		hideGloader();

		// checked if user click finished button or save & continue
		if (!finished) {
			setRedirectTo(`/serviceadministrator`);
		} else {
			setOpen(true);
			setTimeout(() => {
				setOpen(false);
				props.location.state.action === 'update'
					? props.history.push('/service-dashboard-per-site', {
							site: [...props.location.state.site],
					  })
					: props.history.push('/serviceadministrator');
			}, 2000);
		}
	};

	const clearForm = () => {
		setFileData([]);
		// setParsedWorkdesks([]);
		setImageFile([]);
	};

	// breadcrumbs navigation
	const breadcrumbNavigation = () => {
		clearForm();
		props.history.goBack();
	};

	return (
		<LayoutPage>
			{service_type.name === 'Shuttle' ? null : (
				<Breadcrumbs
					breadcrumbs={[
						...props.location.state.breadcrumbs,
						{ label: 'Set up service' },
					]}
					breadcrumbNavigation={breadcrumbNavigation}
				/>
			)}
			<Header as="h1">Set up service</Header>
			<p>
				This set up will apply to{' '}
				{props.location.state.site.length <= 1 ? (
					<span className="setup-service-popup">
						{props.location.state.site[0].name}
					</span>
				) : (
					<Popup
						content={
							<ul className="setup-service-ul">
								{renderCompanySite()}
							</ul>
						}
						trigger={
							<span className="setup-service-popup">
								{props.location.state.site.length} company sites
							</span>
						}
					/>
				)}
			</p>
			<Form>
				<div className={clsx('stepper-step-container', classes.root)}>
					<Stepper activeStep={activeStep} alternativeLabel>
						{steps.map(label => (
							<Step key={label}>
								<StepLabel>
									<h5 className="fs-elliot">{label}</h5>
								</StepLabel>
							</Step>
						))}
					</Stepper>
					<Grid>
						<Grid.Column
							mobile="sixteen"
							tablet="fourteen"
							computer="eleven"
						>
							<div>
								<div>
									<Typography
										className={classes.instructions}
									>
										{getStepContent(activeStep)}
									</Typography>
									<div className="w-100">
										{activeStep !== 0 ? (
											<Button
												onClick={handleBack}
												className={
													'app-primary-inverted-button prev'
												}
											>
												Previous
											</Button>
										) : (
											<a></a>
										)}

										{!completeSetup ? (
											<span
												style={{
													flex: 'auto',
													margin: '15px',
												}}
											>
												<a
													style={{
														cursor: 'pointer',
													}}
													className={
														fileError.length > 0
															? 'disable-btn'
															: ''
													}
													onClick={() =>
														onFinishHandler(false)
													}
												>
													<u>Save & continue later</u>
												</a>
											</span>
										) : null}

										{activeStep === steps.length - 1 ? (
											<Button
												className="stepper-btn next"
												primary
												onClick={() =>
													onFinishHandler(true)
												}
											>
												Submit
											</Button>
										) : (
											<Button
												className="stepper-btn next"
												primary
												disabled={disableButtonHandler()}
												onClick={handleNext}
											>
												Next
											</Button>
										)}
									</div>
								</div>
							</div>
						</Grid.Column>
						{activeStep !== 3 ? (
							<Grid.Column
								mobile="sixteen"
								tablet="fourteen"
								computer="five"
							>
								{serviceType.name !== 'Shuttle' && (
									<SetupPreview
										form={form}
										activeStep={activeStep}
										serviceType={serviceType}
										site={props.location.state.site}
									></SetupPreview>
								)}
							</Grid.Column>
						) : (
							<Grid.Column
								mobile="sixteen"
								tablet="fourteen"
								computer="five"
							>
								<div className="page-guidelines">
									<Header as="h3">
										{/* <Icon name="lightbulb outline" /> Guidelines: */}
										<img
											src={`${process.env.REACT_APP_BASE_URL}/images/idea.svg`}
											className="idea-img"
										/>{' '}
										<span className="guidelines-txt">
											Guidelines
										</span>
									</Header>
									<List bulleted>
										<List.Item>
											Lorem ipsum dolor sit amet,
											consectetur adipiscing elit. Nulla
											luctus nibh vitae urna aliquet
											sodales.
										</List.Item>
										<List.Item>
											Lorem ipsum dolor sit amet,
											consectetur adipiscing elit. Nulla
											luctus nibh vitae urna aliquet
											sodales.
										</List.Item>
									</List>
								</div>
							</Grid.Column>
						)}
					</Grid>
				</div>
			</Form>
			<Modal
				closeOnEscape={false}
				closeOnDimmerClick={false}
				onOpen={() => setOpen(true)}
				open={open}
				size="tiny"
				// trigger={<Button>Show Modal</Button>}
				className="setup-service-complete app-modal"
				dimmer="blurring"
			>
				<Modal.Content>
					<img
						src={`${process.env.REACT_APP_BASE_URL}/images/setupservice-complete.png`}
					/>
					<Header as="h3">
						{props.location.state.action === 'update'
							? 'Service was successfully updated'
							: 'Service set up complete!'}
					</Header>
					<p>
						{props.location.state.action === 'update'
							? 'All upcoming bookings will be adjusted to your updated service setup'
							: serviceType && serviceType.name
							? ServiceTypes[serviceType.name].success_prompt
							: `You may now proceed with setting up other sites or start
							managing the service you just finished setting up`}
					</p>
					<a
						className="ui button app-primary-button medium-button"
						onClick={() =>
							props.location.state.action === 'update'
								? props.history.push(
										'/service-dashboard-per-site',
										{ site: [...props.location.state.site] }
								  )
								: props.history.push('/serviceadministrator')
						}
					>
						Proceed
					</a>
				</Modal.Content>
			</Modal>
		</LayoutPage>
	);
};

ServiceSetup.propTypes = {
	history: PropTypes.any,
	location: PropTypes.any,
};

export default ServiceSetup;

import React from 'react';

import './NewCompanySite.css';

import PropTypes from 'prop-types';

import { Header, Form, Grid, Button, Modal } from 'semantic-ui-react';

const moreImg = process.env.REACT_APP_BASE_NAME + 'svgs/more.svg';
const closeImg = process.env.REACT_APP_BASE_NAME + 'svgs/close-blue.svg';

const CompanySiteManually = ({
	setOpen,
	site,
	removeSiteHandler,
	checkSiteName,
	handleOnchange,
	onClickProceed,
	addNewSite,
	open,
	showLoading,
	siteNameRef,
}) => {
	return (
		<div>
			{site.map((siteVal, siteIndex) => {
				return (
					<div
						className={`step-highlight site-padding site-item ${
							site.length > 1 ? 'custom-step-highlight' : ''
						}`}
						key={siteIndex}
					>
						<div className="site-close">
							{site.length > 1 ? (
								<Button
									icon
									onClick={e =>
										removeSiteHandler(siteIndex, e)
									}
									className="btn-remove"
								>
									<img
										src={closeImg}
										className="custom-close-icon"
									/>
								</Button>
							) : null}
						</div>
						<Grid>
							<Grid.Column
								width="ten"
								computer="10"
								tablet="16"
								mobile="16"
							>
								<Form.Field>
									<label>Site name </label>
									<input
										type="text"
										className={
											checkSiteName[siteIndex] ||
											(siteVal.name &&
												site.filter(
													singleSite =>
														singleSite.name ===
														siteVal.name
												).length > 1)
												? 'input-error input-error-color'
												: ''
										}
										placeholder="Enter site name here"
										name="name"
										ref={siteNameRef}
										onChange={ev =>
											handleOnchange({
												property: 'name',
												key: siteIndex,
												value: ev.target.value,
											})
										}
										value={siteVal.name}
									/>
									{checkSiteName[siteIndex] ||
									(siteVal.name &&
										site.filter(
											singleSite =>
												singleSite.name === siteVal.name
										).length > 1) ? (
										<small className="input-error-message">
											Site name is already used.
										</small>
									) : null}
								</Form.Field>
							</Grid.Column>
							<Grid.Column
								width="six"
								computer="6"
								tablet="16"
								mobile="16"
							>
								<Form.Field>
									<label>
										Site abbreviation (if applicable)
									</label>
									<input
										type="text"
										placeholder="Enter site abbreviation"
										onChange={ev =>
											handleOnchange({
												property: 'abbrev',
												key: siteIndex,
												value: ev.target.value,
											})
										}
										value={siteVal.abbrev}
									/>
								</Form.Field>
							</Grid.Column>
							<Grid.Column width="sixteen">
								<Form.Field>
									<label>Site address</label>
									<input
										type="text"
										placeholder="Enter site address here"
										name="siteAddress"
										onChange={ev =>
											handleOnchange({
												property: 'address',
												key: siteIndex,
												value: ev.target.value,
											})
										}
										value={siteVal.address}
									/>
								</Form.Field>
							</Grid.Column>
							<Grid.Column
								width="eight"
								computer="6"
								tablet="16"
								mobile="16"
							>
								<Form.Field>
									<label>Floor/unit number (optional)</label>
									<input
										type="text"
										placeholder="Enter floor/unit no. here"
										onChange={ev =>
											handleOnchange({
												property: 'floor',
												key: siteIndex,
												value: ev.target.value,
											})
										}
										value={siteVal.floor}
									/>
								</Form.Field>
							</Grid.Column>
						</Grid>
					</div>
				);
			})}

			<span className="add-site" onClick={() => addNewSite()}>
				<img src={moreImg} className="custom-more-icon" alt="add" />
				Add another site
			</span>

			<Modal
				onClose={() => setOpen(false)}
				onOpen={() => setOpen(true)}
				open={open}
				className="employee-form-modal"
			>
				<Modal.Content>
					<img
						src={`${process.env.REACT_APP_BASE_URL}/images/question-logo.png`}
					/>
					<Header as="h3">Save & create new site</Header>
					<p className="modal-sub-header">
						New site will be created so you may add services that
						will be offered to your employees assign here. Are you
						sure you would like to proceed?
					</p>
					<div className="modal-action">
						<Button
							onClick={() => {
								showLoading('Saving...');
								onClickProceed();
							}}
							className="app-primary-button custom-full-button"
						>
							Yes, proceed
						</Button>
						<Button
							className="app-primary-inverted-button custom-full-button"
							onClick={() => setOpen(false)}
						>
							Cancel and go back
						</Button>
					</div>
				</Modal.Content>
			</Modal>
		</div>
	);
};

CompanySiteManually.propTypes = {
	handleOnchange: PropTypes.func,
	site: PropTypes.array,
	addNewSite: PropTypes.func,
	removeSiteHandler: PropTypes.func,
	register: PropTypes.object,
	handleSubmit: PropTypes.any,
	open: PropTypes.bool,
	setOpen: PropTypes.bool,
	handleSameSiteError: PropTypes.func,
	checkSiteName: PropTypes.array,
	message: PropTypes.any,
	onClickProceed: PropTypes.func,
	showLoading: PropTypes.any,
	sameSite: PropTypes.any,
	siteNameRef: PropTypes.any,
};

export default CompanySiteManually;

// react lib
import React from 'react';

const CompanyBulkList = () => {
	return (
		<div>
			<div>
				<div>Upload list of site locations</div>
				<div className="grey-text">
					Missed the site location list template we shared?
				</div>
				<a href="">Download template here.</a>
			</div>
		</div>
	);
};

export default CompanyBulkList;

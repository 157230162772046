import React, { useState, createContext } from 'react';
import PropTypes from 'prop-types';

const ToastContext = createContext({});

export const ToastProvider = ({ children }) => {
	const [toastError, setToastError] = useState({
		toggle: false,
		message: null,
	});
	return (
		<ToastContext.Provider value={{ toastError, setToastError }}>
			{children}
		</ToastContext.Provider>
	);
};

ToastContext.propTypes = {
	children: PropTypes.any,
};

export default ToastContext;

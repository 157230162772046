// react lib
import React from 'react';

import clsx from 'clsx';

import moment from 'moment';

// css
import './ServiceBuilder.css';

// semantic ui components
import { Grid, Header, Form, Icon } from 'semantic-ui-react';

import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';

import { ServiceTypes } from '../../../../models/ServiceTypes';

import { decrypt } from '../../../../utils';
import Workflow from './Workflow/Workflow';
import Booking from './Booking/Booking';
import Policy from './Policy/Policy';
import BasicInformation from './BasicInformation/BasicInformation';

const editIcon = process.env.REACT_APP_BASE_NAME + 'svgs/edit.svg';

const ServiceBuilder = ({
	service,
	services,
	sortedAvailabilities,
	// service_type,
}) =>
	React.useMemo(() => {
		const service_type = JSON.parse(
			decrypt(localStorage.getItem('service_type'))
		);
		const serviceTypeName = service_type.name.toLowerCase();

		const bookingStay = () => {
			let elem = [];
			if (
				(service[serviceTypeName] &&
					service[serviceTypeName].is_whole_day == 1) ||
				service[serviceTypeName].is_whole_day == 2
			) {
				elem.push(<span>Whole day</span>);
			}

			if (
				service[serviceTypeName] &&
				(service[serviceTypeName].is_whole_day == 0 ||
					service[serviceTypeName].is_whole_day == 2)
			) {
				elem.push(
					<>
						{elem.length > 0 ? <br /> : null}
						<span>
							Limited time -{' '}
							{service[serviceTypeName].max_stay % 60 == 0 // if w/o fraction just divide into minutes
								? service[serviceTypeName].max_stay / 60
								: (
										service[serviceTypeName].max_stay / 60
								  ).toFixed(2) // else fix decimal numbers to two decimal places
							}{' '}
							hour(s)
						</span>
					</>
				);
			}

			return elem;
		};

		return (
			<div className="site-item service-builder">
				<Grid>
					<Grid.Column mobile={16} table={16} computer={11}>
						<div className="service-builder-title">
							<Header as="h3">Service builder</Header>
							<Link
								to={{
									pathname: '/setupservice',
									state: {
										site: services,
										action: 'update',
										breadcrumbs: [
											{
												label: 'Service dashboard',
												link:
													'/service-dashboard-per-site',
											},
										],
									},
								}}
							>
								<img
									src={editIcon}
									style={{ marginBottom: -1 }}
									className="btn-icon"
								/>{' '}
								<a className="service-builder-edit cursor-pointer">
									Edit
								</a>
							</Link>
						</div>
						<div className="service-builder-container">
							<BasicInformation service={service} />
						</div>
						<div className="service-builder-container">
							<Policy service={service} />
						</div>
						<div className="service-builder-container">
							<Booking
								serviceTypeName={serviceTypeName}
								service={service}
								sortedAvailabilities={sortedAvailabilities}
								bookingStay={bookingStay}
								ServiceTypes={ServiceTypes}
								service_type={service_type}
							/>
						</div>
						<div className="service-builder-container">
							<Workflow service_type={service_type} />
						</div>
					</Grid.Column>
				</Grid>
			</div>
		);
	}, [service, sortedAvailabilities]);

ServiceBuilder.propTypes = {
	history: PropTypes.any,
	service: PropTypes.object,
	services: PropTypes.array,
	sortedAvailabilities: PropTypes.array,
	// service_type: PropTypes.string,
};

export default ServiceBuilder;

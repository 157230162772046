export const ServiceTypes = {
	Workdesk: {
		id: 1,
		name: 'Work desk',
		service_name: 'Workdesk',
		drawerIcon: '/svgs/desk.svg',
		slotType1: 'desk',
		slotType2: 'work desk',
		slotType3: 'Desk',
		columnName: 'Bookable desks',
		dashboard_spiel: `List of company sites you're assigned to manage work desk`,
		site_dashboard_column_id: 'Desk ID',
		site_dashboard_column_name: 'Desk name',
		site_dashboard_btn_add_individual_slot: 'Add new desk',
		site_dashboard_tab: 'Desk',
		site_dashboard_header_individual_slot: 'Add new desk',
		site_dashboard_label_individual_slot: 'Desk name',
		site_dashboard_placeholder_individual_slot: 'Enter desk name here',
		site_dashboard_header_success: 'New desk added!',
		site_dashboard_message_success:
			'You have successfully added a new desk/s in',
		site_dashboard_bulk_upload_header: 'Bulk upload desk',
		success_prompt: `You may now proceed with setting up other sites or start
		managing the service you just finished setting up`,
	},
	Parking: {
		id: 2,
		name: 'Parking',
		service_name: 'Parking',
		drawerIcon: '/svgs/parking.svg',
		slotType1: 'slot',
		slotType2: 'parking slot',
		slotType3: 'Parking slot',
		columnName: 'Bookable parking slots',
		dashboard_spiel: `List of company sites you're assigned to manage the bookable parking slots`,
		site_dashboard_column_id: 'Parking ID',
		site_dashboard_column_name: 'Parking name',
		site_dashboard_btn_add_individual_slot: 'Add new slot',
		site_dashboard_tab: 'Parking slots',
		site_dashboard_header_individual_slot: 'Add new parking slot',
		site_dashboard_label_individual_slot: 'Parking slot name',
		site_dashboard_placeholder_individual_slot:
			'Enter parking slot name here',
		site_dashboard_header_success: 'New parking slot added!',
		site_dashboard_message_success:
			'You have successfully added a new parking slot/s in',
		site_dashboard_bulk_upload_header: 'Bulk upload parking slots',
		success_prompt: `You may now proceed with setting up other sites or start
		managing the service you just finished setting up`,
	},
	Cafeteria: {
		id: 3,
		name: 'Cafeteria',
		service_name: 'Cafeteria',
		drawerIcon: '/svgs/dinner.svg',
		slotType1: 'table',
		slotType2: 'cafeteria table',
		slotType3: 'Cafeteria table',
		columnName: 'Bookable tables',
		dashboard_spiel: `List of company sites you're assigned to manage the cafeteria table booking service`,
		site_dashboard_column_id: 'Table ID',
		site_dashboard_column_name: 'Table name',
		site_dashboard_btn_add_individual_slot: 'Add new table',
		site_dashboard_tab: 'Tables',
		site_dashboard_header_individual_slot: 'Add new table',
		site_dashboard_label_individual_slot: 'Table name',
		site_dashboard_placeholder_individual_slot: 'Enter table name here',
		site_dashboard_header_success: 'New cafeteria table added!',
		site_dashboard_message_success:
			'You have successfully added a new table/s in',
		site_dashboard_bulk_upload_header: 'Bulk upload cafeteria table',
		success_prompt: `You may now proceed with setting up other sites or start
		managing the service you just finished setting up`,
	},
	Shuttle: {
		id: 3,
		name: 'Shuttle',
		service_name: 'Shuttle ride',
		drawerIcon: '/svgs/shuttle.svg',
		slotType1: 'table',
		slotType2: 'shuttle seat',
		// slotType3: 'Cafeteria table',
		columnName: 'Bookable tables',
		dashboard_spiel: `List of company sites you’re assigned to manage the shuttle services`,
		site_dashboard_column_id: 'Trip ID',
		success_prompt: `You may now proceed with setting up the trip scheduler and start managing the Shuttle service`,
		// site_dashboard_column_name: 'Table name',
		// site_dashboard_btn_add_individual_slot: 'Add new table',
		// site_dashboard_tab: 'Tables',
		// site_dashboard_header_individual_slot: 'Add new table',
		// site_dashboard_label_individual_slot: 'Table name',
		// site_dashboard_placeholder_individual_slot: 'Enter new table name here',
		// site_dashboard_header_success: 'New cafeteria table added!',
		// site_dashboard_message_success:
		// 	'You have successfully added a new table/s in',
		// site_dashboard_bulk_upload_header: 'Bulk upload cafeteria table',
	},
};

import { useForm as userFormHooks } from 'react-hook-form';

function useForm() {
	return userFormHooks({
		mode: 'onSubmit',
		reValidateMode: 'onChange',
		defaultValues: {},
		resolver: undefined,
		context: undefined,
		criteriaMode: 'firstError',
		shouldFocusError: true,
		shouldUnregister: true,
	});
}

export default useForm;

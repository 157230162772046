// react lib
import React, {
	useState,
	useEffect,
	useContext,
	useRef,
	useCallback,
} from 'react';
import { Redirect } from 'react-router-dom';

import PropTypes from 'prop-types';

// grahpql queries
import { useMutation, useLazyQuery } from '@apollo/client';
import { GET_USER } from '../graphql/query'; // from parent
import { PATCH_SETUP_PASSWORD } from '../graphql/mutation';

// hoc
import LoginMaster from '../../../hoc/Layout/Login/LoginMaster';

// semantic ui component
import {
	Form,
	Button,
	Icon,
	Header,
	Modal,
	Message,
	Popup,
	Grid,
} from 'semantic-ui-react';

// context
import AuthContext from '../../../context/AuthContext/AuthContext';

// helpers
import { decrypt } from '../../../utils';

// component
import Page404 from '../../Page404/Page404';
import GLoader from '../../../components/Loader/Loader';

import useForm from '../../../hooks/useForm';

const SetUpPassword = props => {
	const [email, setEmail] = useState(
		decrypt(props.match.params.encryptedEmail)
	);
	const [companyCode, setCompanyCode] = useState(
		decrypt(props.match.params.encryptedCompanyCode)
	);
	const [role, setRole] = useState('');
	const [company, setCompany] = useState('');
	const [redirectTo, setRedirectTo] = useState(null);
	const [is404, set404] = useState(null);
	const [loading, setLoading] = useState(true);
	const [open, setOpen] = useState(false);
	const [patchError, setPatchError] = useState({});
	const [passwordErrors, setPasswordErrors] = useState([]);
	const [passwordRetypeErrors, setPasswordRetypeErrors] = useState([]);
	const passwordRef = useRef();
	const passwordRetypeRef = useRef();

	const { loginHandler, user, adminRole } = useContext(AuthContext);
	useEffect(() => {
		setLoading(false);

		if (!email || !companyCode) {
			set404(true);
			return;
		}
		getUser({
			variables: {
				input: {
					email,
				},
			},
		});
	}, []);

	const [
		getUser,
		{ data: userData, errors: qErrors, loading: isUserLoading },
	] = useLazyQuery(GET_USER, {
		fetchPolicy: 'network-only',
		onError: error => {},
		onCompleted: data => {
			if (data.getUser.status == 0) {
				set404(true);
				return;
			}

			const { roles, company } = data.getUser;
			const admin_role = roles.filter(role => {
				return role.code == 'ca' || role.code == 'sa';
			});

			if (!admin_role.length) {
				set404(true);
				return;
			}
			setRole(admin_role[0]);
			setCompany(company);

			// set 404 if user has date_confirmed (possibly via sso)
			if (
				data.getUser &&
				data.getUser.date_confirmed !== null &&
				data.getUser.hasSetPassword == null
			) {
				setRedirectTo('/');
				return;
			}

			// redirect to login if date_confirmed and password have been updated already
			if (data.getUser.date_confirmed && data.getUser.hasSetPassword) {
				setRedirectTo('/');
				return;
			}

			set404(false);
		},
	});

	const { register, errors, handleSubmit } = useForm();

	const [formFields, setFormFields] = useState({
		password: '',
		verifyPassword: '',
	});

	const [
		patchSetupPassowrd,
		{
			data: SetupPasswordResponse,
			errors: patchErrors,
			loading: isPatchLoading,
		},
	] = useMutation(PATCH_SETUP_PASSWORD, {
		variables: {
			input: formFields,
		},
		onError: error => {},
		onCompleted: data => {
			if (data.patchSetupPassword.status == 1) {
				setOpen(true);
				setTimeout(() => {
					setLoading(true);
					setTimeout(() => {
						props.history.push('/');
					}, 1000);
				}, 2000);
			} else if (data.patchSetupPassword.status == 0) {
				setPatchError({ ...data.patchConfirmEmail });
			}
		},
	});

	const handleChange = (key, value) => {
		setFormFields({
			...formFields,
			[key]: value,
		});
	};

	const onSubmitFunction = () => {
		if (
			formFields.password.length > 0 &&
			formFields.verifyPassword.length > 0 &&
			passwordErrors.length == 0 &&
			passwordRetypeErrors.length == 0
		) {
			patchSetupPassowrd({
				variables: {
					input: {
						email,
						companyCode,
						password: formFields.password,
						verifyPassword: formFields.verifyPassword,
					},
				},
			});
		}
	};

	const validatePasswordFormat = useCallback(() => {
		let hasError = false;
		let tempErrors = [];

		if (formFields.password.length < 8) {
			hasError = true;
			tempErrors = [...tempErrors, 'Must be at least 8 characters'];
		}

		if (
			/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[\d]).{0,}$/gm.test(
				formFields.password
			) == false
		) {
			hasError = true;
			tempErrors = [
				...tempErrors,
				'A combination of uppercase, lowercase & numbers',
			];
		}

		if (/^(?=.*?[\W_]).{0,}$/gm.test(formFields.password) == false) {
			hasError = true;
			tempErrors = [...tempErrors, 'Must have special characters'];
		}

		if (!hasError) {
			setPasswordErrors([]);
		} else {
			setPasswordErrors(tempErrors);
		}

		if (formFields.verifyPassword.length > 0) {
			validatePasswordRetype();
		}
	}, [passwordErrors, formFields]);

	const validatePasswordRetype = useCallback(() => {
		let hasError = false;
		let tempErrors = [];
		if (!formFields.verifyPassword || formFields.verifyPassword == '') {
			hasError = true;
			tempErrors = [...tempErrors, 'Re-type password is required'];
		}

		if (
			formFields.verifyPassword !== '' &&
			formFields.password !== formFields.verifyPassword
		) {
			hasError = true;
			tempErrors = [...tempErrors, 'Password did not match'];
		}

		if (!hasError) {
			setPasswordRetypeErrors([]);
		} else {
			setPasswordRetypeErrors(tempErrors);
		}
	}, [passwordRetypeErrors, formFields]);

	if (
		loading ||
		isUserLoading ||
		isPatchLoading ||
		(is404 == null && redirectTo == null)
	) {
		return <GLoader />;
	}

	if (!email || !companyCode || is404) {
		return <Page404 />;
	}

	if (redirectTo !== null) {
		return <Redirect to={redirectTo} />;
	}

	return (
		<LoginMaster>
			<Header as="h2">{role ? role.name : ''}</Header>

			<Grid>
				<Grid.Row>
					<Grid.Column
						mobile="sixteen"
						tablet="tablet"
						computer="six"
					>
						{patchError && patchError.status === 0 ? (
							<Message negative>
								<Icon
									className="error-icon"
									name="exclamation circle"
								/>
								{patchError.message}
							</Message>
						) : null}
						<Form onSubmit={handleSubmit(onSubmitFunction)}>
							<Form.Field>
								<p>
									<Icon name="check"></Icon> Company code for
									{userData && userData.getUser
										? ' ' + userData.getUser.company.name
										: ''}{' '}
									accepted
								</p>
							</Form.Field>
							<div className="login-item">
								<Header as="h5" style={{ marginBottom: '0' }}>
									Set up password to your account
								</Header>
								<ul
									style={{
										listStyleType: 'disc',
										color: '#707070',
									}}
									className="password-errors"
								>
									<li>At least 8 characters</li>
									<li>
										A combination of uppercase, lowercase &
										numbers
									</li>
									<li>Must have special characters</li>
								</ul>
							</div>
							<Form.Field>
								<label htmlFor="">Password</label>
								<input
									className={
										passwordErrors &&
										passwordErrors.length > 0
											? 'input-error'
											: ''
									}
									type="password"
									autoComplete="off"
									placeholder="Enter password here"
									name="password"
									autoFocus
									// ref={register({
									// 	required: 'Password is required*',
									// 	/* pattern: {
									// 		value: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[\d])(?=.*?[\W_]).{8,}$/gm,
									// 		message:
									// 			'Password must be at least 8 characters with a mix of uppercase, lowercase and special characters*',
									// 	},
									// 	min: {
									// 		value: 8,
									// 		message:
									// 			'Password must be atleast 8 characters*',
									// 	} ,*/
									// })}
									ref={passwordRef}
									onBlur={e => {
										validatePasswordRetype();
										validatePasswordFormat();
									}}
									onChange={e =>
										handleChange('password', e.target.value)
									}
								/>
							</Form.Field>
							<Popup
								context={passwordRef}
								position="right center"
								open={
									passwordErrors && passwordErrors.length > 0
								}
								size="mini"
								wide="very"
								basic
								style={{
									marginLeft: '20px',
									boxShadow: 'none',
									backgroundColor: '#FFCBCB',
									color: '#E55151',
									border: 'none',
								}}
							>
								<ul
									style={{
										listStyleType: 'disc',
										color: '#E55151',
										fontWeight: 'bold',
									}}
									className="password-errors"
								>
									{passwordErrors.map((error, i) => {
										return <li key={i}>{error}</li>;
									})}
								</ul>
							</Popup>
							<Form.Field>
								<label htmlFor="">Re-type password</label>
								<input
									className={
										passwordRetypeErrors &&
										passwordRetypeErrors.length > 0
											? 'input-error'
											: ''
									}
									type="password"
									autoComplete="off"
									placeholder="Re-type password here"
									name="verifyPassword"
									// ref={register({
									// 	required: 'Re-type password is required*',
									// 	validate: value =>
									// 		formFields.password === value ||
									// 		'Password did not match*',
									// })}
									ref={passwordRetypeRef}
									onFocus={e => validatePasswordFormat()}
									onBlur={e => validatePasswordRetype()}
									onChange={e =>
										handleChange(
											'verifyPassword',
											e.target.value
										)
									}
								/>
								{/* {errors.verifyPassword && (
						<small className="input-error-message">
							{errors.verifyPassword.message}
						</small>
					)} */}
							</Form.Field>
							<Popup
								context={passwordRetypeRef}
								position="right center"
								open={
									passwordRetypeErrors &&
									passwordRetypeErrors.length > 0
								}
								size="mini"
								wide="very"
								basic
								style={{
									marginLeft: '20px',
									boxShadow: 'none',
									backgroundColor: '#FFCBCB',
									color: '#E55151',
									border: 'none',
								}}
							>
								<ul
									style={{
										listStyleType: 'disc',
										color: '#E55151',
										fontWeight: 'bold',
									}}
									className="password-errors"
								>
									{passwordRetypeErrors.map((error, i) => {
										return <li key={i}>{error}</li>;
									})}
								</ul>
							</Popup>
							<Button
								type="submit"
								className="app-primary-button medium-button"
								// disabled={
								// 	formFields.password.length == 0 ||
								// 	formFields.verifyPassword.length == 0 ||
								// 	passwordErrors.length > 0 ||
								// 	passwordRetypeErrors.length > 0
								// }
							>
								Save
							</Button>
						</Form>
					</Grid.Column>
					<Grid.Column
						mobile="sixteen"
						tablet="tablet"
						computer="eight"
					></Grid.Column>
				</Grid.Row>
			</Grid>

			<Modal
				closeOnEscape={false}
				closeOnDimmerClick={false}
				onClose={() => setOpen(false)}
				onOpen={() => setOpen(true)}
				open={open}
				size="tiny"
				className="app-modal"
				// trigger={<Button>Show Modal</Button>}
			>
				<Modal.Content>
					<img
						src={`${process.env.REACT_APP_BASE_URL}/images/setupservice-complete.png`}
					/>
					<Header as="h3">Password saved!</Header>
					<p style={{ padding: '0 5rem' }}>
						Make sure to not share your password to keep your
						account secured
					</p>
					{/* <Button
						content="Okay"
						className="app-primary-button full-button"
						onClick={() => handleLoginLater(adminRole)}
					/> */}
					{/* <Button
						content="I will login later"
						className="app-primary-inverted-button full-button"
						onClick={() => handleLoginLater()}
					/> */}
				</Modal.Content>
			</Modal>
		</LoginMaster>
	);
};

SetUpPassword.propTypes = {
	history: PropTypes.any,
	match: PropTypes.any,
};

export default SetUpPassword;

// react lib
import React, { useState } from 'react';

import PropTypes from 'prop-types';

// semantic ui component
import { Header, Grid, Form, List } from 'semantic-ui-react';

// components
import CompanyBulkList from './CompanyBulkList';
import CompanyListManually from './CompanyListManually';

const CompanySite = ({
	register,
	site,
	handleOnchange,
	addNewSite,
	removeSiteHandler,
}) => {
	const [value, setValue] = useState('manual');

	return (
		<Grid centered reversed="computer">
			<Grid.Column mobile="sixteen" tablet="fourteen" computer="six">
				<div className="page-guidelines">
					<Header as="h3">
						<img
							src={`${process.env.REACT_APP_BASE_URL}/images/idea.svg`}
							className="idea-img"
						/>{' '}
						<span className="guidelines-txt">Guidelines</span>
					</Header>
					<List bulleted>
						<List.Item>
							Office sites are different branches where G Access
							will be made available
						</List.Item>
						<List.Item>
							Site name here should match the site names listed in
							the Employee list
						</List.Item>
					</List>
				</div>
			</Grid.Column>
			<Grid.Column mobile="sixteen" tablet="fourteen" computer="ten">
				<div className="step-container">
					<Header as="h3">Office sites</Header>
					<p>
						Enter office sites where G Access will be made available
					</p>
					<Form.Group widths="equal">
						<Form.Field>
							<label className="checkcontainer">
								<input
									type="radio"
									name="office-sites"
									value="manual"
									checked={value === 'manual'}
									onClick={() => setValue('manual')}
								/>
								<span>&nbsp;List manually</span>
								<span className="radiobtn"></span>
							</label>
						</Form.Field>
						<Form.Field>
							<label className="checkcontainer">
								<input
									type="radio"
									name="office-sites"
									value="bulk"
									checked={value === 'bulk'}
									onClick={() => setValue('bulk')}
									disabled={true}
								/>
								<span>&nbsp;Upload bulk list</span>
								<span className="radiobtn"></span>
							</label>
						</Form.Field>
					</Form.Group>
					{(() => {
						if (value === 'bulk') {
							return <CompanyBulkList />;
						} else if (value === 'manual') {
							return (
								<CompanyListManually
									site={site}
									handleOnchange={handleOnchange}
									addNewSite={addNewSite}
									register={register}
									removeSiteHandler={removeSiteHandler}
								/>
							);
						}
					})()}
				</div>
			</Grid.Column>
		</Grid>
	);
};

CompanySite.propTypes = {
	handleOnchange: PropTypes.func,
	site: PropTypes.array,
	addNewSite: PropTypes.func,
	removeSiteHandler: PropTypes.func,
	register: PropTypes.object,
};

export default CompanySite;

// react lib
import React from 'react';

// master layout
import LayoutPage from '../CompanyDashboard';

// style
import './EmployeeList.css';

// semantic ui components
import {
	Header,
	Input,
	Form,
	Breadcrumb,
	Grid,
	Button,
	Modal,
} from 'semantic-ui-react';

import PropTypes from 'prop-types';

// components
import Page404 from '../../Page404/Page404';

const ViewEmployee = () => {
	return (
		<LayoutPage>
			<div className="view-employee">
				<Breadcrumb>
					<Breadcrumb.Section link>Employee list</Breadcrumb.Section>
					<Breadcrumb.Divider icon="right chevron" />
					<Breadcrumb.Section active>
						Add new employee
					</Breadcrumb.Section>
				</Breadcrumb>
				<Header as="h1">New employee form</Header>
				<p className="employee-form-subheader">
					<strong>Status</strong>
					<span className="status-info status-info--active">
						Active
					</span>
				</p>
				<Grid>
					<Grid.Column width={11}>
						<div className="employee-form-header">
							<Header as="h3">Employee details</Header>
							<Header as="h3">
								<a href="">Edit</a>
							</Header>
						</div>
						<div className="content-container">
							<Grid>
								<Grid.Row>
									<Grid.Column width={8}>
										<strong>ID Number</strong>
										<div>100121152</div>
									</Grid.Column>
								</Grid.Row>
								<Grid.Row>
									<Grid.Column width={5}>
										<strong>First name</strong>
										<div>Alberto</div>
									</Grid.Column>
									<Grid.Column width={5}>
										<strong>Last name</strong>
										<div>San Miguel</div>
									</Grid.Column>
								</Grid.Row>
								<Grid.Row>
									<Grid.Column width={5}>
										<strong>E-mail address</strong>
										<div>abc_def@globe.com.ph</div>
									</Grid.Column>
									<Grid.Column width={5}>
										<strong>Mobile number</strong>
										<div>0917-688-2345</div>
									</Grid.Column>
								</Grid.Row>
								<Grid.Row>
									<Grid.Column width={16}>
										<hr />
									</Grid.Column>
								</Grid.Row>
								<Grid.Row>
									<Grid.Column width={5}>
										<strong>Date hired</strong>
										<div>Dec 10, 2019</div>
									</Grid.Column>
									<Grid.Column width={5}>
										<strong>Work position</strong>
										<div>User experience designer</div>
									</Grid.Column>
								</Grid.Row>
								<Grid.Row>
									<Grid.Column width={5}>
										<strong>
											Immediate supervisor (IS)
										</strong>
										<div>Michelle Dela Peña</div>
									</Grid.Column>
									<Grid.Column width={5}>
										<strong>IS e-mail address</strong>
										<div>mdelapena@globe.com.ph</div>
									</Grid.Column>
								</Grid.Row>
								<Grid.Row>
									<Grid.Column width={8}>
										<strong>Group</strong>
										<div>Information Systems Group</div>
									</Grid.Column>
								</Grid.Row>
							</Grid>
						</div>
					</Grid.Column>
				</Grid>
			</div>
		</LayoutPage>
	);
};

ViewEmployee.propTypes = {
	history: PropTypes.any,
};

export default ViewEmployee;

import gql from 'graphql-tag';

export const PATCH_CONFIRM_EMAIL = gql`
	mutation($input: inputConfirmEmail) {
		patchConfirmEmail(input: $input) {
			email
			companyCode
			message
			status
		}
	}
`;

export const PATCH_SETUP_PASSWORD = gql`
	mutation($input: inputSetupPassword) {
		patchSetupPassword(input: $input) {
			message
			status
		}
	}
`;

export const FORGOT_PASSWORD = gql`
	mutation($email: String) {
		forgotPassword(email: $email) {
			message
			status
		}
	}
`;

export const CHECK_PASSWORD_RESET_CODE = gql`
	mutation($input: inputUpdatePassword) {
		checkPasswordResetCode(input: $input) {
			message
			status
		}
	}
`;

export const CHANGE_PASSWORD_VIA_EMAIL = gql`
	mutation($input: inputUpdatePassword) {
		changePasswordViaEmail(input: $input) {
			message
			status
		}
	}
`;

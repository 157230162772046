import gql from 'graphql-tag';

export const LOGIN_USER = gql`
	query($input: inputAuthUser, $isMobile: Boolean) {
		signinUser(input: $input, isMobile: $isMobile) {
			user {
				email
				roles {
					id
					code
					name
				}
			}
			picture
			accessToken
			refreshToken
			expiresIn
			message
			status
		}
	}
`;

export const REFETCH_TOKEN = gql`
	query($users_id: EncryptedString!, $googleToken: String) {
		refetchToken(users_id: $users_id, googleToken: $googleToken) {
			user {
				email
				roles {
					id
					code
					name
				}
			}
			accessToken
			expiresIn
		}
	}
`;

// react lib
import React, { useState, useContext, useEffect, useCallback } from 'react';

import PropTypes from 'prop-types';

// react validation form
// import { useForm } from 'react-hook-form';
import useForm from '../../../hooks/useForm';

// less (styling)
import './AccountSetup.css';

// semantic ui component
import { Header, Form, Button } from 'semantic-ui-react';

// master layout
import AdminMaster from '../../../hoc/Layout/Admin/AdminMaster';

// components
import EmployeeList from './EmployeeList/EmployeeList';
import CompanySite from './CompanySite/CompanySite';

// Material UI Stepper
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
// import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

// Apollo Client
import { useMutation, useApolloClient } from '@apollo/client';

// Graphql
import { POST_USERS } from '../graphql/mutation';
import { VERIFY_EMAIL_ADDRESSES } from '../graphql/query';
// helper
import { isEmail, splittedName, stringSanitize } from '../../../utils/index';

// parser context
import ParserContext from '../../../context/ParserContext/ParserContext';
import LoaderContext from '../../../context/LoaderContext/LoaderContext';
import AuthContext from '../../../context/AuthContext/AuthContext';

import jwt_decode from 'jwt-decode';

import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%',
	},
	backButton: {
		marginRight: theme.spacing(1),
	},
	instructions: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
}));

const AccountSetup = props => {
	const decodedUser = jwt_decode(
		window.localStorage.getItem(process.env.REACT_APP_TOKEN_NAME)
	);
	const client = useApolloClient();
	const classes = useStyles();
	const [activeStep, setActiveStep] = React.useState(0);
	const { register } = useForm();

	const {
		fileData,
		setFileError,
		fileError,
		setFileData,
		setExcelFile,
		setFileLoader,
		fileLoader,
	} = useContext(ParserContext);
	const { showLoading, hideLoading } = useContext(LoaderContext);
	const { user, refetchTokenHandler } = useContext(AuthContext);
	const [site, setSite] = useState([
		{
			name: null,
			abbrev: null,
			address: null,
			floor: null,
		},
	]);
	const [siteArr, setSiteArr] = useState([]); // * Site Array
	const [groupArr, setGroupArr] = useState([]); // * Group Array
	const [userArr, setUserArr] = useState([]); // * User Array
	// const [userErrors, setUserErrors] = useState([]);
	const [ISArray, setISArray] = useState([]);

	const [postUsers] = useMutation(POST_USERS);

	// get steps
	const getSteps = useCallback(() => {
		return [<p key={1}>Employee list</p>, <p key={2}>Office sites</p>];
	}, []);

	// get step content
	const getStepContent = useCallback(
		stepIndex => {
			switch (stepIndex) {
				case 0:
					return <EmployeeList />;
				case 1:
					return (
						<CompanySite
							site={site}
							handleOnchange={handleOnchange}
							addNewSite={addNewSite}
							register={register}
							removeSiteHandler={removeSiteHandler}
						/>
					);
				default:
					return 'Unknown stepIndex';
			}
		},
		[site]
	);
	const steps = getSteps();

	const addNewSite = () => {
		setSite([
			...site,
			{
				name: null,
				abbrev: null,
				address: null,
				floor: null,
			},
		]);
	};

	const handleOnchange = ({ key, property, value }) => {
		const arrayForm = [...site];
		const arrayElement = arrayForm[key];
		arrayElement[property] = value;
		arrayForm[key] = arrayElement;
		setSite(arrayForm);
	};

	useEffect(() => {
		if (fileData && fileData.length > 0) {
			formatParsedData();
		}
	}, [fileData]);

	const formatParsedData = async () => {
		let siteArray = [];
		let groupArray = [];
		let users = [];
		let err = null;
		let userIndex = 0;
		let immediateSupervisors = [];
		let countErrors = 0;
		const parsedData = fileData[0].data;
		let duplicateEmails;
		if (fileData && fileData.length > 0) {
			if (parsedData.length <= 1) {
				err = 'File must have at least one row/line data.';
			}

			parsedData.forEach((rows, index) => {
				if (index >= 1) {
					if (rows.length != 12) {
						countErrors += 1;
						err = `We found ${countErrors} line errors on your uploaded file. Please double check that all fields are filled out with valid content`;
					}

					// checking if emails is valid
					else if (!isEmail(rows[4]) || !isEmail(rows[9])) {
						countErrors += 1;
						err = `We found ${countErrors} line errors on your uploaded file. Please double check that all fields are filled out with valid content`;
					}
					// check if employee email and supervisor email is the same
					else if (rows[4] === rows[9]) {
						countErrors += 1;
						err = `We found ${countErrors} line errors on your uploaded file. Please double check that all fields are filled out with valid content`;
					}

					// check empty cells
					else if (
						rows.includes('') ||
						rows.includes(null) ||
						rows.includes(undefined)
					) {
						countErrors += 1;
						err = `We found ${countErrors} line errors on your uploaded file. Please double check that all fields are filled out with valid content`;
					}

					// store user's data on array
					users.push({
						email: stringSanitize(parsedData[index][4]),
						is_email: stringSanitize(parsedData[index][9]),
						profile: {
							idno: parsedData[index][0],
							first_name: parsedData[index][1],
							last_name: parsedData[index][2],
							suffix: parsedData[index][3],
							mobile_no: parsedData[index][5],
							date_hired: parsedData[index][6],
							position: parsedData[index][7],
						},
						group: {
							name: parsedData[index][10],
						},
						site: {
							name: parsedData[index][11],
						},
					});
					// store immediate supervisor to new set of array
					if (
						!immediateSupervisors.some(
							is => is.email === parsedData[index][9]
						)
					) {
						immediateSupervisors.push({
							email: parsedData[index][9],
							profile: {
								first_name:
									parsedData[index][8] &&
									splittedName(parsedData[index][8]).length >
										1
										? splittedName(parsedData[index][8])[0]
										: '',
								last_name:
									parsedData[index][8] &&
									splittedName(parsedData[index][8]).length >
										1
										? splittedName(parsedData[index][8])[1]
										: '',
							},
						});
					}

					// check employee's email if existing to a new set of users

					if (
						users.filter(
							user => user.email === users[userIndex].email
						).length > 1
					) {
						countErrors += 1;
						err = `We found ${countErrors} line errors on your uploaded file. Please double check that all fields are filled out with valid content`;
					}

					// create sets of group & eliminate all duplicate group name
					if (
						!groupArray.some(
							group => group.name === parsedData[index][10]
						)
					) {
						groupArray.push({
							name: parsedData[index][10],
						});
					}

					// create sets of sites & eliminate all duplicate site name
					if (
						!siteArray.some(
							site => site.name === parsedData[index][11]
						)
					) {
						siteArray.push({
							name: parsedData[index][11],
							abbrev: null,
							address: null,
							floor: null,
						});
					}
					userIndex++;
				}
			});
		}
		setUserArr(users);
		setSiteArr(siteArray);
		setGroupArr(groupArray);
		setISArray(immediateSupervisors);
		setFileLoader(true);
		duplicateEmails = await client.query({
			query: VERIFY_EMAIL_ADDRESSES,
			fetchPolicy: 'network-only',
			variables: {
				input: {
					users: users,
					companies_id:
						decodedUser && decodedUser.user
							? decodedUser.user.company.id
							: null,
				},
			},
		});
		setFileLoader(false);
		if (
			duplicateEmails &&
			duplicateEmails.data &&
			!duplicateEmails.data.verifyEmailAddresses.status
		) {
			countErrors += duplicateEmails.data.verifyEmailAddresses.count;
			err = `We found ${countErrors} line errors on your uploaded file. Please double check that all fields are filled out with valid content`;
			setFileData([]);
			setFileError([
				...fileError,
				{
					id: 'employees',
					fileError: `We found ${countErrors} line errors on your uploaded file. Please double check that all fields are filled out with valid content`,
				},
			]);
		}

		if (err) {
			setFileData([]);
			setFileError([
				...fileError,
				{
					id: 'employees',
					fileError: err,
				},
			]);
		}
	};

	// next button on stepper
	const handleNext = async () => {
		if (activeStep === steps.length - 1) {
			let newSites = [];
			site.map(siteData => {
				if (
					!newSites.some(
						newSite =>
							newSite.name.toLowerCase() ===
							siteData.name.toLowerCase()
					)
				) {
					newSites.push(siteData);
				}
			});
			siteArr.map(mySiteArray => {
				if (
					!newSites.some(
						newSite =>
							newSite.name.toLowerCase() ===
							mySiteArray.name.toLowerCase()
					)
				) {
					newSites.push(mySiteArray);
				}
			});

			showLoading(`Saving data...`);

			try {
				await postUsers({
					variables: {
						input: {
							users_id: user.user.id,
							users: userArr,
							groups: groupArr,
							sites: newSites,
							supervisors: ISArray,
							companies_id:
								decodedUser && decodedUser.user
									? decodedUser.user.company.id
									: null,
						},
					},
				});

				// refetched updated user object
				await refetchTokenHandler();

				// store a temporary success message on localStorage
				window.localStorage.setItem(
					'success',
					'Service was successfully added.'
				);

				clearParserData();

				// redirect when it's done
				props.history.push('/companysite');
				hideLoading();
			} catch (err) {
				// setUserErrors(err.graphQLErrors);
				hideLoading();
			}
			// if (user) {
			// 	return <Redirect to="/companysite" />;
			// }
		} else {
			setActiveStep(prevActiveStep => prevActiveStep + 1);
		}
	};

	// clearing parser data
	const clearParserData = () => {
		setFileError([]);
		setFileData([]);
		setExcelFile([]);
	};

	// on click back button on stepper
	const handleBack = () => {
		setActiveStep(prevActiveStep => prevActiveStep - 1);
	};

	// resetting stepper steps
	const handleReset = () => {
		setActiveStep(0);
	};

	// disabled button handler for each steps
	const disableButtonHandler = useCallback(() => {
		if (activeStep === 0) {
			// returns true when line per errors exists and fileData variable has no datas
			return (
				fileError.length > 0 ||
				(fileData && fileData.length <= 0) ||
				fileLoader
			);
		}
		if (activeStep === 1) {
			let emptyVal = [];
			site.map(singleSite => {
				Object.keys(singleSite).map(objectVal => {
					// empty validation for site name
					if (singleSite.name === null || singleSite.name === '') {
						emptyVal.push(singleSite[objectVal]);
					}

					// empty validation for address
					if (
						singleSite.address === null ||
						singleSite.address === ''
					) {
						emptyVal.push(singleSite[objectVal]);
					}
				});
			});

			return emptyVal.length > 0;
		}

		return false;
	}, [fileError, fileData, activeStep, site, fileLoader]);

	// removing site form
	const removeSiteHandler = siteIndex => {
		const updatedSiteForm = [...site];
		updatedSiteForm.splice(siteIndex, 1);
		setSite(updatedSiteForm);
	};
	return (
		<AdminMaster>
			<Form className="account-setup">
				<div className="page-title">
					<Header as="h1">Company setup </Header>
					<p>
						Fill out the fields below to setup your company in G
						Access
					</p>
				</div>
				<div className={clsx('stepper-step-container', classes.root)}>
					<Stepper activeStep={activeStep} alternativeLabel>
						{steps.map(label => (
							<Step key={label}>
								<StepLabel>
									<h5>{label}</h5>
								</StepLabel>
							</Step>
						))}
					</Stepper>
					<div>
						{activeStep === steps.length ? (
							<div>
								<Typography className={classes.instructions}>
									All steps completed
								</Typography>
								<Button onClick={handleReset}>Reset</Button>
							</div>
						) : (
							<div>
								<Typography className={classes.instructions}>
									{getStepContent(activeStep)}
								</Typography>
								<div>
									{activeStep > 0 ? (
										<Button
											disabled={activeStep === 0}
											onClick={handleBack}
											className={clsx(
												'app-primary-inverted-button',
												classes.backButton
											)}
										>
											Previous
										</Button>
									) : (
										<a className="btnLink">
											Save progress and close
										</a>
									)}
									<Button
										variant="contained"
										primary
										className="stepper-btn"
										onClick={handleNext}
										disabled={disableButtonHandler()}
									>
										{activeStep === steps.length - 1
											? 'Finish'
											: 'Next'}
									</Button>
								</div>
							</div>
						)}
					</div>
				</div>
			</Form>
		</AdminMaster>
	);
};

AccountSetup.propTypes = {
	history: PropTypes.any,
};

export default AccountSetup;

import gql from 'graphql-tag';

export const SIGNIN_USER = gql`
	query($input: inputAuthUser) {
		signinUser(input: $input) {
			user {
				email
			}
			accessToken
			expiresIn
			message
		}
	}
`;

export const GET_USER = gql`
	query($input: inputUser) {
		getUser(input: $input) {
			id
			email
			hasSetPassword
			date_confirmed
			roles {
				id
				code
				name
			}
			company {
				name
				code
			}
			message
			status
		}
	}
`;

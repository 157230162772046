import React, { useState, useContext, useEffect, useRef } from 'react';

import LayoutPage from '../../../../hoc/Layout/Admin/AdminMaster';

import PropTypes from 'prop-types';

import { Header, Grid, List, Form, Button } from 'semantic-ui-react';

import Breadcrumbs from '../../../../components/Breadcrumbs/Breadcrumbs';
import AuthContext from '../../../../context/AuthContext/AuthContext';

import useForm from '../../../../hooks/useForm';
import { useMutation, useApolloClient } from '@apollo/client';
import { POST_COMPANY_SITE } from '../../graphql/mutation';
import useDebounce from '../../../../hooks/useDebounce';
import { VERIFY_COMPANY_SITE } from '../../graphql/query';
import CompanySiteManually from './CompanySiteManually';
import CompanySiteBulk from './CompanySiteBulk';
import LoaderContext from '../../../../context/LoaderContext/LoaderContext';

import { checkIfDuplicateExists } from '../../../../utils';

let siteNameIndex = 0;

const NewCompanySite = ({ history, location }) => {
	let message = 'Site name is already used.';
	const siteNameRef = useRef(null);
	const { user } = useContext(AuthContext);
	const { showLoading, hideLoading } = useContext(LoaderContext);
	const client = useApolloClient();
	const { handleSubmit } = useForm();

	const [open, setOpen] = useState(false);
	const [checkSiteName, setCheckSiteName] = useState([]);
	const [sameSite, setSameSite] = useState([]);
	const [selectCompanyForm, setSelectCompanyForm] = useState('manual');
	const [requestLoading, setRequestLoading] = useState(false);
	const [site, setSite] = useState([
		{
			name: null,
			abbrev: null,
			address: null,
			floor: null,
		},
	]);

	const debouncedSiteName = useDebounce(site, 500);

	useEffect(() => {
		verifySite();
	}, [debouncedSiteName]);

	const verifySite = async () => {
		setRequestLoading(true);
		const result = await client.query({
			query: VERIFY_COMPANY_SITE,
			fetchPolicy: 'network-only',
			variables: {
				input: {
					companies_id: user.user.companies_id,
					sites: site,
				},
			},
		});
		setRequestLoading(false);
		setCheckSiteName(result.data.verifySite.arrayMessage);
	};

	const [postCompanySite] = useMutation(POST_COMPANY_SITE);

	const onPostCompanySite = async () => {
		await postCompanySite({
			variables: {
				input: {
					companies_id: user.user.companies_id,
					sites: site,
				},
			},
		});
		hideLoading();
		history.push({
			pathname: '/companysite',
		});
	};

	const onClickProceed = async () => {
		onPostCompanySite();
	};

	const breadcrumbNavigation = breadcrumb => {
		history.push(`/${breadcrumb.link}`, {
			breadcrumbs: history.location.state.breadcrumbs,
		});
	};

	const onCancel = () => {
		history.goBack();
	};

	const addNewSite = () => {
		setSite([
			...site,
			{
				name: null,
				abbrev: null,
				address: null,
				floor: null,
			},
		]);
	};

	const handleOnchange = async ({ key, property, value }) => {
		siteNameIndex = key;
		const arrayForm = [...site];
		const arrayElement = arrayForm[key];
		arrayElement[property] = value;
		arrayForm[key] = arrayElement;
		setSite(arrayForm);
	};
	const removeSiteHandler = (siteIndex, e) => {
		e.preventDefault();
		const updatedSiteForm = [...site];
		updatedSiteForm.splice(siteIndex, 1);
		setSite(updatedSiteForm);
	};

	const handleDisable = () => {
		let emptyVal = [];
		if (requestLoading) {
			return true;
		}

		if (checkIfDuplicateExists(site.map(singleSite => singleSite.name))) {
			return true;
		}

		const result = site.map(findSite => {
			Object.keys(findSite).map(objVal => {
				if (!findSite.name) {
					emptyVal.push(findSite[objVal]);
				}

				if (!findSite.address) {
					emptyVal.push(findSite[objVal]);
				}
			});

			return emptyVal.length > 0;
		});

		const find = result.filter(site => site).length >= 1;

		for (let i = 0; i < checkSiteName.length; i++) {
			if (checkSiteName[i]) {
				return true;
			}
		}

		if (find) {
			return true;
		} else {
			return false;
		}
	};

	return (
		<LayoutPage>
			<Breadcrumbs
				breadcrumbs={[...history.location.state.breadcrumbs]}
				breadcrumbNavigation={breadcrumbNavigation}
			/>
			<Header as="h1">New office site form</Header>{' '}
			<Grid>
				<Grid.Column width={11}>
					<Form onSubmit={handleSubmit(() => setOpen(true))}>
						<div className="content-container employee-form">
							<div className="new-site-header-container ">
								<Header as="h3" className="new-site-title">
									Office sites
								</Header>
								Enter office sites where G Access will be made
								available
								<div className="step-container header-container">
									<Grid>
										<Grid.Column width="six">
											<Form.Group>
												<Form.Field>
													<label className="checkcontainer">
														<input
															type="radio"
															name="office-sites"
															value="manual"
															checked={
																selectCompanyForm ===
																'manual'
															}
															onClick={() =>
																setSelectCompanyForm(
																	'manual'
																)
															}
														/>
														<span>
															&nbsp;List manually
														</span>
														<span className="radiobtn"></span>
													</label>
												</Form.Field>
											</Form.Group>
										</Grid.Column>
										<Grid.Column width="six">
											<Form.Group widths="equal">
												<Form.Field>
													<label className="checkcontainer">
														<input
															type="radio"
															name="office-sites"
															value="bulk"
															checked={
																selectCompanyForm ===
																'bulk'
															}
															disabled={true}
															onClick={() =>
																setSelectCompanyForm(
																	'bulk'
																)
															}
														/>
														<span>
															&nbsp;Upload bulk
															list
														</span>
														<span className="radiobtn"></span>
													</label>
												</Form.Field>
											</Form.Group>
										</Grid.Column>
									</Grid>
								</div>
								{(() => {
									if (selectCompanyForm === 'bulk') {
										return <CompanySiteBulk />;
									} else if (selectCompanyForm === 'manual') {
										return (
											<CompanySiteManually
												open={open}
												setOpen={setOpen}
												site={site}
												removeSiteHandler={
													removeSiteHandler
												}
												siteNameRef={siteNameRef}
												sameSite={sameSite}
												checkSiteName={checkSiteName}
												handleOnchange={handleOnchange}
												message={message}
												onClickProceed={onClickProceed}
												addNewSite={addNewSite}
												showLoading={showLoading}
											/>
										);
									}
								})()}
							</div>
						</div>
						<div className="employee-form-actions">
							<Button
								onClick={() => onCancel()}
								className="app-primary-inverted-button medium-button"
							>
								Cancel
							</Button>
							<Button
								className="app-primary-button medium-button"
								disabled={handleDisable()}
							>
								Save
							</Button>
						</div>
					</Form>
				</Grid.Column>
				<Grid.Column width={4}>
					<div className="page-guidelines">
						<Header as="h3">
							<img
								src={`${process.env.REACT_APP_BASE_URL}/images/idea.svg`}
								className="idea-img"
							/>{' '}
							<span className="guidelines-txt">Guidelines</span>
						</Header>
						<List bulleted>
							<List.Item>
								All company sites are different office locations
								where G Access will be made available
							</List.Item>
							<List.Item>
								Site name here should match the site names
								listed in the Employee list
							</List.Item>
						</List>
					</div>
				</Grid.Column>
			</Grid>
		</LayoutPage>
	);
};

NewCompanySite.propTypes = {
	history: PropTypes.any,
	location: PropTypes.any,
};

export default NewCompanySite;

import React from 'react';

import './SetupPreview.css';
import { Grid, Tab, Form } from 'semantic-ui-react';

import PropTypes from 'prop-types';

import { ServiceTypes } from '../../models/ServiceTypes';

const originIcon = process.env.REACT_APP_BASE_NAME + 'svgs/origin.svg';
const destinationIcon =
	process.env.REACT_APP_BASE_NAME + 'svgs/destination.svg';

const SetupPreview = ({ form, activeStep, serviceType, site }) =>
	React.useMemo(() => {
		const activeTab = activeStep === 1 ? 1 : 0;
		const panes = [
			{
				menuItem:
					serviceType.name === 'Shuttle'
						? 'Select route'
						: 'Booking Form',
				render: () => (
					<Tab.Pane attached={false}>
						<BookingForm
							form={form}
							activeStep={activeStep}
							serviceType={serviceType}
							site={site}
						/>
					</Tab.Pane>
				),
			},
			{
				menuItem: 'Policies',
				render: () => (
					<Tab.Pane attached={false}>
						<Policies form={form} activeStep={activeStep} />
					</Tab.Pane>
				),
			},
		];

		return (
			<section className="preview-container">
				<h5 className="preview-container-title">Preview</h5>
				<p className="preview-container-text">
					Below is how your service will look in app:
				</p>
				<div className="setup-preview">
					<div className="setup-preview-header"></div>
					<div className="setup-preview-body">
						<div className="setup-preview-title">
							{form.service.name ? form.service.name : 'Workdesk'}
						</div>
						<div className="setup-preview-text">
							{form.service.description ? (
								form.service.description
							) : (
								<div className="setup-preview-text">
									Enter your short description
								</div>
							)}
						</div>
						<div className="setup-preview-tab">
							<Tab panes={panes} activeIndex={activeTab} />
						</div>
					</div>
				</div>
			</section>
		);
	}, [
		activeStep,
		form.service.name,
		form.service.description,
		form.serviceable,
		form.policy,
		serviceType,
	]);

const BookingForm = ({ form, serviceType, site }) => {
	const slotType = () => {
		return ServiceTypes[serviceType.name]
			? ServiceTypes[serviceType.name].slotType1
			: null;
	};

	return serviceType.name === 'Shuttle' ? (
		<div className="setup-preview-tab-content">
			<div>
				<div className="service-preview-item">
					<Grid>
						<Grid.Row>
							<Grid.Column width={2} className="p-0">
								<div>
									<img
										src={originIcon}
										className="origin-icon"
									/>
								</div>
								<div className="border-dotted" />
								<div>
									<img
										src={destinationIcon}
										className="destination-icon"
									/>
								</div>
							</Grid.Column>
							<Grid.Column width={14}>
								<Grid.Column width={16}>
									<Form.Field>
										<Form.Input
											label="&nbsp;"
											placeholder="Select pick-up "
											readOnly
											icon="angle down"
										/>
									</Form.Field>
								</Grid.Column>
								<Grid.Column width={16}>
									<Form.Field>
										<Form.Input
											label="&nbsp;"
											placeholder="Select pick-up "
											readOnly
											icon="angle down"
										/>
									</Form.Field>
								</Grid.Column>
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</div>
			</div>
			<a className="setup-preview-button ui button">Book a shuttle</a>
		</div>
	) : (
		<div className="setup-preview-tab-content">
			{form.serviceable.is_whole_day !== 0 &&
			form.serviceable.is_whole_day !== null ? (
				<div className="service-preview-item">
					You are booking a{' '}
					{ServiceTypes[serviceType.name]
						? ServiceTypes[serviceType.name].slotType2
						: null}{' '}
					for the whole day of <strong>[date]</strong>
					{serviceType.name === 'Parking' &&
					form.serviceable.with_pay ? (
						<div className="my-1">
							Parking slot fee: P
							{form.serviceable.amt ? form.serviceable.amt : 0}
						</div>
					) : null}
				</div>
			) : null}
			{(() => {
				switch (serviceType.name) {
					case 'Parking':
						return (
							form.serviceable.is_whole_day == 0 && (
								<div className="service-preview-item">
									You&apos;re currently in{' '}
									<b>{site[0].name}</b>
									<br />
									<span>Book a parking slot in</span>
								</div>
							)
						);
				}
			})()}
			<div className="service-preview-item">
				<Form.Field>
					{(() => {
						switch (serviceType.name) {
							case 'Parking':
								return (
									<Form.Input
										label="Parking area"
										placeholder="Select parking area"
										readOnly
										icon="angle down"
									/>
								);
							case 'Workdesk':
							case 'Cafeteria':
								return (
									<Form.Input
										label="Office site"
										placeholder="Select office site"
										readOnly
										icon="angle down"
									/>
								);
						}
					})()}
				</Form.Field>
			</div>
			{form.serviceable.is_whole_day !== null ? (
				<div>
					<div className="service-preview-item">
						<Grid>
							<>
								<Grid.Column width={9}>
									<Form.Field>
										<Form.Input
											label="Booking schedule"
											placeholder="Select date"
											readOnly
											icon="calendar outline"
										/>
									</Form.Field>
								</Grid.Column>
								<Grid.Column width={7}>
									<Form.Field>
										<Form.Input
											label="&nbsp;"
											placeholder={
												form.serviceable.is_whole_day ==
												0
													? 'Morning'
													: 'Wholeday'
											}
											readOnly
											icon="angle down"
										/>
									</Form.Field>
								</Grid.Column>
							</>
						</Grid>
					</div>
					<div className="service-preview-item">
						<label htmlFor="">Available time slots:</label>
						<div className="time-slots">
							<div className="time-slots-item">
								[time]
								<div className="service-preview-text-small">
									# {slotType()}s available
								</div>
							</div>
							<div className="time-slots-item">
								[time]
								<div className="service-preview-text-small">
									# {slotType()}s available
								</div>
							</div>
							<div className="time-slots-item">
								[time]
								<div className="service-preview-text-small">
									# {slotType()}s available
								</div>
							</div>
							<div className="time-slots-item">
								[time]
								<div className="service-preview-text-small">
									# {slotType()}s available
								</div>
							</div>
						</div>
					</div>
				</div>
			) : null}
			<a className="setup-preview-button ui button">
				Book a{' '}
				{ServiceTypes[serviceType.name]
					? ServiceTypes[serviceType.name].slotType2
					: null}
			</a>
		</div>
	);
};

const Policies = ({ form }) => {
	return (
		<div
			className="setup-preview-tab-content"
			style={{
				maxHeight: '400px',
				overflowY: 'auto',
				wordBreak: 'break-all',
			}}
		>
			<div className="service-preview-item">
				<div className="service-preview-item-title">
					{form.policy.title ? form.policy.title : 'Title goes here'}
				</div>
				<div
					className="service-preview-item-content ql-editor overflow-none"
					dangerouslySetInnerHTML={{
						__html:
							form.policy && form.policy.content
								? form.policy.content
								: 'Content goes here',
					}}
					style={{ padding: '0' }}
				></div>
			</div>
		</div>
	);
};

SetupPreview.propTypes = {
	form: PropTypes.object,
	activeStep: PropTypes.number,
	serviceType: PropTypes.object,
	site: PropTypes.array,
};

Policies.propTypes = {
	form: PropTypes.object,
	activeStep: PropTypes.number,
};

BookingForm.propTypes = {
	form: PropTypes.object,
	activeStep: PropTypes.number,
	serviceType: PropTypes.object,
	site: PropTypes.array,
};

export default SetupPreview;

import PropTypes from 'prop-types';
import React, { createContext, useState, useContext, useEffect } from 'react';
import * as XLSX from 'xlsx';

import LoaderContext from '../LoaderContext/LoaderContext';

const ParserContext = createContext({});

export const ParserProvider = ({ children }) => {
	const { showLoading, hideLoading } = useContext(LoaderContext);
	const [fileData, setFileData] = useState([]);
	const [excelFile, setExcelFile] = useState([]);
	const [fileError, setFileError] = useState([]);
	const [error, setError] = useState([]);
	const [fileLoader, setFileLoader] = useState(false);

	// clean up
	useEffect(() => {
		return () => {
			setError([]);
			setExcelFile([]);
			setFileData([]);
		};
	}, []);

	// file reader
	const reader = new FileReader();

	const parserHandler = (file, id = null) => {
		showLoading(`Parsing Data...`);
		reader.readAsBinaryString(file);
		reader.onload = evt => {
			// evt = on_file_select event
			/* Parse data */
			const bstr = evt.target.result;
			const wb = XLSX.read(bstr, { type: 'binary' });
			/* Get first worksheet */
			const wsname = wb.SheetNames[0];
			const ws = wb.Sheets[wsname];
			/* Convert array of arrays */
			const data = XLSX.utils.sheet_to_json(ws, {
				header: 1,
				blankrows: false,
				raw: false,
			});

			// getting index of parsed data
			const dataIndex = fileData.findIndex(data => data.id === id);

			// add file data to array when no index found
			if (dataIndex < 0) {
				setFileData([
					...fileData,
					{
						id,
						data,
					},
				]);
			} else {
				const updatedFileData = [...fileData];
				updatedFileData[dataIndex] = {
					id,
					data,
				};
				setFileData(updatedFileData);
			}
			hideLoading();
		};
	};

	return (
		<ParserContext.Provider
			value={{
				parserHandler,
				fileData,
				setFileData,
				excelFile,
				setExcelFile,
				fileError,
				setFileError,
				error,
				setError,
				fileLoader,
				setFileLoader,
			}}
		>
			{children}
		</ParserContext.Provider>
	);
};

ParserProvider.propTypes = {
	children: PropTypes.any,
};

export default ParserContext;

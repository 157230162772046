import gql from 'graphql-tag';

// get all sites
export const GET_SITES = gql`
	query($companies_id: EncryptedString!, $filter: String, $limit: Int) {
		getSites(companies_id: $companies_id, filter: $filter, limit: $limit) {
			id
			employee_count
			site_services_count
			name
			abbrev
			address
			floor
			latitude
			longitude
			date_created
			enabled
			employees {
				id
			}
			company {
				id
				name
				code
				date_created
			}
		}
	}
`;

// get all enable service types
export const GET_SERVICE_TYPES = gql`
	query {
		getServiceTypes {
			id
			name
			status
			enabled
		}
	}
`;

// get all enable service types
export const GET_SITE_SERVICETYPES = gql`
	query($sites_id: EncryptedString!) {
		getSiteServiceTypes(sites_id: $sites_id) {
			id
			name
			status
			enabled
		}
	}
`;

/**
	* get all users within the company
	@param {companies_id}
*/
export const GET_COMPANY_USERS = gql`
	query(
		$companies_id: EncryptedString!
		$users_id: EncryptedString
		$filter: String
	) {
		getCompanyUsers(
			companies_id: $companies_id
			users_id: $users_id
			filter: $filter
		) {
			id
			email
			profile {
				id
				first_name
				last_name
				mobile_no
			}
		}
	}
`;

export const GET_SITE = gql`
	query($input: inputSite) {
		getSite(input: $input) {
			name
			abbrev
			address
			floor
			latitude
			longitude
			employee_count
			employees {
				user {
					id
				}
			}
		}
	}
`;

//get services tagged per site
export const GET_SITE_SERVICE = gql`
	query($input: inputSiteService) {
		getService(input: $input) {
			id
			enabled
			serviceable_id
			user {
				email
				profile {
					first_name
					last_name
					suffix
					mobile_no
				}
			}
			service_type {
				id
				name
			}
		}
	}
`;

export const GET_SERVICE_TYPE_BY_SERVICE_INSTANCE_ID = gql`
	query getServiceTypeByServiceInstancesId(
		$service_instances_id: EncryptedString!
	) {
		getServiceTypeByServiceInstancesId(
			service_instances_id: $service_instances_id
		) {
			service_type {
				id
				name
			}
			user {
				id
				email
				profile {
					id
					first_name
					last_name
					mobile_no
				}
			}
		}
	}
`;

export const GET_COMPANY_EMPLOYEES = gql`
	query($input: inputCompanyEmployees, $paginate: paginate) {
		getCompanyEmployees(input: $input, paginate: $paginate) {
			employees {
				id
				email
				status
				profile {
					idno
					first_name
					last_name
					suffix
					mobile_no
					date_hired
					position
				}
				supervisor {
					email
					profile {
						first_name
						last_name
						suffix
					}
				}
				company {
					id
					name
				}
				employee {
					id
					group {
						id
						name
					}
					site {
						id
						name
					}
				}
			}
			total_employees
			total_active
			total_suspended
			total_count
		}
	}
`;

// check email employee if existing
export const VERIFY_EMPLOYEE = gql`
	query($input: inputVerifyEmployee) {
		verifyEmployee(input: $input) {
			idnoMessage
			emailMessage
		}
	}
`;

//check if company site if existing
export const VERIFY_COMPANY_SITE = gql`
	query($input: inputCompanySite) {
		verifySite(input: $input) {
			message
			arrayMessage
		}
	}
`;

// react lib
import { useApolloClient, useMutation } from '@apollo/client';
import * as moment from 'moment';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import FocusLock from 'react-focus-lock';
// semantic ui components
import {
	Button,
	Dropdown,
	Form,
	Grid,
	Header,
	Icon,
	Message,
	Modal,
	Popup,
	Select,
} from 'semantic-ui-react';
import FileUpload from '../../../../components/FileUpload/FileUpload';
import ParkingPDFQRCode from '../../../../components/PDFQR/ParkingPDFQRCode';
// pdf qrcode
import PDFQRCode from '../../../../components/PDFQR/PDFQRCode';
// component
import Table from '../../../../components/Table/Table';
import LoaderContext from '../../../../context/LoaderContext/LoaderContext';
import ParserContext from '../../../../context/ParserContext/ParserContext';
import { ServiceTypes } from '../../../../models/ServiceTypes';
// pdf helper
import { mapOrder, savePdf } from '../../../../utils';
import {
	DELETE_BOOKING_SLOT,
	PATCH_BOOKING_SLOT_STATE,
	POST_SINGLE_WORKDESK_SLOT,
} from '../../graphql/mutation';
import { GET_SLOT_QRCODES, GET_WORKDESK_SLOTS } from '../../graphql/query';
// less styling
import './Desks.css';

const successImg =
	process.env.REACT_APP_BASE_NAME + 'images/setupservice-complete.png';
const downloadImg = process.env.REACT_APP_BASE_NAME + 'svgs/download.svg';
const addIcon = process.env.REACT_APP_BASE_NAME + 'svgs/more-white.svg';
const searchImg = process.env.REACT_APP_BASE_NAME + 'svgs/search.svg';
const errorAlert = process.env.REACT_APP_BASE_NAME + 'svgs/light-alert.svg';

const state = [
	{ key: 'enabled', text: 'Enabled', value: '1' },
	{ key: 'disabled', text: 'Disabled', value: '0' },
];

const status = [
	{ key: 'occupied', text: 'Occupied', value: '1' },
	{ key: 'free', text: 'Free', value: '0' },
];

const Desks = ({
	slots,
	floors,
	areas,
	filter,
	handleChangeFilter,
	props,
	parseDesk,
	getWorkdeskSlots,
	postWorkdeskSlotHandler,
	paginate,
	setPaginate,
	onPageChange,
	service,
	checkedWorkdesks,
	setCheckedWorkdesks,
	workdeskSort,
	onFilterFloorsDropdowwn,
	floorLoading,
	setBulkModalAlert,
	bulkModalAlert,
	bulkUploadModal,
	setBulkUploadModal,
	onFilterAreasDropdowwn,
	areaLoading,
	setFileToUpload,
	getSiteFloors,
	getServiceInstanceArea,
	searchFloorString,
	setSearchFloorString,
}) => {
	const client = useApolloClient();
	const { showLoading, hideLoading } = useContext(LoaderContext);
	const {
		fileError,
		setFileData,
		setFileError,
		excelFile,
		error: excelError,
	} = useContext(ParserContext);

	useEffect(() => {
		setFileToUpload(excelFile.length ? excelFile[0].file.name : '');
	}, [excelFile]);

	const [workdeskIDs, setWorkdeskIDs] = useState([]);
	// const [firstOpenBulk, setFirstOpenBulk] = useState(false);

	const [firstOpenSingle, setFirstOpenSingle] = useState(false);
	const [secondOpenSingle, setSecondOpenSingle] = useState(false);
	const [actionModal, setActionModal] = useState(false);
	const [confirmationModal, setConfirmationModal] = useState(false);

	const [selectedFloor, setSelectedFloor] = useState('');
	const [formSlot, setFormSlot] = useState({
		name: '',
		area: '',
		floor_name: '',
	});

	const [slotData, setSlotData] = useState({
		state: null,
		id: '',
		enabled: null,
		name: '',
	});

	useEffect(() => {
		const updatedWorkdeskIDs = slots.slots.map(slot => slot.id);
		setWorkdeskIDs([...workdeskIDs, ...updatedWorkdeskIDs]);
	}, [slots.slots]);

	const [postError, setPostError] = useState({});
	const handleChangeFormSlot = (key, value) => {
		setFormSlot({
			...formSlot,
			[key]: value,
		});
	};
	const sort_obj = Object.keys(workdeskSort).map(sortKey => {
		if (workdeskSort[sortKey] !== null) {
			return {
				column: sortKey,
				order: workdeskSort[sortKey] ? 'asc' : 'desc',
			};
		}
	});

	const [postSingleWorkdeskSlot] = useMutation(POST_SINGLE_WORKDESK_SLOT, {
		variables: {
			service_instances_id:
				props.location.state.site[0].service_instances_id,
			input: formSlot,
		},
		onError: () => {
			hideLoading();
		},
		awaitRefetchQueries: true,
		refetchQueries: [
			{
				query: GET_WORKDESK_SLOTS,
				fetchPolicy: 'network-only',
				variables: {
					input: {
						...filter,
						service_instances_id:
							props.location.state.site[0].service_instances_id,
						area: filter.area === 0 ? null : filter.area,
						floors_id:
							filter.floors_id === 0 ? null : filter.floors_id,
						state: filter.state === 0 ? null : filter.state,
						booking_status:
							filter.booking_status === 0
								? null
								: filter.booking_status,
					},
					sort: sort_obj[0],
					paginate,
				},
			},
		],
		onCompleted: async data => {
			// 1 or true value should go here
			if (data.postSingleWorkdeskSlot.status) {
				setFirstOpenSingle(false);

				getWorkdeskSlots().then(() => {
					setFormSlot({
						...formSlot,
						name: '',
						area: '',
						floor_name: '',
					});

					setSearchQuery('');
					setSearchFloorString('');
				});
				getSiteFloors();
				getServiceInstanceArea();
				setPostError({});
				setSecondOpenSingle(true);
				setTimeout(() => {
					setSecondOpenSingle(false);
				}, 2000);
			} else {
				setPostError({ ...data.postSingleWorkdeskSlot });
			}
			hideLoading();
		},
	});
	const [patchBookingSlotState] = useMutation(PATCH_BOOKING_SLOT_STATE, {
		variables: {
			input: {
				id: slotData.id,
				state: slotData.state,
			},
		},
		onError: () => {},
		awaitRefetchQueries: true,
		refetchQueries: [
			{
				query: GET_WORKDESK_SLOTS,
				fetchPolicy: 'network-only',
				variables: {
					input: {
						...filter,
						service_instances_id:
							props.location.state.site[0].service_instances_id,
						area: filter.area === 0 ? null : filter.area,
						floors_id:
							filter.floors_id === 0 ? null : filter.floors_id,
						state: filter.state === 0 ? null : filter.state,
						booking_status:
							filter.booking_status === 0
								? null
								: filter.booking_status,
					},
					sort: sort_obj[0],
					paginate,
				},
			},
		],
		onCompleted: async () => {
			getWorkdeskSlots().then(() => {
				setActionModal(false);
				setConfirmationModal(true);
				setTimeout(() => {
					setConfirmationModal(false);
				}, 2000);
				hideLoading();
			});
		},
	});
	const [deleteBookingSlot] = useMutation(DELETE_BOOKING_SLOT, {
		variables: {
			input: {
				id: slotData.id,
				enabled: slotData.enabled,
			},
		},
		onError: () => {},
		awaitRefetchQueries: true,
		refetchQueries: [
			{
				query: GET_WORKDESK_SLOTS,
				fetchPolicy: 'network-only',
				variables: {
					input: {
						...filter,
						service_instances_id:
							props.location.state.site[0].service_instances_id,
						area: filter.area === 0 ? null : filter.area,
						floors_id:
							filter.floors_id === 0 ? null : filter.floors_id,
						state: filter.state === 0 ? null : filter.state,
						booking_status:
							filter.booking_status === 0
								? null
								: filter.booking_status,
					},
					sort: sort_obj[0],
					paginate,
				},
			},
		],
		onCompleted: async () => {
			getWorkdeskSlots().then(() => {
				setActionModal(false);
				setConfirmationModal(true);
				setTimeout(() => {
					setConfirmationModal(false);
				}, 2000);
				setSearchFloorString('');
				getSiteFloors();
				hideLoading();
				if (slots.slots.length <= 1) {
					setPaginate({
						page: 1,
						offset: 10,
					});
				}
			});
		},
	});

	const onClickState = (e, slot) => {
		if (slot.state === 2) {
			e.preventDefault();
		}
		setSlotData({
			...slotData,
			state: slot.state === 1 ? 0 : 1,
			id: slot.id,
			name: slot.name,
			enabled: null,
		});
		setActionModal(true);
	};

	const onClickDelete = (e, slot) => {
		if (slot.enabled === 2) {
			e.preventDefault();
		}
		setSlotData({
			...slotData,
			enabled: 0,
			id: slot.id,
			name: slot.name,
		});
		setActionModal(true);
		setSearchFloorString('');
	};

	const onClickProceed = () => {
		if (slotData.enabled === 0) {
			deleteBookingSlot();
		} else {
			patchBookingSlotState();
		}
		showLoading('Updating record...');
	};

	const onClickCancel = () => {
		setSlotData({
			...slotData,
			state: null,
			id: '',
			enabled: null,
			name: '',
		});
		setActionModal(false);
	};

	// onchange check all desk handler
	const onCheckAllHandler = evt => {
		if (evt.target.checked) {
			const newCheckedWorkdesks = slots.slots.filter(slot => {
				return !checkedWorkdesks.some(
					workdesk => slot.id === workdesk.id
				);
			});
			const orderedArray = mapOrder(
				[...checkedWorkdesks, ...newCheckedWorkdesks],
				workdeskIDs,
				'id'
			);
			setCheckedWorkdesks([...orderedArray]);
		} else {
			const newCheckedWorkdesks = slots.slots.filter(slot => {
				return !checkedWorkdesks.some(
					workdesk => slot.id === workdesk.id
				);
			});
			setCheckedWorkdesks(newCheckedWorkdesks);
		}
	};

	// onCLick bulk print qr
	const onPrintQRCode = async (single_slot = null) => {
		showLoading('Printing QR Codes...');
		const serviceSite = props.location.state.site[0];
		const filename = !single_slot
			? `${service.service_type.name}QR_${
					serviceSite.abbrev
			  }_${moment().format('MMDDYY')}-${moment().format('HHmm')}`
			: `${service.service_type.name}QR_${serviceSite.abbrev}_${serviceSite.name}`;
		const inputSlotsIds = single_slot
			? [
					{
						id: single_slot.id,
						serviceName: single_slot.name,
					},
			  ]
			: checkedWorkdesks.map(workdesk => {
					return {
						id: workdesk.id,
						serviceName: workdesk.name,
					};
			  });
		const qrcodes = await client.query({
			query: GET_SLOT_QRCODES,
			variables: {
				inputSlotsIds,
			},
		});
		if (service.service_type && service.service_type.name === 'Parking') {
			await savePdf(
				<ParkingPDFQRCode
					slots={qrcodes.data.getSlotQRCode}
					service={service.service_type.name}
				/>,
				`${filename}.pdf`
			);
		} else {
			await savePdf(
				<PDFQRCode
					slots={qrcodes.data.getSlotQRCode}
					service={service.service_type.name}
				/>,
				`${filename}.pdf`
			);
		}
		hideLoading();
	};

	const onCheckDeskHandler = (evt, slot) => {
		if (evt.target.checked) {
			const updatedWorkdesks = [...checkedWorkdesks, { ...slot }];
			const orderedArray = mapOrder(updatedWorkdesks, workdeskIDs, 'id');
			setCheckedWorkdesks(orderedArray);
		} else {
			setCheckedWorkdesks(prevWorkdesks => {
				return prevWorkdesks.filter(
					workdesk => workdesk.id !== slot.id
				);
			});
		}
	};

	const clearForm = () => {
		setFileData([]);
		setFileError([]);
		setPostError({});
	};

	const handleCancelSingleAdd = () => {
		setFirstOpenSingle(false);
		setFormSlot({
			...formSlot,
			name: '',
			area: '',
			floor_name: '',
		});

		setSearchQuery('');
		setSearchFloorString('');
	};

	const [searchQuery, setSearchQuery] = useState('');

	const handleSearchChange = (e, { searchQuery }) => {
		setSearchQuery(searchQuery);
		setFormSlot({
			...formSlot,
			floor_name: searchQuery,
		});
		onFilterFloorsDropdowwn(e);
	};

	const disableSaveButtonHandler = useCallback(() => {
		if (
			formSlot.floor_name == null ||
			formSlot.floor_name == '' ||
			formSlot.name == null ||
			formSlot.name == '' ||
			formSlot.area == null ||
			formSlot.area == ''
		) {
			return true;
		}

		return false;
	}, [formSlot]);

	const parseFloor =
		parseDesk.length >= 1
			? parseDesk[0].floors.map(item => {
					return { floor: item.name + ' ' };
			  })
			: [];

	const uniqueFloor = [
		...new Map(parseFloor.map(item => [item.floor, item])).values(),
	];

	const renderFloor = uniqueFloor.map(item => {
		return item.floor;
	});

	const header = [
		...((service && service.is_qr_checkin) ||
		(service && service.is_qr_checkout)
			? [
					{
						name: (
							<>
								<label className="checkcontainer booking-checkbox">
									<input
										type="checkbox"
										onChange={evt => onCheckAllHandler(evt)}
										checked={
											slots.slots.length ===
												slots.slots.filter(slot => {
													return checkedWorkdesks.some(
														workdesk =>
															workdesk.id ===
															slot.id
													);
												}).length &&
											checkedWorkdesks.length !== 0
										}
									/>
									<span className="checkmark"></span>
								</label>
							</>
						),
						width: 2,
					},
			  ]
			: []),
		ServiceTypes[service.service_type.name].site_dashboard_column_id,
		ServiceTypes[service.service_type.name].site_dashboard_column_name,
		'Floor',
		'Area',
		'State',
		'Booking status',
		'Action',
	];

	const slot_datas = slots.slots
		? slots.slots.map(slot => {
				return {
					...((service && service.is_qr_checkin) ||
					(service && service.is_qr_checkout)
						? {
								checkbox: {
									data: (
										<>
											<label className="checkcontainer booking-checkbox">
												<input
													type="checkbox"
													id={slot.id}
													checked={checkedWorkdesks.some(
														workdesk =>
															workdesk.id ===
															slot.id
													)}
													onChange={evt =>
														onCheckDeskHandler(
															evt,
															slot
														)
													}
												/>
												<span className="checkmark"></span>
											</label>
										</>
									),
									options: {
										width: 1,
									},
								},
						  }
						: {}),
					workdeskID: {
						data: slot.workdeskID,
						options: {
							width: 2,
						},
					},
					name: {
						data: slot.name,
						options: {
							width: 2,
						},
					},
					floor: {
						data: slot.floor.name,
						options: {
							width: 2,
						},
					},
					area: {
						data: slot.area,
						options: {
							width: 2,
						},
					},
					state: {
						data:
							slot.state === 1 || slot.state === 2
								? 'Enabled'
								: 'Disabled',
						options: {
							width: 2,
						},
					},
					booking_status: {
						data: (
							<>
								<Popup
									disabled={true}
									trigger={
										<div className="status-row status-div">
											<div
												className={`status-badge ${
													slot.state === 1
														? slot.booking_status ===
														  1
															? 'status-occupied'
															: 'status-free'
														: '-'
												}`}
											></div>
											<span className="status-text">
												{slot.state === 1
													? slot.booking_status === 1
														? 'Occupied'
														: 'Free'
													: '-'}
											</span>
										</div>
									}
								/>
							</>
						),
						options: {
							width: 2,
						},
					},
					action: {
						data: (
							<>
								{(service && service.is_qr_checkin) ||
								(service && service.is_qr_checkout) ? (
									<a
										className="btnLink cursor-pointer custom-margin-right"
										onClick={() => onPrintQRCode(slot)}
									>
										Print QR
									</a>
								) : null}
								<a
									className={`${
										slot.state === 2
											? 'btnLink-disable custom-margin-right'
											: 'btnLink custom-margin-right'
									}`}
									onClick={e =>
										slot.state === 2
											? null
											: onClickState(e, slot)
									}
								>
									{slot.state === 1 || slot.state === 2
										? 'Disable'
										: 'Enable'}
								</a>
								<a
									className="btnLink"
									onClick={e =>
										slot.enabled === 2
											? // ||
											  // service.service_type.name == 'Cafeteria'
											  null
											: onClickDelete(e, slot)
									}
								>
									Delete
								</a>
							</>
						),
						options: {
							width: 1,
						},
					},
				};
		  })
		: [];

	const [floorSearchQuery, setFloorSearchQuery] = useState('');
	const [areaSearchQuery, setAreaSearchQuery] = useState('');

	const handleFloorSearchChange = (e, { searchQuery }) => {
		setFloorSearchQuery(searchQuery);
		onFilterFloorsDropdowwn(e);
	};

	const handleAreaSearchChange = (e, { searchQuery }) => {
		setAreaSearchQuery(searchQuery);
		onFilterAreasDropdowwn(e);
	};

	return (
		<div className="site-item">
			<div className="table-list">
				<div className="table-list-filter workdesks-filter">
					<Form>
						<Grid>
							<Grid.Row>
								<Grid.Column
									mobile={16}
									tablet={12}
									computer={10}
								>
									<Form.Group inline>
										<label>Showing</label>
										<Form.Field className="custom-width-filter">
											<Dropdown
												className="slot-search-filter"
												icon={
													floorLoading ? (
														<div className="sk-chase search-loader">
															<div className="sk-chase-dot"></div>
															<div className="sk-chase-dot"></div>
															<div className="sk-chase-dot"></div>
															<div className="sk-chase-dot"></div>
															<div className="sk-chase-dot"></div>
															<div className="sk-chase-dot"></div>
														</div>
													) : (
														'angle down'
													)
												}
												selectOnBlur={false}
												forceSelection={false}
												iconPosition="right"
												placeholder="Floor #"
												{...(filter.floors_id === null
													? {
															text: 'Floor #',
													  }
													: {})}
												// clearable
												fluid
												search
												selection
												options={[
													{
														id: 'all',
														text: 'All floors',
														value: '0',
														selected: false,
													},
													...floors,
												]}
												value={
													filter.floors_id
														? filter.floors_id
														: null
												}
												onSearchChange={
													handleFloorSearchChange
												}
												{...(filter.floors_id
													? {
															searchQuery: floorSearchQuery,
													  }
													: {})}
												onChange={(e, { value }) => {
													handleChangeFilter(
														'floors_id',
														value
													);

													setFloorSearchQuery(
														value == '0'
															? ''
															: e.target
																	.textContent
													);
												}}
											></Dropdown>
										</Form.Field>
										<Form.Field className="custom-width-filter">
											<Dropdown
												className="slot-search-filter"
												icon="angle down"
												selectOnBlur={false}
												forceSelection={false}
												iconPosition="right"
												placeholder="Select area"
												{...(filter.area === null
													? {
															text: 'Select area',
													  }
													: {})}
												// clearable
												fluid
												search
												selection
												options={areas}
												value={
													filter.area
														? filter.area
														: null
												}
												onSearchChange={
													handleAreaSearchChange
												}
												{...(filter.area
													? {
															searchQuery: areaSearchQuery,
													  }
													: {})}
												onChange={(e, { value }) => {
													handleChangeFilter(
														'area',
														value
													);
													setAreaSearchQuery(
														value == 'all'
															? ''
															: e.target
																	.textContent
													);
												}}
											>
												{areaLoading ? (
													<div className="sk-chase search-loader">
														<div className="sk-chase-dot"></div>
														<div className="sk-chase-dot"></div>
														<div className="sk-chase-dot"></div>
														<div className="sk-chase-dot"></div>
														<div className="sk-chase-dot"></div>
														<div className="sk-chase-dot"></div>
													</div>
												) : null}
											</Dropdown>
										</Form.Field>
										<Form.Field>
											<Form.Field
												control={Select}
												selectOnBlur={false}
												forceSelection={false}
												{...(filter.state === null
													? {
															text: 'State',
													  }
													: {})}
												options={[
													{
														id: 'all',
														text: 'All States',
														value: 'all',
														selected: false,
													},
													...state,
												]}
												className="filter-select"
												icon="angle down"
												placeholder="State"
												name="state"
												onChange={(e, { value }) =>
													handleChangeFilter(
														'state',
														value
													)
												}
												value={filter.state}
											/>
										</Form.Field>
										<Form.Field>
											<Form.Field
												control={Select}
												selectOnBlur={false}
												forceSelection={false}
												{...(filter.booking_status ===
												null
													? {
															text: 'Status',
													  }
													: {})}
												options={[
													{
														id: 'all',
														text: 'All statuses',
														value: 'all',
														selected: false,
													},
													...status,
												]}
												className="filter-select"
												icon="angle down"
												placeholder="Status"
												name="floor"
												onChange={(e, { value }) =>
													handleChangeFilter(
														'booking_status',
														value
													)
												}
												value={filter.booking_status}
											/>
										</Form.Field>
									</Form.Group>
								</Grid.Column>
								<Grid.Column
									mobile={16}
									tablet={12}
									computer={6}
								>
									<div
										className="table-list-actions"
										style={{ float: 'right' }}
									>
										{service.is_qr_checkin ||
										service.is_qr_checkout ? (
											<Button
												className="ui button app-primary-button btn-bulk-print"
												onClick={() =>
													onPrintQRCode(null)
												}
												size="small"
												disabled={
													checkedWorkdesks.length <= 1
												}
											>
												<Icon name="print" />
												Bulk print QR
											</Button>
										) : null}
										{/* </Transition> */}
										<Button
											className="ui button app-primary-button"
											onClick={() =>
												setBulkUploadModal(true)
											}
										>
											{/* <Icon name="upload" /> */}
											<img
												src={downloadImg}
												className="btn-icon btn-download"
											/>{' '}
											Bulk upload
										</Button>
										<Button
											className="ui button app-primary-button"
											style={{
												height: '51px !important',
											}}
											onClick={() => {
												setFirstOpenSingle(true);
												clearForm();
											}}
										>
											<img
												src={addIcon}
												className="btn-icon"
											/>{' '}
											{
												ServiceTypes[
													service.service_type.name
												]
													.site_dashboard_btn_add_individual_slot
											}
										</Button>
									</div>
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</Form>
				</div>
				<Table
					headers={header}
					datas={slot_datas}
					page={paginate.page}
					totalRows={slots.total_slots}
					rowsPerPage={10}
					onPageChange={onPageChange}
				/>
			</div>
			<Modal
				onClose={() => {
					handleCancelSingleAdd(false);
				}}
				onOpen={() => setFirstOpenSingle(true)}
				// onUnmount={handleCancelSingleAdd(false)}
				open={firstOpenSingle}
				className="desks-modal single-first-modal"
			>
				<Modal.Content>
					<FocusLock disabled={!firstOpenSingle} autoFocus={false}>
						<Header as="h3">
							{
								ServiceTypes[service.service_type.name]
									.site_dashboard_header_individual_slot
							}
						</Header>
						<Form>
							<Form.Group>
								{postError && postError.status == 0 ? (
									<Message
										negative
										className="error-message w-100"
									>
										<img
											src={errorAlert}
											alt="error alert"
										/>
										{postError.message}
									</Message>
								) : null}
							</Form.Group>
							<Form.Group widths="equal">
								<Form.Field width={3}>
									<label>Floor</label>
									<Dropdown
										className="custom-filter-height slot-search-filter"
										icon="angle down"
										label="Floor"
										selectOnBlur={false}
										forceSelection={false}
										// iconPosition="right"
										placeholder="Select floor"
										// clearable
										fluid
										search
										selection
										options={floors}
										onSearchChange={handleSearchChange}
										searchQuery={searchQuery}
										onChange={(e, { value }) => {
											handleChangeFormSlot(
												'floors_id',
												value
											);
											handleChangeFormSlot(
												'floor_name',
												e.target.textContent
											);
											handleSearchChange(e, {
												searchQuery:
													e.target.textContent,
											});
											setSelectedFloor(
												e.target.textContent
											);
										}}
										// value={formSlot.floor_name}
										noResultsMessage={null}
									>
										{floorLoading ? (
											<div className="sk-chase search-loader">
												<div className="sk-chase-dot"></div>
												<div className="sk-chase-dot"></div>
												<div className="sk-chase-dot"></div>
												<div className="sk-chase-dot"></div>
												<div className="sk-chase-dot"></div>
												<div className="sk-chase-dot"></div>
											</div>
										) : null}
									</Dropdown>
								</Form.Field>
								<Form.Input
									width={3}
									fluid
									label="Area"
									placeholder="Enter area here"
									onChange={e => {
										handleChangeFormSlot(
											'area',
											e.target.value
										);
									}}
								/>
							</Form.Group>
							<Form.Group>
								<Form.Input
									fluid
									label={
										ServiceTypes[service.service_type.name]
											.site_dashboard_label_individual_slot
									}
									placeholder={
										ServiceTypes[service.service_type.name]
											.site_dashboard_placeholder_individual_slot
									}
									onChange={e => {
										handleChangeFormSlot(
											'name',
											e.target.value
										);
									}}
								/>
							</Form.Group>
							<div className="modal-action">
								<Button
									className="app-primary-button full-button"
									onClick={() => {
										showLoading('Saving...');
										postSingleWorkdeskSlot();
									}}
									disabled={disableSaveButtonHandler()}
								>
									Save
								</Button>
								<Button
									className="app-primary-inverted-button full-button"
									onClick={() => handleCancelSingleAdd(false)}
								>
									Cancel
								</Button>
							</div>
						</Form>
					</FocusLock>
				</Modal.Content>
			</Modal>
			<Modal
				onClose={() => setSecondOpenSingle(false)}
				open={secondOpenSingle}
				className="desks-modal bulk-completion-modal"
			>
				<Modal.Content>
					<div className="bulk-completion-modal-icon">
						<img src={successImg} alt="success" />
						<Header as="h3">
							{
								ServiceTypes[service.service_type.name]
									.site_dashboard_header_success
							}
						</Header>
						<p>
							{
								ServiceTypes[service.service_type.name]
									.site_dashboard_message_success
							}
							<br></br>
							{props.location.state.site[0].name}
						</p>
					</div>
				</Modal.Content>
			</Modal>

			{/* Single upload modal */}

			<Modal
				onClose={() => {
					clearForm();
					setBulkUploadModal(false);
				}}
				open={bulkUploadModal}
				className="desks-modal bulk-first-modal"
			>
				<Modal.Content>
					<FocusLock disabled={!bulkUploadModal} autoFocus={false}>
						<Header as="h3">
							{
								ServiceTypes[service.service_type.name]
									.site_dashboard_bulk_upload_header
							}
						</Header>
						<p style={{ marginBottom: '5px' }}>
							Upload list of new{' '}
							{ServiceTypes[service.service_type.name].slotType2}s
							<p style={{ marginBottom: '10px' }}>
								<a
									className="btnLink"
									href={(() => {
										switch (service.service_type.name) {
											case 'Workdesk':
												return `${process.env.REACT_APP_AWS_S3URL}/GAccess_WorkDesk_Template.xlsx`;
											case 'Parking':
												return `${process.env.REACT_APP_AWS_S3URL}/GAccess_Parking_Template.xlsx`;
											case 'Cafeteria':
												return `${process.env.REACT_APP_AWS_S3URL}/GAccess_Cafeteria_Template.xlsx`;
										}
									})()}
								>
									Download template here
								</a>
							</p>
						</p>
						<FileUpload id={props.location.state.site[0].id} />
						{fileError.some(
							file => file.id === props.location.state.site[0].id
						) ? (
							<Message
								negative
								className="file-error-message"
								size="tiny"
							>
								{
									fileError.filter(
										file =>
											file.id ===
											props.location.state.site[0].id
									)[0].fileError
								}
							</Message>
						) : null}
						<div className="modal-action">
							<Button
								className="app-primary-button full-button"
								disabled={
									fileError.length > 0 ||
									parseDesk.length <= 0
								}
								onClick={() => {
									postWorkdeskSlotHandler();
								}}
							>
								Save
							</Button>
							<Button
								className="app-primary-inverted-button full-button"
								onClick={() => {
									clearForm();
									setBulkUploadModal(false);
								}}
							>
								Cancel
							</Button>
						</div>
					</FocusLock>
				</Modal.Content>
			</Modal>
			<Modal
				onClose={() => setBulkModalAlert(false)}
				open={bulkModalAlert}
				className="desks-modal bulk-completion-modal"
			>
				<Modal.Content>
					<div className="single-completion-modal-icon">
						<img src={successImg} alt="success" />
						<Header as="h3">
							{
								ServiceTypes[service.service_type.name]
									.site_dashboard_header_success
							}
						</Header>
						<p>
							{
								ServiceTypes[service.service_type.name]
									.site_dashboard_message_success
							}
							<br></br>
							{props.location.state.site[0].name}
						</p>
					</div>
				</Modal.Content>
			</Modal>

			{/* status modal */}
			<Modal
				onClose={() => setActionModal(false)}
				onOpen={() => setActionModal(true)}
				open={actionModal}
				className="employee-form-modal"
			>
				<Modal.Content>
					<img
						src={`${process.env.REACT_APP_BASE_URL}/images/question-logo.png`}
					/>
					<Header as="h3">
						{slotData.enabled === 0
							? `Delete ${
									ServiceTypes[service.service_type.name]
										.slotType2
							  }`
							: slotData.state
							? `Enable ${
									ServiceTypes[service.service_type.name]
										.slotType2
							  }`
							: `Disable ${
									ServiceTypes[service.service_type.name]
										.slotType2
							  }`}
					</Header>
					<p className="modal-sub-header custom-sentence-break">
						{slotData.enabled === 0
							? `Employees will no longer be able to book ${
									slotData.name
							  }.
							Administrators will also no longer be able to enable it.
							Are you sure you want to delete this ${
								ServiceTypes[service.service_type.name]
									.slotType2
							}?`
							: slotData.state
							? `Employees will now be able to book ${
									slotData.name
							  }.
							Are you sure you want to enable this ${
								ServiceTypes[service.service_type.name]
									.slotType2
							}?`
							: `Employees will no longer be able to book ${
									slotData.name
							  }.
							Are you sure you want to disable this ${
								ServiceTypes[service.service_type.name]
									.slotType2
							}?`}
					</p>
					<p className="note-modal custom-sentence-break">
						{!slotData.state || slotData.enabled === 0
							? `
						Note: ${
							slotData.enabled === 0 ? 'Deleting' : 'Disabling'
						} only takes effect after its current user
						has checked-out`
							: null}
					</p>
					<div className="modal-action">
						<Button
							onClick={() => {
								onClickProceed();
							}}
							className="app-primary-button custom-full-button"
						>
							Yes, proceed
						</Button>
						<Button
							className="app-primary-inverted-button custom-full-button"
							onClick={() => {
								onClickCancel();
							}}
						>
							Cancel and go back
						</Button>
					</div>
				</Modal.Content>
			</Modal>
			<Modal
				onClose={() => setConfirmationModal(false)}
				open={confirmationModal}
				className="desks-modal bulk-completion-modal"
			>
				<Modal.Content>
					<div className="bulk-completion-modal-icon">
						<img src={successImg} alt="success" />
						<Header as="h3">
							{slotData.enabled === 0
								? `${
										ServiceTypes[service.service_type.name]
											.slotType3
								  } deleted`
								: slotData.state
								? `${
										ServiceTypes[service.service_type.name]
											.slotType3
								  } enabled`
								: `${
										ServiceTypes[service.service_type.name]
											.slotType3
								  } disabled`}
						</Header>
						<p>
							You have successfully{' '}
							{slotData.enabled === 0
								? 'deleted'
								: slotData.state
								? 'enabled'
								: 'disabled'}{' '}
							{ServiceTypes[service.service_type.name].slotType2}
							{'s '}
							{slotData.name}
						</p>
					</div>
				</Modal.Content>
			</Modal>
		</div>
	);
};

Desks.propTypes = {
	location: PropTypes.any,
	slots: PropTypes.object,
	floors: PropTypes.array,
	areas: PropTypes.array,
	filter: PropTypes.object,
	handleChangeFilter: PropTypes.func,
	lineError: PropTypes.string,
	props: PropTypes.any,
	parseDesk: PropTypes.array,
	getWorkdeskSlots: PropTypes.func,
	postWorkdeskSlotHandler: PropTypes.func,
	paginate: PropTypes.object,
	setPaginate: PropTypes.object,
	onPageChange: PropTypes.func,
	service: PropTypes.object,
	checkedWorkdesks: PropTypes.array,
	setCheckedWorkdesks: PropTypes.any,
	setBulkModalAlert: PropTypes.any,
	bulkModalAlert: PropTypes.bool,
	bulkUploadModal: PropTypes.bool,
	setBulkUploadModal: PropTypes.any,
	workdeskSort: PropTypes.any,
	onFilterFloorsDropdowwn: PropTypes.func,
	floorLoading: PropTypes.bool,
	onFilterAreasDropdowwn: PropTypes.func,
	areaLoading: PropTypes.bool,
	getSiteFloors: PropTypes.func,
	getServiceInstanceArea: PropTypes.func,
	setFileToUpload: PropTypes.any,
	searchFloorString: PropTypes.string,
	setSearchFloorString: PropTypes.func,
};

export default Desks;

import gql from 'graphql-tag';

export const VERIFY_EMAIL_ADDRESSES = gql`
	query($input: inputEmailAddresses) {
		verifyEmailAddresses(input: $input) {
			status
			message
			count
		}
	}
`;

export const REFETCH_TOKEN = gql`
	query($users_id: EncryptedString!) {
		query(users_id: $users_id) {
			user
			accessToken
			expiresIn
		}
	}
`;

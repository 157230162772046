export const ServiceInstance = {
	name: null,
	description: null,
	is_all_employees: 1,
	is_default_icon: 1,
	icon_url: null,
	is_qr_checkin: 0,
	is_qr_checkout: 0,
	with_approval: 0,
	groups: [],
	availabilities: [
		{
			day: 1,
			startime: '08:00',
			endtime: '17:00',
			is_available: 1,
		},
		{
			day: 2,
			startime: '08:00',
			endtime: '17:00',
			is_available: 1,
		},
		{
			day: 3,
			startime: '08:00',
			endtime: '17:00',
			is_available: 1,
		},
		{
			day: 4,
			startime: '08:00',
			endtime: '17:00',
			is_available: 1,
		},
		{
			day: 5,
			startime: '08:00',
			endtime: '17:00',
			is_available: 1,
		},
		{
			day: 6,
			startime: '08:00',
			endtime: '17:00',
			is_available: 0,
		},
		{
			day: 7,
			startime: '08:00',
			endtime: '17:00',
			is_available: 0,
		},
	],
};

// react lib
import React, { useContext, useEffect, useState } from 'react';

// components
import FileUpload from '../../../../components/FileUpload/FileUpload';
import LayoutPage from '../../../../hoc/Layout/Admin/AdminMaster';
import Breadcrumbs from '../../../../components/Breadcrumbs/Breadcrumbs';

// less (styling)
import './Bulk-upload.css';

// semantic ui component
import { Grid, Header, Message, Button, Modal } from 'semantic-ui-react';

// parser
import ParserContext from '../../../../context/ParserContext/ParserContext';
import LoaderContext from '../../../../context/LoaderContext/LoaderContext';

// Apollo Client
import { useMutation, useApolloClient } from '@apollo/client';
import { POST_USERS } from '../../../AccountSetUp/graphql/mutation';
import { VERIFY_EMAIL_ADDRESSES } from '../../../AccountSetUp/graphql/query';
import { GET_COMPANY_EMPLOYEES } from '../../graphql/query';

// prop-types
import PropTypes from 'prop-types';

import { isEmail, stringSanitize, splittedName } from '../../../../utils';

import jwt_decode from 'jwt-decode';

const BulkUpload = ({ history }) => {
	const client = useApolloClient();
	const { showLoading, hideLoading } = useContext(LoaderContext);
	const {
		fileError,
		fileData,
		setFileData,
		setFileError,
		setExcelFile,
	} = useContext(ParserContext);
	const decodedUser = jwt_decode(
		window.localStorage.getItem(process.env.REACT_APP_TOKEN_NAME)
	);
	const [confirmModal, setConfirmModal] = useState(false);
	const [userArr, setUserArr] = useState([]);
	const [siteArr, setSiteArr] = useState([]);
	const [groupArr, setGroupArr] = useState([]);
	const [isArr, setISArray] = useState([]);

	// employee mutations
	const [postUsers] = useMutation(POST_USERS);

	useEffect(() => {
		// clean up
		return () => {
			clearParserData();
		};
	}, []);

	// onChange fileData
	useEffect(() => {
		if (fileData && fileData.length > 0) {
			formatParsedData();
		}
	}, [fileData]);

	// parsing function
	const formatParsedData = async () => {
		let siteArray = [];
		let groupArray = [];
		let users = [];
		let err = null;
		let userIndex = 0;
		let immediateSupervisors = [];
		let countErrors = 0;
		const parsedData = fileData[0].data;
		let duplicateEmails;
		if (fileData && fileData.length > 0) {
			if (parsedData.length <= 1) {
				err = 'File must have at least one row/line data.';
			}

			parsedData.forEach((rows, index) => {
				if (index >= 1) {
					if (rows.length != 12) {
						countErrors += 1;
						err = `We found ${countErrors} line errors on your uploaded file. Please double check that all fields are filled out with valid content`;
					}

					// checking if emails is valid
					else if (!isEmail(rows[4]) || !isEmail(rows[9])) {
						countErrors += 1;
						err = `We found ${countErrors} line errors on your uploaded file. Please double check that all fields are filled out with valid content`;
					}
					// check if employee email and supervisor email is the same
					else if (rows[4] === rows[9]) {
						countErrors += 1;
						err = `We found ${countErrors} line errors on your uploaded file. Please double check that all fields are filled out with valid content`;
					}

					// check empty cells
					else if (
						rows.includes('') ||
						rows.includes(null) ||
						rows.includes(undefined)
					) {
						countErrors += 1;
						err = `We found ${countErrors} line errors on your uploaded file. Please double check that all fields are filled out with valid content`;
					}

					// store user's data on array
					users.push({
						email: stringSanitize(parsedData[index][4]),
						is_email: stringSanitize(parsedData[index][9]),
						profile: {
							idno: parsedData[index][0],
							first_name: parsedData[index][1],
							last_name: parsedData[index][2],
							suffix: parsedData[index][3],
							mobile_no: parsedData[index][5],
							date_hired: parsedData[index][6],
							position: parsedData[index][7],
						},
						group: {
							name: parsedData[index][10],
						},
						site: {
							name: parsedData[index][11],
						},
					});
					// store immediate supervisor to new set of array
					if (
						!immediateSupervisors.some(
							is =>
								stringSanitize(is.email) ===
								stringSanitize(parsedData[index][9])
						)
					) {
						immediateSupervisors.push({
							email: stringSanitize(parsedData[index][9]),
							profile: {
								first_name:
									parsedData[index][8] &&
									splittedName(parsedData[index][8]).length >
										1
										? splittedName(parsedData[index][8])[0]
										: '',
								last_name:
									parsedData[index][8] &&
									splittedName(parsedData[index][8]).length >
										1
										? splittedName(parsedData[index][8])[1]
										: '',
							},
						});
					}

					// check employee's email if existing to a new set of users

					if (
						users.filter(
							user => user.email === users[userIndex].email
						).length > 1
					) {
						countErrors += 1;
						err = `We found ${countErrors} line errors on your uploaded file. Please double check that all fields are filled out with valid content`;
					}

					// create sets of group & eliminate all duplicate group name
					if (
						!groupArray.some(
							group => group.name === parsedData[index][10]
						)
					) {
						groupArray.push({
							name: parsedData[index][10],
						});
					}

					// create sets of sites & eliminate all duplicate site name
					if (
						!siteArray.some(
							site => site.name === parsedData[index][11]
						)
					) {
						siteArray.push({
							name: parsedData[index][11],
							abbrev: null,
							address: null,
							floor: null,
						});
					}
					userIndex++;
				}
			});
		}
		setUserArr(users);
		setSiteArr(siteArray);
		setGroupArr(groupArray);
		setISArray(immediateSupervisors);
		duplicateEmails = await client.query({
			query: VERIFY_EMAIL_ADDRESSES,
			fetchPolicy: 'network-only',
			variables: {
				input: {
					users: users,
					companies_id:
						decodedUser && decodedUser.user
							? decodedUser.user.company.id
							: null,
				},
			},
		});
		if (
			duplicateEmails &&
			duplicateEmails.data &&
			!duplicateEmails.data.verifyEmailAddresses.status
		) {
			countErrors += duplicateEmails.data.verifyEmailAddresses.count;
			err = `We found ${countErrors} line errors on your uploaded file. Please double check that all fields are filled out with valid content`;
			setFileData([]);
			setFileError([
				...fileError,
				{
					id: 'employees',
					fileError: `We found ${countErrors} line errors on your uploaded file. Please double check that all fields are filled out with valid content`,
				},
			]);
		}

		if (err) {
			setFileData([]);
			setFileError([
				...fileError,
				{
					id: 'employees',
					fileError: err,
				},
			]);
		}
	};

	const disableButtonHandler = () => {
		return fileError.length > 0 || fileData.length <= 0;
	};

	const onSaveBulkUpload = async () => {
		let newSites = [];
		siteArr.map(mySiteArray => {
			if (
				!newSites.some(
					newSite =>
						newSite.name.toLowerCase() ===
						mySiteArray.name.toLowerCase()
				)
			) {
				newSites.push(mySiteArray);
			}
		});

		showLoading(`Saving data...`);

		await postUsers({
			variables: {
				input: {
					users_id: decodedUser.user.id,
					users: userArr,
					groups: groupArr,
					sites: newSites,
					supervisors: isArr,
					companies_id:
						decodedUser && decodedUser.user
							? decodedUser.user.company.id
							: null,
				},
			},
			awaitRefetchQueries: true,
			refetchQueries: [
				{
					query: GET_COMPANY_EMPLOYEES,
					fetchPolicy: 'network-only',
					variables: {
						input: {
							companies_id: decodedUser.user.company.id,
							status: null,
							group_id: null,
							type_search: null,
							regular_sort: false,
						},
						paginate: {
							page: 1,
							offset: 10,
						},
					},
				},
			],
		});

		hideLoading();

		// redirect when it's done
		history.push({
			pathname: '/employeelist',
			state: {
				from_post: true,
			},
		});
	};

	// clearing parser
	const clearParserData = () => {
		setFileData([]);
		setFileError([]);
		setExcelFile([]);
	};

	const breadcrumbNavigation = () => {
		history.goBack();
	};
	return (
		<LayoutPage>
			<Breadcrumbs
				breadcrumbs={[
					...history.location.state.breadcrumbs,
					{ label: 'Bulk upload employees' },
				]}
				breadcrumbNavigation={breadcrumbNavigation}
			/>
			<Header as="h1">Bulk upload employees</Header>
			<Grid>
				<Grid.Column mobile="sixteen" tablet="fourteen" computer="ten">
					<div className="step-container employee-list">
						<di className="mb-4 mt-2 d-block">
							<Header as="h3">Employee list</Header>
						</di>
						<div className="employee-list-note">
							<div className="mt-2 mb-2">
								Upload list of your employees
							</div>
							<div className="grey-text mt-2 mb-2">
								Missed the employee list template we shared?
							</div>
							<a
								className="btnLink mt-2 mt-2"
								href={`${process.env.REACT_APP_AWS_S3URL}/GAccess_EmployeeList_Template.xlsx`}
							>
								Download template here
							</a>
						</div>
						<Grid left>
							<Grid.Column
								mobile="sixteen"
								tablet="fourteen"
								computer="seven"
							>
								<FileUpload id="employees" />
								{fileError.some(
									file => file.id === 'employees'
								) ? (
									<Message
										negative
										className="file-error-message"
										size="tiny"
									>
										{
											fileError.filter(
												file => file.id === 'employees'
											)[0].fileError
										}
									</Message>
								) : (
									<div className="fileupload-info">
										<div className="mb-2 mt-2">
											<i>- File must be less than 3MB</i>
										</div>
										<div className="mb-2 mt-2">
											<i>
												- CSV file or any Excel/Google
												sheet
											</i>
										</div>
									</div>
								)}
							</Grid.Column>
						</Grid>
					</div>
				</Grid.Column>
			</Grid>
			<div className="bulk-actions">
				<Button
					className="app-primary-inverted-button bulk-btn"
					size="huge"
					onClick={() => history.goBack()}
				>
					Cancel
				</Button>
				<Button
					variant="contained"
					primary
					className="bulk-btn"
					size="huge"
					disabled={disableButtonHandler()}
					onClick={() => setConfirmModal(true)}
				>
					Save
				</Button>
			</div>
			<Modal
				onClose={() => setConfirmModal(false)}
				open={confirmModal}
				className="employee-form-modal"
			>
				<Modal.Content>
					<img
						src={`${process.env.REACT_APP_BASE_URL}/images/question-logo.png`}
					/>
					<Header as="h3">
						Are you sure you want to add new employees?
					</Header>
					<p className="modal-sub-header">
						New employees will be added. Once saved, they will be
						able to use G Access app
					</p>
					<div className="modal-action">
						<Button
							onClick={() => {
								onSaveBulkUpload();
							}}
							className="app-primary-button custom-full-button"
						>
							Yes, proceed
						</Button>
						<Button
							className="app-primary-inverted-button custom-full-button"
							onClick={() => setConfirmModal(false)}
						>
							Cancel and go back
						</Button>
					</div>
				</Modal.Content>
			</Modal>
		</LayoutPage>
	);
};

BulkUpload.propTypes = {
	history: PropTypes.any,
};

export default BulkUpload;

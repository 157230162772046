// react lib
import React, { useState, useContext } from 'react';
import { Redirect } from 'react-router-dom';

// hoc
import LoginMaster from '../../../hoc/Layout/Login/LoginMaster';

// less(styling)
import '../Login.css';

// grahpql queries
import { useMutation, useLazyQuery } from '@apollo/client';
import { FORGOT_PASSWORD } from '../graphql/mutation';

// semantic ui component
import { Form, Button, Header, Message, Icon, Grid } from 'semantic-ui-react';

// component
import Page404 from '../../Page404/Page404';

// context
import LoaderContext from '../../../context/LoaderContext/LoaderContext';

// utils
import { encrypt } from '../../../utils';

import useForm from '../../../hooks/useForm';

const ForgotPassword = () => {
	const [email, setEmail] = useState('');
	const [redirectTo, setRedirectTo] = useState(null);
	const [error, setError] = useState({});
	const { showGloader, hideGloader } = useContext(LoaderContext);
	const { register, errors, handleSubmit, setValue } = useForm();

	const [
		forgotPassword,
		{
			data: SetupPasswordResponse,
			errors: patchErrors,
			loading: isPatchLoading,
		},
	] = useMutation(FORGOT_PASSWORD, {
		onError: error => {},
		onCompleted: data => {
			hideGloader();
			if (data.forgotPassword.status == 1) {
				const encryptedEmail = encrypt(email);
				setRedirectTo(`/verification-code/${encryptedEmail}`);
			} else if (data.forgotPassword.status == 0) {
				setError({ ...data.forgotPassword });
			}
		},
	});

	const onSubmitHandler = () => {
		showGloader();
		forgotPassword({
			variables: {
				email,
			},
		});
	};

	if (redirectTo !== null) {
		return <Redirect to={redirectTo} />;
	}

	return (
		<LoginMaster>
			<div className="login-header">
				<Header as="h2">Forgot your password?</Header>
				<p>
					First, let’s find your account by providing your e-mail
					address
				</p>
			</div>
			<Grid>
				<Grid.Row>
					<Grid.Column
						mobile="sixteen"
						tablet="tablet"
						computer="six"
					>
						{error && error.status === 0 ? (
							<Message negative>
								<Icon
									className="error-icon"
									name="exclamation circle"
								/>
								{error.message}
							</Message>
						) : null}
						<Form onSubmit={handleSubmit(onSubmitHandler)}>
							<Form.Field>
								<label htmlFor="">E-mail address</label>
								<input
									className={
										errors.email ? 'input-error' : ''
									}
									type="email"
									placeholder="Enter your e-mail address"
									name="email"
									ref={register({
										required: 'Email is required*',
										pattern: {
											value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
											message: 'Invalid email address*',
										},
									})}
									autoFocus
									onChange={e => setEmail(e.target.value)}
								/>
								{errors.email && (
									<small className="input-error-message">
										{errors.email.message}
									</small>
								)}
							</Form.Field>
							<Button
								type="submit"
								className="app-primary-button medium-button"
							>
								Submit
							</Button>
						</Form>
					</Grid.Column>
					<Grid.Column
						mobile="sixteen"
						tablet="tablet"
						computer="eight"
					></Grid.Column>
				</Grid.Row>
			</Grid>
		</LoginMaster>
	);
};

export default ForgotPassword;

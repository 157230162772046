import gql from 'graphql-tag';

export const GET_FRESH_TOKEN = gql`
	query($users_id: EncryptedString!, $refresh_token: String) {
		getFreshToken(
			users_id: $users_id
			refresh_token: $refresh_token
			isMobile: false
		) {
			access_token
			refresh_token
		}
	}
`;

import { useApolloClient, useLazyQuery, useMutation } from '@apollo/client';
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useState } from 'react';
import FocusLock from 'react-focus-lock';
import {
	Button,
	Form,
	Grid,
	Header,
	Input,
	Message,
	Modal,
	Popup,
	Select,
} from 'semantic-ui-react';
import FileUpload from '../../../../components/FileUpload/FileUpload';
import Table from '../../../../components/Table/Table';
import LoaderContext from '../../../../context/LoaderContext/LoaderContext';
import ParserContext from '../../../../context/ParserContext/ParserContext';
import { Driver as driverModel } from '../../../../models';
import {
	checkIfNull,
	concatFullname,
	encrypt,
	isEmail,
	isMobileNum,
	isPersonName,
	mapDays,
	updateObject,
	chunkArray,
	arraySort,
} from '../../../../utils';
import {
	DELETE_DRIVER,
	PATCH_DRIVER,
	PATCH_DRIVER_STATUS,
	POST_DRIVERS,
	POST_SINGLE_DRIVER,
} from '../../graphql/mutation';
import { GET_DRIVER, GET_DRIVERS } from '../../graphql/query';
import './Drivers.css';

const successImg =
	process.env.REACT_APP_BASE_NAME + 'images/setupservice-complete.png';
const downloadImg = process.env.REACT_APP_BASE_NAME + 'svgs/download.svg';
const addIcon = process.env.REACT_APP_BASE_NAME + 'svgs/more-white.svg';
// const searchImg = process.env.REACT_APP_BASE_NAME + 'svgs/search.svg';
const errorAlert = process.env.REACT_APP_BASE_NAME + 'svgs/light-alert.svg';

const days = [
	{ key: 'all_day', text: 'All rest days', value: 'all' },
	{ key: 'sunday', text: 'Sunday', value: '0' },
	{ key: 'monday', text: 'Monday', value: '1' },
	{ key: 'tuesday', text: 'Tuesday', value: '2' },
	{ key: 'wednesday', text: 'Wednesday', value: '3' },
	{ key: 'thursday', text: 'Thursday', value: '4' },
	{ key: 'friday', text: 'Friday', value: '5' },
	{ key: 'saturday', text: 'Saturday', value: '6' },
];

const driver_status = [
	{ key: 'all_status', text: 'All statuses', value: 'all' },
	// { key: 'inactive', text: 'Inactive', value: '0' },
	{ key: 'active', text: 'Active', value: '1' },
	{ key: 'suspended', text: 'Suspended', value: '2' },
	// { key: 'dismissed', text: 'Dismissed', value: '3' },
];

const searchImg = process.env.REACT_APP_BASE_NAME + 'svgs/search.svg';

const now = moment().format('YYYY-MM-DD');

const Drivers = ({
	props,
	drivers,
	getDrivers,
	paginate,
	onPageChange,
	driverFilter,
	handleChangeFilter,
	regularSort,
	setRegularSort,
	parsedDatas,
	clearForm,
	setPaginate,
	onTabChange,
}) => {
	const client = useApolloClient();
	const { showLoading, hideLoading } = useContext(LoaderContext);
	const {
		fileError,
		fileData,
		setFileData,
		setFileError,
		excelFile,
		error: excelError,
	} = useContext(ParserContext);

	const [individualForm, setIndividualForm] = useState(false);
	const [bulkUpload, setBulkUpload] = useState(false);

	const [postError, setPostError] = useState({});

	const [form, setForm] = useState(driverModel);
	const [editData, seteditData] = useState({});

	const [postAction, setPostAction] = useState('');
	const [actionModal, setActionModal] = useState(false);
	const [confirmationModal, setConfirmationModal] = useState(false);
	const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);
	const [successfulDeletionModal, setSuccessfulDeletionModal] = useState(
		false
	);
	const [selectedDriver, setSelectedDriver] = useState({});
	const [changeStatusModal, setChangeStatusModal] = useState(false);
	const [successChangeStatusModal, setSuccessChangeStatusModal] = useState(
		false
	);
	const [actionStatus, setActionStatus] = useState(null);
	const [conflictTripModal, setConflictTripModal] = useState(false);

	const disableSaveButtonHandler = useCallback(() => {
		if (
			form.user.email == null ||
			form.user.email.trim() == '' ||
			isEmail(form.user.email) == false ||
			form.user.profile.first_name == null ||
			form.user.profile.first_name.trim() == '' ||
			// isPersonName(form.user.profile.first_name) == false ||
			form.user.profile.last_name == null ||
			form.user.profile.last_name.trim() == '' ||
			// isPersonName(form.user.profile.last_name) == false ||
			form.user.profile.birthdate == null ||
			form.user.profile.birthdate == '' ||
			form.user.profile.mobile_no == null ||
			form.user.profile.mobile_no == '' ||
			isMobileNum(form.user.profile.mobile_no) == false
		) {
			return true;
		}

		return false;
	});
	const drivers_data = drivers.drivers
		? drivers.drivers.map(driver => {
				let rest_day =
					driver.rest_days && driver.rest_days.length
						? driver.rest_days
								.map(day => {
									return {
										restday: day.restday,
									};
								})
								.sort(arraySort('restday'))
						: [];

				if (rest_day && rest_day.length) {
					// if first index restday == 0, move  that item to the last
					if (rest_day[0].restday == 0) {
						let toMove = rest_day.shift();
						rest_day.push(toMove);
					}

					rest_day = rest_day.map(rest_day => {
						let dayOfWeek = mapDays(rest_day.restday);

						return `${dayOfWeek.shortname} `;
					});
				} else {
					rest_day = '';
				}

				return {
					id: driver.idno ? driver.idno : '',
					fullname: `${concatFullname(
						driver.user.profile.first_name,
						driver.user.profile.last_name
					)}`,
					email_address: driver.user.email,
					date_of_birth: moment(driver.user.profile.birthdate).format(
						'MM/DD/YYYY'
					),
					contact_number: checkIfNull(driver.user.profile.mobile_no),
					rest_day,
					status: {
						data: (
							<>
								<Popup
									disabled={true}
									trigger={
										<div className="status-row status-div">
											<div
												className={`status-badge ${(() => {
													switch (driver.status) {
														case 0:
															return 'status-inactive';
														case 1:
															return 'status-active';
														case 2:
															return 'status-suspended';
														/* case 3:
															return 'status-dismissed'; */
														default:
															return '';
													}
												})()}`}
											></div>
											<span className="status-text">
												{(() => {
													switch (driver.status) {
														case 0:
															return 'Inactive';
														case 1:
															return 'Active';
														case 2:
															return 'Suspended';
														/* case 3:
															return 'Dismissed'; */
														default:
															return '';
													}
												})()}
											</span>
										</div>
									}
								/>
							</>
						),
					},
					action: (
						<>
							<a
								className="btnLink"
								onClick={() => {
									handleDriverEdit(driver, rest_day);
								}}
							>
								Edit
							</a>
							<a
								className="btnLink"
								onClick={evt => {
									setSelectedDriver(driver);
									setDeleteConfirmModal(true);
								}}
							>
								Delete
							</a>
							<a
								className="btnLink"
								onClick={
									evt => {
										onChangeStatus(
											evt.target.text.toLowerCase(),
											driver
										);
									}
									// onPatchDriverStatus(evt, driver)
								}
							>
								{driver.status == 2 &&
								![0, 1, 3].includes(driver.status)
									? 'Unsuspend'
									: 'Suspend'}
							</a>
							{/* <a
								className="btnLink"
								onClick={evt =>
									onPatchDriverStatus(evt, driver)
								}
							>
								{driver.status == 3 &&
								![0, 1, 2].includes(driver.status)
									? 'Undismissed'
									: 'Dismissed'}
							</a> */}
						</>
					),
				};
		  })
		: [];

	/* const handleChange = (key, value) => {
		setFilterDrivers({
			...filterDrivers,
			[key]: value,
		});
	}; */

	const header = [
		'Driver ID',
		'Full name',
		'Email address',
		'Date of birth',
		'Contact number',
		'Rest day',
		'Status',
		'Action',
	];
	const handleDriverEdit = driver => {
		const updatedRestDays = form.rest_days.map(rest => {
			return {
				restday: rest.restday,
				drivers_id: driver.id,
				is_available:
					driver.rest_days.filter(
						restday => restday.restday === rest.restday
					).length > 0
						? 1
						: 0,
			};
		});
		setForm({
			...form,
			id: driver.id,
			user: {
				id: driver.user.id,
				email: driver.user.email,
				profile: {
					first_name: driver.user.profile.first_name,
					last_name: driver.user.profile.last_name,
					birthdate: moment(driver.user.profile.birthdate).format(
						'YYYY-MM-DD'
					),
					mobile_no: checkIfNull(driver.user.profile.mobile_no),
				},
			},
			rest_days: updatedRestDays,
		});
		setIndividualForm(true);
		setPostAction('edit');
		setPostError({});
	};

	// onClick days checkbox
	const onClickRestDays = (evt, data) => {
		const selected = form.rest_days.filter(availability => {
			return availability.is_available == 1;
		});

		if (selected.length == 1 && data.is_available == 1) {
			return;
		}

		const updatedAvailabilities = form.rest_days.map(availability => {
			return availability.restday === data.restday
				? { ...data, is_available: data.is_available ? 0 : 1 }
				: availability;
		});

		const updatedForm = updateObject(form, {
			rest_days: updatedAvailabilities,
		});

		setForm(updatedForm);
	};

	const onChangeValueHandler = (parentKey, key, value) => {
		const updatedForm = updateObject(form, {
			[parentKey]: updateObject(form[parentKey], {
				[key]:
					key == 'profile'
						? { ...form.user.profile, ...value }
						: value,
			}),
		});

		setForm(updatedForm);
	};

	const onClickProceed = () => {
		showLoading('Saving driver...');
		if (postAction == 'add') {
			setPaginate({
				...paginate,
				page: 1,
			});
		}
		postSingleDriver();
	};

	const onClickBulkProceed = async () => {
		setPaginate({
			...paginate,
			page: 1,
		});

		const chunkedDrivers = chunkArray(parsedDatas['upload-drivers'], 1000);

		for (let i = 0; i < chunkedDrivers.length; i++) {
			await showLoading(
				`Saving drivers...${
					chunkedDrivers.length > 1
						? i + 1 + '/' + chunkedDrivers.length
						: ''
				}`
			);

			await postDrivers({
				variables: {
					service_instances_id:
						props.location.state.site[0].service_instances_id,
					drivers: chunkedDrivers[i],
				},
				onError: () => {
					hideLoading();
				},
				awaitRefetchQueries: true,
				refetchQueries: [
					{
						query: GET_DRIVERS,
						fetchPolicy: 'network-only',
						variables: {
							input: {
								...driverFilter,
								fullname: driverFilter.fullname,
								status: driverFilter.status,
								restday: driverFilter.restday,
								regular_sort: true,
							},
							service_instances_id:
								props.location.state.site[0]
									.service_instances_id,
							paginate,
						},
					},
				],
			});
		}

		await getDrivers('network-only');

		setIndividualForm(false);
		setForm(driverModel);
		setConfirmationModal(true);
		setTimeout(() => {
			setConfirmationModal(false);
		}, 2000);
		setPostError({});
		clearForm();
		setActionModal(false);
		hideLoading();
	};

	const onPatchDriver = () => {
		showLoading('Updating drivers...');
		patchDriver();
	};

	const onClickCancel = () => {
		if (postAction == 'add' || postAction == 'edit')
			setIndividualForm(true);
		else setBulkUpload(true);

		setActionModal(false);
	};

	const [getDriver] = useLazyQuery(GET_DRIVER, {
		fetchPolicy: 'network-only',
		variables: {
			input: {
				id: form.id,
			},
		},
		onCompleted: async data => {
			setForm({
				...form,
				...data.getDriver,
			});
		},
	});

	const [postSingleDriver] = useMutation(POST_SINGLE_DRIVER, {
		variables: {
			service_instances_id:
				props.location.state.site[0].service_instances_id,
			input: form,
		},
		onError: () => {
			hideLoading();
		},
		awaitRefetchQueries: true,
		refetchQueries: [
			{
				query: GET_DRIVERS,
				fetchPolicy: 'network-only',
				variables: {
					input: {
						...driverFilter,
						fullname: driverFilter.fullname,
						status: driverFilter.status,
						restday: driverFilter.restday,
						regular_sort: true,
					},
					service_instances_id:
						props.location.state.site[0].service_instances_id,
					// sort: sort_obj[0],
					paginate,
				},
			},
		],
		onCompleted: async data => {
			// 1 or true value should go here
			if (data.postSingleDriver.status) {
				setIndividualForm(false);

				getDrivers('network-only').then(() => {
					setForm(driverModel);
					setConfirmationModal(true);
					setTimeout(() => {
						setConfirmationModal(false);
					}, 2000);
					setPostError({});
				});
			} else {
				setIndividualForm(true);
				setPostError({ ...data.postSingleDriver });
			}

			setActionModal(false);
			hideLoading();
		},
	});

	const [postDrivers] = useMutation(POST_DRIVERS);

	const onPatchDriverStatus = async driver => {
		try {
			setChangeStatusModal(false);

			await patchDriverStatus({
				variables: {
					input: {
						id: driver.id,
						status: actionStatus,
					},
				},
			});

			hideLoading();
		} catch (error) {

		}
	};

	const onChangeStatus = (action, driver) => {
		let status;
		switch (action) {
			case 'suspend':
				status = 2;
				break;
			default:
				status = 1;
				break;
		}
		setSelectedDriver(driver);
		setActionStatus(status);
		setChangeStatusModal(true);
	};

	const [patchDriverStatus] = useMutation(PATCH_DRIVER_STATUS, {
		onError: () => {
			hideLoading();
		},
		awaitRefetchQueries: true,
		refetchQueries: [
			{
				query: GET_DRIVERS,
				fetchPolicy: 'network-only',
				variables: {
					input: {
						...driverFilter,
						fullname: driverFilter.fullname,
						status: driverFilter.status,
						restday: driverFilter.restday,
						regular_sort: regularSort,
					},
					service_instances_id:
						props.location.state.site[0].service_instances_id,
					paginate,
				},
			},
		],
		onCompleted: async data => {
			// 1 or true value should go here
			if (data.patchDriverStatus.status) {
				getDrivers();
				setPostError({});
				setSuccessChangeStatusModal(true);
				setTimeout(() => {
					setSuccessChangeStatusModal(false);
				}, 2000);
			} else {
				setConflictTripModal(true);
			}
			hideLoading();
		},
	});

	const onDriverDisabled = async (evt, driver) => {
		showLoading('Deleting driver...');
		setDeleteConfirmModal(false);
		await deleteDriver({
			variables: {
				input: {
					id: driver.id,
					enabled: 0,
				},
			},
		});
	};

	const [deleteDriver] = useMutation(DELETE_DRIVER, {
		onError: () => {
			hideLoading();
		},
		awaitRefetchQueries: true,
		refetchQueries: [
			{
				query: GET_DRIVERS,
				fetchPolicy: 'network-only',
				variables: {
					input: {
						...driverFilter,
						fullname: driverFilter.fullname,
						status: driverFilter.status,
						restday: driverFilter.restday,
						regular_sort: regularSort,
					},
					service_instances_id:
						props.location.state.site[0].service_instances_id,
					paginate,
				},
			},
		],
		onCompleted: async data => {
			if (data.deleteDriver.status) {
				getDrivers();
				if (drivers.drivers.length <= 1) {
					setPaginate({
						page: 1,
						offset: 10,
					});
				}
				setPostError({});
				setDeleteConfirmModal(false);
				setSuccessfulDeletionModal(true);
				setTimeout(() => {
					setSuccessfulDeletionModal(false);
				}, 2000);
			} else {
				setConflictTripModal(true);
			}
			hideLoading();
		},
	});
	const [patchDriver] = useMutation(PATCH_DRIVER, {
		variables: {
			input: {
				id: form.id,
				users_id: form.user.id,
				profile: {
					mobile_no: form.user.profile.mobile_no,
				},
				rest_days: form.rest_days,
			},
		},
		onError: () => {
			hideLoading();
		},
		awaitRefetchQueries: true,
		refetchQueries: [
			{
				query: GET_DRIVERS,
				fetchPolicy: 'network-only',
				variables: {
					input: {
						...driverFilter,
						fullname: driverFilter.fullname,
						status: driverFilter.status,
						restday: driverFilter.restday,
						regular_sort: regularSort,
					},
					service_instances_id:
						props.location.state.site[0].service_instances_id,
					// sort: sort_obj[0],
					paginate,
				},
			},
		],
		onCompleted: async data => {
			// 1 or true value should go here
			if (data.patchDriver.status) {
				setIndividualForm(false);

				getDrivers().then(() => {
					setForm(driverModel);
					setConfirmationModal(true);
					setPostError({});
					setActionModal(false);
					setTimeout(() => {
						setConfirmationModal(false);
					}, 2000);
				});
			} else {
				setPostError({ ...data.patchDriver });
				onClickCancel();
			}

			// setActionModal(false);
			hideLoading();
		},
	});

	return (
		<div>
			<div className="sub-title-shuttle-date">
				<p style={{ fontSize: '14px' }}>Drivers list</p>
			</div>
			<div className="table-list">
				<div className="table-list-filter workdesks-filter">
					<Form>
						<Grid>
							<Grid.Row>
								<Grid.Column
									mobile={16}
									tablet={12}
									computer={10}
								>
									<Form.Group inline>
										<Form.Field width={10}>
											<Input
												icon={
													<img
														src={searchImg}
														className="filter-search-icon "
													/>
												}
												iconPosition="left"
												className="input-class"
												placeholder=" Type search here"
												onChange={e =>
													handleChangeFilter(
														'driver',
														'fullname',
														e.target.value
													)
												}
												value={driverFilter.fullname}
											/>
										</Form.Field>
										<label
											style={{
												marginLeft: 10,
											}}
										>
											Showing
										</label>
										<Form.Field>
											<Form.Field
												control={Select}
												selectOnBlur={false}
												forceSelection={false}
												options={days}
												className="filter-select vehicle-filter-select"
												icon="angle down"
												defaultValue={'all'}
												name="driver_restdays"
												onChange={(e, { value }) =>
													handleChangeFilter(
														'driver',
														'restday',
														value
													)
												}
											/>
										</Form.Field>
										<Form.Field>
											<Form.Field
												control={Select}
												selectOnBlur={false}
												forceSelection={false}
												options={driver_status}
												className="filter-select vehicle-filter-select"
												icon="angle down"
												defaultValue={'all'}
												name="driver_status"
												onChange={(e, { value }) =>
													handleChangeFilter(
														'driver',
														'status',
														value
													)
												}
											/>
										</Form.Field>
									</Form.Group>
								</Grid.Column>
								<Grid.Column
									mobile={16}
									tablet={12}
									computer={6}
								>
									<div
										className="table-list-actions"
										style={{ float: 'right' }}
									>
										<Button
											onClick={() => {
												setBulkUpload(true);
												setPostAction('bulk-add');
											}}
											className="ui button app-primary-button"
										>
											<img
												src={downloadImg}
												className="btn-icon btn-download"
											/>{' '}
											Bulk upload
										</Button>
										<Button
											className="ui button app-primary-button"
											onClick={() => {
												setIndividualForm(true);
												setPostError({});
												setForm({
													// ...form,
													...driverModel,
													id: '',
												});
												setPostAction('add');
											}}
										>
											<img
												src={addIcon}
												className="btn-icon"
											/>{' '}
											Add new driver
										</Button>
									</div>
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</Form>
				</div>
				<Table
					headers={header}
					datas={drivers_data}
					page={paginate.page}
					totalRows={drivers.total_drivers}
					rowsPerPage={10}
					onPageChange={onPageChange}
				/>
			</div>
			{/* Modal section */}
			<Modal
				onClose={() => {
					setPostError({});
					setIndividualForm(false);
				}}
				open={individualForm}
				className="desks-modal single-first-modal"
			>
				<Modal.Content>
					<FocusLock disabled={!individualForm} autoFocus={false}>
						<Header as="h3">
							{postAction == 'add'
								? `Add new`
								: postAction == 'edit'
								? `Edit`
								: null}{' '}
							driver
						</Header>
						<Form>
							<Form.Group>
								{postError && postError.status == 0 ? (
									<Message
										negative
										className="error-message w-100"
									>
										<img
											src={errorAlert}
											alt="error alert"
										/>
										{postError.message}
									</Message>
								) : null}
							</Form.Group>
							<Form.Group>
								<Form.Field>
									<label>First name</label>
									<input
										disabled={
											postAction == 'edit' ? true : false
										}
										className="custom-driver-input"
										placeholder="Enter first name here"
										onChange={evt =>
											onChangeValueHandler(
												'user',
												'profile',
												{
													first_name:
														evt.target.value,
												}
											)
										}
										value={form.user.profile.first_name}
									/>
								</Form.Field>
								<Form.Field>
									<label>Last name</label>
									<input
										disabled={
											postAction == 'edit' ? true : false
										}
										className="custom-driver-input"
										placeholder="Enter last name here"
										onChange={evt =>
											onChangeValueHandler(
												'user',
												'profile',
												{
													last_name: evt.target.value,
												}
											)
										}
										value={form.user.profile.last_name}
									/>
								</Form.Field>
							</Form.Group>
							<Form.Group>
								<Form.Field>
									<label>Email address</label>
									<input
										disabled={
											postAction === 'edit' ? true : false
										}
										className="custom-driver-input"
										placeholder="Enter email address here"
										onChange={evt =>
											onChangeValueHandler(
												'user',
												'email',
												evt.target.value
											)
										}
										value={form.user.email}
									/>
								</Form.Field>
							</Form.Group>
							<Form.Group>
								<Form.Field width={8}>
									<label>Date of birth</label>
									<input
										disabled={
											postAction === 'edit' ? true : false
										}
										className="custom-driver-input"
										type="date"
										format="YYYY-MM-DD"
										placeholder="Enter birth date here"
										onChange={evt =>
											onChangeValueHandler(
												'user',
												'profile',
												{
													birthdate: evt.target.value,
												}
											)
										}
										width={8}
										max={now}
										value={form.user.profile.birthdate}
										style={{ height: '49.4px' }}
									/>
								</Form.Field>
								<Form.Input
									label="Contact number"
									placeholder="09xxxxxxxxx"
									onChange={evt =>
										onChangeValueHandler(
											'user',
											'profile',
											{
												mobile_no: evt.target.value,
											}
										)
									}
									width={8}
									value={form.user.profile.mobile_no}
								/>
							</Form.Group>
							<Form.Group grouped>
								<p>Rest day</p>
								<Form.Group className="ml-0 mr-0">
									<div className="day-checkbox">
										{form.rest_days.map(day => {
											return (
												<Form.Field
													control="input"
													type="checkbox"
													primary={true}
													key={encrypt(day.restday)}
													label={
														day.restday === 4 ||
														day.restday === 0
															? moment()
																	.day(
																		day.restday
																	)
																	.format(
																		'dd'
																	)
															: moment()
																	.day(
																		day.restday
																	)
																	.format(
																		'dd'
																	)
																	.substr(
																		0,
																		1
																	)
													}
													name={day.day}
													style={{
														cursor: 'pointer',
													}}
													className={clsx(
														'day-checkbox-item',
														day.is_available
															? 'checked'
															: ''
													)}
													onClick={e => {
														onClickRestDays(e, day);
													}}
												/>
											);
										})}
									</div>
								</Form.Group>
							</Form.Group>

							<div className="modal-action">
								<Button
									className="app-primary-button full-button"
									onClick={e => {
										setActionModal(true);
										setIndividualForm(false);
									}}
									disabled={disableSaveButtonHandler()}
								>
									Save
								</Button>
								<Button
									className="app-primary-inverted-button full-button"
									onClick={() => setIndividualForm(false)}
									type="button"
								>
									Cancel
								</Button>
							</div>
						</Form>
					</FocusLock>
				</Modal.Content>
			</Modal>

			{/* Bulk modal form */}
			<Modal
				onClose={() => {
					clearForm();
					setBulkUpload(false);
				}}
				open={bulkUpload}
				className="desks-modal bulk-first-modal"
			>
				<Modal.Content>
					<FocusLock disabled={!bulkUpload} autoFocus={false}>
						<Header as="h3">Bulk upload new drivers</Header>
						<p style={{ marginBottom: '5px' }}>
							<strong>
								Select file from your computer or drag file to
								attach
							</strong>
							<br />
							<em style={{ color: '#AFAFAF' }}>
								File/s must be less than 3MB of .xlsx or .csv
								file each
							</em>
							<p style={{ marginBottom: '10px' }}>
								<a
									className="btnLink"
									href={`${process.env.REACT_APP_AWS_S3URL}/GAccess_Driver_Template.xlsx`}
								>
									Download template here
								</a>
							</p>
						</p>
						<FileUpload id="upload-drivers" />
						{fileError.some(
							file => file.id === 'upload-drivers'
						) ? (
							<Message
								negative
								className="file-error-message"
								size="tiny"
							>
								{
									fileError.filter(
										file => file.id === 'upload-drivers'
									)[0].fileError
								}
							</Message>
						) : null}
						<div className="modal-action">
							<Button
								className="app-primary-button full-button"
								disabled={
									fileData.length <= 0 ||
									fileError.length > 0 ||
									(parsedDatas && parsedDatas.length <= 0) ||
									(parsedDatas['upload-drivers'] &&
										parsedDatas['upload-drivers'].length <=
											0)
								}
								onClick={() => {
									if (postAction !== 'edit') {
										setRegularSort(false);
									}
									setActionModal(true);
									setBulkUpload(false);
								}}
							>
								Save
							</Button>
							<Button
								className="app-primary-inverted-button full-button"
								onClick={() => {
									clearForm();
									setBulkUpload(false);
								}}
							>
								Cancel
							</Button>
						</div>
					</FocusLock>
				</Modal.Content>
			</Modal>

			{/* Single post prompt modal */}
			<Modal
				closeOnEscape={false}
				closeOnDimmerClick={false}
				onClose={() => setActionModal(false)}
				onOpen={() => setActionModal(true)}
				open={actionModal}
				className="employee-form-modal"
				style={{ maxWidth: '515px' }}
			>
				<Modal.Content>
					<FocusLock disabled={!actionModal} autoFocus={false}>
						<img
							src={`${process.env.REACT_APP_BASE_URL}/images/question-logo.png`}
						/>
						<Header as="h3">
							{postAction == 'add' || postAction == 'bulk-add'
								? `Save new driver/s?`
								: 'Save changes?'}
						</Header>
						<p className="modal-sub-header custom-sentence-break">
							Are you sure you want to{' '}
							{postAction == 'add' || postAction == 'bulk-add'
								? `save and add the new driver to the list?`
								: 'save the changes made to the driver details?'}
						</p>
						<div className="modal-action">
							<Button
								onClick={() => {
									if (postAction !== 'edit') {
										setRegularSort(false);
									}

									if (postAction == 'bulk-add') {
										onClickBulkProceed();
									} else if (postAction === 'edit') {
										onPatchDriver();
									} else {
										onClickProceed();
									}
								}}
								className="app-primary-button custom-full-button"
							>
								Yes, proceed
							</Button>
							<Button
								className="app-primary-inverted-button custom-full-button"
								onClick={() => {
									onClickCancel();
								}}
							>
								Cancel and go back
							</Button>
						</div>
					</FocusLock>
				</Modal.Content>
			</Modal>

			{/* Success prompt for adding/editing */}
			<Modal
				onClose={() => setConfirmationModal(false)}
				open={confirmationModal}
				className="desks-modal bulk-completion-modal"
			>
				<Modal.Content>
					<div className="bulk-completion-modal-icon">
						<img src={successImg} alt="success" />
						<Header as="h3">
							{postAction == 'add' || postAction == 'bulk-add'
								? `New driver added`
								: 'Changes saved!'}
						</Header>
						<p>
							{postAction == 'add' || postAction == 'bulk-add'
								? `You may now assign trips to the new driver`
								: 'Changes made to the driver details were saved successfully'}
						</p>
					</div>
				</Modal.Content>
			</Modal>

			{/* Delete driver prompt */}

			<Modal
				closeOnEscape={false}
				closeOnDimmerClick={false}
				onClose={() => setDeleteConfirmModal(false)}
				onOpen={() => setDeleteConfirmModal(true)}
				open={deleteConfirmModal}
				className="employee-form-modal"
				style={{ maxWidth: '515px' }}
			>
				<Modal.Content>
					<FocusLock disabled={!actionModal} autoFocus={false}>
						<img
							src={`${process.env.REACT_APP_BASE_URL}/images/question-logo.png`}
						/>
						<Header as="h3">Delete driver?</Header>
						<p className="modal-sub-header custom-sentence-break">
							Are you sure you want to delete{' '}
							{concatFullname(
								selectedDriver.user
									? selectedDriver.user.profile.first_name
									: '',
								selectedDriver.user
									? selectedDriver.user.profile.last_name
									: ''
							)}
							from the list of shuttle drivers?
						</p>
						<div className="modal-action">
							<Button
								onClick={event => {
									onDriverDisabled(event, selectedDriver);
								}}
								className="app-primary-button custom-full-button"
							>
								Yes, proceed
							</Button>
							<Button
								className="app-primary-inverted-button custom-full-button"
								onClick={() => {
									setDeleteConfirmModal(false);
								}}
							>
								Cancel
							</Button>
						</div>
					</FocusLock>
				</Modal.Content>
			</Modal>

			{/* Successsful deletion of driver prompt */}

			<Modal
				onClose={() => setSuccessfulDeletionModal(false)}
				open={successfulDeletionModal}
				className="desks-modal bulk-completion-modal"
			>
				<Modal.Content>
					<div className="bulk-completion-modal-icon">
						<img src={successImg} alt="success" />
						<Header as="h3">Driver deleted!</Header>
						<p>
							You have successfully deleted{' '}
							{concatFullname(
								selectedDriver.user
									? selectedDriver.user.profile.first_name
									: '',
								selectedDriver.user
									? selectedDriver.user.profile.last_name
									: ''
							)}{' '}
							from the list of shuttle drivers
						</p>
					</div>
				</Modal.Content>
			</Modal>

			{/* On change status confirmation prompt */}

			<Modal
				closeOnEscape={false}
				closeOnDimmerClick={false}
				onClose={() => setChangeStatusModal(false)}
				onOpen={() => setChangeStatusModal(true)}
				open={changeStatusModal}
				className="employee-form-modal"
				style={{ maxWidth: '515px' }}
			>
				<Modal.Content>
					<FocusLock disabled={!changeStatusModal} autoFocus={false}>
						<img
							src={`${process.env.REACT_APP_BASE_URL}/images/question-logo.png`}
						/>
						<Header as="h3">Save Changes?</Header>
						<p className="modal-sub-header custom-sentence-break">
							Are you sure you want to save the changes made to
							the driver details?
						</p>
						<div className="modal-action">
							<Button
								onClick={event => {
									onPatchDriverStatus(selectedDriver);
								}}
								className="app-primary-button custom-full-button"
							>
								Yes, proceed
							</Button>
							<Button
								className="app-primary-inverted-button custom-full-button"
								onClick={() => {
									setChangeStatusModal(false);
								}}
							>
								Cancel
							</Button>
						</div>
					</FocusLock>
				</Modal.Content>
			</Modal>

			{/* Successsful deletion of driver prompt */}

			<Modal
				onClose={() => setSuccessChangeStatusModal(false)}
				open={successChangeStatusModal}
				className="desks-modal bulk-completion-modal"
			>
				<Modal.Content>
					<div className="bulk-completion-modal-icon">
						<img src={successImg} alt="success" />
						<Header as="h3">Changes saved!</Header>
						<p>
							Changes made to the driver details were saved
							successfully
						</p>
					</div>
				</Modal.Content>
			</Modal>

			{/* Successsful deletion of vehicle prompt */}

			<Modal
				onClose={() => setConflictTripModal(false)}
				open={conflictTripModal}
				className="desks-modal bulk-completion-modal"
			>
				<Modal.Content>
					<div className="bulk-completion-modal-icon">
						<img src={successImg} alt="success" />
						<Header as="h3">Changes did not saved!</Header>
						<p>
							Action will affect connected trip schedules. <br />{' '}
							Please unassign this driver first.
						</p>
						<div className="modal-action">
							<Button
								className="app-primary-button custom-full-button"
								onClick={evt => {
									onTabChange(evt, { activeIndex: 1 });
								}}
							>
								Go to trip scheduler
							</Button>
							<Button
								className="app-primary-inverted-button custom-full-button"
								onClick={() => {
									setConflictTripModal(false);
								}}
							>
								Cancel
							</Button>
						</div>
					</div>
				</Modal.Content>
			</Modal>
		</div>
	);
};

Drivers.propTypes = {
	props: PropTypes.any,
	drivers: PropTypes.object,
	getDrivers: PropTypes.func,
	paginate: PropTypes.object,
	onPageChange: PropTypes.func,
	driverFilter: PropTypes.object,
	handleChangeFilter: PropTypes.func,
	location: PropTypes.any,
	history: PropTypes.any,
	match: PropTypes.any,
	regularSort: PropTypes.bool,
	setRegularSort: PropTypes.func,
	parsedDatas: PropTypes.any,
	clearForm: PropTypes.func,
	setPaginate: PropTypes.func,
	onTabChange: PropTypes.func,
};

export default Drivers;

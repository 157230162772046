// react lib
// grahpql queries
import {
	useApolloClient,
	useLazyQuery,
	useMutation,
	useQuery,
} from '@apollo/client';
import * as moment from 'moment';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
// semantic ui components
import { Header, Tab } from 'semantic-ui-react';
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';
import GLoader from '../../../components/Loader/Loader';
import LoaderContext from '../../../context/LoaderContext/LoaderContext';
import ParserContext from '../../../context/ParserContext/ParserContext';
import useDebounce from '../../../hooks/useDebounce';
import { ServiceTypes } from '../../../models/ServiceTypes';
import { arraySort, chunkArray, decrypt, ordinalSort } from '../../../utils';
import { POST_WORKDESKS } from '../graphql/mutation';
import {
	CHECK_WORKDESKS,
	GET_SERVICE_INSTANCE,
	GET_SERVICE_INSTANCES_AREAS,
	GET_SITE_FLOORS,
	GET_TRIP_SLOTS,
	GET_WORKDESK_SLOTS,
	GET_TRIP_ORIGINS,
	GET_TRIP_DESTINATIONS,
} from '../graphql/query';
// master layout
import LayoutPage from '../ServiceDashboard';
import Components from './Components/Components';
// components
import Desks from './Desks/Desks';
import Reports from './Reports/Reports';
import ServiceBuilder from './ServiceBuilder/ServiceBuilder';
// style
import './ServiceDashboardPerSite.css';
import Shareaparking from './Share-a-parking/Share-a-parking';
import TripScheduler from './TripScheduler/TripScheduler';
import TripsToday from './TripsToday/TripsToday';

const ServiceDashboardPerSite = props => {
	const serviceType = JSON.parse(
		decrypt(localStorage.getItem('service_type'))
	);

	const client = useApolloClient();
	const { showLoading, hideLoading } = useContext(LoaderContext);
	const {
		fileData,
		setFileError,
		fileError,
		setFileData,
		setExcelFile,
		setError,
	} = useContext(ParserContext);
	// const [loading, setLoading] = useState(false);
	const [workdeskSort, setWorkdeskSort] = useState({});
	const [checkedWorkdesks, setCheckedWorkdesks] = useState([]);
	const [bulkModalAlert, setBulkModalAlert] = useState(false);
	const [bulkUploadModal, setBulkUploadModal] = useState(false);
	const [tripOrigins, setTripOrigins] = useState([]);
	const [tripDestinations, setTripDestinations] = useState([]);
	const [vehiclesOptions, setVehiclesOptions] = useState([]);
	const [driversOptions, setDriversOptions] = useState([]);

	const [filter, setFilter] = useState({
		floors_id: null,
		area: null,
		booking_status: null, // 0-disabled, 1-enabled,
		state: null,
		year: parseInt(moment().format('YYYY')),
		month: parseInt(moment().format('M')),
	});
	// const [formSlot, setFormSlot] = useState({
	// 	name: '',
	// 	area: '',
	// 	floors_id: null,
	// });
	// const debouncedWorkdeskSort = useDebounce(workdeskSort, 500);

	const [slots, setSlots] = useState({
		slots: [],
		total_slots: 0,
	});
	const [trips, setTrips] = useState({
		trips: [],
		total_trips: 0,
	});
	const [floors, setFloors] = useState([]);
	const [areas, setAreas] = useState([]);
	const [parseDesk, setParseDesk] = useState([]);
	const [defaultActiveIndex, setDefaultActiveIndex] = useState(0);
	const [tabActiveIndex, setTabActiveIndex] = useState(0);
	const [paginate, setPaginate] = useState({
		page: 1,
		offset: 10,
	});
	const [tripFilter, setTripFilter] = useState({
		origin: 0,
		destination: 0,
		vehicle: 0,
		driver: 0,
		keyword: null,
	});
	const sitesIds = props.location.state.site.map(
		site => site.service_instances_id
	);

	const [searchFloorString, setSearchFloorString] = useState(null);
	const debouncedSearchFloorString = useDebounce(searchFloorString, 500);
	const [floorLoading, setFloorLoading] = useState(false);

	const [searchAreaString, setSearchAreaString] = useState(null);
	const debouncedSearchAreaString = useDebounce(searchAreaString, 500);
	const [areaLoading, setAreaLoading] = useState(false);
	const [vehiclesKeyword, setVehiclesKeyword] = useState(null);
	const [driversKeyword, setDriversKeyword] = useState(null);
	const [originsKeyword, setOriginsKeyword] = useState(null);
	const [destinationKeyword, setDestinationKeyword] = useState(null);

	const [fileToUpload, setFileToUpload] = useState('');

	const debouncedKeyword = useDebounce(tripFilter.keyword, 500);

	const debouncedOrigins = useDebounce(originsKeyword, 500);
	const debouncedDestinations = useDebounce(destinationKeyword, 500);

	const {
		data: serviceInstance,
		loading: serviceLoading,
		refetch: refetchServiceInstance,
	} = useQuery(GET_SERVICE_INSTANCE, {
		variables: {
			service_instances_ids: sitesIds,
			service_types_id: serviceType.id,
		},
	});

	const [getTripOrigins, { loading: tripOriginLoading }] = useLazyQuery(
		GET_TRIP_ORIGINS,
		{
			onCompleted: ({ getTripOrigins }) => {
				setTripOrigins(getTripOrigins);
			},
		}
	);

	const [
		getTripDestinations,
		{ loading: tripDestinationLoading },
	] = useLazyQuery(GET_TRIP_DESTINATIONS, {
		onCompleted: ({ getTripDestinations }) => {
			setTripDestinations(getTripDestinations);
		},
	});

	useEffect(() => {
		if (serviceType.name === 'Shuttle') {
			// const sites = await client.query();
			getTripOrigins({
				variables: {
					sites_id: props.location.state.site[0].id,
					keyword: debouncedOrigins,
				},
			});
		}
	}, [debouncedOrigins]);

	useEffect(() => {
		if (serviceType.name === 'Shuttle') {
			// const sites = await client.query();
			getTripDestinations({
				variables: {
					sites_id: props.location.state.site[0].id,
					keyword: debouncedDestinations,
				},
			});
		}
	}, [debouncedDestinations]);

	// componentDidMount
	useEffect(() => {
		if (localStorage.getItem('tab')) {
			setTabActiveIndex(localStorage.getItem('tab'));
			localStorage.removeItem('tab');
		}
	}, []);

	// const getTripPoints = async () => {
	// 	const sites = await client.query({
	// 		query: GET_TRIP_POINTS,
	// 		variables: {
	// 			sites_id: props.location.state.site[0].id,
	// 		},
	// 	});

	// 	setTripPoints(sites.data.getTripPoints);
	// };

	useEffect(() => {
		// clean up fileuploaded on bulk modal trigger
		setFileData([]);
		setFileError([]);
		setError([]);
		setFileData([]);
		setExcelFile([]);
	}, [bulkUploadModal]);

	const onChangeWorkdeskSort = key => {
		setWorkdeskSort(prev => {
			return {
				[key]: !prev[key],
			};
		});
	};

	useEffect(() => {
		showLoading('Searching...');
		refetchServiceInstance({
			variables: {
				service_instances_ids: sitesIds,
			},
		});
		hideLoading();
	}, [serviceInstance]);

	// when typing and searching on option in vehicle
	const onFilterVehiclePlateNumber = evt => {
		setVehiclesKeyword(evt.target.value);
	};

	// when typing and searching on option in driver
	const onFilterDriverName = evt => {
		setDriversKeyword(evt.target.value);
	};

	// when typing and searching on option in origin
	const onFilterOrigin = evt => {
		setOriginsKeyword(evt.target.value);
	};

	// when typing and searching on option in destination
	const onFilterDestination = evt => {
		setDestinationKeyword(evt.target.value);
	};

	const handleChangeFilter = (key, value) => {
		if (key === 'floors_id') {
			const floorsIndex = floors.findIndex(floor => floor.id === value);
			if (floorsIndex > -1) {
				const floor = floors[floorsIndex];
				setSearchFloorString(floor.text);
			} else {
				setSearchFloorString('All floors');
			}
		}
		setFilter({
			...filter,
			[key]: value,
		});
		setPaginate({
			...paginate,
			page: 1,
		});
	};

	const handleTripFilterChange = (key, value) => {
		if (
			key === 'vehicle' ||
			key === 'driver' ||
			key === 'origin' ||
			key === 'destination'
		) {
			if (key === 'vehicle') {
				setVehiclesKeyword(
					value != 0
						? `${value.make} ${value.model} ${value.plate_number}`
						: ''
				);
			}
			if (key === 'driver') {
				setDriversKeyword(
					value != 0
						? `${value.user.profile.first_name} ${value.user.profile.last_name}`
						: ''
				);
			}
			if (key === 'origin') {
				setOriginsKeyword(value != 0 ? value.name : '');
			}
			if (key === 'destination') {
				setDestinationKeyword(value != 0 ? value.name : '');
			}
			setTripFilter({
				...tripFilter,
				[key]: value.id,
			});
		} else {
			setTripFilter({
				...tripFilter,
				[key]: value,
			});
		}

		setPaginate({
			...paginate,
			page: 1,
		});
	};

	const onNavigateToReportItem = slot => {
		props.history.push('report-item/' + slot.id, {
			site: props.location.state.site,
			slot: [{ ...slot }],
			service:
				serviceInstance && serviceInstance.getServiceInstance
					? serviceInstance.getServiceInstance
					: {},
		});
	};
	const tabPanes = [
		{
			menuItem: 'Reports',
			render: () => (
				<Tab.Pane attached={false}>
					<Reports
						slots={serviceType.name === 'Shuttle' ? trips : slots}
						filter={filter}
						onChangeWorkdeskSort={onChangeWorkdeskSort}
						workdeskSort={workdeskSort}
						onNavigateToReportItem={onNavigateToReportItem}
						floors={floors}
						paginate={paginate}
						onPageChange={onPageChange}
						handleChangeFilter={handleChangeFilter}
						service={
							serviceInstance &&
							serviceInstance.getServiceInstance
								? serviceInstance.getServiceInstance
								: {}
						}
						tripFilter={tripFilter}
						site={props.location.state.site[0]}
						onFilterFloorsDropdowwn={onFilterFloorsDropdowwn}
						floorLoading={floorLoading}
						ServiceTypes={ServiceTypes}
						handleTripFilterChange={handleTripFilterChange}
						tripOrigins={tripOrigins}
						tripDestinations={tripDestinations}
						tripOriginLoading={tripOriginLoading}
						tripDestinationLoading={tripDestinationLoading}
						setFilter={setFilter}
						vehiclesKeyword={vehiclesKeyword}
						driversKeyword={driversKeyword}
						originsKeyword={originsKeyword}
						destinationKeyword={destinationKeyword}
						onFilterVehiclePlateNumber={onFilterVehiclePlateNumber}
						onFilterDriverName={onFilterDriverName}
						onFilterOrigin={onFilterOrigin}
						searchFloorString={searchFloorString}
						onFilterDestination={onFilterDestination}
						vehiclesOptions={vehiclesOptions}
						setVehiclesOptions={setVehiclesOptions}
						driversOptions={driversOptions}
						setDriversOptions={setDriversOptions}
					/>
				</Tab.Pane>
			),
		},
		{
			menuItem: 'Service builder',
			render: () => (
				<Tab.Pane attached={false}>
					<ServiceBuilder
						service={
							serviceInstance &&
							serviceInstance.getServiceInstance
								? serviceInstance.getServiceInstance
								: {}
						}
						services={props.location.state.site}
						// service_type={JSON.parse(decrypt(localStorage.getItem('service_type')))}
						// added here the sorted availabilities from serviceInstance data
						sortedAvailabilities={
							serviceInstance &&
							serviceInstance.getServiceInstance &&
							serviceInstance.getServiceInstance.availabilities &&
							serviceInstance.getServiceInstance.availabilities
								.length > 0
								? serviceInstance.getServiceInstance.availabilities
										.map(availabilityDay => {
											return {
												day: availabilityDay.day,
												startime:
													availabilityDay.startime,
												endtime:
													availabilityDay.endtime,
												is_available:
													availabilityDay.is_available,
											};
										})
										.sort(arraySort('day'))
								: []
						}
					/>
				</Tab.Pane>
			),
		},
	];

	if (serviceType.name === 'Parking') {
		tabPanes.unshift({
			menuItem: '‘Share-a-parking’',
			render: () => (
				<Tab.Pane attached={false}>
					<Shareaparking
						service={
							serviceInstance &&
							serviceInstance.getServiceInstance
								? serviceInstance.getServiceInstance
								: {}
						}
						sites_id={props.location.state.site[0].id}
					/>
				</Tab.Pane>
			),
		});
	}

	if (serviceType.name !== 'Shuttle') {
		tabPanes.unshift({
			menuItem:
				serviceInstance &&
				serviceInstance.getServiceInstance &&
				serviceInstance.getServiceInstance.service_type.name &&
				ServiceTypes[
					serviceInstance.getServiceInstance.service_type.name
				].site_dashboard_tab,
			render: () => (
				<Tab.Pane attached={false}>
					<Desks
						site={props.location.state.site}
						slots={slots}
						getWorkdeskSlots={getWorkdeskSlots}
						floors={floors}
						areas={areas}
						filter={filter}
						handleChangeFilter={handleChangeFilter}
						props={props}
						parseDesk={parseDesk}
						postWorkdeskSlotHandler={postWorkdeskSlotHandler}
						paginate={paginate}
						onPageChange={onPageChange}
						bulkModalAlert={bulkModalAlert}
						setBulkModalAlert={setBulkModalAlert}
						bulkUploadModal={bulkUploadModal}
						setBulkUploadModal={setBulkUploadModal}
						service={
							serviceInstance &&
							serviceInstance.getServiceInstance
								? serviceInstance.getServiceInstance
								: {}
						}
						checkedWorkdesks={checkedWorkdesks}
						setCheckedWorkdesks={setCheckedWorkdesks}
						workdeskSort={workdeskSort}
						onFilterFloorsDropdowwn={onFilterFloorsDropdowwn}
						floorLoading={floorLoading}
						onFilterAreasDropdowwn={onFilterAreasDropdowwn}
						areaLoading={areaLoading}
						setFileToUpload={setFileToUpload}
						searchFloorString={searchFloorString}
						setSearchFloorString={setSearchFloorString}
						getSiteFloors={getSiteFloors}
						getServiceInstanceArea={getServiceInstanceArea}
						setPaginate={setPaginate}
					/>
				</Tab.Pane>
			),
		});
	} else {
		tabPanes.unshift(
			{
				menuItem: 'Trips today',
				render: () => (
					<Tab.Pane attached={false} style={{ padding: '0px' }}>
						<TripsToday
							service={
								serviceInstance &&
								serviceInstance.getServiceInstance
									? serviceInstance.getServiceInstance
									: {}
							}
							fetchTripSlots={fetchTripSlots}
						/>
					</Tab.Pane>
				),
			},
			{
				menuItem: 'Trip scheduler',
				render: () => (
					<Tab.Pane attached={false} style={{ padding: '0px' }}>
						<TripScheduler
							service={
								serviceInstance &&
								serviceInstance.getServiceInstance
									? serviceInstance.getServiceInstance
									: {}
							}
							fetchTripSlots={fetchTripSlots}
						/>
					</Tab.Pane>
				),
			},
			{
				menuItem: 'Components',
				render: () => (
					<Tab.Pane attached={false} style={{ padding: '0px' }}>
						<Components
							props={props}
							// drivers={drivers}
							// getDrivers={getDrivers}
							paginate={paginate}
							setPaginate={setPaginate}
							onPageChange={onPageChange}
							onTabChange={onTabChange}
							// driverFilter={driverFilter}
						/>
					</Tab.Pane>
				),
			}
			// {
			// 	menuItem: 'Drivers',
			// 	render: () => (
			// 		<Tab.Pane attached={false} style={{ padding: '0px' }}>
			// 			<Drivers drivers={drivers} getDrivers={getDrivers} />
			// 		</Tab.Pane>
			// 	),
			// }
		);
	}

	const onTabChange = (evt, tab) => {
		if (slots.slots && slots.slots.length > 0) {
			// report's tab in workdesk, cafeteria
			if (serviceType.name !== 'Parking' && tab.activeIndex === 1) {
				setFilter({
					floors_id: null,
					area: null,
					booking_status: null, // 0-disabled, 1-enabled,
					state: null,
					year: parseInt(moment().format('YYYY')),
					month: parseInt(moment().format('M')),
				});
				setPaginate({
					...paginate,
					page: 1,
				});
			}

			// report's tab in parking service
			if (serviceType.name === 'Parking' && tab.activeIndex === 2) {
				setFilter({
					floors_id: null,
					area: null,
					booking_status: null, // 0-disabled, 1-enabled,
					state: null,
					year: parseInt(moment().format('YYYY')),
					month: parseInt(moment().format('M')),
				});
				setPaginate({
					...paginate,
					page: 1,
				});
			}

			if (tab.activeIndex === 0) {
				setWorkdeskSort({});
				setFilter({
					floors_id: null,
					area: null,
					booking_status: null, // 0-disabled, 1-enabled,
					state: null,
					year: parseInt(moment().format('YYYY')),
					month: parseInt(moment().format('M')),
				});
				setPaginate({
					...paginate,
					page: 1,
				});
			}
		}

		if (serviceType.name === 'Shuttle') {
			if (tab.activeIndex === 2) {
				setPaginate({
					...paginate,
					page: 1,
				});
			}
		}

		setTabActiveIndex(tab.activeIndex);
	};
	const [postWorkdeskSlot] = useMutation(POST_WORKDESKS);

	useEffect(() => {
		getWorkdeskSlots();
	}, [
		useDebounce(filter, 500),
		paginate.page,
		workdeskSort,
		tripFilter.origin,
		tripFilter.destination,
		tripFilter.vehicle,
		tripFilter.driver,
		debouncedKeyword,
		filter,
	]);

	useEffect(() => {
		if (serviceType.name !== 'Shuttle') {
			getServiceInstanceArea();
		}
	}, [debouncedSearchAreaString]);

	useEffect(() => {
		if (serviceType.name !== 'Shuttle') {
			getSiteFloors();
		}
	}, [debouncedSearchFloorString]);

	const onPageChange = (evt, page) => {
		setPaginate({
			...paginate,
			page: page.activePage,
		});
	};
	const getWorkdeskSlots = async () => {
		// let slotTxt = ;
		let service_type = JSON.parse(
			decrypt(localStorage.getItem('service_type'))
		);

		showLoading(`Loading ${ServiceTypes[serviceType.name].slotType2}s...`);
		const sort_obj = Object.keys(workdeskSort).map(sortKey => {
			if (workdeskSort[sortKey] !== null) {
				return {
					column: sortKey,
					order: workdeskSort[sortKey] ? 'asc' : 'desc',
				};
			}
		});
		if (serviceType.name !== 'Shuttle') {
			const result = await client.query({
				query: GET_WORKDESK_SLOTS,
				fetchPolicy: 'network-only',
				variables: {
					input: {
						...filter,
						service_instances_id:
							props.location.state.site[0].service_instances_id,
						area: filter.area === 0 ? null : filter.area,
						floors_id:
							filter.floors_id === 0 ? null : filter.floors_id,
						state: filter.state === 0 ? null : filter.state,
						booking_status:
							filter.booking_status === 0
								? null
								: filter.booking_status,
					},
					sort: sort_obj[0],
					paginate,
				},
			});
			setSlots({
				slots: result.data.getWorkdeskSlots.slots,
				total_slots: result.data.getWorkdeskSlots.total,
			});
		} else {
			fetchTripSlots();
		}
		hideLoading();
	};

	const fetchTripSlots = async () => {
		const sort_obj = Object.keys(workdeskSort).map(sortKey => {
			if (workdeskSort[sortKey] !== null) {
				return {
					column: sortKey,
					order: workdeskSort[sortKey] ? 'asc' : 'desc',
				};
			}
		});
		const result = await client.query({
			query: GET_TRIP_SLOTS,
			fetchPolicy: 'network-only',
			variables: {
				service_instances_id:
					props.location.state.site[0].service_instances_id,
				tripDateFilter: {
					month: parseInt(filter.month),
					year: parseInt(filter.year),
				},
				tripFilter: {
					...tripFilter,
				},
				sort: sort_obj[0],
				paginate,
			},
		});
		setTrips({
			trips: result.data.getTripSlotsReport.trips,
			total_trips: result.data.getTripSlotsReport.total_count,
		});
	};

	const getSiteFloors = async () => {
		setFloorLoading(true);

		const result = await client.query({
			query: GET_SITE_FLOORS,
			fetchPolicy: 'network-only',
			variables: {
				service_instances_id:
					props.location.state.site[0].service_instances_id,
				sites_id: props.location.state.site[0].id,
				filter: debouncedSearchFloorString,
			},
		});
		setFloorLoading(false);
		setFloors(
			result.data.getSiteFloors
				.map(floor => {
					return {
						id: floor.id,
						text: floor.name,
						value: floor.id,
					};
				})
				.sort(ordinalSort('text'))
		);
	};

	const getServiceInstanceArea = async () => {
		setAreaLoading(true);
		/* setPaginate({
			...paginate,
			page: 1,
		}); */
		const result = await client.query({
			query: GET_SERVICE_INSTANCES_AREAS,
			fetchPolicy: 'network-only',
			variables: {
				service_instances_id:
					props.location.state.site[0].service_instances_id,
				filter: debouncedSearchAreaString,
			},
		});
		setAreaLoading(false);
		// if (areas.length == 0) {
		let siteAreas = [];
		siteAreas.push({
			key: 'all',
			text: 'All areas',
			value: 'all',
			selected: false,
		});
		result.data.getServiceInstanceAreas.map((slot, i) => {
			if (!siteAreas.some(siteArea => siteArea.value === slot.area)) {
				siteAreas.push({
					key: i,
					text: slot.area,
					value: slot.area,
				});
			}
		});

		setAreas(siteAreas);
		// }
	};

	// parsing excel file when fileData changes
	if (serviceType.name !== 'Shuttle') {
		useEffect(() => {
			if (fileData && fileData.length <= 0) {
				clearForm();
			}
			if (
				fileData.filter(fileId => fileId.id === 'share-a-parking')
					.length <= 0
			) {
				formatParseData();
			}
		}, [fileData]);
	}

	const formatParseData = async () => {
		let slotArr = [];
		let floorArr = [];
		let validateDesk = [];
		let err = null;
		let updatedParseDesk;
		if (fileData && fileData.length > 0) {
			const fileIndex = fileData.length - 1;
			const parsedData = fileData[fileIndex];
			if (parsedData.data.length <= 3) {
				err = 'File must have at least one row/line data';
			}
			if (parsedData.data && parsedData.data.length > 1) {
				for (let i = 0; i < parsedData.data.length; i++) {
					const rows = parsedData.data[i];
					if (err === null && i > 2) {
						if (rows.length != 3) {
							err = 'We found error/s on your uploaded file';
						}
						if (i >= 3) {
							if (
								rows.includes('') ||
								rows.includes(null) ||
								rows.includes(undefined)
							) {
								err = 'We found error/s on your uploaded file';
							}
						}
					}
				}

				const parsedIndex = parseDesk.findIndex(
					parsed => parsed.id === parsedData.id
				);

				if (parsedIndex < 0) {
					// updating parsed desks
					updatedParseDesk = [
						...parseDesk,
						{
							id: parsedData.id,
							slots: slotArr,
							floors: floorArr,
						},
					];
				} else {
					const updatedDesks = [...parseDesk];
					updatedDesks[parsedIndex] = {
						id: parsedData.id,
						slots: slotArr,
						floors: floorArr,
					};
					updatedParseDesk = updatedDesks;
				}
			} else {
				updatedParseDesk = parseDesk.filter(
					desk => desk.id !== parsedData.id
				);
				err = 'We found error/s on your uploaded file';
			}

			if (!err) {
				parsedData.data.forEach((rows, index) => {
					if (index > 2) {
						slotArr.push({
							name: parsedData.data[index][2],
							floor: parsedData.data[index][1],
							area: parsedData.data[index][0],
						});

						floorArr.push({
							name: parsedData.data[index][1],
						});

						validateDesk.push({
							name: parsedData.data[index][2],
							area: parsedData.data[index][0],
							floor: parsedData.data[index][1],
						});
					}
				});

				const duplicateSlots = await client.query({
					query: CHECK_WORKDESKS,
					fetchPolicy: 'network-only',
					variables: {
						siteSlots: {
							sites_id: props.location.state.site[0].id,
							slots: validateDesk,
						},
					},
				});

				if (
					duplicateSlots &&
					duplicateSlots.data &&
					!duplicateSlots.data.checkWorkdeskSlots.status
				) {
					err = 'We found error/s on your uploaded file';
				}
			}

			if (err) {
				// clearForm();
				setFileError([
					...fileError,
					{
						id: parsedData.id,
						fileError: err,
					},
				]);
			} else {
				setParseDesk(updatedParseDesk);
			}
		}
	};

	const postWorkdeskSlotHandler = async () => {
		const chunkedWorkdesks = chunkArray(parseDesk[0].slots, 1000);
		// const chunkedFloors = chunkArray(parseDesk[0].floors, 5000);
		for (let i = 0; i < chunkedWorkdesks.length; i++) {
			showLoading(
				`Inserting workdesks...${
					chunkedWorkdesks.length > 1
						? i + '/' + chunkedWorkdesks.length
						: ''
				}`
			);
			const sort_obj = Object.keys(workdeskSort).map(sortKey => {
				if (workdeskSort[sortKey] !== null) {
					return {
						column: sortKey,
						order: workdeskSort[sortKey] ? 'asc' : 'desc',
					};
				}
			});
			await postWorkdeskSlot({
				variables: {
					input: {
						service_instances_id:
							props.location.state.site[0].service_instances_id,
						sites_id: props.location.state.site[0].id,
						slots: chunkedWorkdesks[i].sort(arraySort('floor')),
						file_to_upload: fileToUpload,
					},
				},
				awaitRefetchQueries: true,
				refetchQueries: [
					{
						query: GET_WORKDESK_SLOTS,
						fetchPolicy: 'network-only',
						variables: {
							input: {
								...filter,
								service_instances_id:
									props.location.state.site[0]
										.service_instances_id,
								area: filter.area === 0 ? null : filter.area,
								floors_id:
									filter.floors_id === 0
										? null
										: filter.floors_id,
								state: filter.state === 0 ? null : filter.state,
								booking_status:
									filter.booking_status === 0
										? null
										: filter.booking_status,
							},
							sort: sort_obj[0],
							paginate,
						},
					},
					{
						query: GET_SERVICE_INSTANCE,
						fetchPolicy: 'network-only',
						variables: {
							service_instances_ids: sitesIds,
							service_types_id: serviceType.id,
						},
					},
				],
			});
			hideLoading();
		}
		setBulkUploadModal(false);
		setBulkModalAlert(true);
		setTimeout(() => {
			setBulkModalAlert(false);
		}, 2000);
		clearForm();
		getWorkdeskSlots();
		getSiteFloors();
		getServiceInstanceArea();
	};

	// breadcrumbs navigation
	const breadcrumbNavigation = breadcrumb => {
		props.history.push(`${breadcrumb.link}`);
	};

	const clearForm = () => {
		if (fileData && fileData.length > 0) {
			setFileData([]);
		}
		setParseDesk([]);
	};

	if (serviceLoading) {
		return <GLoader />;
	}

	// when typing and searching on option
	const onFilterFloorsDropdowwn = evt => {
		setSearchFloorString(evt.target.value);
	};

	// when typing and searching on option
	const onFilterAreasDropdowwn = evt => {
		setSearchAreaString(evt.target.value);
	};
	/** End shuttle related functions */
	return (
		<LayoutPage>
			{serviceType.name !== 'Shuttle' ? (
				<Breadcrumbs
					breadcrumbs={[
						{
							label:
								serviceInstance &&
								serviceInstance.getServiceInstance &&
								serviceInstance.getServiceInstance.service_type
									? serviceInstance.getServiceInstance
											.service_type.name
									: null,
							link: `/serviceadministrator`,
						},
						{ label: props.location.state.site[0].name },
					]}
					breadcrumbNavigation={breadcrumbNavigation}
				/>
			) : (
				// <div className="pt-2 pb-2" />
				<></>
			)}
			{serviceType.name !== 'Shuttle' ? (
				// <div className="mb-4">
				<Header as="h1">{props.location.state.site[0].name}</Header>
			) : (
				<>
					<Header as="h1" className="page-title">
						{ServiceTypes[serviceType.name].name}
					</Header>
					<div className="mb-4">
						<p className="page-subtitle">
							{ServiceTypes[serviceType.name].dashboard_spiel}
						</p>
					</div>
				</>
			)}
			<Tab
				// defaultActiveIndex={defaultActiveIndex}
				className="service-dashboard-site-tab"
				menu={{ secondary: true, pointing: true }}
				panes={tabPanes}
				onTabChange={(evt, tab) => onTabChange(evt, tab)}
				activeIndex={tabActiveIndex}
			/>
		</LayoutPage>
	);
};

ServiceDashboardPerSite.propTypes = {
	location: PropTypes.any,
	history: PropTypes.any,
	match: PropTypes.any,
};

export default ServiceDashboardPerSite;

// react lib
import React, { useContext, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Button, Message } from 'semantic-ui-react';
import ParserContext from '../../context/ParserContext/ParserContext';
import { wordEllipsis } from '../../utils/helper';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';

import './FileUpload.css';

// prop-types for mapping props
import PropTypes from 'prop-types';

const uploadIcon = process.env.REACT_APP_BASE_NAME + 'svgs/upload.svg';
const closeBlue = process.env.REACT_APP_BASE_NAME + 'svgs/close-blue.svg';
const closeRed = process.env.REACT_APP_BASE_NAME + 'svgs/close-red.svg';
const errorAlert = process.env.REACT_APP_BASE_NAME + 'svgs/dark-alert.svg';

const FileUpload = ({ id }) => {
	// setting of setFileData to consume anywhere
	const {
		parserHandler,
		excelFile,
		setExcelFile,
		setFileError,
		fileError,
		fileData,
		setFileData,
		error,
		setError,
		fileLoader,
	} = useContext(ParserContext);
	const { getRootProps, getInputProps } = useDropzone({
		onDrop: acceptedFiles => checkFileHandler(acceptedFiles[0]),
	});

	const checkFileHandler = useCallback(
		file => {
			let err = null;

			// define accepted file types
			let validTypes = [
				'text/csv',
				'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
				'application/vnd.ms-excel',
			];

			// validates file type
			if (!validTypes.includes(file.type)) {
				err =
					'Invalid file type. Please select a .CSV file or any Excel/Google sheet';
			} else if (file.size / 1024 / 1024 > 3) {
				err = 'file must be less than 3MB';
			}

			// getting index of excel files
			const dataIndex = excelFile.findIndex(data => data.id === id);

			// check if there's an errors
			if (err) {
				// const updatedError = fileError.map
				if (error.filter(err => err.id === id).length <= 0) {
					setError([
						...error,
						{
							fileError: err,
							id: id,
						},
					]);
				} else {
					const updatedError = [...error];
					const errorIndex = error.findIndex(err => err.id === id);
					updatedError[errorIndex] = {
						fileError: err,
						id: id,
					};
					setError(updatedError);
				}
				const updatedExcelFile = excelFile.filter(
					excel => excel.id !== id
				);
				const updatedFileData = fileData.filter(
					filedata => filedata.id !== id
				);
				setExcelFile(updatedExcelFile);
				setFileData(updatedFileData);
				return false;
			} else {
				const updatedError = error.filter(err => err.id !== id);
				const updatedFileError = fileError.filter(err => err.id !== id);
				setFileError(updatedFileError);
				setError(updatedError);
			}

			if (dataIndex < 0) {
				// store file
				setExcelFile([
					...excelFile,
					{
						id,
						file,
					},
				]);
			} else {
				const updatedExcelFile = [...excelFile];
				updatedExcelFile[dataIndex] = {
					id,
					file,
				};
				setExcelFile(updatedExcelFile);
			}

			parserHandler(file, id);
		},
		[error, excelFile, fileData, fileError]
	);

	const onRemoveFileHandler = event => {
		event.stopPropagation();
		if (fileLoader) {
			return;
		}
		const updatedError = error.filter(err => err.id !== id);
		const updatedFileError = fileError.filter(err => err.id !== id);
		const updatedExcel = excelFile.filter(excel => excel.id !== id);
		const updatedFileData = fileData.filter(data => data.id !== id);

		setExcelFile(updatedExcel);
		setError(updatedError);
		setFileData(updatedFileData);
		setFileError(updatedFileError);
	};

	return (
		<div>
			<section className="container">
				{fileError.filter(fileRef => fileRef.id === id).length <= 0 &&
				error.filter(fileRef => fileRef.id === id).length <= 0 ? (
					!excelFile.some(fileRef => fileRef.id === id) ? (
						<div {...getRootProps({ className: 'dropzone' })}>
							<input {...getInputProps()} />
							<span className="custom-upload-icon-text">
								Drop your file here to upload or
							</span>
							<div className="btn-container w-75">
								<Button className="app-primary-inverted-button btn-select-file">
									Select file from computer
								</Button>
							</div>
						</div>
					) : (
						<Message
							info
							visible
							className="selected-file file-success"
							size="large"
						>
							<img src={uploadIcon} alt="error alert" />
							<span>
								{wordEllipsis(
									excelFile.filter(
										excel => excel.id === id
									)[0].file.path,
									30
								)}
							</span>
							<img
								src={closeBlue}
								// className="file-close cursor-pointer"
								className={clsx(
									'file-close',
									fileLoader
										? 'cursor-default disabled-img-button'
										: 'cursor-pointer'
								)}
								onClick={onRemoveFileHandler}
							/>
						</Message>
					)
				) : error.filter(fileRef => fileRef.id === id).length > 0 ? (
					<Message
						negative
						size="large"
						className="selected-file file-error"
					>
						{error.filter(error => error.id === id)[0].fileError ===
						'file must be less than 3MB' ? (
							<img
								src={errorAlert}
								className="alert-icon"
								alt="error alert"
							/>
						) : null}
						<span>
							{
								error.filter(error => error.id === id)[0]
									.fileError
							}
						</span>
						<img
							src={closeRed}
							className="file-close cursor-pointer"
							onClick={onRemoveFileHandler}
						/>
					</Message>
				) : (
					<Message
						info
						visible
						className="selected-file file-error"
						size="large"
					>
						<img src={errorAlert} className="alert-icon" />
						<span>
							{wordEllipsis(
								excelFile.filter(excel => excel.id === id)[0]
									.file.path,
								30
							)}
						</span>
						<img
							src={closeRed}
							className="file-close cursor-pointer"
							onClick={onRemoveFileHandler}
						/>
					</Message>
				)}
				{/* <div className="fileupload-info">
					<i>File must be less than 3MB of .csv or .xlsx file</i>
				</div> */}
			</section>
		</div>
	);
};

FileUpload.propTypes = {
	id: PropTypes.string,
};

export default FileUpload;

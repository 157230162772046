// react lib
import React, { useContext } from 'react';
// less (styling)
import './LoginMaster.css';

// semantic ui component
import { Container, Grid, Header } from 'semantic-ui-react';

// prop-types for mapping props
import PropTypes from 'prop-types';

import LoaderContext from '../../../context/LoaderContext/LoaderContext';
import GLoader from '../../../components/Loader/Loader';

const LoginMaster = ({ children }) => {
	const { loading, gloader } = useContext(LoaderContext);
	if (gloader) {
		return <GLoader />;
	}
	return (
		<>
			<div className="login">
				<img
					className="login-bg login-left"
					src={`${process.env.REACT_APP_BASE_URL}/images/login-bg-lower-left.png`}
					alt="Login Background"
				/>
				<img
					className="login-bg login-right"
					src={`${process.env.REACT_APP_BASE_URL}/images/login-bg-upper-right.png`}
					alt="Login Background"
				/>
				<Container>
					<Grid centered>
						<Grid.Row>
							<Grid.Column
								mobile="sixteen"
								tablet="twelve"
								computer="twelve"
							>
								<div className="login-company-logo">
									<img
										src={`${process.env.REACT_APP_BASE_URL}/images/company-logo.png`}
										alt=""
									/>
								</div>
								<div className="login-app-logo">
									<img
										src={`${process.env.REACT_APP_BASE_URL}/images/GAccess-colored.png`}
										alt=""
									/>
								</div>
								{children}
							</Grid.Column>
							<Grid.Column computer="four"></Grid.Column>
						</Grid.Row>
					</Grid>
				</Container>
			</div>
		</>
	);
};

LoginMaster.propTypes = {
	children: PropTypes.any,
};

export default LoginMaster;

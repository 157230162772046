import { useLazyQuery, useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useState } from 'react';
import FocusLock from 'react-focus-lock';
import {
	Button,
	Form,
	Grid,
	Header,
	Message,
	Modal,
	Popup,
	Select,
	Dropdown,
} from 'semantic-ui-react';
import FileUpload from '../../../../components/FileUpload/FileUpload';
import Table from '../../../../components/Table/Table';
import LoaderContext from '../../../../context/LoaderContext/LoaderContext';
import ParserContext from '../../../../context/ParserContext/ParserContext';
import { Vehicle as vehicleModel } from '../../../../models';
import { chunkArray, isAlphaNumSpace, mapDays } from '../../../../utils';
import {
	DELETE_VEHICLE,
	PATCH_VEHICLE_STATUS,
	POST_SINGLE_VEHICLE,
	POST_VEHICLES,
} from '../../graphql/mutation';
import { GET_VEHICLE, GET_VEHICLES } from '../../graphql/query';
import './Vehicles.css';

const successImg =
	process.env.REACT_APP_BASE_NAME + 'images/setupservice-complete.png';
const downloadImg = process.env.REACT_APP_BASE_NAME + 'svgs/download.svg';
const addIcon = process.env.REACT_APP_BASE_NAME + 'svgs/more-white.svg';
// const searchImg = process.env.REACT_APP_BASE_NAME + 'svgs/search.svg';
const errorAlert = process.env.REACT_APP_BASE_NAME + 'svgs/light-alert.svg';

const status = [
	{ key: 'all_status', text: 'All vehicle statuses', value: 'all' },
	{ key: 'in_good_condition', text: 'In good condition', value: '1' },
	{ key: 'sent_for_repair', text: 'Sent for repair', value: '0' },
];

const days = [
	{ key: 'monday', text: 'Monday', value: '1' },
	{ key: 'tuesday', text: 'Tuesday', value: '2' },
	{ key: 'wednesday', text: 'Wednesday', value: '3' },
	{ key: 'thursday', text: 'Thursday', value: '4' },
	{ key: 'friday', text: 'Friday', value: '5' },
];

const Vehicles = ({
	props,
	vehicles,
	getVehicles,
	vehicleFilter,
	paginate,
	onPageChange,
	handleChangeFilter,
	regularSort,
	setRegularSort,
	parsedDatas,
	clearForm,
	makes,
	models,
	getDistinctMake,
	getDistinctModel,
	makeLoading,
	onFilterMakesDropdown,
	setSearchMakeString,
	modelLoading,
	onFilterModelsDropdown,
	setSearchModelString,
	setPaginate,
	onTabChange,
}) => {
	const { showLoading, hideLoading } = useContext(LoaderContext);
	const [individualForm, setIndividualForm] = useState(false);
	const [bulkUpload, setBulkUpload] = useState(false);
	const {
		fileData,
		fileError,
		setFileData,
		setFileError,
		excelFile,
		error: excelError,
	} = useContext(ParserContext);
	const [postError, setPostError] = useState({});

	const [form, setForm] = useState(vehicleModel);

	const [postAction, setPostAction] = useState('');
	const [actionModal, setActionModal] = useState(false);
	const [confirmationModal, setConfirmationModal] = useState(false);
	const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);
	const [successfulDeletionModal, setSuccessfulDeletionModal] = useState(
		false
	);
	const [selectedVehicle, setSelectedVehicle] = useState({});
	const [changeStatusModal, setChangeStatusModal] = useState(false);
	const [successChangeStatusModal, setSuccessChangeStatusModal] = useState(
		false
	);
	const [conflictTripModal, setConflictTripModal] = useState(false);

	const disableSaveButtonHandler = useCallback(() => {
		if (
			form.plate_number == null ||
			form.plate_number.trim() == '' ||
			isAlphaNumSpace(form.plate_number) == false ||
			form.make == null ||
			form.make.trim() == '' ||
			form.model == null ||
			form.model.trim() == '' ||
			form.coding_day == null ||
			form.coding_day == '' ||
			form.capacity == null ||
			form.capacity.trim() == '' ||
			form.capacity == '0' ||
			form.capacity == 0
		) {
			return true;
		}

		return false;
	});

	const vehicles_data = vehicles.vehicles
		? vehicles.vehicles.map(vehicle => {
				let coding_day = mapDays(vehicle.coding_day).name;

				return {
					plate_number: vehicle.plate_number,
					make: vehicle.make,
					model: vehicle.model,
					coding_day,
					capacity: vehicle.capacity,
					status: {
						data: (
							<>
								<Popup
									disabled={true}
									trigger={
										<div className="status-row status-div">
											<div
												className={`status-badge ${(() => {
													switch (vehicle.status) {
														case 0:
															return 'status-suspended';
														case 1:
															return 'status-active';
														default:
															return '';
													}
												})()}`}
											></div>
											<span className="status-text">
												{(() => {
													switch (vehicle.status) {
														case 0:
															return 'Sent for repair';
														case 1:
															return 'In good condition';
														default:
															return '';
													}
												})()}
											</span>
										</div>
									}
								/>
							</>
						),
					},
					action: (
						<>
							<a
								className="btnLink"
								onClick={() => {
									// setIndividualForm(true);
									// setPostAction('edit');
									// onChangeValueHandler('id', vehicle.id);
									// getVehicle();
									handleVehicleEdit(vehicle);
								}}
							>
								Edit
							</a>
							<a
								className="btnLink"
								onClick={evt => {
									setSelectedVehicle(vehicle);
									setDeleteConfirmModal(true);
								}}
							>
								Delete
							</a>
							<a
								className="btnLink"
								onClick={evt => onChangeStatus(vehicle)}
							>
								{vehicle.status == 1
									? 'Send for repair'
									: 'Fixed'}
							</a>
						</>
					),
				};
		  })
		: [];

	const header = [
		'Plate number',
		'Make',
		'Model',
		'Coding day',
		'Capacity',
		'Status',
		'Action',
	];

	const handleVehicleEdit = vehicle => {
		setForm({
			...form,
			id: vehicle.id,
			plate_number: vehicle.plate_number,
			make: vehicle.make,
			model: vehicle.model,
			coding_day: vehicle.coding_day,
			status: vehicle.status.toString(),
			capacity: vehicle.capacity,
		});

		setIndividualForm(true);
		setPostAction('edit');
		// onChangeValueHandler('id', vehicle.id);
	};

	const onChangeValueHandler = (key, value) => {
		if (key === 'capacity') {
			// don't allow negative and empty value
			if (isNaN(value) || parseFloat(value) < 0) value = 0;
			value = value.toString();
		}

		setForm({
			...form,
			[key]: value,
		});
	};

	const onClickProceed = () => {
		showLoading('Saving vehicle...');
		if (postAction == 'add') {
			setPaginate({
				...paginate,
				page: 1,
			});
		}
		postSingleVehicle();
	};
	const onClickBulkProceed = async () => {
		await setPaginate({
			...paginate,
			page: 1,
		});

		const chunckedVehicles = chunkArray(
			parsedDatas['upload-vehicles'],
			1000
		);
		for (let i = 0; i < chunckedVehicles.length; i++) {
			showLoading(
				`Saving vehicles...${
					chunckedVehicles.length > 1
						? i + 1 + '/' + chunckedVehicles.length
						: ''
				}`
			);

			await postVehicles({
				variables: {
					service_instances_id:
						props.location.state.site[0].service_instances_id,
					vehicles: chunckedVehicles[i],
				},
				awaitRefetchQueries: true,
				refetchQueries: [
					{
						query: GET_VEHICLES,
						fetchPolicy: 'network-only',
						variables: {
							input: {
								...vehicleFilter,

								plate_number: vehicleFilter.plate_number,
								status: vehicleFilter.status,
								coding_day: vehicleFilter.coding_day,
								make: vehicleFilter.make,
								model: vehicleFilter.model,
								regular_sort: regularSort,
							},
							service_instances_id:
								props.location.state.site[0]
									.service_instances_id,
							paginate,
						},
					},
				],
			});
		}

		await getVehicles('network-only');

		setIndividualForm(false);
		setForm(vehicleModel);
		setConfirmationModal(true);
		setTimeout(() => {
			setConfirmationModal(false);
		}, 2000);
		setPostError({});
		clearForm();
		getDistinctMake();
		getDistinctModel();
		setSearchMakeString('');
		setSearchModelString('');
		setActionModal(false);
		hideLoading();
	};

	const onClickCancel = () => {
		if (postAction == 'add' || postAction == 'edit')
			setIndividualForm(true);
		else setBulkUpload(true);

		setActionModal(false);
	};

	const [getVehicle] = useLazyQuery(GET_VEHICLE, {
		query: GET_VEHICLE,
		fetchPolicy: 'network-only',
		variables: {
			input: {
				id: form.id,
			},
		},
		onCompleted: async data => {
			setForm({
				...form,
				...data.getVehicle,
			});
		},
	});

	const [postSingleVehicle] = useMutation(POST_SINGLE_VEHICLE, {
		variables: {
			service_instances_id:
				props.location.state.site[0].service_instances_id,
			input: form,
		},
		onError: () => {
			hideLoading();
		},
		awaitRefetchQueries: true,
		refetchQueries: [
			{
				query: GET_VEHICLES,
				fetchPolicy: 'network-only',
				variables: {
					input: {
						...vehicleFilter,

						plate_number: vehicleFilter.plate_number,
						status: vehicleFilter.status,
						coding_day: vehicleFilter.coding_day,
						make: vehicleFilter.make,
						model: vehicleFilter.model,
						regular_sort: regularSort,
					},
					service_instances_id:
						props.location.state.site[0].service_instances_id,
					// sort: sort_obj[0],
					paginate,
				},
			},
		],
		onCompleted: async data => {
			// 1 or true value should go here
			if (data.postSingleVehicle.status) {
				setIndividualForm(false);

				getVehicles('network-only').then(() => {
					setForm(vehicleModel);
					setConfirmationModal(true);
					setTimeout(() => {
						setConfirmationModal(false);
					}, 2000);
					setPostError({});
					getDistinctMake();
					getDistinctModel();
					setSearchMakeString('');
					setSearchModelString('');
				});
			} else {
				setIndividualForm(true);
				setPostError({ ...data.postSingleVehicle });
			}
			setActionModal(false);
			hideLoading();
		},
	});

	const [postVehicles] = useMutation(POST_VEHICLES);

	const onPatchVehicleStatus = async vehicle => {
		try {
			setChangeStatusModal(false);
			let status = vehicle.status == 1 ? 0 : 1;

			await patchVehicleStatus({
				variables: {
					input: {
						id: vehicle.id,
						status,
					},
				},
			});

			hideLoading();
		} catch (error) {
			// TODO: global error handler (toast) - INTERNAL_SERVER_ERROR

		}
	};

	const onChangeStatus = vehicle => {
		setSelectedVehicle(vehicle);
		setChangeStatusModal(true);
	};

	const [patchVehicleStatus] = useMutation(PATCH_VEHICLE_STATUS, {
		onError: () => {
			hideLoading();
		},
		awaitRefetchQueries: true,
		refetchQueries: [
			{
				query: GET_VEHICLES,
				fetchPolicy: 'network-only',
				variables: {
					input: {
						...vehicleFilter,

						plate_number: vehicleFilter.plate_number,
						status: vehicleFilter.status,
						coding_day: vehicleFilter.coding_day,
						make: vehicleFilter.make,
						model: vehicleFilter.model,
						regular_sort: regularSort,
					},
					service_instances_id:
						props.location.state.site[0].service_instances_id,
					// sort: sort_obj[0],
					paginate,
				},
			},
		],
		onCompleted: async data => {
			// 1 or true value should go here
			if (data.patchVehicleStatus.status) {
				getVehicles();
				setPostError({});
				setSuccessChangeStatusModal(true);
				setTimeout(() => {
					setSuccessChangeStatusModal(false);
				}, 2000);
			} else {
				setConflictTripModal(true);
			}
			hideLoading();
		},
	});

	const onVehicleDisabled = async (evt, driver) => {
		showLoading('Deleting vehicle ...');
		setDeleteConfirmModal(false);
		await deleteVehicle({
			variables: {
				input: {
					id: driver.id,
					enabled: 0,
				},
			},
		});
	};

	const [deleteVehicle] = useMutation(DELETE_VEHICLE, {
		onError: () => {
			hideLoading();
		},
		awaitRefetchQueries: true,
		refetchQueries: [
			{
				query: GET_VEHICLES,
				fetchPolicy: 'network-only',
				variables: {
					input: {
						...vehicleFilter,
						plate_number: vehicleFilter.plate_number,
						status: vehicleFilter.status,
						coding_day: vehicleFilter.coding_day,
						make: vehicleFilter.make,
						model: vehicleFilter.model,
						regular_sort: regularSort,
					},
					service_instances_id:
						props.location.state.site[0].service_instances_id,
					// sort: sort_obj[0],
					paginate,
				},
			},
		],
		onCompleted: async data => {
			// 1 or true value should go here
			if (data.deleteVehicle.status) {
				getVehicles();
				if (vehicles.vehicles.length <= 1) {
					setPaginate({
						page: 1,
						offset: 10,
					});
				}
				setPostError({});
				setDeleteConfirmModal(false);
				setSuccessfulDeletionModal(true);
				setTimeout(() => {
					setSuccessfulDeletionModal(false);
				}, 2000);
				setSearchMakeString('');
				setSearchModelString('');
			} else {
				setConflictTripModal(true);
			}
			hideLoading();
		},
	});

	const [makeSearchQuery, setMakeSearchQuery] = useState('');
	const [modelSearchQuery, setModelSearchQuery] = useState('');

	const handleMakeSearchChange = (e, { searchQuery }) => {
		setMakeSearchQuery(searchQuery);
		onFilterMakesDropdown(e);
	};

	const handleModelSearchChange = (e, { searchQuery }) => {
		setModelSearchQuery(searchQuery);
		onFilterModelsDropdown(e);
	};

	return (
		<div>
			<div className="sub-title-shuttle-date">
				<p style={{ fontSize: '14px' }}>Vehicles list</p>
			</div>
			<div className="table-list">
				<div className="table-list-filter workdesks-filter">
					<Form>
						<Grid>
							<Grid.Row>
								<Grid.Column
									mobile={16}
									tablet={12}
									computer={10}
								>
									<Form.Group inline>
										<label>Showing</label>
										<Form.Field width={3}>
											{/* <Form.Field
												control={Select}
												selectOnBlur={false}
												forceSelection={false}
												options={[
													{
														key: 'all_makes',
														text: 'All makes',
														value: 'all',
													},
													...makes,
												]}
												className="filter-select vehicle-filter-select"
												icon="angle down"
												defaultValue={'all'}
												name="coding"
												onChange={(e, { value }) =>
													handleChangeFilter(
														'vehicle',
														'make',
														value
													)
												}
											/> */}
											<Dropdown
												className="slot-search-filter"
												icon={
													makeLoading ? (
														<div className="sk-chase search-loader">
															<div className="sk-chase-dot"></div>
															<div className="sk-chase-dot"></div>
															<div className="sk-chase-dot"></div>
															<div className="sk-chase-dot"></div>
															<div className="sk-chase-dot"></div>
															<div className="sk-chase-dot"></div>
														</div>
													) : (
														'angle down'
													)
												}
												selectOnBlur={false}
												forceSelection={false}
												iconPosition="right"
												defaultValue={'all'}
												placeholder="All makes"
												fluid
												search
												selection
												options={[
													{
														key: 'all_makes',
														text: 'All makes',
														value: 'all',
														selected: false,
													},
													...makes,
												]}
												onSearchChange={
													handleMakeSearchChange
												}
												{...(vehicleFilter.make
													? {
															searchQuery: makeSearchQuery,
													  }
													: {})}
												name="make"
												onChange={(e, { value }) => {
													handleChangeFilter(
														'vehicle',
														'make',
														value
													);
													setMakeSearchQuery(
														value == 'all'
															? ''
															: e.target
																	.textContent
													);
												}}
											></Dropdown>
										</Form.Field>
										<Form.Field width={3}>
											<Dropdown
												className="slot-search-filter"
												icon={
													modelLoading ? (
														<div className="sk-chase search-loader">
															<div className="sk-chase-dot"></div>
															<div className="sk-chase-dot"></div>
															<div className="sk-chase-dot"></div>
															<div className="sk-chase-dot"></div>
															<div className="sk-chase-dot"></div>
															<div className="sk-chase-dot"></div>
														</div>
													) : (
														'angle down'
													)
												}
												selectOnBlur={false}
												forceSelection={false}
												iconPosition="right"
												defaultValue={'all'}
												placeholder="All models"
												fluid
												search
												selection
												options={[
													{
														key: 'all_model',
														text: 'All models',
														value: 'all',
													},
													...models,
												]}
												onSearchChange={
													handleModelSearchChange
												}
												{...(vehicleFilter.model
													? {
															searchQuery: modelSearchQuery,
													  }
													: {})}
												name="model"
												onChange={(e, { value }) => {
													handleChangeFilter(
														'vehicle',
														'model',
														value
													);
													setModelSearchQuery(
														value == 'all'
															? ''
															: e.target
																	.textContent
													);
												}}
											></Dropdown>
										</Form.Field>
										<Form.Field>
											<Form.Field
												control={Select}
												selectOnBlur={false}
												forceSelection={false}
												options={[
													{
														key: 'all_day',
														text: 'All coding days',
														value: 'all',
													},
													...days,
												]}
												className="filter-select vehicle-filter-select"
												icon="angle down"
												defaultValue={'all'}
												name="coding"
												onChange={(e, { value }) =>
													handleChangeFilter(
														'vehicle',
														'coding_day',
														value
													)
												}
											/>
										</Form.Field>
										<Form.Field>
											<Form.Field
												control={Select}
												selectOnBlur={false}
												forceSelection={false}
												options={status}
												className="filter-select vehicle-filter-select"
												icon="angle down"
												defaultValue={'all'}
												name="vehicle"
												onChange={(e, { value }) =>
													handleChangeFilter(
														'vehicle',
														'status',
														value
													)
												}
											/>
										</Form.Field>
									</Form.Group>
								</Grid.Column>
								<Grid.Column
									mobile={16}
									tablet={12}
									computer={6}
								>
									<div
										className="table-list-actions"
										style={{ float: 'right' }}
									>
										<Button
											onClick={() => {
												setBulkUpload(true);
												setPostAction('bulk-add');
											}}
											className="ui button app-primary-button"
										>
											<img
												src={downloadImg}
												className="btn-icon btn-download"
											/>{' '}
											Bulk upload
										</Button>
										<Button
											className="ui button app-primary-button"
											onClick={() => {
												setIndividualForm(true);
												setPostError({});
												setForm({
													...form,
													...vehicleModel,
													id: '',
												});
												setPostAction('add');
											}}
										>
											<img
												src={addIcon}
												className="btn-icon"
											/>{' '}
											Add new vehicle
										</Button>
									</div>
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</Form>
				</div>
				<Table
					headers={header}
					datas={vehicles_data}
					page={paginate.page}
					totalRows={vehicles.total_vehicles}
					rowsPerPage={10}
					onPageChange={onPageChange}
				/>
			</div>
			{/* Modal section */}
			<Modal
				onClose={() => {
					setPostError({});
					setIndividualForm(false);
				}}
				open={individualForm}
				className="desks-modal single-first-modal"
				role="modal"
			>
				<Modal.Content>
					<FocusLock disabled={!individualForm} autoFocus={false}>
						<Header as="h3">
							{postAction == 'add'
								? `Add new`
								: postAction == 'edit'
								? `Edit`
								: null}{' '}
							vehicle
						</Header>
						<Form>
							<Form.Group>
								{postError && postError.status == 0 ? (
									<Message
										negative
										className="error-message w-100"
									>
										<img
											src={errorAlert}
											alt="error alert"
										/>
										{postError.message}
									</Message>
								) : null}
							</Form.Group>
							<Form.Group>
								<Form.Field>
									<label>Plate number</label>
									<input
										placeholder="Enter plate number"
										className="custom-driver-input"
										onChange={evt =>
											onChangeValueHandler(
												'plate_number',
												evt.target.value
											)
										}
										value={form.plate_number}
										disabled={postAction == 'edit'}
									/>
								</Form.Field>
								<Form.Field>
									<label>Coding day</label>
									<Form.Select
										style={{ height: 'auto' }}
										icon={{
											name: 'angle down',
											className: 'icon-vehicle-form',
										}}
										placeholder="Select day"
										className="filter-select"
										options={days}
										onChange={(evt, { value }) =>
											onChangeValueHandler(
												'coding_day',
												value
											)
										}
										value={form.coding_day}
										disabled={postAction == 'edit'}
									/>
								</Form.Field>
							</Form.Group>
							<Form.Group>
								<Form.Field>
									<label>Make</label>
									<input
										placeholder="Enter vehicle's make"
										className="custom-driver-input"
										onChange={evt =>
											onChangeValueHandler(
												'make',
												evt.target.value
											)
										}
										value={form.make}
										disabled={postAction == 'edit'}
									/>
								</Form.Field>
							</Form.Group>
							<Form.Group>
								<Form.Field>
									<label>Model</label>
									<input
										placeholder="Enter vehicle's model"
										className="custom-driver-input"
										onChange={evt =>
											onChangeValueHandler(
												'model',
												evt.target.value
											)
										}
										value={form.model}
										disabled={postAction == 'edit'}
									/>
								</Form.Field>
							</Form.Group>
							<Form.Group>
								<Form.Input
									type="number"
									label="No. of capacity"
									placeholder="0"
									width={8}
									onChange={evt =>
										onChangeValueHandler(
											'capacity',
											parseFloat(evt.target.value)
										)
									}
									value={form.capacity}
								/>
							</Form.Group>
							<div className="modal-action">
								<Button
									className="app-primary-button full-button"
									onClick={e => {
										setActionModal(true);
										setIndividualForm(false);
									}}
									disabled={disableSaveButtonHandler()}
								>
									Save
								</Button>
								<Button
									className="app-primary-inverted-button full-button"
									onClick={() => setIndividualForm(false)}
								>
									Cancel
								</Button>
							</div>
						</Form>
					</FocusLock>
				</Modal.Content>
			</Modal>

			{/* Bulk modal form */}
			<Modal
				onClose={() => {
					clearForm();
					setBulkUpload(false);
				}}
				open={bulkUpload}
				className="desks-modal bulk-first-modal"
			>
				<Modal.Content>
					<FocusLock disabled={!bulkUpload} autoFocus={false}>
						<Header as="h3">Bulk upload new vehicles</Header>
						<p style={{ marginBottom: '5px' }}>
							<strong>
								Select file from your computer or drag file to
								attach
							</strong>
							<br />
							<em style={{ color: '#AFAFAF' }}>
								File/s must be less than 3MB of .xlsx or .csv
								file each
							</em>
							<p style={{ marginBottom: '10px' }}>
								<a
									className="btnLink"
									href={`${process.env.REACT_APP_AWS_S3URL}/GAccess_Vehicle_Template.xlsx`}
								>
									Download template here
								</a>
							</p>
						</p>
						<FileUpload id="upload-vehicles" />
						{fileError.some(
							file => file.id === 'upload-vehicles'
						) ? (
							<Message
								negative
								className="file-error-message"
								size="tiny"
							>
								{
									fileError.filter(
										file => file.id === 'upload-vehicles'
									)[0].fileError
								}
							</Message>
						) : null}
						<div className="modal-action">
							<Button
								className="app-primary-button full-button"
								disabled={
									fileData.length <= 0 ||
									fileError.length > 0 ||
									(parsedDatas && parsedDatas.length <= 0) ||
									(parsedDatas['upload-vehicles'] &&
										parsedDatas['upload-vehicles'].length <=
											0)
								}
								onClick={() => {
									if (postAction !== 'edit') {
										setRegularSort(false);
									}
									setActionModal(true);
									setBulkUpload(false);
								}}
							>
								Save
							</Button>
							<Button
								className="app-primary-inverted-button full-button"
								onClick={() => {
									clearForm();
									setBulkUpload(false);
								}}
							>
								Cancel
							</Button>
						</div>
					</FocusLock>
				</Modal.Content>
			</Modal>

			{/* Single post prompt modal */}
			<Modal
				closeOnEscape={false}
				closeOnDimmerClick={false}
				onClose={() => setActionModal(false)}
				onOpen={() => setActionModal(true)}
				open={actionModal}
				className="employee-form-modal"
				style={{ maxWidth: '515px' }}
			>
				<Modal.Content>
					<FocusLock disabled={!actionModal} autoFocus={false}>
						<img
							src={`${process.env.REACT_APP_BASE_URL}/images/question-logo.png`}
						/>
						<Header as="h3">
							{postAction == 'add' || postAction == 'bulk-add'
								? `Save new vehicle/s?`
								: `Save changes?`}
						</Header>
						<p className="modal-sub-header custom-sentence-break">
							Are you sure you want to{' '}
							{postAction == 'add' || postAction == 'bulk-add'
								? `save and add the new vehicle to the list?`
								: 'save the changes made to the vehicle details?'}
						</p>
						<div className="modal-action">
							<Button
								onClick={() => {
									if (postAction !== 'edit') {
										setRegularSort(false);
									}

									if (postAction == 'bulk-add') {
										onClickBulkProceed();
									} else {
										onClickProceed();
									}
								}}
								className="app-primary-button custom-full-button"
							>
								Yes, proceed
							</Button>
							<Button
								className="app-primary-inverted-button custom-full-button"
								onClick={() => {
									onClickCancel();
								}}
							>
								Cancel and go back
							</Button>
						</div>
					</FocusLock>
				</Modal.Content>
			</Modal>

			{/* Success prompt for adding */}
			<Modal
				onClose={() => setConfirmationModal(false)}
				open={confirmationModal}
				className="desks-modal bulk-completion-modal"
			>
				<Modal.Content>
					<div className="bulk-completion-modal-icon">
						<img src={successImg} alt="success" />
						<Header as="h3">
							{postAction == 'add' || postAction == 'bulk-add'
								? `New vehicle added`
								: 'Changes saved!'}
						</Header>
						<p>
							{postAction == 'add' || postAction == 'bulk-add'
								? `You may now assign trips to the new vehicle`
								: 'Changes made to the vehicle details were saved successfully'}
						</p>
					</div>
				</Modal.Content>
			</Modal>

			{/* Delete driver prompt */}

			<Modal
				closeOnEscape={false}
				closeOnDimmerClick={false}
				onClose={() => setDeleteConfirmModal(false)}
				onOpen={() => setDeleteConfirmModal(true)}
				open={deleteConfirmModal}
				className="employee-form-modal"
				style={{ maxWidth: '515px' }}
			>
				<Modal.Content>
					<FocusLock disabled={!actionModal} autoFocus={false}>
						<img
							src={`${process.env.REACT_APP_BASE_URL}/images/question-logo.png`}
						/>
						<Header as="h3">
							{/* {postAction == 'add' || postAction == 'bulk-add'
								? `Save new driver/s?`
								: 'Save changes?'} */}
							Delete vehicle?
						</Header>
						<p className="modal-sub-header custom-sentence-break">
							Are you sure you want to delete vehicle with the
							plate number {selectedVehicle.plate_number} from the
							list of shuttle vehicles?
							{/* {postAction == 'add' || postAction == 'bulk-add'
								? `save and add the new driver to the list?`
								: 'save the changes made to the driver details?'} */}
						</p>
						<div className="modal-action">
							<Button
								onClick={event => {
									onVehicleDisabled(event, selectedVehicle);
								}}
								className="app-primary-button custom-full-button"
							>
								Yes, proceed
							</Button>
							<Button
								className="app-primary-inverted-button custom-full-button"
								onClick={() => {
									// onClickCancel();
									setDeleteConfirmModal(false);
								}}
							>
								Cancel
							</Button>
						</div>
					</FocusLock>
				</Modal.Content>
			</Modal>

			{/* Successsful deletion of driver prompt */}

			<Modal
				onClose={() => setSuccessfulDeletionModal(false)}
				open={successfulDeletionModal}
				className="desks-modal bulk-completion-modal"
			>
				<Modal.Content>
					<div className="bulk-completion-modal-icon">
						<img src={successImg} alt="success" />
						<Header as="h3">
							{/* {postAction == 'add' || postAction == 'bulk-add'
								? `New driver added`
								: 'Changes saved!'} */}
							Vehicle deleted!
						</Header>
						<p>
							{/* {postAction == 'add' || postAction == 'bulk-add'
								? `You may now assign trips to the new driver`
								: 'Changes made to the driver details wered saved successfully'} */}
							You have successfully deleted the vehicle with the
							plate number {selectedVehicle.plate_number} from the
							list of shuttle vehicles
						</p>
					</div>
				</Modal.Content>
			</Modal>

			{/* On change status confirmation prompt */}

			<Modal
				closeOnEscape={false}
				closeOnDimmerClick={false}
				onClose={() => setChangeStatusModal(false)}
				onOpen={() => setChangeStatusModal(true)}
				open={changeStatusModal}
				className="employee-form-modal"
				style={{ maxWidth: '515px' }}
			>
				<Modal.Content>
					<FocusLock disabled={!changeStatusModal} autoFocus={false}>
						<img
							src={`${process.env.REACT_APP_BASE_URL}/images/question-logo.png`}
						/>
						<Header as="h3">Save Changes?</Header>
						<p className="modal-sub-header custom-sentence-break">
							Are you sure you want to save the changes made to
							the vehicle details?
						</p>
						<div className="modal-action">
							<Button
								onClick={event => {
									onPatchVehicleStatus(selectedVehicle);
								}}
								className="app-primary-button custom-full-button"
							>
								Yes, proceed
							</Button>
							<Button
								className="app-primary-inverted-button custom-full-button"
								onClick={() => {
									setChangeStatusModal(false);
								}}
							>
								Cancel
							</Button>
						</div>
					</FocusLock>
				</Modal.Content>
			</Modal>

			{/* Successsful deletion of vehicle prompt */}

			<Modal
				onClose={() => setSuccessChangeStatusModal(false)}
				open={successChangeStatusModal}
				className="desks-modal bulk-completion-modal"
			>
				<Modal.Content>
					<div className="bulk-completion-modal-icon">
						<img src={successImg} alt="success" />
						<Header as="h3">Changes saved!</Header>
						<p>
							Changes made to the vehicle details were saved
							successfully
						</p>
					</div>
				</Modal.Content>
			</Modal>

			{/* Successsful deletion of vehicle prompt */}

			<Modal
				onClose={() => setConflictTripModal(false)}
				open={conflictTripModal}
				className="desks-modal bulk-completion-modal"
			>
				<Modal.Content>
					<div className="bulk-completion-modal-icon">
						<img src={successImg} alt="success" />
						<Header as="h3">Changes did not saved!</Header>
						<p>
							Action will affect connected trip schedules. <br />{' '}
							Please unassign this vehicle first.
						</p>
						<div className="modal-action">
							<Button
								className="app-primary-button custom-full-button"
								onClick={evt => {
									onTabChange(evt, { activeIndex: 1 });
								}}
							>
								Go to trip scheduler
							</Button>
							<Button
								className="app-primary-inverted-button custom-full-button"
								onClick={() => {
									setConflictTripModal(false);
								}}
							>
								Cancel
							</Button>
						</div>
					</div>
				</Modal.Content>
			</Modal>
		</div>
	);
};

Vehicles.propTypes = {
	props: PropTypes.any,
	vehicles: PropTypes.object,
	getVehicles: PropTypes.func,
	paginate: PropTypes.object,
	onPageChange: PropTypes.func,
	vehicleFilter: PropTypes.object,
	handleChangeFilter: PropTypes.func,
	location: PropTypes.any,
	history: PropTypes.any,
	match: PropTypes.any,
	regularSort: PropTypes.bool,
	setRegularSort: PropTypes.func,
	parsedDatas: PropTypes.array,
	clearForm: PropTypes.func,
	makes: PropTypes.array,
	models: PropTypes.array,
	getDistinctMake: PropTypes.func,
	getDistinctModel: PropTypes.func,
	makeLoading: PropTypes.bool,
	onFilterMakesDropdown: PropTypes.func,
	setSearchMakeString: PropTypes.func,
	modelLoading: PropTypes.bool,
	onFilterModelsDropdown: PropTypes.func,
	setSearchModelString: PropTypes.func,
	setPaginate: PropTypes.func,
	onTabChange: PropTypes.func,
};

export default Vehicles;

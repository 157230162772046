import PropTypes from 'prop-types';
import React, { createContext, useState, useContext, useEffect } from 'react';

import LoaderContext from '../LoaderContext/LoaderContext';

const ImageContext = createContext({});

const dataURLToBlob = dataURL => {
	var BASE64_MARKER = ';base64,';
	let parts;
	let contentType;
	let raw;
	if (dataURL.indexOf(BASE64_MARKER) == -1) {
		parts = dataURL.split(',');
		contentType = parts[0].split(':')[1];
		raw = parts[1];
		return new Blob([raw], { type: contentType });
	}
	parts = dataURL.split(BASE64_MARKER);
	contentType = parts[0].split(':')[1];
	raw = window.atob(parts[1]);
	let rawLength = raw.length;
	let uInt8Array = new Uint8Array(rawLength);
	for (let i = 0; i < rawLength; ++i) {
		uInt8Array[i] = raw.charCodeAt(i);
	}
	return new Blob([uInt8Array], { type: contentType });
};

export const ImageProvider = ({ children }) => {
	const { showLoading, hideLoading } = useContext(LoaderContext);
	const [fileData, setFileData] = useState([]);
	const [imageFile, setImageFile] = useState(null);
	const [fileError, setFileError] = useState(null);
	const [imageSrc, setImageSrc] = useState(null);

	// file reader
	const reader = new FileReader();

	useEffect(() => {
		if (!setImageFile) {
			setImageFile(null);
		}
	}, [imageFile]);

	const imageHandler = file => {
		reader.readAsDataURL(file);
		reader.onload = evt => {
			var image = new Image();
			image.src = evt.target.result;
			setImageSrc(image.src);
			image.onload = imageEvt => {
				if (image.width != 250 || image.height != 250) {
					/* Update state */
					setImageSrc(null);
					setFileError('Image dimension must be 250x250 pixels');
				} else {
					setFileError(null);
				}
			};

			setImageFile(file);
			hideLoading();
		};
	};

	return (
		<ImageContext.Provider
			value={{
				imageHandler,
				fileData,
				imageFile,
				setImageFile,
				fileError,
				setFileError,
				imageSrc,
				setImageSrc,
			}}
		>
			{children}
		</ImageContext.Provider>
	);
};

ImageProvider.propTypes = {
	children: PropTypes.any,
};

export default ImageContext;

// react lib
import React, { useContext } from 'react';

// components
import FileUpload from '../../../../components/FileUpload/FileUpload';

// less (styling)
import './EmployeeList.css';

// semantic ui component
import { Grid, Header, List, Message } from 'semantic-ui-react';

import ParserContext from '../../../../context/ParserContext/ParserContext';

import PropTypes from 'prop-types';

const EmployeeList = () => {
	const { fileError } = useContext(ParserContext);
	return (
		<div>
			<Grid centered reversed="computer">
				<Grid.Column mobile="sixteen" tablet="fourteen" computer="six">
					<div className="page-guidelines">
						<Header as="h3">
							<img
								src={`${process.env.REACT_APP_BASE_URL}/images/idea.svg`}
								className="idea-img"
							/>{' '}
							<span className="guidelines-txt">Guidelines</span>
						</Header>
						<List bulleted>
							<List.Item>
								&apos;Employee list&apos; is a list of everyone
								from your company who will use the G Access app
							</List.Item>
							<List.Item>
								An Employee list template was sent to your
								email. In case you&apos;ve missed it, you may{' '}
								<a
									href={`${process.env.REACT_APP_AWS_S3URL}/GAccess_EmployeeList_Template.xlsx`}
									className="btnLink"
								>
									click here to download
								</a>
							</List.Item>
						</List>
					</div>
				</Grid.Column>
				<Grid.Column mobile="sixteen" tablet="fourteen" computer="ten">
					<div className="step-container employee-list">
						<di className="mb-4 mt-2 d-block">
							<Header as="h3">Employee List</Header>
						</di>
						<div className="employee-list-note">
							<div className="mb-1">
								Upload list of your employees
							</div>
							<div className="grey-text mb-1">
								Missed the employee list template we shared?
							</div>
							<a
								className="btnLink mb-1"
								href={`${process.env.REACT_APP_AWS_S3URL}/GAccess_EmployeeList_Template.xlsx`}
							>
								Download template here
							</a>
						</div>
						<Grid left>
							<Grid.Column
								mobile="sixteen"
								tablet="fourteen"
								computer="seven"
							>
								<FileUpload id="employees" />
								{fileError.some(
									file => file.id === 'employees'
								) ? (
									<Message
										negative
										className="file-error-message"
										size="tiny"
									>
										{
											fileError.filter(
												file => file.id === 'employees'
											)[0].fileError
										}
									</Message>
								) : (
									<div className="fileupload-info">
										<div>
											<i>- File must be less than 3MB</i>
										</div>
										<div>
											<i>
												- CSV file or any Excel/Google
												sheet
											</i>
										</div>
									</div>
								)}
							</Grid.Column>
						</Grid>
					</div>
				</Grid.Column>
			</Grid>
		</div>
	);
};

EmployeeList.propTypes = {
	lineError: PropTypes.string,
};

export default EmployeeList;

// react library
import React, { useContext, Suspense } from 'react';

// react router library
import {
	BrowserRouter as Router,
	Route,
	Switch,
	Redirect,
} from 'react-router-dom';

//semantic ui
import 'semantic-ui-css/semantic.min.css';

// styles
import './App.css';

// pages
import ConfirmEmail from '../pages/Login/ConfirmEmail/ConfirmEmail';
import SetUpPassword from '../pages/Login/SetUpPassword/SetUpPassword';
import Login from '../pages/Login/Login';
import ForgotPassword from '../pages/Login/ForgotPassword/ForgotPassword';
import VerificationCode from '../pages/Login/ForgotPassword/VerificationCode';
import SetupNewPassword from '../pages/Login/ForgotPassword/SetupNewPassword';
import Welcome from '../pages/AccountSetUp/Welcome';
// import CompanyDashboard from '../pages/CompanyDashboard/CompanyDashboard';
// import ServiceDashboard from '../pages/ServiceDashboard/ServiceDashboard';
import WorkDesk from '../pages/ServiceDashboard/WorkDesk/WorkDesk';
import ServiceDashboardPerSite from '../pages/ServiceDashboard/ServiceDashboardPerSite/ServiceDashboardPerSite';
import ReportItem from '../pages/ServiceDashboard/ServiceDashboardPerSite/Reports/ReportItem';
import EmployeeList from '../pages/CompanyDashboard/EmployeeList/EmployeeList';
import NewEmployee from '../pages/CompanyDashboard/EmployeeList/NewEmployee';
import ViewEmployee from '../pages/CompanyDashboard/EmployeeList/ViewEmployee';
import ServiceSetup from '../pages/ServiceDashboard/ServiceSetup/ServiceSetup';
import AccountSetup from '../pages/AccountSetUp/AccountSetUp/AccountSetup';
// import DummyPage from '../pages/DummyPage/DummyPage';
import CompanySite from '../pages/CompanyDashboard/CompanySite/CompanySite';
import CompanySiteDetails from '../pages/CompanyDashboard/CompanySiteDetails/CompanySiteDetails';
import AddNewService from '../pages/CompanyDashboard/AddNewService/AddNewService';
import BulkUpload from '../pages/CompanyDashboard/EmployeeList/Bulk-upload/Bulk-upload';
import Page404 from '../pages/Page404/Page404';
import AddNewCompanySite from '../pages/CompanyDashboard/CompanySite/NewCompanySite/NewCompanySite';
// import SampleServiceAdminPage from '../pages/SampleServiceAdminPage/SampleServiceAdminPage';

// global contexts
import { LoaderProvider } from '../context/LoaderContext/LoaderContext';
import { ParserProvider } from '../context/ParserContext/ParserContext';
import { ImageProvider } from '../context/ImageContext/ImageContext';
import AuthContext from '../context/AuthContext/AuthContext';

// components
import GLoader from '../components/Loader/Loader';
import ProtectedRoute from '../components/ProtectedRoute/ProtectedRoute';

const EditService = React.lazy(() =>
	import('../pages/CompanyDashboard/EditService/EditService')
);

const App = () => {
	let routes;
	const { isAuthenticating, user } = useContext(AuthContext);

	if (isAuthenticating) {
		return <GLoader />;
	}

	routes = (
		<Router basename={process.env.REACT_APP_BASE_NAME}>
			<Switch>
				<Route exact path="/" component={Login}></Route>
				<Route
					path="/confirm-email/:encryptedEmail"
					component={ConfirmEmail}
				/>
				{/* <Route path="/dummy" component={DummyPage} /> */}
				<Route
					exact
					path="/setup-password/:encryptedEmail/:encryptedCompanyCode"
					component={SetUpPassword}
				/>
				<Route
					exact
					path="/forgot-password"
					component={ForgotPassword}
				/>
				<Route
					exact
					path="/verification-code/:encryptedEmail"
					component={VerificationCode}
				/>
				<Route
					exact
					path="/setup-new-password/:encryptedEmail/:encryptedCode"
					component={SetupNewPassword}
				/>
				<ProtectedRoute
					exact
					path="/welcome"
					component={Welcome}
					viewFor="ca|sa"
				/>
				<ProtectedRoute
					exact
					path="/account-setup"
					component={AccountSetup}
					viewFor="ca"
				/>
				<ProtectedRoute
					exact
					path="/serviceadministrator"
					component={WorkDesk}
					viewFor="sa"
					allow={true}
				/>
				<ProtectedRoute
					exact
					path="/service-dashboard-per-site"
					component={ServiceDashboardPerSite}
					viewFor="sa"
					allow={true}
				/>
				<ProtectedRoute
					exact
					path="/report-item/:encryptedId"
					component={ReportItem}
					viewFor="sa"
					allow={true}
				/>
				<ProtectedRoute
					exact
					path="/employeelist"
					component={EmployeeList}
					viewFor="ca"
					allow={true}
				/>
				<ProtectedRoute
					exact
					path="/employeelist/bulk-upload"
					component={BulkUpload}
					viewFor="ca"
					allow={true}
				/>
				<ProtectedRoute
					exact
					path="/add-new-employee"
					component={NewEmployee}
					viewFor="ca"
					allow={true}
				/>
				<ProtectedRoute
					exact
					path="/view-employee"
					component={ViewEmployee}
					viewFor="ca"
					allow={true}
				/>
				<ProtectedRoute
					exact
					path="/setupservice"
					component={ServiceSetup}
					viewFor="sa"
				/>
				<ProtectedRoute
					exact
					path="/companysite"
					component={CompanySite}
					viewFor="ca"
				/>
				<ProtectedRoute
					exact
					path="/companysite-details/:encryptedId"
					component={CompanySiteDetails}
					viewFor="ca"
				/>
				<ProtectedRoute
					exact
					path="/new-service/:sites_id"
					component={AddNewService}
					viewFor="ca"
				/>
				<ProtectedRoute
					exact
					path="/new-service/:sites_id"
					component={AddNewService}
					viewFor="ca"
				/>
				<ProtectedRoute
					exact
					path="/add-new-companysite"
					component={AddNewCompanySite}
					viewFor="ca"
				/>
				<ProtectedRoute
					exact
					path="/edit-service/:service_instances_id"
					component={EditService}
					viewFor="ca"
				/>
				{!user && <Redirect to="/" />}
				<Route component={Page404} />
			</Switch>
		</Router>
	);

	return (
		<LoaderProvider>
			<ParserProvider>
				<ImageProvider>
					<Suspense fallback={<GLoader />}>{routes}</Suspense>
				</ImageProvider>
			</ParserProvider>
		</LoaderProvider>
	);
};

export default App;

import React from 'react';
import PropTypes from 'prop-types';
import { Document, Font } from '@react-pdf/renderer';

import styled from '@react-pdf/styled-components';
import FSElliotPro from '../../assets/fonts/FSElliotPro.ttf';
import FSElliotProBold from '../../assets/fonts/FSElliotPro-Bold.ttf';
import { wordEllipsis } from '../../utils';

const inAppLogo =
	process.env.REACT_APP_BASE_NAME + 'images/GAccess-inApp@2x.png';

const Body = styled.Page`
	flex-direction: row;
	display: flex;
	flex-wrap: wrap;
	margin: 0 32px;
`;

const PageView = styled.View`
	width: 370px;
	height: 390px;
	background: #0064d8 0% 0% no-repeat padding-box;
	opacity: 1;
	text-align: center;
	justify-content: center;
	padding: 24px;
	top: 0;
	bottom: 0;
	margin: auto 8px;
`;

const Picture = styled.Image`
	width: 103px;
	height: 32px;
	display: block;
	align-self: center;
`;

const Title = styled.Text`
	font-size: 35pt;
	color: white;
	font-weight: 900;
	text-align: center;
	display: block;
	margin: 4px 0;
	font-family: FSElliotProBold;
	line-height: 0;
`;

const Subtitle = styled.Text`
	font-size: 16pt;
	margin: 4px 0 12px 0;
	color: white;
	text-align: center;
	display: block;
	font-family: FSElliotPro;
`;

const QRBody = styled.View`
	width: 200px;
	height: 200px;
	background: #ffffff 0% 0% no-repeat padding-box;
	border: 1px solid #b9b9b9;
	opacity: 1;
	display: block;
	align-self: center;
	margin: 4px 0;
`;

const QRImage = styled.Image`
	object-fit: contain;
`;

// PDF Document component
const PDFQRCode = ({ slots, service }) => {
	return (
		<Document>
			<Body wrap orientation="landscape">
				{slots.map(slot => {
					return (
						<PageView wrap={false} key={slot.id}>
							<Picture src={inAppLogo} />
							<Title>{wordEllipsis(slot.serviceName, 15)}</Title>
							<Subtitle>{service}</Subtitle>
							<QRBody>
								<QRImage src={slot.qr} />
							</QRBody>
						</PageView>
					);
				})}
			</Body>
		</Document>
	);
};

Font.register({
	family: 'FSElliotPro',
	src: FSElliotPro,
	format: 'truetype',
});

Font.register({
	family: 'FSElliotProBold',
	src: FSElliotProBold,
	format: 'truetype',
});

PDFQRCode.propTypes = {
	slots: PropTypes.array,
	service: PropTypes.string,
};

export default PDFQRCode;

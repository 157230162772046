import gql from 'graphql-tag';

export const DELETE_TOKEN = gql`
	mutation($refresh_token: String, $users_id: EncryptedString!) {
		deleteRefreshToken(refresh_token: $refresh_token, users_id: $users_id) {
			message
			status
		}
	}
`;

/**
 * Patch enabling gcheck mutation request body
 */
export const PATCH_GCHECK_IS_ENABLED = gql`
	mutation($enabled: Boolean) {
		patchGcheckIsEnabled(enabled: $enabled) {
			code
			message
		}
	}
`;

import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import AuthContext from '../../context/AuthContext/AuthContext';
import { Route, withRouter } from 'react-router-dom';
import jwt_decode from 'jwt-decode';

import PageForbidden from '../../pages/PageForbidden/PageForbidden';

const ProtectedRoute = ({ component: Component, viewFor, ...params }) => {
	const decodedToken = window.localStorage.getItem(
		process.env.REACT_APP_TOKEN_NAME
	)
		? jwt_decode(
				window.localStorage.getItem(process.env.REACT_APP_TOKEN_NAME)
		  )
		: null;
	// upon login the user context is a decoded user object
	// but when refreshed on a protected page, user is an undecoded jwt
	const { user, setUser, adminRole, setAdminRole, setName } = useContext(
		AuthContext
	);

	// * for temporary fixed
	// ! To be removed later
	const setups = [
		'/companyadministrator',
		'/companysite',
		'/companysite-details/:encryptedId',
		'/new-service/:sites_id',
	];
	// const [tempRole, setTempRole] = useState(null);
	if (!user) {
		return <Redirect to="/" />;
	}

	if (
		adminRole &&
		(adminRole[0] == 0 || !viewFor.includes(adminRole[0].code))
	) {
		return <PageForbidden />;
	}

	// * Temporary fixed
	// ! To be removed later
	// TODO: Fix this permission handler
	if (
		decodedToken &&
		params.path === '/account-setup' &&
		decodedToken.user.account_setup
	) {
		return <Redirect to="/companysite" />;
	}

	// * This is temporary fixed
	// ! To be removed later
	// TODO: Fix this permission handler
	if (
		decodedToken &&
		setups.includes(params.path) &&
		!decodedToken.user.account_setup
	) {
		return <Redirect to="/account-setup" />;
	}

	return (
		<>
			<Route {...params} component={Component} />
		</>
	);
};

export default withRouter(ProtectedRoute);

// react lib
import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';

// grahpql queries
import { useMutation, useQuery } from '@apollo/client';
import { PATCH_HAS_LOGIN } from './graphql/mutation';

import { GET_USER_SERVICETYPES } from '../../components/graphql/query';
import GLoader from '../../components/Loader/Loader';

// less (styling)
import './Welcome.css';

// semantic ui component
import { Grid, Header } from 'semantic-ui-react';

// layout
import AdminMaster from '../../hoc/Layout/Admin/AdminMaster';

import AuthContext from '../../context/AuthContext/AuthContext';

import { encrypt } from '../../utils';
import { ServiceTypes } from '../../models/ServiceTypes';

const Welcome = () => {
	const { user, adminRole, refetchTokenHandler } = useContext(AuthContext);

	const { data: serviceTypes, loading: serviceTypeLoading } = useQuery(
		GET_USER_SERVICETYPES,
		{
			variables: {
				users_id: user.user.id,
			},
		}
	);

	if (user.user.admin_has_logged_in && serviceTypeLoading) {
		return <GLoader />;
	}
	if (user.user.admin_has_logged_in == 1) {
		if (adminRole[0].code == 'pa') {
			return <Redirect to="/sample-home-pa" />; // update to Platform Admin's Home Page
		} else if (adminRole[0].code == 'ca') {
			if (user.user && user.user.account_setup) {
				return <Redirect to="/companysite" />;
			} else {
				return <Redirect to="/account-setup" />;
			}
		} else if (adminRole[0].code == 'sa') {
			localStorage.setItem(
				'service_type',
				encrypt(JSON.stringify(serviceTypes.getUserServiceTypes[0]))
			);
			return <Redirect to={`/serviceadministrator`} />;
		}
	}

	const [patchHasLogin] = useMutation(PATCH_HAS_LOGIN, {
		onError: () => {
		},
		onCompleted: async data => {
			if (data.patchHasLogin.status === 1) {
				await refetchTokenHandler();
				await window.location.reload('/');
			} else if (data.patchHasLogin.status === 0) {

			}
		},
	});

	const onStart = () => {
		patchHasLogin({
			variables: {
				users_id: user.user.id,
			},
		});
	};

	const handleMessage = () => {
		if (adminRole[0].code == 'ca') {
			return (
				<>
					<Header as="h3">
						Let&apos;s get started by setting up your company&apos;s
						account
					</Header>
					<p
						style={{
							padding: '0 5px 0 0',
						}}
					>
						<p className="sub-message">
							Provide basic information about your company, upload
							employee list and customize the app branding
						</p>
					</p>
				</>
			);
		} else if (adminRole[0].code == 'sa') {
			return (
				<>
					<Header as="h3">
						Let&apos;s get started by service set up
					</Header>
					<p style={{ padding: '0 5px 0 0', fontSize: 'initial' }}>
						You are the assigned service administrator of{' '}
						{
							ServiceTypes[
								serviceTypes.getUserServiceTypes[0].name
							].name
						}{' '}
						booking service.
					</p>
				</>
			);
		}
	};

	return (
		<AdminMaster>
			<Grid
				centered
				className="setup-welcome"
				style={{ height: '100vh' }}
			>
				<Grid.Column
					mobile="sixteen"
					tablet="fourteen"
					computer="eight"
					style={{ paddingTop: '50px', alignSelf: 'center' }}
				>
					<div className="card">
						<Grid>
							<Grid.Column
								only="tablet computer"
								width="six"
								className="setup-welcome-accent"
								style={{
									backgroundImage: `url("${process.env.REACT_APP_BASE_URL}/images/welcome-graphic.png")`,
								}}
							></Grid.Column>
							<Grid.Column
								mobile="sixteen"
								tabled="ten"
								computer="ten"
								className="setup-welcome-content"
							>
								<div>
									{serviceTypes ? (
										<>
											<div className="app-logo-container">
												<img
													className="app-logo"
													src={`${process.env.REACT_APP_BASE_URL}/images/app-logo-g-only.png`}
													alt="GAccess"
												/>
											</div>
											{handleMessage()}
											<a
												className="ui button app-primary-button medium-button"
												onClick={() => onStart()}
											>
												Start
											</a>
										</>
									) : null}
								</div>
							</Grid.Column>
						</Grid>
					</div>
				</Grid.Column>
			</Grid>
		</AdminMaster>
	);
};

export default Welcome;
